<template>
  <div class='what_is'>
    <div class="c_banner_1_box">

      <div class="c_b_1_con">
        <img v-class="'pc b'" src="../assets/img/what/banner_1_icon.png" alt="">
        <div class="info" v-class="'info b'">
          <p class="title">{{$t("What.banner_top.title")}}</p>
          <p>{{$t("What.banner_top.info")}}

          </p>
        </div>
        <img v-class="'m b'" src="../assets/img/what/banner_1_icon.png" alt="">

      </div>

    </div>
    <div class="c_banner_2_box">
      <div class="c_b_cont_box">
        <BigTile />
        <p class="title" v-class="'title b'">{{$t("What.banner_2.title")}}</p>
        <p class="info" v-class="'info b'">{{$t("What.banner_2.info")}}</p>
        <ul class="scene" v-class="'scene b'">
          <li class="v-hide" v-class="'info b'">
            <img src="../assets/img/what/c_icon_1.png" alt="">
            <p>{{$t("What.banner_2.list_info_1")}}</p>
          </li>
          <li class="v-hide" v-class="'info delay-1'">
            <img src="../assets/img/what/c_icon_2.png" alt="">
            <p>{{$t("What.banner_2.list_info_2")}}</p>
          </li>
          <li class="v-hide" v-class="'info delay-2'">
            <img src="../assets/img/what/c_icon_3.png" alt="">
            <p>{{$t("What.banner_2.list_info_3")}}</p>
          </li>
          <li class="v-hide" v-class="'info delay-3'">
            <img src="../assets/img/what/c_icon_4.png" alt="">
            <p>{{$t("What.banner_2.list_info_4")}}</p>
          </li>
          <li class="v-hide" v-class="'info delay-4'">
            <img src="../assets/img/what/c_icon_5.png" alt="">
            <p>{{$t("What.banner_2.list_info_5")}}</p>
          </li>
          <li class="v-hide" v-class="'info delay-5'">
            <img src="../assets/img/what/c_icon_6.png" alt="">
            <p>{{$t("What.banner_2.list_info_6")}}</p>
          </li>
        </ul>
      </div>
      <div class="c_b_cont_box">
        <BigTile />
        <p class="title" v-class="'title b'">{{$t("What.banner_3.title")}}</p>

        <ul class="ex">
          <li v-class="'b'">
            <a href="https://bittok.io/"> <img src="../assets/img/what/ex_icon_1.png" alt=""></a>
          </li>
          <li v-class="'b'">
            <a href="https://upex.io/trade/BCHC_USDT"> <img src="../assets/img/what/ex_icon_2.png" alt=""></a>
          </li>
          <li v-class="'b'">
            <a href="https://coinsbit.io/trade"> <img src="../assets/img/what/ex_icon_3.png" alt=""></a>
          </li>
          <li v-class="'b'">
            <a href="https://poloniex.com/exchange/USDT_BCHC"><img src="../assets/img/what/ex_icon_4.png" alt=""></a>
          </li>
          <li v-class="'b'">
            <a href="https://btex.me/trade/BCHC_USDT"><img src="../assets/img/what/ex_icon_5.png" alt=""></a>
          </li>

        </ul>
      </div>
      <div class="c_b_cont_box">

        <p class="title" v-class="'title b'">{{$t("What.banner_4.title")}}</p>

        <ul class="ex">
          <li v-class="'b'">
            <a href="https://www.cherrywallet.io/"> <img src="../assets/img/what/w_icon_1.png" alt=""></a>

          </li>
          <li v-class="'b'">
            <a href="https://bitpay.com/"><img src="../assets/img/what/w_icon_2.png" alt=""></a>

          </li>
          <li v-class="'b'">
            <a href="https://token.im/"><img src="../assets/img/what/w_icon_3.png" alt=""></a>

          </li>
          <li v-class="'b'">
            <a href="https://trustwallet.com/"><img src="../assets/img/what/w_icon_4.png" alt=""></a>

          </li>
          <li v-class="'b'">
            <a href="https://www.tokenpocket.pro/en/"><img src="../assets/img/what/w_icon_5.png" alt=""></a>

          </li>
          <li v-class="'b'">
            <a href="https://www.myetherwallet.com/"><img src="../assets/img/what/w_icon_6.png" alt=""></a>

          </li>
          <li v-class="'b'">
            <a href="https://mathwallet.org/en-us/"> <img src="../assets/img/what/w_icon_7.png" alt=""></a>

          </li>

        </ul>
      </div>
      <div class="c_b_cont_box">

        <p class="title" v-class="'title b'">{{$t("What.banner_5.title")}}</p>

        <ul class="ex">
          <li v-class="'b'">
            <a href="https://coinranking.com/coin/9oew_ZYm+bitcherry-bchc"><img src="../assets/img/what/d_icon_1.png" alt=""></a>

          </li>
          <li v-class="'b'">

            <a href="https://www.worldcoinindex.com/coin/bitcherry"> <img src="../assets/img/what/d_icon_2.png" alt=""></a>

          </li>
          <li v-class="'b'">
            <a href="https://www.cryptocompare.com/coins/bchc/overview"> <img src="../assets/img/what/d_icon_3.png" alt=""></a>
          </li>
          <li v-class="'b'">

            <a href="https://coinpaprika.com/coin/bchc-bitcherry/"> <img src="../assets/img/what/d_icon_4.png" alt=""></a>
          </li>
          <li v-class="'b'">

            <a href="https://coinlib.io/coin/BCHC/BitCherry"> <img src="../assets/img/what/d_icon_5.png" alt=""></a>
          </li>
          <li v-class="'b'">

            <a href="https://coincodex.com/crypto/bitcherry/"> <img src="../assets/img/what/d_icon_6.png" alt=""></a>
          </li>
          <li v-class="'b'">

            <a href="https://neironix.io/cryptocurrency/bitcherry"> <img src="../assets/img/what/d_icon_7.png" alt=""></a>
          </li>
          <li v-class="'b'">

            <a href="https://www.coingecko.com/en/coins/bitcherry"> <img src="../assets/img/what/d_icon_8.png" alt=""></a>
          </li>
          <li v-class="'b'">

            <a href="https://coindar.org/en/coin/bitcherry"> <img src="../assets/img/what/d_icon_9.png" alt=""></a>
          </li>
          <li v-class="'b'">

            <a href="https://coinmarketcap.com/ru/currencies/bitcherry/markets"> <img src="../assets/img/what/d_icon_10.png" alt=""></a>
          </li>

        </ul>
      </div>

    </div>
  </div>
</template>

<script>
import BigTile from '../components/BigTitle'
export default {
  components: { BigTile },
  name: '',
  data () {
    return {

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.b {
  animation: slideInUp 1s 0s;
}
.v-hide {
  visibility: hidden;
}
.delay-1 {
  animation: slideInUp 1s 0.1s;
  opacity: 1;
  transition-delay: 0.1s;
  visibility: visible;
}
.delay-2 {
  animation: slideInUp 1s 0.2s;
  opacity: 1;
  transition-delay: 0.2s;
  visibility: visible;
}
.delay-3 {
  animation: slideInUp 1s 0.3s;
  opacity: 1;
  transition-delay: 0.3s;
  visibility: visible;
}
.delay-4 {
  animation: slideInUp 1s 0.4s;
  opacity: 1;
  transition-delay: 0.4s;
  visibility: visible;
}
.delay-5 {
  animation: slideInUp 1s 0.5s;
  opacity: 1;
  transition-delay: 0.5s;
  visibility: visible;
}
.delay-6 {
  animation: slideInUp 1s 0.6s;
}
.what_is {
  width: 100%;
  min-height: 100vh;
  .c_banner_1_box {
    width: 100%;
    min-height: 692px;
    background: #000000;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: flex-end;
    .c_b_1_con {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      align-content: flex-end;
      align-items: center;
      text-align: right;
      .info {
        width: 55%;
        p.title {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 45px;
          padding-bottom: 55px;
        }
        p {
          font-size: 18px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 100;
          color: #ffffff;
          line-height: 34px;
        }
      }
      img {
        width: 447px;
      }
      img.m {
        display: none;
      }
    }
  }
  .c_banner_2_box {
    width: 100%;
    min-height: 36vw;
    background: #ffffff;
    display: flex;
    justify-content: space-around;
    padding: 130px 0;
    flex-wrap: wrap;
    .c_b_cont_box {
      width: 1200px;
      text-align: left;
      p.title {
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 50px;
        padding: 50px 0;
      }
      p.info {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 38px;
        width: 50%;
      }
      .scene {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-top: 110px;
        li {
          width: 30%;
          text-align: center;
          padding-bottom: 110px;
          p {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 25px;
          }
        }
      }
      .ex {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-top: 110px;
        li {
          width: 24%;
          text-align: center;
          padding-bottom: 100px;
          padding-right: 1%;
          img {
            width: 100%;
          }
          img:hover {
            box-shadow: #c1c1c1 0px 0px 6px 2px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .what_is {
    width: 100%;
    min-height: 100vh;
    .c_banner_1_box {
      .c_b_1_con {
        width: 1000px;
      }
    }
    .c_banner_2_box {
      width: 100%;
      min-height: 36vw;
      background: #ffffff;
      display: flex;
      justify-content: space-around;
      padding: 130px 0;
      flex-wrap: wrap;
      .c_b_cont_box {
        width: 1000px;
        text-align: left;
        p.title {
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 50px;
          padding: 50px 0;
        }
        p.info {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 38px;
          width: 50%;
        }
        .scene {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          padding-top: 110px;
          li {
            width: 30%;
            text-align: center;
            padding-bottom: 110px;
            p {
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .what_is {
    width: 100%;
    min-height: 100vh;
    margin-top: 60px;
    .c_banner_1_box {
      width: 100%;
      min-height: 692px;
      background: #000000;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-content: flex-end;
      .c_b_1_con {
        width: 88%;
        display: flex;
        justify-content: space-between;
        align-content: flex-end;
        align-items: center;
        text-align: center;
        flex-wrap: wrap;
        .info {
          width: 100%;
          padding: 50px 0;

          p.title {
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 45px;
            padding-bottom: 25px;
          }
          p {
            font-size: 12px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 100;
            color: #ffffff;
            line-height: 24px;
          }
        }
        img {
          width: 88%;
          flex-shrink: 0;
        }
        img.pc {
          display: none;
        }
        img.m {
          display: block;
        }
      }
    }
    .c_banner_2_box {
      width: 100%;
      min-height: 36vw;
      background: #ffffff;
      display: flex;
      justify-content: space-around;
      padding: 60px 0;
      flex-wrap: wrap;
      .c_b_cont_box {
        width: 88%;
        text-align: left;
        p.title {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 36px;
          padding: 20px 0;
        }
        p.info {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 28px;
          width: 100%;
        }
        .scene {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          padding-top: 55px;
          text-align: center;
          li {
            width: 30%;

            padding-bottom: 55px;
            align-content: space-around;
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            align-items: center;
            p {
              font-size: 14px;
              width: 100%;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 25px;
            }
            img {
              width: 60%;
              margin: 20px 20%;
              flex-shrink: 0;
            }
          }
        }
        .ex {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          padding-top: 55px;
          li {
            width: 24%;
            text-align: center;
            padding-bottom: 50px;
            padding-right: 1%;
            img {
              width: 100%;
            }
            img:hover {
              box-shadow: #c1c1c1 0px 0px 6px 2px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>