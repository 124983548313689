<template>
  <div class='destruction'>
    <div class="c_banner_1_box">
      <div class="c_b_1_con">
        <img v-class="'b'" src="../assets/img/des/banner_1_icon.png" alt="">
        <div class="info" v-class="'info b'">
          <p class="title">{{$t("Destruction.banner_top.title")}}</p>
          <p>{{$t("Destruction.banner_top.info")}}
          </p>
          <button>{{$t("Destruction.banner_top.btn_info")}}</button>
        </div>

      </div>
    </div>
    <div class="c_banner_2_box">
      <div class="c_b_2_cont">
        <BigTile />
        <p v-class="'title b'">
          {{$t("Destruction.banner_2.title")}}
        </p>
        <ul class="notice">
          <li v-for="(item,index) in $t('Destruction.banner_2.list_1')" v-bind:key="index" v-class="'b'">
            <span>{{item.title}}</span>
            <span :class="index>1?'red':'num'">{{item.info}}</span>
          </li>

        </ul>
        <button v-class="'b'">{{$t('Destruction.banner_2.btn_info')}}</button>
      </div>
      <div class="c_b_2_cont">

        <p v-class="'title b'">
          {{$t('Destruction.banner_2.title_2')}}
        </p>
        <ul class="liutong">
          <li v-for="(item,index) in $t('Destruction.banner_2.list_2')" v-bind:key="index" v-class="'b'">
            <span>{{item.title}}</span>

          </li>

        </ul>

      </div>
      <div class="c_b_2_cont">

        <p v-class="'title b'">
          {{$t('Destruction.banner_2.title_3')}}
        </p>
        <ul class="more">
          <li v-class="'title b'">
            <span v-for="(item,index) in $t('Destruction.banner_2.list_title')" v-bind:key="index">{{item}}</span>

          </li>

          <li v-for="(item,index) in $t('Destruction.banner_2.list_3')" v-bind:key="index" v-class="'b'">
            <span>{{item.name}}</span>
            <span class="red">{{item.time}}</span>
            <span>{{item.num}}</span>
            <span class="see">{{item.btn}} <i class="el-icon-arrow-right"></i></span>
          </li>

        </ul>
        <button v-class="'b'">{{$t('Destruction.banner_2.see_more')}} <i class="el-icon-arrow-down"></i></button>
      </div>

    </div>

  </div>
</template>

<script>
import BigTile from '../components/BigTitle'
export default {
  components: { BigTile },
  name: '',
  data () {
    return {

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.b {
  animation: slideInUp 1s 0s;
}
.destruction {
  width: 100%;
  min-height: 100vh;
  .c_banner_1_box {
    width: 100%;
    min-height: 852px;
    background: #000000;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: flex-end;
    .c_b_1_con {
      width: 1200px;
      display: flex;
      justify-content: flex-end;
      align-content: flex-end;
      align-items: center;
      text-align: right;
      position: relative;

      .info {
        width: 45%;
        text-align: right;
        padding-bottom: 120px;
        p.title {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 45px;
          padding-bottom: 55px;
        }
        p {
          font-size: 18px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 100;
          color: #ffffff;
          line-height: 34px;
          padding-bottom: 55px;
        }
        button {
          color: #ffffff;
          width: 208px;
          height: 64px;
          border: 1px solid #ca1425;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          background: transparent;
        }
        button:hover {
          background: #ca1425;
          border: 1px solid #ca1425;
          color: #ffffff;
        }
      }
      img {
        position: absolute;
        width: 969px;
        display: inline;
        flex: 0.5;
        left: -120px;
        bottom: 0;
      }
    }
  }
  .c_banner_2_box {
    width: 100%;
    min-height: 50vh;
    background: #ffffff;
    display: flex;
    justify-content: space-around;
    padding: 100px 0 0 0;
    flex-wrap: wrap;
    .c_b_2_cont {
      width: 1200px;
      padding-bottom: 146px;
      p.title {
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 50px;
        padding: 47px 0;
        text-align: left;
      }
      .notice {
        width: 100%;
        padding: 0 0 100px 0;
        display: flex;
        justify-content: space-around;
        li {
          width: 21%;
          text-align: left;
          span {
            display: inline-block;
            width: 100%;
            padding-bottom: 28px;
            padding-left: 30px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 38px;
          }
          span.num {
            color: #333333;
          }
          span.red {
            color: #ca1425;
          }
        }
      }
      .liutong {
        width: 100%;
        padding: 0 0 100px 0;
        display: flex;
        justify-content: flex-start;
        li {
          width: 21%;
          text-align: left;
          span {
            display: inline-block;
            width: 100%;
            padding-bottom: 28px;
            padding-left: 100px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 38px;
          }
          span.num {
            color: #333333;
          }
          span.red {
            color: #ca1425;
          }
        }
      }
      .more {
        width: 100%;
        padding: 0 0 100px 0;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        li {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 11px 0;
          span {
            display: inline-block;
            width: 25%;
            text-align: left;
            padding: 0 60px;

            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 38px;
          }
          span:last-child {
            width: 25%;
            text-align: right;
          }
          span.see:hover {
            color: #ca1425;
            cursor: pointer;
          }
        }
        li.title {
          span {
            color: #666666;
            background: transparent;
          }
        }
        li:nth-child(2n + 3) {
          background: #ffffff;
        }
      }
      button {
        color: #333333;
        width: 208px;
        height: 64px;
        border: 1px solid #d6d6d6;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        background: transparent;
      }
      button:hover {
        background: #ca1425;
        border: 1px solid #ca1425;
        color: #ffffff;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .destruction {
    width: 100%;
    min-height: 100vh;
    .c_banner_1_box {
      width: 100%;
      min-height: 802px;
      background: #000000;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-content: flex-end;
      .c_b_1_con {
        width: 1000px;
        display: flex;
        justify-content: flex-end;
        align-content: flex-end;
        align-items: center;
        text-align: right;
        position: relative;

        .info {
          width: 45%;
          text-align: right;
          padding-bottom: 120px;
          p.title {
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 45px;
            padding-bottom: 55px;
          }
          p {
            font-size: 18px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 100;
            color: #ffffff;
            line-height: 34px;
            padding-bottom: 55px;
          }
          button {
            color: #ffffff;
            width: 208px;
            height: 64px;
            border: 1px solid #ca1425;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            background: transparent;
          }
          button:hover {
            background: #ca1425;
            border: 1px solid #ca1425;
            color: #ffffff;
          }
        }
        img {
          position: absolute;
          width: 859px;
          display: inline;
          flex: 0.5;
          left: -120px;
          bottom: 0;
        }
      }
    }
    .c_banner_2_box {
      width: 100%;
      min-height: 50vh;
      background: #ffffff;
      display: flex;
      justify-content: space-around;
      padding: 100px 0 0 0;
      flex-wrap: wrap;
      .c_b_2_cont {
        width: 1000px;
        padding-bottom: 146px;
        p.title {
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 50px;
          padding: 42px 0;
          text-align: left;
        }
        .notice {
          width: 100%;
          padding: 0 0 100px 0;
          display: flex;
          justify-content: space-around;
          li {
            width: 21%;
            text-align: left;
            span {
              display: inline-block;
              width: 100%;
              padding-bottom: 28px;
              padding-left: 30px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 38px;
            }
            span.num {
              color: #333333;
            }
            span.red {
              color: #ca1425;
            }
          }
        }
        .liutong {
          width: 100%;
          padding: 0 0 100px 0;
          display: flex;
          justify-content: flex-start;
          li {
            width: 21%;
            text-align: left;
            span {
              display: inline-block;
              width: 100%;
              padding-bottom: 28px;
              padding-left: 100px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 38px;
            }
            span.num {
              color: #333333;
            }
            span.red {
              color: #ca1425;
            }
          }
        }
        .more {
          width: 100%;
          padding: 0 0 100px 0;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          li {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 11px 0;
            span {
              display: inline-block;
              width: 25%;
              text-align: left;
              padding: 0 60px;

              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 38px;
            }
            span:last-child {
              width: 20%;
              text-align: right;
            }
            span.see:hover {
              color: #ca1425;
              cursor: pointer;
            }
          }
          li.title {
            span {
              color: #666666;
              background: transparent;
            }
          }
          li:nth-child(2n + 3) {
            background: #ffffff;
          }
        }
        button {
          color: #333333;
          width: 208px;
          height: 64px;
          border: 1px solid #d6d6d6;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          background: transparent;
        }
        button:hover {
          background: #ca1425;
          border: 1px solid #ca1425;
          color: #ffffff;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .destruction {
    width: 100%;
    min-height: 100vh;
    .c_banner_1_box {
      width: 100%;
      min-height: 652px;
      background: #000000;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-content: flex-end;
      margin-top: 60px;
      .c_b_1_con {
        width: 88%;
        display: flex;
        justify-content: flex-end;
        align-content: flex-end;
        align-items: center;
        text-align: center;
        position: relative;

        .info {
          width: 100%;
          text-align: center;
          padding-bottom: 250px;
          p.title {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 36px;
            padding-bottom: 25px;
            padding-top: 100px;
          }
          p {
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 100;
            color: #ffffff;
            line-height: 28px;
            padding-bottom: 25px;
          }
          button {
            color: #ffffff;
            width: 120px;
            height: 40px;
            border: 1px solid #ca1425;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            background: transparent;
          }
          button:hover {
            background: #ca1425;
            border: 1px solid #ca1425;
            color: #ffffff;
          }
        }
        img {
          position: absolute;
          width: 100%;
          display: inline;
          flex: 1;
          left: 0px;
          bottom: 0;
        }
      }
    }
    .c_banner_2_box {
      width: 100%;
      min-height: 50vh;
      background: #ffffff;
      display: flex;
      justify-content: space-around;
      padding: 50px 0 0 0;
      flex-wrap: wrap;
      .c_b_2_cont {
        width: 88%;
        padding-bottom: 146px;
        p.title {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 36px;
          padding: 23px 0;
          text-align: left;
        }
        .notice {
          width: 100%;
          padding: 0 0 0px 0;
          display: flex;
          justify-content: space-around;
          li {
            width: 21%;
            text-align: left;
            span {
              display: inline-block;
              width: 100%;
              padding-bottom: 18px;
              padding-left: 10px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 24px;
            }
            span.num {
              color: #333333;
            }
            span.red {
              color: #ca1425;
            }
          }
        }
        .liutong {
          width: 100%;
          padding: 0 0 100px 0;
          display: flex;
          justify-content: flex-start;
          li {
            width: 21%;
            text-align: left;
            span {
              display: inline-block;
              width: 100%;
              padding-bottom: 28px;
              padding-left: 100px;
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 38px;
            }
            span.num {
              color: #333333;
            }
            span.red {
              color: #ca1425;
            }
          }
        }
        .more {
          width: 100%;
          padding: 0 0 100px 0;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          li {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 11px 0;
            span {
              display: inline-block;
              width: 25%;
              text-align: left;
              padding: 0 60px;

              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 38px;
            }
            span:last-child {
              width: 20%;
              text-align: right;
            }
            span.see:hover {
              color: #ca1425;
              cursor: pointer;
            }
          }
          li.title {
            span {
              color: #666666;
              background: transparent;
            }
          }
          li:nth-child(2n + 3) {
            background: #ffffff;
          }
        }
        button {
          color: #333333;
          width: 120px;
          height: 40px;
          border: 1px solid #d6d6d6;
          font-size: 12px;
          font-weight: 500;
          cursor: pointer;
          background: transparent;
        }
        button:hover {
          background: #ca1425;
          border: 1px solid #ca1425;
          color: #ffffff;
        }
      }
    }
  }
}
</style>