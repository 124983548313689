<template>
  <div class="home">
    <div class="banner_top_box">
      <!-- <img src="../assets/img/home_bg.jpeg" alt=""> -->
      <div class="content b ">

        {{$t('Home.banner1.info1')}} <span> {{$t('Home.banner1.info2')}}</span> {{$t('Home.banner1.info3')}}
      </div>
      <div class="go_down_box">
        <p>Learn More</p>
        <img src="../assets/img/go_down.gif" alt="" class="go_down ">
      </div>

    </div>
    <div class="home_banner_1">
      <div class="inner_box">
        <div class="content">
          <ul>
            <li v-for="(item,index) in $t('Home.banner2.list')" v-bind:key="index" class="v-hide" v-class="'delay-'+(index+1)">
              <img :src="item.imgsrc" alt="">
              <p class="title">{{item.title}}</p>
              <p v-for="(item1,index1) in item.infos" v-bind:key="index1">
                {{item1}}
              </p>
            </li>

          </ul>
        </div>
      </div>
    </div>
    <div class="home_banner_2">
      <div class="banner_2_content">
        <p class="bigtitle"></p>
        <div class="what">
          <div v-class="'what_name b'">{{$t("Home.banner3.what_list_1.title1")}}<br />
            {{$t("Home.banner3.what_list_1.title2")}}</div>
          <div v-class="'what_info b'">
            {{$t("Home.banner3.what_list_1.info")}}
          </div>
        </div>

        <div class="video_box">
          <video v-show="isEn" controls="">
            <source src="https://www.bitcherry.io/video/videoen.mp4" type="video/mp4">
          </video>
          <video v-show="!isEn" controls="">
            <source src="https://www.bitcherry.io/video/videoch.mp4" type="video/mp4">
          </video>
        </div>

        <div class="what_list">
          <img v-class="'m b'" src="../assets/img/home_banner_2_list_1.png" alt="">
          <div class="what_l_info" v-class="'what_l_info b'">
            <p class=" block_t">{{$t('Home.banner3.what_list_2.bigtitle')}}<br />
              {{$t('Home.banner3.what_list_2.bigtitle1')}}</p>
            <p class="red_t">{{$t('Home.banner3.what_list_2.title1')}}</p>

            <p class="info">{{$t('Home.banner3.what_list_2.info')}}</p>
          </div>
          <img v-class="'pc b'" src="../assets/img/home_banner_2_list_1.png" alt="">
        </div>
        <div class="what_list">
          <img v-class="''" src="../assets/img/home_banner_2_list_2.png" alt="">
          <div class="what_l_info" v-class="'what_l_info b'">

            <p class="red_b_t">{{$t('Home.banner3.what_list_3.title1')}}</p>
            <p class="info">{{$t('Home.banner3.what_list_3.info')}}</p>
          </div>

        </div>
        <div class="what_list">
          <img v-class="'m b'" src="../assets/img/home_banner_2_list_3.png" alt="">
          <div class="what_l_info" v-class="'what_l_info b'">

            <p class="red_b_t">{{$t('Home.banner3.what_list_4.title1')}}</p>
            <p class="info">{{$t('Home.banner3.what_list_4.info')}}</p>
          </div>
          <img v-class="'pc b'" src="../assets/img/home_banner_2_list_3.png" alt="">
        </div>
        <div class="what_list">
          <img v-class="''" src="../assets/img/home_banner_2_list_4.png" alt="">
          <div class="what_l_info" v-class="'what_l_info b'">

            <p class="red_b_t">{{$t('Home.banner3.what_list_5.title1')}}</p>
            <p class="info"> {{$t('Home.banner3.what_list_5.info')}}</p>
          </div>

        </div>

      </div>

    </div>
    <!-- <div class="home_banner_3">
      <div class="home_b_3_c">
        <p class="bigtitle"></p>
        <p class="title" v-class="'title b'">{{$t('Home.home_banner_3.bigtitle')}}</p>
        <ul>

          <li v-class="'b'" v-for="(item,index) in $t('Home.home_banner_3.lists')" v-bind:key="index">
            <span>{{item.title}}</span>
            <span class="num">{{item.val}}</span>
          </li>

        </ul>
        <router-link v-class="'b'" to="/what">{{$t('Home.home_banner_3.more_btn')}}</router-link>
      </div>
    </div> -->
    <div class="home_banner_4">
      <div class="banner_4_content">
        <p class="bigtitle"></p>
        <p class="" v-class="'title b'">{{$t('Home.home_banner_4.bigtitle')}}</p>
        <ul>
          <li class="v-hide" v-for="(item,index) in $t('Media.mediaList')" v-class="index<6?'delay-'+(index+1):'b'" v-bind:key="index" v-show="index<mediashowInd">
            <a :href="item.links">
              <img src="../assets/img/mediacom.jpg" alt="">
              <p class="list_title">{{item.title.slice(0,35)}}...</p>
              <p class="list_info">{{item.infos[0].info.slice(0,85)}}...</p>
              <p class="list_time"></p>
            </a>
          </li>

        </ul>

        <router-link v-class="'btn b'" to="project">{{$t('Home.home_banner_4.more_btn')}} <i class="el-icon-arrow-down"></i></router-link>
        <!-- <button>查看更多 <i class="el-icon-arrow-down"></i></button> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld
  },
  data () {
    return {
      isEn: true,
      mediashowInd: 6,
    };
  },
  mounted () {
    window.addEventListener('scroll', this.windowScroll)

    if (window.localStorage.getItem('lan') == "en_US") {
      this.isEn = true
    } else {
      this.isEn = false
    }
  },
  methods: {
    windowScroll () {

      var domHight = document.body.offsetHeight;
      // 以下写法原生兼容
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      var dd = document.querySelector('.go_down_box')
      if (scrollTop > 50) {


        dd.style.display = "none";
        //document.documentElement.getElementsByClassName('go_down').style.dispaly = "none"
        // document.querySelector('.go_down').style.dispaly = "none"
      } else {
        dd.style.display = "block";
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.b {
  animation: slideInUp 1s 0s;
}
.v-hide {
  visibility: hidden;
}
.delay-1 {
  animation: slideInUp 1s 0.1s;
  opacity: 1;
  transition-delay: 0.1s;
  visibility: visible;
}
.delay-2 {
  animation: slideInUp 1s 0.2s;
  opacity: 1;
  transition-delay: 0.2s;
  visibility: visible;
}
.delay-3 {
  animation: slideInUp 1s 0.3s;
  opacity: 1;
  transition-delay: 0.3s;
  visibility: visible;
}
.delay-4 {
  animation: slideInUp 1s 0.4s;
  opacity: 1;
  transition-delay: 0.4s;
  visibility: visible;
}
.delay-5 {
  animation: slideInUp 1s 0.5s;
  opacity: 1;
  transition-delay: 0.5s;
  visibility: visible;
}
.delay-6 {
  animation: slideInUp 1s 0.6s;
  opacity: 1;
  transition-delay: 0.6s;
  visibility: visible;
}
.yourElement {
  animation-duration: 3s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}
.home {
  width: 100%;
  min-height: 100vh;
  .banner_top_box {
    width: 100%;
    height: 100vh;
    background: url("../assets/img/home_bg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    position: relative;
    .img_bg {
      position: absolute;
      right: calc(50% - 600px);
    }
    .content {
      z-index: 99;
      font-size: 32px;
      font-weight: bold;
      color: #ffffff;
      width: 720px;
      margin-left: calc(50% - 550px);
      margin-top: -100px;
      text-align: left;
      line-height: 82px;
      span {
        font-size: 63px;
      }
    }
    .go_down_box {
      position: absolute;
      left: calc(50% + 500px);
      bottom: 60px;
      width: 100px;
      color: #ffffff;
      p {
        padding-bottom: 1px;
      }
      img.go_down {
        width: 100%;
        //animation: bounce 3s 0s infinite;
      }
    }
  }
  .home_banner_1 {
    width: 100%;
    min-height: 50vh;
    background: #ffffff;

    .inner_box {
      width: 100%;

      min-height: 694px;

      background: url("../assets/img/home_banner_1_bg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      text-align: center;

      .content {
        width: 1200px;
        margin-left: calc(50% - 600px);
        ul {
          width: 100%;
          list-style: none;
          display: flex;
          justify-content: space-between;
          li {
            width: 25%;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            text-align: center;
            p {
              width: 100%;
              // padding: 10px 0;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: #999999;
            }
            p.title {
              color: #333333;
              font-size: 20px;
              font-weight: bold;
              padding: 40px 0;
            }
            img {
              width: 22%;
              margin: 10px 0 20px 0;
            }
          }
        }
      }
    }
  }
  .bigtitle {
    width: 360px;
    height: 10px;
    background: #ca1425;
  }
  .home_banner_2 {
    width: 100%;
    min-height: 50vh;
    background: #ffffff;
    .banner_2_content {
      padding: 100px 0;
      width: 1200px;
      margin-left: calc(50% - 600px);
      text-align: center;
      .what {
        width: 100%;
        color: #333333;
        text-align: left;
        padding: 50px 0;
        display: flex;
        justify-content: flex-start;
        .what_name {
          width: 360px;
          font-size: 36px;
          font-weight: 600;
          line-height: 50px;
        }
        .what_info {
          width: calc(100% - 520px);
          font-size: 18px;
          padding-left: 50px;
          line-height: 38px;
        }
      }
      .video_box {
        width: 1000px;
        margin-left: 90px;
        padding: 5px 10px;
        background: #ffffff;
        video {
          width: 100%;
          border-radius: 10px;
        }
      }
      .what_list {
        display: flex;
        justify-content: space-between;
        padding-top: 150px;
        align-items: center;
        width: 100%;
        .what_l_info {
          width: 460px;
          text-align: left;
          p.block_t {
            font-size: 30px;
            line-height: 54px;
            color: #333333;
            font-weight: 600;
            padding-top: 34px;
          }
          p.red_t {
            font-size: 30px;
            line-height: 54px;
            color: #ca1425;
            padding-bottom: 32px;
          }
          p.red_b_t {
            font-size: 30px;
            line-height: 54px;
            color: #ca1425;
            font-weight: 600;
            padding-bottom: 46px;
            padding-top: 70px;
          }
          p.info {
            font-size: 18px;
            line-height: 38px;
            color: #777777;
          }
        }
        img {
          width: 25%;
        }
        img.m {
          display: none;
        }
      }
    }
  }
  .home_banner_3 {
    width: 100%;
    min-height: 475px;
    background: #000000;
    padding: 110px 0 100px;
    .home_b_3_c {
      width: 1200px;
      margin-left: calc(50% - 600px);
      text-align: center;
      p.title {
        color: #ffffff;
        font-size: 36px;
        padding: 48px 0;
        line-height: 50px;
        font-weight: 600;
        text-align: left;
      }
      ul {
        list-style: none;
        width: 100%;
        padding: 40px 0;
        display: flex;
        justify-content: space-around;
        text-align: left;
        li {
          color: #ffffff;
          font-size: 26px;
          text-align: center;
          line-height: 37px;
          font-weight: 300;
          span {
            display: inline-block;
            width: 100%;
            padding-bottom: 10px;
          }
          span.num {
            font-size: 36px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ca1425;
            line-height: 50px;
          }
        }
      }
      a {
        margin-top: 60px;
        color: #ffffff;
        text-decoration: none;
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
        width: 208px;
        height: 64px;
        line-height: 64px;
        border: 1px solid #ca1425;
      }
      a:hover {
        background: #ca1425;
      }
    }
  }
  .home_banner_4 {
    width: 100%;
    min-height: 50vh;
    background: #ffffff;
    .banner_4_content {
      padding: 100px 0 80px 0;
      width: 1200px;
      margin-left: calc(50% - 600px);
      text-align: center;
      p.title {
        color: #333333;
        font-size: 36px;
        padding: 48px 0;
        line-height: 50px;
        font-weight: 600;
        text-align: left;
      }
      ul {
        list-style: none;
        padding-top: 40px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          padding-bottom: 60px;
          width: 380px;
          color: #333333;
          text-align: left;
          // opacity: 0;
          // transition: all 1s ease;
          // -webkit-animation-timing-function: ease;
          // animation-timing-function: ease;
          a {
            text-decoration: none;
            color: #333333;
            display: inline-block;
            width: 100%;
            p.list_info {
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
            }
            p.list_title {
              font-weight: 500;
              font-size: 24px;
              line-height: 42px;

              padding: 10px 0;
            }
            p.list_time {
              color: #999999;
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
              padding-top: 20px;
            }
            img {
              width: 100%;
            }
          }
          a:hover {
            color: #888888;
            cursor: pointer;
            border: none;
            p.list_title {
              color: #787878;
            }
            p.list_time {
              color: #c8c8c8;
            }
          }
        }
        li:hover {
          color: #888888;
          cursor: pointer;
          p.list_title {
            color: #787878;
          }
          p.list_time {
            color: #c8c8c8;
          }
        }
      }
      a.btn {
        width: 208px;
        display: inline-block;
        height: 64px;
        line-height: 64px;
        border: 1px solid #d6d6d6;
        background: transparent;
        color: #333333;
        text-decoration: none;
      }
      a.btn:hover {
        background: #ca1425;
        color: #ffffff;
        border: 1px solid #ca1425;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .home {
    width: 100%;
    min-height: 100vh;
    .banner_top_box {
      .content {
        font-size: 28px;

        span {
          font-size: 60px;
        }
      }
      .go_down_box {
        position: absolute;
        left: calc(50% + 300px);
      }
    }
    .home_banner_1 {
      width: 100%;
      min-height: 50vh;
      background: #ffffff;

      .inner_box {
        width: 100%;

        min-height: 694px;

        .content {
          width: 1000px;
          margin-left: calc(50% - 500px);
          ul {
            justify-content: space-around;
          }
        }
      }
    }
    .bigtitle {
      width: 360px;
      height: 10px;
      background: #ca1425;
    }
    .home_banner_2 {
      .banner_2_content {
        width: 1000px;
        margin-left: calc(50% - 500px);
        text-align: center;
        .what {
          width: 100%;
          color: #333333;
          text-align: left;
          padding: 50px 0;
          display: flex;
          justify-content: flex-start;
          .what_name {
            width: 360px;
            font-size: 36px;
            font-weight: 600;
            line-height: 50px;
          }
          .what_info {
          }
        }
        .video_box {
          width: 900px;
          margin-left: 50px;
          padding: 5px 10px;
          background: #ffffff;
          video {
            width: 100%;
            border-radius: 10px;
          }
        }
        .what_list {
          display: flex;
          justify-content: space-around;

          .what_l_info {
            width: 460px;
            text-align: left;
            p.block_t {
              font-size: 30px;
              line-height: 54px;
              color: #333333;
              font-weight: 600;
              padding-top: 34px;
            }
            p.red_t {
              font-size: 30px;
              line-height: 54px;
              color: #ca1425;
              padding-bottom: 32px;
            }
            p.red_b_t {
              font-size: 30px;
              line-height: 54px;
              color: #ca1425;
              font-weight: 600;
              padding-bottom: 46px;
              padding-top: 70px;
            }
            p.info {
              font-size: 18px;
              line-height: 38px;
              color: #777777;
            }
          }
          img {
            width: 30%;
          }
        }
      }
    }
    .home_banner_3 {
      width: 100%;
      min-height: 475px;
      background: #000000;
      padding: 110px 0 100px;
      .home_b_3_c {
        width: 1000px;
        margin-left: calc(50% - 500px);
        text-align: center;
      }
    }
    .home_banner_4 {
      width: 100%;
      min-height: 50vh;
      background: #ffffff;
      .banner_4_content {
        padding: 100px 0 25px 0;
        width: 1000px;
        margin-left: calc(50% - 500px);
        text-align: center;
        p.title {
          color: #333333;
          font-size: 36px;
          padding: 48px 0;
          line-height: 50px;
          font-weight: 600;
          text-align: left;
        }
        ul {
          list-style: none;
          padding-top: 40px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            padding-bottom: 60px;
            width: 310px;
            color: #333333;
            text-align: left;
            // opacity: 0;
            // transition: all 1s ease;
            // -webkit-animation-timing-function: ease;
            // animation-timing-function: ease;
            a {
              text-decoration: none;
              color: #333333;
              display: inline-block;
              width: 100%;
              p.list_info {
                font-size: 12px;
                line-height: 22px;
                font-weight: 400;
              }
              p.list_title {
                font-weight: 500;
                font-size: 22px;
                line-height: 42px;

                padding: 10px 0;
              }
              p.list_time {
                color: #999999;
                font-size: 12px;
                line-height: 22px;
                font-weight: 400;
                padding-top: 20px;
              }
              img {
                width: 100%;
              }
            }
          }
          li:hover {
            color: #888888;
            cursor: pointer;
            p.list_title {
              color: #787878;
            }
            p.list_time {
              color: #c8c8c8;
            }
          }
        }
      }
    }
  }
}

@media screen and(max-width: 750px) {
  .home {
    width: 100%;
    min-height: 100vh;
    .banner_top_box {
      width: 100%;
      height: 369px;
      margin-top: 60px;

      background: linear-gradient(45deg, #190006 0%, #3d0000 100%);
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      position: relative;
      .content {
        font-size: 15px;
        font-weight: bold;
        color: #ffffff;
        width: 70%;
        margin-left: calc(15%);
        margin-top: 0px;
        text-align: center;
        line-height: 42px;
        padding-bottom: 60px;
        span {
          font-size: 15px;
        }
      }
      .go_down_box {
        position: absolute;
        left: calc(50% - 40px);
        bottom: 10px;
        width: 80px;
        color: #ffffff;
        font-size: 12px;
        p {
          padding-bottom: 1px;
        }
        img.go_down {
          width: 100%;
          //animation: bounce 3s 0s infinite;
        }
      }
    }
    .home_banner_1 {
      width: 100%;
      min-height: 35vh;
      background: #ffffff;

      .inner_box {
        width: 100%;

        min-height: 429px;

        background: url("../assets/img/home_banner_1_bg_m.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        text-align: center;

        .content {
          width: 88%;
          margin-left: calc(6%);
          ul {
            width: 100%;
            list-style: none;
            display: flex;
            justify-content: space-between;
            align-content: flex-start;
            li {
              width: 33%;
              display: flex;
              justify-content: space-around;
              flex-wrap: wrap;
              text-align: center;
              align-items: center;
              align-content: flex-start;
              p {
                width: 100%;
                // padding: 10px 0;
                font-size: 12px;
                font-weight: 500;
                line-height: 24px;
                color: #999999;
              }
              p.title {
                color: #333333;
                font-size: 14px;
                font-weight: bold;
                padding: 20px 0;
              }
              img {
                width: 30%;
                margin: 5px 0 10px 0;
              }
            }
          }
        }
      }
    }
    .bigtitle {
      width: 360px;
      height: 10px;
      background: #ca1425;
      display: none;
    }
    .home_banner_2 {
      width: 100%;
      min-height: 35vh;
      background: #ffffff;
      .banner_2_content {
        padding: 50px 0;
        width: 88%;
        margin-left: calc(6%);
        text-align: center;
        .what {
          width: 100%;
          color: #333333;
          text-align: left;
          padding: 0px 0;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .what_name {
            width: 360px;
            font-size: 18px;
            font-weight: 600;
            line-height: 25px;
            padding-bottom: 20px;
          }
          .what_info {
            width: calc(100%);
            font-size: 14px;
            padding-left: 0px;
            line-height: 24px;
            padding-bottom: 20px;
          }
        }
        .video_box {
          width: 100%;
          margin-left: 0%;
          padding: 0;
          background: #ffffff;
          video {
            width: 100%;
            border-radius: 10px;
            background: #000000;
          }
        }
        .what_list {
          display: flex;
          justify-content: space-around;
          padding-top: 50px;
          align-items: center;
          flex-wrap: wrap;
          text-align: center;
          width: 100%;
          .what_l_info {
            width: 460px;
            text-align: left;
            p.block_t {
              font-size: 18px;
              line-height: 28px;
              color: #333333;
              font-weight: 600;
              padding-top: 34px;
            }
            p.red_t {
              font-size: 18px;
              line-height: 28px;
              color: #ca1425;
              padding-bottom: 16px;
            }
            p.red_b_t {
              font-size: 14px;
              line-height: 24px;
              color: #ca1425;
              font-weight: 600;
              padding-bottom: 26px;
              padding-top: 0px;
            }
            p.info {
              font-size: 14px;
              line-height: 28px;
              color: #777777;
            }
          }
          img {
            width: 45%;
            display: flex;
            margin: 20px 0;
          }
          img.m {
            display: block;
          }
          img.pc {
            display: none;
          }
        }
      }
    }
    .home_banner_3 {
      width: 100%;
      min-height: 275px;
      background: #000000;
      padding: 50px 0;
      .home_b_3_c {
        width: 88%;
        margin-left: calc(6%);
        text-align: center;
        p.title {
          color: #ffffff;
          font-size: 18px;
          padding: 24px 0;
          line-height: 36px;
          font-weight: 600;
          text-align: left;
        }
        ul {
          list-style: none;
          width: 100%;
          padding: 20px 0;
          display: flex;
          justify-content: space-around;
          text-align: left;
          li {
            color: #ffffff;
            font-size: 13px;
            text-align: center;
            line-height: 16px;
            font-weight: 300;
            span {
              display: inline-block;
              width: 100%;
              padding-bottom: 10px;
            }
            span.num {
              font-size: 18px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ca1425;
              line-height: 36px;
            }
          }
        }
        a {
          margin-top: 30px;
          color: #ffffff;
          text-decoration: none;
          font-size: 12px;
          font-weight: 600;
          display: inline-block;
          width: 120px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #ca1425;
        }
        a:hover {
          background: #ca1425;
        }
      }
    }
    .home_banner_4 {
      width: 100%;
      min-height: 50vh;
      background: #ffffff;
      .banner_4_content {
        padding: 50px 0 25px 0;
        width: 88%;
        margin-left: calc(6%);
        text-align: center;
        p.title {
          color: #333333;
          font-size: 26px;
          padding: 24px 0;
          line-height: 50px;
          font-weight: 600;
          text-align: left;
        }
        ul {
          list-style: none;
          padding-top: 20px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            padding-bottom: 30px;
            width: 100%;
            color: #333333;
            text-align: left;
            // opacity: 0;
            // transition: all 1s ease;
            // -webkit-animation-timing-function: ease;
            // animation-timing-function: ease;
            a {
              text-decoration: none;
              color: #333333;
              display: inline-block;
              width: 100%;
              p.list_info {
                font-size: 13px;
                line-height: 20px;
                font-weight: 400;
              }
              p.list_title {
                font-weight: 600;
                font-size: 14px;
                line-height: 28px;

                padding: 10px 0;
              }
              p.list_time {
                color: #999999;
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                padding-top: 10px;
              }
              img {
                width: 100%;
              }
            }
          }
          li:hover {
            color: #888888;
            cursor: pointer;
            p.list_title {
              color: #787878;
            }
            p.list_time {
              color: #c8c8c8;
            }
          }
        }
        a.btn {
          width: 120px;
          display: inline-block;
          height: 40px;
          line-height: 40px;
          border: 1px solid #d6d6d6;
          background: transparent;
          color: #333333;
          text-decoration: none;
          font-size: 12px;
        }
        a.btn:hover {
          background: #ca1425;
          color: #ffffff;
          border: 1px solid #ca1425;
        }
      }
    }
  }
}
</style>
