<template>
  <div class='details'>
    <div class="banner_1_box"></div>
    <div class="banner_2_box">
      <div class="cont_box" v-for="(item,index) in $t('Notice.noticeList')" v-bind:key="index" v-show="index == infoindex">
        <p class="title">{{item.title}}</p>
        <p class="time">{{item.time}}</p>

        <p class="info" v-for="(item1,index1) in item.infos" v-bind:key="index1">
          {{item1.info}}
          <img :src="item1.img" />
        </p>

      </div>
      <!-- <div class="cont_box" v-for="(item,index) in $t('Notice.noticeList')" v-bind:key="index" v-show="index == infoindex">
        <p class="title">{{item.title}}</p>
        <p class="time">{{item.time}}</p>
        <p v-for="(iteminfo,indexinfo) in item.infos" v-bind:key="indexinfo" class="info">
          {{iteminfo.info}}</p>
        <p class="img"><img :src="iteminfo.img" /></p>

      </div> -->
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  name: '',
  data () {
    return {
      infoindex: 0
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {


  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () {
    this.infoindex = this.$route.query.id
    console.log(this.infoindex)
  }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.details {
  width: 100%;
  min-height: 100vh;
  .banner_1_box {
    width: 100%;
    height: 330px;
    background: #000000;
  }
  .banner_2_box {
    width: 100%;
    min-height: 330px;
    background: #ffffff;
    display: flex;
    justify-content: space-around;
    padding: 109px 0;
    .cont_box {
      width: 810px;
      min-height: 50vh;
      text-align: left;
      p.info {
        padding-bottom: 50px;

        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 33px;
        img {
          width: 100%;
        }
      }
      p.time {
        padding: 27px 0;

        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
      }
      p.img {
        padding-bottom: 50px;
        img {
          width: 100%;
        }
      }
      p.title {
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 55px;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .details {
    width: 100%;
    min-height: 100vh;
    .banner_1_box {
      width: 100%;
      height: 330px;
      background: #000000;
    }
    .banner_2_box {
      width: 88%;
      margin-left: 6%;
      min-height: 330px;
      background: #ffffff;
      display: flex;
      justify-content: space-around;
      padding: 54px 0;
      .cont_box {
        width: 100%;
        min-height: 50vh;
        text-align: left;
        p.info {
          padding-bottom: 50px;

          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          img {
            width: 100%;
          }
        }
        p.time {
          padding: 13px 0;

          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
        }
        p.img {
          padding-bottom: 30px;
          img {
            width: 100%;
          }
        }
        p.title {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 36px;
        }
      }
    }
  }
}
</style>