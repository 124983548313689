<template>
  <div class='Nav'>
    <div class="m_top_box">
      <img class="logo" src="../assets/img/logo_block.png" alt="">
      <img class="mun" @click=" showMun" src="../assets/img/m_more_nav.png" alt="">
      <!-- <i  class="el-icon-s-data"></i> -->
    </div>
    <div class="nav_box" v-show="ispc">
      <img class="logo" src="../assets/img/logo.png" alt="">

      <div class="nav_right_box">
        <div class="nav_link">
          <router-link to="/home">{{$t("Nav.home")}}</router-link>
          <div class="dropdown_box" @mouseenter="DownShow($event)" @mouseleave="DownHidn($event)">
            {{$t("Nav.developer")}}
            <!-- <a @click=" goRpc( '/developer')" href="javascript:void(0)"> {{$t("Nav.developer")}}</a> -->
            <ul>
              <li>
                <router-link to="/developer">{{$t("Nav.de_ceshi")}}</router-link>
              </li>
              <li><a href="https://github.com/BitcherryFoundation">GitHub</a> </li>
              <li><a href="http://developers.bitcherry.io/apiReferenceManual/#/apiReferenceManual">{{$t("Nav.de_wendang")}}</a></li>
              <!-- <li>{{$t("Nav.de_shenji")}}</li> -->
            </ul>

          </div>

          <a href="http://blockscan.bitcherry.io/#/home"> {{$t("Nav.explorer")}}</a>
          <router-link to='/wallet'>{{$t("Nav.wallet")}}</router-link>
          <router-link to='/develop'>{{$t("Nav.roadmap")}}</router-link>

          <!-- <a @click=" goRpc( '/community')" href="javascript:void(0)"> {{$t("Nav.Community")}}</a> -->
          <div class="dropdown_box" @mouseenter="DownShow($event)" @mouseleave="DownHidn($event)">
            {{$t("Nav.about")}}
            <!-- <a @click=" goRpc( '/developer')" href="javascript:void(0)"> {{$t("Nav.developer")}}</a> -->
            <ul>
              <li>
                <router-link to="/project">{{$t("Nav.xiangmudongtai")}}</router-link>
              </li>
              <li>
                <router-link to="/community">{{$t("Nav.Community")}}</router-link>
              </li>
              <li>
                <router-link to="/what">{{$t("Nav.token")}}</router-link>
              </li>
              <!-- <li>{{$t("Nav.de_shenji")}}</li> -->
            </ul>

          </div>
          <!-- <a @click="  goRpc( '/destruction')" href="javascript:void(0)">{{$t("Nav.Destruction")}}</a> -->

          <div class="dropdown_box" @mouseenter="DownShow($event)" @mouseleave="DownHidn($event)">
            {{$t("Nav.Whitepaper")}}

            <ul>
              <li><a href="https://www.bitcherry.io/static/BitCherry-White-Paper-CN-V2.0.1.pdf">{{$t("Nav.ChineseWhitepaper")}}</a> </li>
              <li><a href="https://www.bitcherry.io/static/BitCherry-White-Paper-EN-V2.0.1.pdf"> {{$t("Nav.EnglishWhitepaper")}}</a></li>
              <li><a href="https://www.bitcherry.io/static/BitCherry-White-Paper-JP-V2.0.1.pdf"> {{$t("Nav.JapaneseWhitepaper")}}</a></li>
            </ul>

          </div>
        </div>
        <div class="nav_lang">
          <div class="dropdown_box" @mouseenter="DownShow($event)" @mouseleave="DownHidn($event)">
            {{$t("Nav.Language")}}

            <ul>
              <li @click="lanClickpc('zh')"> {{$t("Nav.Chinese")}}</li>
              <li @click="lanClickpc('English')"> {{$t("Nav.English")}}</li>

            </ul>

          </div>
        </div>
      </div>
    </div>
    <div class="nav_box_m" v-show="!munlShow">
      <img class="logo" src="../assets/img/logo.png" alt="">

      <div class="nav_right_box">
        <div class="nav_link">
          <a @click="goR( '/')" href="javascript:void(0)">{{$t("Nav.home")}}</a>

          <div class="dropdown_box" @click="DownShowm($event)">
            <!-- {{$t("Nav.developer")}} -->
            <p><a class="down" href="javascript:void(0)"> {{$t("Nav.developer")}}</a><i class="el-icon-arrow-down"></i></p>
            <ul>

              <li @click=" goR( '/developer')">{{$t("Nav.de_ceshi")}}</li>
              <li @click=" showMun"> <a href="https://github.com/BitcherryFoundation">GitHub</a> </li>
              <li @click=" showMun"><a href="http://developers.bitcherry.io/apiReferenceManual/#/apiReferenceManual">{{$t("Nav.de_wendang")}}</a></li>

            </ul>

          </div>
          <a href="http://blockscan.bitcherry.io/#/home"> {{$t("Nav.explorer")}}</a>
          <a @click=" goR( '/wallet')" href="javascript:void(0)">{{$t("Nav.wallet")}}</a>
          <!-- <a @click="goR('/')" href="javascript:void(0)"> {{$t("Nav.explorer")}}</a> -->
          <a @click=" goR('/develop')" href="javascript:void(0)">{{$t("Nav.roadmap")}}</a>

          <div class="dropdown_box" @click="DownShowm($event)">
            <!-- {{$t("Nav.developer")}} -->
            <p><a class="down" href="javascript:void(0)"> {{$t("Nav.about")}}</a><i class="el-icon-arrow-down"></i></p>
            <ul>

              <li @click=" goR( '/project')">{{$t("Nav.xiangmudongtai")}}</li>
              <li @click="goR( '/community')"> {{$t("Nav.Community")}} </li>
              <li @click=" goR( '/what')">{{$t("Nav.token")}}</li>

            </ul>

          </div>
          <!-- <a @click="  goR( '/destruction')" href="javascript:void(0)">{{$t("Nav.Destruction")}}</a> -->

          <div class="dropdown_box" @click="DownShowm($event)">
            <p> {{$t("Nav.Whitepaper")}}<i class="el-icon-arrow-down"></i></p>

            <ul>
              <li><a href="https://www.bitcherry.io/static/BitCherry-White-Paper-CN-V2.0.1.pdf">{{$t("Nav.ChineseWhitepaper")}}</a> </li>
              <li><a href="https://www.bitcherry.io/static/BitCherry-White-Paper-EN-V2.0.1.pdf"> {{$t("Nav.EnglishWhitepaper")}}</a></li>
              <li><a href="https://www.bitcherry.io/static/BitCherry-White-Paper-JP-V2.0.1.pdf"> {{$t("Nav.JapaneseWhitepaper")}}</a></li>
            </ul>

          </div>
        </div>
        <div class="nav_lang">
          <div class="dropdown_box" @click="DownShowm($event)">
            {{$t("Nav.Language")}}

            <ul>
              <li @click="lanClick('zh')"> {{$t("Nav.Chinese")}}</li>
              <li @click="lanClick('English')"> {{$t("Nav.English")}}</li>

            </ul>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { changeLanguage } from '../lang'
export default {
  components: {},
  name: '',
  data () {
    return {
      munlShow: true,
      ispc: true,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    showMun () {
      this.munlShow = !this.munlShow
    },

    goR (ph) {
      this.$router.push({ path: ph })
      this.munlShow = !this.munlShow
    },
    goRpc (ph) {
      this.$router.push({ path: ph })
      //this.munlShow = !this.munlShow
    },

    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    lanClick (lan) {
      console.log(lan)
      this.munlShow = !this.munlShow
      if (lan == "English") {
        changeLanguage("en_US")
        localStorage.setItem('lan1', "en_US")


      } else {
        changeLanguage("zh_CN")
        localStorage.setItem('lan1', "zh_CN")
      }

    },
    lanClickpc (lan) {
      console.log(lan)
      // this.munlShow = !this.munlShow
      if (lan == "English") {
        changeLanguage("en_US")
        localStorage.setItem('lan1', "en_US")


      } else {
        changeLanguage("zh_CN")
        localStorage.setItem('lan1', "zh_CN")
      }

    },
    DownShow (e) {
      console.log(e)
      console.log(e.target.children)
      let ul = e.currentTarget.childNodes[1];

      ul.style.display = "block";


    },
    DownShowm (e) {
      console.log(e)
      console.log(e.target.children)
      let ul = e.currentTarget.childNodes[1];
      if (ul.style.display == "block") {
        ul.style.display = "none";
      } else {
        ul.style.display = "block";
      }

    },
    DownHidn (e) {
      let ul = e.currentTarget.childNodes[1];
      ul.style.display = "none";
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    if (this._isMobile()) {
      this.munlShow = true
      this.ispc = false
    } else {
      //this.munlShow = false
      this.ispc = true
    }
    console.log(this.$router)
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.el-dropdown {
  color: #ffffff;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.Nav {
  width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  margin-top: 30px;
  text-align: center;
  .m_top_box {
    display: none;
  }
  .nav_box {
    width: 1200px;
    margin-left: calc(50% - 600px);
    display: flex;
    justify-content: space-between;
    z-index: 9999;
    .logo {
      height: 33px;
    }
    .nav_right_box {
      // width: 60%;
      display: flex;
      justify-content: space-between;

      .nav_link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff;
        font-size: 16px;
        a {
          text-decoration: none;
          padding: 5px 15px;
          color: #ffffff;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
        a:hover {
          color: #ca1425;
        }
        a.active {
          color: #ca1425;
        }
      }

      .nav_lang {
        color: #ffffff;
      }
      .dropdown_box {
        padding: 5px 15px;
        position: relative;
        height: 30px;
        line-height: 30px;
        ul {
          position: absolute;
          min-width: 128px;
          top: 40px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          display: none;
          li {
            list-style: none;
            width: 100%;
            padding: 10px 0;
            text-align: center;
            background: #000;
            color: #c1c1c1;
            font-size: 14px;
            a {
              color: #c1c1c1;
              font-size: 14px;
              text-align: center;
            }
          }
          li:hover {
            color: #ca1425;
            cursor: pointer;
          }
        }
      }
      .dropdown_box:hover {
        color: #ca1425;
        cursor: pointer;
      }
    }
  }
}
@media screen and(max-width: 750px) {
  .Nav {
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 300;
    margin-top: 0px;
    text-align: center;
    background: #ffffff;
    .m_top_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      width: 88%;
      // margin-left: calc(6%);
      padding: 0 6%;
      .logo {
        width: 94px;
      }
      .mun {
        width: 22px;
        cursor: pointer;
      }
    }
    .nav_box_m {
      width: 100%;
      height: calc(100vh - 60px);
      z-index: 9999;
      margin: 0;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: flex-start;

      background: #ffffff;
      color: #000;
      box-shadow: 0px 2px 18px 2px rgba(145, 145, 145, 0.38);
      .logo {
        height: 33px;
        display: none;
      }
      .nav_right_box {
        width: 88%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: flex-start;
        z-index: 9999;
        .nav_link {
          display: flex;
          justify-content: space-around;
          align-items: flex-start;
          flex-wrap: wrap;
          color: #3c3c3c;
          font-size: 16px;
          a {
            display: inline-block;
            width: 100%;
            text-decoration: none;
            padding: 15px 0;
            color: #3c3c3c;
            border-bottom: 1px solid #eeeeee;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            text-align: left;
          }
          a:hover {
            color: #ca1425;
          }
        }

        .nav_lang {
          color: #3c3c3c;
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: flex-start;
          flex-wrap: wrap;
        }
        .dropdown_box {
          padding: 10px 0;
          position: relative;
          min-height: 30px;
          width: 100%;
          text-align: left;
          border-bottom: 1px solid #eeeeee;
          line-height: 30px;
          // display: flex;

          p {
            width: 100%;
            a {
              border: none;
              padding: 0;
              width: auto;
            }
            i {
              color: #999999;
              font-weight: bold;
              margin-left: 5px;
            }
          }
          ul {
            position: relative;
            min-width: 128px;
            top: 10px;
            left: 50%;
            width: 100%;
            z-index: 999;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            display: none;
            li {
              list-style: none;
              width: 100%;
              padding: 10px 0;
              text-align: left;
              background: #fff;
              color: #999999;
              font-size: 14px;
              border-top: 1px solid #eeeeee;
              a {
                border: none;
                padding: 0;
                text-align: left;
                color: #999999;
                font-size: 14px;
              }
            }
            li:hover {
              color: #ca1425;
              cursor: pointer;
            }
          }
        }
        .dropdown_box:hover {
          color: #ca1425;
          cursor: pointer;
        }
      }
    }
  }
}
</style>