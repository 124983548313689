export default {
  Nav: {
    home: '首页',
    wallet: '钱包',
    developer: '开发者',
    de_ceshi: '开发者介绍',
    de_wendang: '开发者文档',
    de_shenji: '审计报告',

    GitHub: 'GitHub',
    explorer: '浏览器',
    roadmap: '发展路线',
    Community: '社区',
    Whitepaper: '白皮书',
    Destruction: '销毁',
    ChineseWhitepaper: '中文白皮书',
    EnglishWhitepaper: '英文白皮书',
    JapaneseWhitepaper: '日文白皮书',
    Language: '语言',
    Chinese: '中文',
    English: '英文',
    about: "关于",
    xiangmudongtai: "项目动态",
    token: "关于BCHC"
  },
  Home: {
    banner1: {
      info1: '全球首个基于',
      info2: 'IPv8',
      info3: '技术服务于分布式商业的可扩容区块链基础设施',
      more: '了解更多'
    },
    banner2: {
      list: [
        {
          title: '安全友好',
          imgsrc: require("../assets/img/home_banner_1_list_1.png"),
          infos: [
            "支持多种开发语言",
            " 支持外部合约调用",
            " 功能完备工具完善",
            "支持IPFS",
          ]
        },
        {
          title: '自主创新',
          imgsrc: require("../assets/img/home_banner_1_list_2.png"),
          infos: [
            " 基于IPv8",
            " 点对点传输",
            " 提升数据可达性",
            " 数据高度安全",
          ]
        },
        {
          title: '性能卓越',
          imgsrc: require("../assets/img/home_banner_1_list_3.png"),
          infos: [
            "aBFT共识算法",
            " 横向分片",
            " 纵向侧链",
            "理论TPS100000+",
          ]
        },
      ]
    },
    banner3: {
      what_list_1: {
        title1: '什么是',
        title2: 'BitCherry?',
        info: 'BitCherry是一个满足于商业应用的通用型区块链基础设施，为链上分布式商业应用提供高性能、高安全、高可用的底层公链支持。'
      },
      video_box: 'https://www.bitcherry.io/video/videoch.mp4',
      what_list_2: {
        bigtitle: 'BLOCKCHAIN PLUS',
        bigtitle1: '重新定义',
        title1: '分布式商业',
        info: '理论方面，BitCherry技术团队综合了互联网多年的工程经验，首次提出了BlockChain Plus，意在通过可执行的技术手段，改善当前公链扩容性差、性能不足、治理结构不合理、安全性低等问题。'
      },
      what_list_3: {

        title1: '数据安全',
        info: 'BitCherry以革新物理层的思维搭建了P2Plus协议，协议工作在更为底层的IP层和数据链路层以内，大幅提升了数据传输速度和可达性，P2Plus采用的点对点私钥加密技术，更有效保证了节点隐私以及数据安全性。'
      },
      what_list_4: {

        title1: '性能高效',
        info: 'BitCherry的全节点运行衣服在HashGraph上，能够支撑大规模网络效应。基于aBFT算法并依托“侧链+分片”的扩容设计，解决了区块拥堵问题，使单个英勇的资源占用不会影响其他链上性能。'
      },
      what_list_5: {

        title1: '共识治理',
        info: 'BitCherry在治理结构上更为开放，首次提出用户价值度证明PoUc，参考用户权重进行虚拟投票，选中节点获得记账奖励，让通证持有者有权参与项目发展及链上治理工作。'
      },

    },
    home_banner_3: {
      bigtitle: "BCHC市场表现",
      lists: [
        {
          title: '总市值',
          val: "$12.11B"
        },
        {
          title: '24H交易量',
          val: "$100M"
        },
        {
          title: '持币用户',
          val: "50,0000"
        },
      ],
      more_btn: '了解更多'
    },
    home_banner_4: {
      bigtitle: "BitCherry项目动态",
      more_btn: "查看更多"
    }
  },
  Footer: {
    left_box: {
      list1: {
        title: "联系BitCherry",
        info1: '商务合作:  business@BitCherry.io',
        info2: '客服邮箱:  contact@BitCherry.io',
      },
      list2: {
        title: "文档下载",
        info1: 'BitCherry 白皮书（中文）',
        info2: 'BitCherry 白皮书（英文）',
        info3: 'BitCherry 白皮书（日文）',
      },
    },
    right_box: {
      title: '官方社交平台'
    }

  },
  Wallet: {
    banner_top: {
      title: "CherryWallet钱包",
      info: "CherryWallet是BitCherry旗下功能强大的生态钱包，其中包含CherryWallet冷钱包、CherryWallet APP、CherryWallet公链钱包。",
    },
    b_list_box_1: {
      info: "冷热分离更安全，配合CherryWallet冷钱包广播交易和咨询余额优质资产全面支持，BTC,ETH,BCHC,USDT，EOS等等尽在掌握动态行情实时更新，行业资讯最新跟踪报道   精品应用极致甑选，通往区块链世界的必备入口"
    },
    b_list_box_2: {
      imgsrc: require('../assets/img/wallet/7.jpg'),
      imgsrcm: require('../assets/img/wallet/s7.jpg')
    },
    b_list_box_3: {
      title: '完全隔离网络',
      infos: [
        '永不联网，彻底根绝网络黑客入侵',
        '冷端构建交易和签名',
        '热端联网广播交易'
      ]
    },
    b_list_box_4: {
      title: '种子密码 + 支付密码',
      infos: [
        '两道密码安全管理，杜绝资产盗用冒用',
        '加密储存，永不触网',
        '安全验证，用完即删 '
      ]
    },
    RecommendedWallet: '推荐钱包'
  },
  Developer: {
    banner_top: {
      title: '开发者',
      info: "BitCherry的区块链服务层设计使开发人员很容易根据市场需求快速创建模块，为缺少区块链经验的开发者提供了流畅、低成本、省时的解决方案。",
      btn_info: "进入开发者网站"
    },
    banner_2: {
      title: "开发者社区",
      info: "BitCherry技术社区和BitCherry的技术核心团队一样重要。社区成员对BitCherry技术架构和产品有深入的了解。社区成员享有自治和社区全力支持所带来的灵活性和创造性，看看你是否能够加入我们的开发小组",
      btn_info: '加入社区',
    },
    banner_3: {
      title: "开发者激励/活动？",
      info: "奖励是作为激励大家共同参与修复软件缺陷或实现小功能的方式。奖励驱动开发是基于开源软件系统商业模型的一种独特性。通过发现bug、添加特性和构建BitCherry辅助资源来获取收益",
      btn_info: '加入社区',
    },
    banner_4: {
      title: "BitCherry测试网",
      info: "BitCherry测试网由核心团队搭建，并得到技术社区的支持。测试网完全模拟了主BitCherry网络的运行情况，用户能够体验BitCherry各种产品的功能，从而方便测试新特性",
      btn_info: '敬请期待',
    },
  },
  //发展路线
  Roadmap: {
    banner_top: {
      title: '发展路线图',
      info: '作为一个新兴的、自迭代的开源平台，BitCherry通过推动创新和消除瓶颈，持续塑造这个行业的未来。 从BitCherry立项以来，我们灵活可扩展的开发方式在安全性、优化、互操作性和可伸缩性等方面创建了新的行业标准。'
    },
    list_1: {
      time: "2020 Q4",
      info: "测试网上线"
    },
    list_2: {
      time: "2020 Q4",
      info: "开发者社区V1上线"
    },
    list_3: {
      time: "2020 Q4",
      info: "区块浏览器测试版上线"
    },
    list_4: {
      time: "2021 Q1",
      info: "主网V1上线"
    },
    list_5: {
      time: "2021 Q1",
      info: "区块浏览器正式版上线"
    },
    list_6: {
      time: "2021 Q1",
      info: "浏览器钱包插件上线"
    },
    list_7: {
      time: "2021 Q2",
      info: "移动端钱包上线"
    },
    list_8: {
      time: "2021 Q2",
      info: "DApp生态开启"
    },
    list_9: {
      time: "2021 Q3",
      info: "开发者社区V2上线"
    },
    list_10: {
      time: "2021 Q3",
      info: "DEX协议上线"
    },
    list_11: {
      time: "2021 Q4",
      info: "主网V2上线"
    },
    list_12: {
      time: "2021 Q4",
      info: "DeFi的借贷/理财协议上线"
    },
    list_13: {
      time: "2022 Q1",
      info: "DeFi的保险协议上线"
    },
    list_14: {
      time: "2022 Q3",
      info: "跨链上线"
    },
    list_15: {
      time: "2022 Q3",
      info: "分片上线"
    },
    list_16: {
      time: "2022 Q4",
      info: "DeFi生态达到100+"
    },
    list_17: {
      time: "2023 Q2",
      info: "分布式商业生态建成"
    },
  },
  Community: {
    banner_top: {
      title: 'BitCherry社区',
      info: ' BitCherry是一个公开、开源并由社区驱动的项目。我们的未来建立在安全稳定的去中心化区块链技术之上，BitCherry基金会将确保合作伙伴和生态系统的相互协调与发展'
    },
    banner_2: {
      title: "社区活动",
      info: "BitCherry不定期举办社区活动，以节日活动、发放空投等形式，增强社区凝聚力。",
      btn_info: '查看更多'
    },
    banner_3: {
      title: "BitCherry大使",
      info: "BitCherry各个国家的大使专注于提高对应地区的意识，以增强BitCherry的全球生态系统和网络效应。大使是社区发展的领袖，每季度由BitCherry社区及核心小组共同选出",
      pleLists: [
        {
          img: require('../assets/img/community/peple/p1.png'),
          title: 'George Deeb',
          ad: 'BitCherry中东负责人',
          info: '中东satoshiat.com 创使人',
        },
        {
          img: require('../assets/img/community/peple/p2.png'),
          title: 'Long Miguel',
          ad: '中东satoshiat.com 创使人',
          info: '区块链市场开发经理，数字货币研究员',
        },
        {
          img: require('../assets/img/community/peple/p3.png'),
          title: 'Reno Rafael Siregar',
          ad: '印尼社区运营专家',
          info: '印尼社区运营专家',
        },
        {
          img: require('../assets/img/community/peple/p4.png'),
          title: 'Kripto Sozluk',
          ad: 'BitCherry欧洲区土耳其负责人',
          info: '知名YouTube KOL',
        },
        {
          img: require('../assets/img/community/peple/p5.png'),
          title: 'Jean Stiven',
          ad: 'BitCherry法语区负责人',
          info: '区块链爱好者，社区运营专家',
        },
        {
          img: require('../assets/img/community/peple/p6.png'),
          title: 'Bodrul Alam',
          ad: 'BitCherry美国区负责人',
          info: '明星社区运营经理，行业专家',
        },
      ]
    },
    banner_4: {
      title: "BitCherry投票",
      info: "BitCherry各个国家的大使专注于提高对应地区的意识，以增强BitCherry的全球生态系统和网络效应。大使是社区发展的领袖，每季度由BitCherry社区及核心小组共同选出。",
      btn_info: '敬请期待'
    },

  },
  Destruction: {
    banner_top: {
      title: '销毁公示',
      info: 'BitCherry致力于构建基于区块链技术的分布式网络搭建，提升行业效率，扩大市场交易规模，有效解决行业痛点，并将不断通过代币销毁等举措，使所有BitCherry支持者都能享受到BCHC总流通量通缩带来的价值提升。我们将不断增多BCHC应用场景和福利反馈活动，用行动感谢大家的支持和鼓励。',
      btn_info: '最新销毁方案'
    },
    banner_2: {
      title: "最新销毁公示",
      btn_info: '销毁地址',
      list_1: [
        {
          title: '销毁项目',
          info: "BTOK矿机活动"
        },
        {
          title: '销毁周期',
          info: "2020/06/06至2020/06/06"
        },
        {
          title: '销毁数量',
          info: "1000万个"
        },
        {
          title: '历史销毁数量',
          info: "10000万个"
        },
      ],
      title_2: "流通信息披露",
      list_2: [
        {
          title: '现存数量',
          info: "BTOK矿机活动"
        },
        {
          title: '现存流通量',
          info: "2020/06/06至2020/06/06"
        },
      ],
      title_3: "销毁数据明细",
      list_title: [
        "销毁项目",
        "销毁时间",
        "销毁数量",
        "销毁地址"
      ],
      list_3: [
        {
          name: "BTOK矿机活动",
          time: "2020/01/01",
          num: '23231.232322',
          address: "rerqerqwerwerwe"
        },
        {
          name: "BTOK矿机活动",
          time: "2020/01/01",
          num: '23231.232322',
          address: "rerqerqwerwerwe"
        },
        {
          name: "BTOK矿机活动",
          time: "2020/01/01",
          num: '23231.232322',
          address: "rerqerqwerwerwe"
        },
        {
          name: "BTOK矿机活动",
          time: "2020/01/01",
          num: '23231.232322',
          address: "rerqerqwerwerwe"
        },
        {
          name: "BTOK矿机活动",
          time: "2020/01/01",
          num: '23231.232322',
          address: "rerqerqwerwerwe"
        },
        {
          name: "BTOK矿机活动",
          time: "2020/01/01",
          num: '23231.232322',
          address: "rerqerqwerwerwe"
        },
      ],
      see_more: "查看更多"

    }
  },
  What: {
    banner_top: {
      title: "什么是BCHC",
      info: "BCHC作为BitCherry公链的价值支撑，保证了BitCherry的去中心化，使生态中的权益者能够自由公平的参与网络治理，此外BCHC可以在Bitcherry生态中支付各种商品和服务。"
    },
    banner_2: {
      title: 'BCHC的使用场景',
      info: 'BitCherry将传统企业的技术资源，人力资源，商业资源以及各类创新资源进行整合，囊括与产品溯源，供应链金融，商业消费，资产数字化，金融，电商，云计算等有关的区块链技术编码与底层构架。',
      list_info_1: '产品溯源',
      list_info_2: '商业消费',
      list_info_3: '电子商务',
      list_info_4: '供应链金融',
      list_info_5: '资产数字化',
      list_info_6: '分布式云计算',
    },
    banner_3: {
      title: 'BCHC已上线交易所'
    },
    banner_4: {
      title: 'BCHC已上线钱包'
    },
    banner_5: {
      title: '已上线行情网站'
    }
  },
  Media: {
    banner: {
      title: '媒体报道',
      infos: [
      ]
    },
    mediaList: [
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "BitCherry展望：数字世界“火种源”，打造多元、持续全球生态",
        time: '2021.04.07',
        links: 'http://www.jpm.cn/article-113048-1.html',
        infos: [
          {
            info: 'BitCherry分布式生态基金成立，并投入1000万美金用于链上生态应用的开发、竞赛、孵化以及投资。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "BitCherry分布式商业生态繁荣爆发 领跑全球公链竞争格局",
        time: '2021.04.07',
        links: 'https://www.jinse.com/news/blockchain/1050547.html',
        infos: [
          {
            info: 'BitCherry作为分布式商业公链项目，致力于打造可服务于各类型商业的IPv8区块链基础设施。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "高昂手续费使以太坊“举步维艰” BitCherry—解决高昂手续费的替代方案",
        time: '2021.03.23',
        links: 'https://www.jinse.com/news/blockchain/1035050.html',
        infos: [
          {
            info: '基于区块链技术的商业应用正在推动全球商业的数字化转型，分布式商业能否成为全球商业经济发展的未来将备受期待。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "分布式商业区块链BitCherry技术可以帮助DEX？",
        time: '2021.03.17',
        links: 'https://www.jinse.com/news/blockchain/1022958.html',
        infos: [
          {
            info: 'BitCherry 的P2Plus网络协议对于DEX交易所的发展起了很大的帮助，真正的可以做到分布式交易。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "BitCherry|基于IPv8技术的区块链网络协议",
        time: '2021.03.05',
        links: 'https://www.163.com/dy/article/G4672TOQ0534UPXM.html',
        infos: [
          {
            info: '当传统互联网愈加摇摇欲坠，我们相信将会有更多的新技术与BitCherry一起构建互联网的美好未来，我们也相信，BitCherry会成为众多技术中，最为耀眼的一颗明星',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "基于BitCherry的去中心化广告有效阻止谷歌搜索恶意广告",
        time: '2021.02.18',
        links: 'http://www.jpm.cn/article-111077-1.html',
        infos: [
          {
            info: 'BitCherry是基于IPv8技术的商业可伸缩区块链基础设施，它有效解决广告网络面临的许多问题，包括猖獗的欺诈和恶意欺诈。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "分布式商业公链BitCherry：支撑分布式商业的渐进式突破",
        time: '2021.01.28',
        links: 'http://www.jpm.cn/article-110490-1.html',
        infos: [
          {
            info: '本文将介绍分布式商业公链项目BitCherry在技术突破和产品成熟度方面的成功，从多个角度分析其是否能够支撑分布式商业化的基本需求。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "BitCherry BCHC区块链技术将如何改变游戏行业",
        time: '2021.01.28',
        links: 'https://www.jinse.com/news/blockchain/989317.html',
        infos: [
          {
            info: 'BitCherry（BCHC）区块链技术具有创造游戏新时代并推动区块链行业的潜力。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "比传统P2P更安全的BitCherry P2Plus网络协议怎么提高电子身份安全性?",
        time: '2021.01.21',
        links: 'https://www.jinse.com/news/blockchain/983115.html',
        infos: [
          {
            info: 'BitCherry 以P2Plus网络协议加上HashGraph算法为核心，相信BitCherry的区块链技术建立在电子身份上会是一个高效又安全的技术支持。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "基于BitCherry aBFT+PoUc共识机制的去中心化投票系统",
        time: '2021.01.14',
        links: 'https://news.huoxing24.com/20210108154838797776.html',
        infos: [
          {
            info: 'BitCherry基于aBFT+PoUc共识机制打造了一个去中心化的投票系统，该系统能够适应正确性，并且是透明、匿名和安全的。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "区块链项目如何永续发展？解读公链项目BitCherry的开发者激励机制",
        time: '2021.01.14',
        links: 'http://www.jpm.cn/article-109968-1.html',
        infos: [
          {
            info: 'BitCherry作为分布式商业公链，尤为注重商业易用性和安全性，相对着也会更加依赖开发者对于项目的贡献。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "BitCherry测试网：颠覆式创新引领分布式商业时代",
        time: '2021.01.06',
        links: 'https://www.jinse.com/news/blockchain/969374.html',
        infos: [
          {
            info: 'BitCherry作为全球首个基于IPv8技术服务于商业的可扩容区块链基础设施，致力于构建区块链技术与现实社会链接的商业通衢，并帮助传统企业构造可信任分布式商业生态环境。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "区块链项目创新盘点：BitCherry测试网络究竟有何潜力帮助商业发展？",
        time: '2020.12.30',
        links: 'https://www.jinse.com/news/blockchain/962995.html',
        infos: [
          {
            info: '本文将围绕BitCherry测试网络进行解读，帮助大家更好的了解BitCherry的区块链技术创新及测试网的网络特征。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1225003.png'),
        title: "BitCherry创始人专访：颠覆传统商业模式 解读BitCherry测试网的商业创新",
        time: '2020.12.25',
        links: 'https://news.huoxing24.com/20201225165928619432.html',
        infos: [
          {
            info: '12月24日，备受关注的区块链项目BitCherry宣布v1.0.1版本测试网正式上线。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "BitCherry创始人+CEO Paul 解读测试网AMA直播回顾",
        time: '2020.12.25',
        links: 'https://www.jinse.com/news/blockchain/957861.html',
        infos: [
          {
            info: '2020年12月24日晚20点，BitCherry创始人&CEO Paul在BitCherry官方英文社区进行了直播AMA活动，就社区用户关于BitCherry测试网v1.0. 版本的问题进行了在线解答。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1224001.png'),
        title: "BitCherry测试网正式上线，以技术成就分布式商业的基石",
        time: '2020.12.24',
        links: 'https://www.bishijie.com/shendu/156370.html',
        infos: [
          {
            info: '12月24日，备受关注的区块链项目BitCherry宣布v1.0.1版本测试网正式上线。BitCherry作为全球首个基于IPv8技术服务于商业的可扩容区块链基础设施，致力于构建区块链技术与现实社会链接的商业通衢，并帮助传统企业构造可信任分...',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "亚马逊服务器宕机背后: 区块链技术IPv8怎么保护资料不外泄",
        time: '2019.06.12',
        links: 'https://www.jinse.com/news/blockchain/949688.html',
        infos: [
          {
            info: '相比于中心化服务器宕机事故会造成大规模互联网应用的瘫痪和信息泄露，去中心化云服务器系统中个别云服务器的宕机根本不会影响用户的体验，更不会造成数据泄露等问题。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1216001.png'),
        title: "IPv8技术如何重新定义数百万智能电视中的IP安全和隐私问题",
        time: '2019.06.12',
        links: 'http://bitcherry.com.cn/info/IPv8JiShuRuHeZhongXinDingYiShuBaiWanZhiNenDianShiZhongDeIPAnQuanHeYinSiWenTi-20121460102.html',
        infos: [
          {
            info: '我们相信会有更多新的安全技术能使用BitCherry IPv8构建一个最安全和隐私的环境。IPv8和BitCherry的发布将共同实现互联网安全和隐私的升级。让我们一起期待一个全新的安全互联网IPv8的到来!',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "巴黎区块链周PBWS | BitCherry创始人Paul：区块链创新金融将变革全球金融体系",
        time: '2019.06.12',
        links: 'https://www.jinse.com/news/blockchain/942467.html',
        infos: [
          {
            info: '2020年12月10日，BitCherry作为主要赞助方受邀参加巴黎区块链周PBWS 2020。作为欧洲最大的区块链和数字资产活动，此次会议聚集到了来自全球各地的区块链企业、投资机构、媒体以及1700多位区块链爱好者。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "“商业赛博空间”——写在BitCherry测试网络上线之际",
        time: '2019.06.12',
        links: 'https://www.jinse.com/news/blockchain/931200.html',
        infos: [
          {
            info: '区块链技术发展的成就主要体现在对现有商业模式的补充或延展，BitCherry认为的产业创新需要的是一个开放、平等、自由、协作的生态，将全球顶级资源整合一体，从资本、技术、市场、商业等多个维度出发，构建全球化的生态，促进全球商业转型发展，推动商业社会的变革。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1020001.png'),
        title: "公链帝国争霸？区块链项目Polkadot与BitCherry的多维度对比",
        time: '2019.06.12',
        links: 'http://m.jpm.cn/article-107874-1.html',
        infos: [
          {
            info: '2020年是公链项目遍地生花的一年，如今区块链已经进入“帝国争霸时代”，众多公链项目诞生并迅速发展，但却存在良莠不齐的情况，在这样的前提下，本文将为大家介绍两个具有前瞻性、成长性和实用性的区块链项目，并列举对比它们的技术。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "分布式商业公链BitCherry项目详解（四）：商业级区块链底层基础设施及产品架构",
        time: '2019.06.12',
        links: 'https://www.jinse.com/news/blockchain/901018.html',
        infos: [
          {
            info: 'BitCherry的项目定位是“全球首个基于IPv8技术服务于分布式商业的可扩容区块链基础设施”，主要包括：一是打造商业级区块链底层基础设施平台为全球商业提供底层支持；二是在此基础上构建分布式商业生态，打造高性能、高安全、高可扩展性的商业级应用。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "分布式商业公链BitCherry项目详解（三）：哈希体（侧链）、哈希圈（分片）技术特性及实现原理",
        time: '2019.06.12',
        links: 'https://www.jinse.com/news/blockchain/889233.html',
        infos: [
          {
            info: 'BitCherry的哈希体（侧链）技术，可根据不同实际应用场景的需求对数据做切分，横向提高区块链的吞吐量……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "分布式商业公链BitCherry项目详解（二）：aBFT+PoUc共识算法",
        time: '2019.06.12',
        links: 'https://www.jinse.com/news/blockchain/881323.html',
        infos: [
          {
            info: 'BitCherry的区块链系统是一种分布式系统，共识算法运行的环境类似于分布式系统的执行环境，要保证共识算法的安全性和可靠性，不可避免的要解决“拜占庭将军”问题……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1020001.png'),
        title: "分布式商业公链BitCherry项目详解（一）：BitCherry的技术框架及创新机制",
        time: '2019.06.12',
        links: 'http://www.jpm.cn/article-106511-1.html',
        infos: [
          {
            info: '区块链已经成为推动商业发展的重要技术，谁能在此领域掌握更多的核心技术，谁就可能成为这个领域的“领头羊”。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "BitCherry_BCHC挖掘区块链环保领域潜在价值",
        time: '2019.06.12',
        links: 'https://www.jinse.com/news/blockchain/833674.html',
        infos: [
          {
            info: '灵活运用区块链技术可以更好地去推进环保工作的发展，也许这才是真正意义上的科技改变未来。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "BitCherry赋能食品安全溯源领域，BCHC适配各溯源环节",
        time: '2019.06.12',
        links: 'https://www.jinse.com/news/blockchain/814409.html',
        infos: [
          {
            info: 'BitCherry所创建的区块链食品溯源平台将是一个包含生产、加工、供应、销售、物流及购买者在内的生态圈……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0909001.png'),
        title: "数字货币BCHC_Bitcherry将助推数字生活有序发展",
        time: '2019.06.12',
        links: 'https://xueqiu.com/4267027564/158383098',
        infos: [
          {
            info: '虽然使用BCHC作为支付手段可能会面临来自现有电子支付工具的激烈竞争，但可以肯定的是，在各类数字货币发行及落地的过程中，将进一步推动全球经济发展及转型的进程。BCHC的支付便利性和安全性也可以释放消费需求，对消费增长起到促进作用',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0901001.png'),
        title: "分布式商业公链BitCherry重塑商业经济体系，驱动价值无国界流通",
        time: '2019.06.12',
        links: 'http://dy.163.com/article/FL6POKAC0518J1JO.html',
        infos: [
          {
            info: 'BitCherry利用无国界的价值主张，通过IPv8技术构建的底层网络可以通过提高商业交易的效率和可行性，开辟一个新的跨境市场，与过去的通信和金融技术不同，这些网络是一种不受政治边界束缚的互联网技术，这项技术让全球联网的智能手机、电脑和设备更简单的联系了起来。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "BitCherry BCHC跨链技术解析：构建区块链价值互联的高速公路",
        time: '2019.06.12',
        links: 'https://www.jinse.com/news/blockchain/781542.html',
        infos: [
          {
            info: 'BitCherry的公链基础设施作为价值互联网的底层架构，将区块链作为价值互联的载体，任何有价值的信息或是数字货币，都可以在BitCherry的区块链上实现互联互通，BitCherry也成为了实现价值网络的关键……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0811001.png'),
        title: "BitCherry——缔造分布式金融2.0时代的先锋者",
        time: '2019.06.12',
        links: 'http://www.jpm.cn/article-103921-1.html',
        infos: [
          {
            info: '分布式金融作为区块链领域的应用，有望解决传统金融的局限性，链接数字经济的实体经济的共同发展，而有着分布式商业公链BitCherry类似项目的出现，必将为推动这一伟大进程的实现提供关键力量。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0805001.png'),
        title: "BCHC_BitCherry挖掘区块链技术 开创下一代的互联网",
        time: '2019.06.12',
        links: 'http://news.iresearch.cn/yx/2020/07/332428.shtml',
        infos: [
          {
            info: 'BitCherry致力于将区块链技术应用于能源、金融、航空航天、制造、物流等众多行业，并在推动金融、科技领域技术革新方面发挥重要作用。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0630002.png'),
        title: "BitCherry_BCHC _区块链+供应链金融助力企业发展",
        time: '2019.06.12',
        links: 'https://www.jinse.com/news/blockchain/766153.html',
        infos: [
          {
            info: '作为全球首家基于IPv8服务于分布式商业的可扩容区块链底层设施，BitCherry可以提供一套完整的解决方案，包括但不限于基于区块链思维的商业模式改造、利用区块链技术或应用对企业进行优化、通过数字权益通证搭建商业生态。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0721001.png'),
        title: "BitCherry|BCHC_区块链公链的核心价值是商业适用性",
        time: '2019.06.12',
        links: 'http://www.jpm.cn/article-103076-1.html',
        infos: [
          {
            info: 'BitCherry基于IPv8技术，以革新物理层的全新思维搭建P2Plus点对点加密网络协议，建立了服务于分布式商业的可扩容区块链基础设施。可以应用到金融、医疗、能源、电商以及分布式云计算等各行各业。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0715001.png'),
        title: "分布式商业公链BitCherry-开启价值互联网的第二个时代",
        time: '2019.06.12',
        links: 'https://xueqiu.com/7563966313/153590512',
        infos: [
          {
            info: 'BitCherry的服务范围涉及众多，除了在分布式金融，供应链管理、资产数字化、商业消费、电商、分布式云计算等领域已经拥有实际应用案例和落地项目，在数字版权、电子政务、公益、数字身份、教育、医疗、能源以及文化娱乐等等垂直产业和领域也已经开始着手对接优质项目。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0630002.png'),
        title: "IPv8——BitCherry对IP层面的探索",
        time: '2019.06.12',
        links: 'https://www.jinse.com/blockchain/740683.html',
        infos: [
          {
            info: '随着人类即将迈向万物互联、万网互联、虚实融合的智能化全互联世界，面对新世代、新业务、新需求，传统的IP网络早已显得力不从心，如何让IP网络在继承以往成功基因的基础上继续发展，以满足未来工业互联网的新需求，促进全产业数字化，成为业界共同思考的课题……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0630001.png'),
        title: "BCHC|BitCherry区块链技术的未来前景客观",
        time: '2019.06.12',
        links: 'http://www.kjw.cc/tupian/2020/0623/062020_16000.html',
        infos: [
          {
            info: '市场中区块链平台层出不穷，真正能满足带动其他行业发展和单体用户需求的平台并不多，区块链技术将是区块链企业硬实力表现的形式之一，而BitCherry作为行业技术领头羊，基于IPv8技术并服务于分布式商业的可扩容区块链基础设施……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0623001.png'),
        title: "BCHC|BitCherry-能源领域区块链值得深究",
        time: '2019.06.12',
        links: 'https://www.jinse.com/news/blockchain/752918.html',
        infos: [
          {
            info: 'BitCherry始于技术着眼商业，作为全球首家基于IPv8服务于分布式商业的可扩容区块链底层技术，BitCherry可以提供一套完整的解决方案，包括但不限于基于区块链思维的商业模式改造、利用区块链技术或应用对企业进行优化、通过数字权益通证搭建商业生态等。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0616001.png'),
        title: "BitCherry“三大战略”定义分布式商业新时代",
        time: '2019.06.12',
        links: 'https://www.bishijie.com/shendu/107668.html',
        infos: [
          {
            info: '越来越多的从业者开始意识到除技术外，商业及资本对于区块链生态的重要性，所以在这场网络革命中，布局全球化市场显得尤为重要。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0609001.png'),
        title: "技术创新到规模化应用，BitCherry发展战略进入成熟期",
        time: '2019.06.12',
        links: 'http://www.jpm.cn/article-100833-1.html',
        infos: [
          {
            info: '在商业领域，BitCherry与多国政企达成深度合作，一年的时间里实现了从试点到规模化的解决方案的突破。目前BitCherry已为全球20+优质企业提供了区块链技术解决方案，并且数据还在增长。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0529002.png'),
        title: "BitCherry的矫正与突围——技术型公链的迷失和商业型公链的崛起",
        time: '2019.06.12',
        links: 'https://www.sohu.com/a/398335341_100206765',
        infos: [
          {
            info: '作为全球首家基于IPv8服务于分布式商业的可扩容区块链底层技术，与商业巨头的入场步伐一致，在技术完善的同时更注重于满足商业需求，不同于EOS、ETH，BitCherry的设计更偏向于使用场景……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0529001.png'),
        title: "BitCherry深度布局中东市场 携手UPEX助力石油经济转型",
        time: '2019.06.12',
        links: 'http://finance.sina.cn/qudao.d.html?vt=4&docID=irczymk3993472',
        infos: [
          {
            info: 'UPEX是中东区域最大的数字货币交易平台，拥有巴林政府颁发的合法牌照，在当地央行的监管下支持美金的合法出入，支持沙特、巴林、阿联酋等本地货币，并支持多国法币、多种数字货币的现货交易服务，日均交易量超过三亿美金。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0527001.png'),
        title: "BCHC（BitCherry项目通证）上线中东最大数字资产交易平台UPEX 加深全球化进程",
        time: '2019.06.12',
        links: 'https://www.jinse.com/blockchain/691943.html',
        infos: [
          {
            info: '新加坡时间2020年5月27日，分布式商业公链BitCherry宣布与中东区域最大的数字货币交易平台UPEX达成战略合作，其项目通证BCHC正式上线UPEX。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0519001.png'),
        title: "BitCherry受邀参加纽约线上共识大会 赋能企业数字化转型",
        time: '2019.06.12',
        links: 'https://www.jinse.com/blockchain/675501.html',
        infos: [
          {
            info: '美国时间2020年5月11-15日，分布式商业公链BitCherry作为赞助方受邀参加由全球顶级区块链投资者媒体平台CoinDesk主办的“Consensus: Distributed”纽约线上共识大会。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0505002.png'),
        title: "专访Paul丨IPv8技术下 BitCherry分布式商业的新视界",
        time: '2019.06.12',
        links: 'https://www.jinse.com/news/blockchain/653668.html',
        infos: [
          {
            info: 'BitCherry用一年时间，完成了从分布式电商公链到全球首家基于IPv8服务于分布式商业的可扩容区块链基础设施的蜕变，升级后的BitCherry会给我们哪些惊喜？区块链未来的发展方向是什么？',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0505001.png'),
        title: "从电商到分布式商业丨解读BitCherry技术升级后的全新商业生态 ",
        time: '2019.06.12',
        links: 'https://news.huoxing24.com/20200505143025915945.html',
        infos: [
          {
            info: '2020年5月5日，分布式商业公链项目BitCherry迎来一周年庆典，并于当天正式发布了新版技术白皮书《BitCherry——全球首个基于IPv8技术服务于分布式商业的可扩容区块链基础设施白皮书v2.0.1》，这是继BitCherry涉足分布式电商领域后的又一重大技术更新。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408002.png'),
        title: "BCHC解读全球IP之战 | 为何要以IP技术重构网络核心？ ",
        time: '2019.06.12',
        links: 'http://www.51emo.com/caijing/2020/0402/042020_13687.html',
        infos: [
          {
            info: '由美国主导的根服务器治理体系造成了全球互联网关键资源管理和分配的不均衡，而这样的网络格局使得没有根服务器的国家在网络安全上存在着莫大的隐患……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0408001.png'),
        title: "经济危机亦是挑战 BCHC价值载体BitCherry定义新公链时代",
        time: '2019.06.12',
        links: 'https://www.jinse.com/blockchain/617128.html',
        infos: [
          {
            info: '2020年开始，受疫情影响，国际经济形势变化激烈，各国纷纷调整经济发展。3月11日，国际金融市场同时发生巨幅波动，各国股市应声而跌，超过10个国家的股市均发生熔断……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0401002.png'),
        title: "区块链行业陷抄袭僵局，BCHC载体BitCherry携新技术破局而生 ",
        time: '2019.06.12',
        links: 'http://www.hzfc.cc/xw/cj/354.html',
        infos: [
          {
            info: '在技术的应用中，区块链技术不仅需要创新发展，还要注意和5G、大数据、人工智能、云计算等其他技术的相互交融，区块链在新兴技术中所代表的，其实更像一个纽带，它提供了一种融合信任的机制，也起到了数据增信的作用',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0401001.png'),
        title: " 观察BCHC | 你没关注过的分布式商业公链BitCherry",
        time: '2019.06.12',
        links: 'http://dy.163.com/v2/article/detail/F8GIALU60511CT10.html',
        infos: [
          {
            info: '关于区块链行业的认知,人们常谈到的是第一代区块链的比特币(BTC),第二代的以太坊(ETH),以及第三代的柚子(EOS)。但作为可以真正意义上引领商业社会变革的新技术,以上三个项目均有其不足的地方。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0324002.png'),
        title: "深度分析BCHC价值载体BitCherry为何安全、稳定、可以共识治理",
        time: '2019.06.12',
        links: 'https://t.cj.sina.com.cn/articles/view/6442033995/17ff9a34b00100mslg?from=tech',
        infos: [
          {
            info: '目前,BitCherry的商业版图辐射全球,覆盖东南亚、欧美等地区在内的近百万社区用户,服务涉及供应链金融、资产数字化、商业消费、电商、分布式云计算等众多领域。未来,BitCherry将继续运用底层技术优势,完善跨行业区块链解决方案,打造产业协同发展的新业态。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0324001.png'),
        title: "公链新时代来临：BCHC的价值体现由这2个因素驱动",
        time: '2019.06.12',
        links: 'http://www.jpm.cn/article-96076-1.html',
        infos: [
          {
            info: '在当下区块链公链赛道上,BitCherry正在打造的是一个从商业需求出发,真正能落地应用、可商用的区块链分布式网络基础设施。实现区块链技术的大规模商业应用落地,这正是当前区块链行业真正需要解决的问题和价值。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0115001.png'),
        title: "分布式商业时代先锋 BCHC价值载体BitCherry路途坦荡",
        time: '2019.06.12',
        links: 'https://www.sohu.com/a/377945074_120510072',
        infos: [
          {
            info: '在面对技术创新时,BitCherry选择从商业的实际需求出发,兼容商业需求和创新技术,对参与者的服务更加贴心。搭建分布式商业网络基础设施,并逐步扩展分布式商业的生态环境,让实体企业的新价值更加有依可寻。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0115001.png'),
        title: "深耕科技、优抓服务 BCHC价值载体BitCherry未来可期",
        time: '2019.06.12',
        links: 'https://www.sohu.com/a/377910417_120321513',
        infos: [
          {
            info: 'BitCherry作为全球领先级的去中心化商业网络基础设施,致力于为全球商业提供便捷的底层技术支持,服务涉及供应链金融、产品溯源、资产数字化、商业消费、电商、分布式云计算等众多领域。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0304002.png'),
        title: "分布式商业技术新浪潮涌起 BCHC价值载体BitCherry踏春而来",
        time: '2019.06.12',
        links: 'https://www.jinse.com/blockchain/594031.htm',
        infos: [
          {
            info: '在面对技术创新时，BitCherry选择从商业需求出发，平衡商业需求与创新技术之间的关系，构建分布式商业网络基础设施，并逐步扩展分布式商业生态，赋能实体企业新价值。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0304001.png'),
        title: "BitCherry首次提出的BlockChain Plus价值几何？",
        time: '2019.06.12',
        links: 'https://www.siliconvalleynews.net/news/264121531/what-is-the-value-of-blockchain-plus-first-proposed-by-bitcherry',
        infos: [
          {
            info: '以安全、高效、自主为设计哲学，BitCherry抓住当前商业环境的刚需，精准布局，一举成为商业公链领域中的焦点。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0226002.png'),
        title: "BitCherry以区块链技术掀起赋能分布式商业新浪潮",
        time: '2019.06.12',
        links: 'https://apnews.com/50beeaf997af302a3022e5f41fbf412b',
        infos: [
          {
            info: '现有公链的P2P协议都是搭建在IPv4/IPv6广域网络的主机端TCP传输层以上，而BitCherry基于IPv8的底层加密通讯协议搭建的P2Plus协议则工作在更为底层的IP层和数据链路层以内……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0226001.png'),
        title: "《BitCherry打造可信任分布式商业生态环境》",
        time: '2019.06.12',
        links: 'https://finance.yahoo.com/news/bitcherry-sets-off-wave-enabling-184500554.html?guccounter=1',
        infos: [
          {
            info: '作为全球首个基于IPv8技术服务于商业的可扩容区块链基础设施，BitCherry着力于在业内进行全面而深度的发展，在其强大的生态资源支持下BitCherry尤其注重商业需求……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0220001.png'),
        title: "分布式商业的春天已来 BitCherry携BCHC打造可信任分布式商业生态环境",
        time: '2019.06.12',
        links: 'https://3g.163.com/money/article/F56QQE5V04249CUG.html',
        infos: [
          {
            info: '第二届区块链与数字货币全领域峰会Turkey Economy Summit 2020将于2020年2月20日至21日在土耳其伊斯坦布尔盛大召开。BitCherry作为本次峰会的主要赞助方，其创始人及CEO Paul将作为特邀嘉宾出席本次峰会。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0212002.png'),
        title: "与BitCherry一起，共襄2020年最具影响力区块链峰会盛宴",
        time: '2019.06.12',
        links: 'https://www.btchaber.com/blockchain-economy-2020-icin-sayili-gun-kaldi/',
        infos: [
          {
            info: '本次峰会主题将包括区块链技术、人工智能、大数据、去中心化技术，物联网等众多领域。是中东、北非和欧亚大陆地区最具影响力的区块链峰会。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0212001.png'),
        title: "BitCherry赞助！欧亚地区最具影响力区块链峰会即将召开",
        time: '2019.06.12',
        links: 'https://www.marketwatch.com/press-release/blockchain-economy-conference-is-about-to-begin-2020-02-07',
        infos: [
          {
            info: 'BitCherry作为主赞助方！第二届区块链与数字货币全领域峰会Turkey Economy Summit 2020将于2020年2月20日至21日在土耳其伊斯坦布尔盛大召开。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0204001.png'),
        title: "会议预告 | BitCherry将受邀出席土耳其经济峰会2020",
        time: '2019.06.12',
        links: 'https://uzmancoin.com/bitcherry-blockchain-economy/',
        infos: [
          {
            info: '第二届区块链与数字货币全领域峰会Turkey Economy Summit 2020将于2020年2月20日至21日在土耳其伊斯坦布尔盛大召开。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0115002.png'),
        title: "对话Bitcherry价值通证BCHC现状与未来",
        time: '2019.06.12',
        links: 'http://3g.163.com/ntes/20/0106/11/F2733NBF04138JTG.html',
        infos: [
          {
            info: 'BCHC价值通证作为一项新事物、新概念，在2019年受到了前所未有的关注。从技术角度出发，进行了深入的应用和推广，其规模和影响日益扩大。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0115001.png'),
        title: "2019年细数BCHC价值载体BitCherry深入南非战略布局",
        time: '2019.06.12',
        links: 'https://www.sohu.com/a/364980597_675570',
        infos: [
          {
            info: '2019年,BitCherry分布式开启全球拓展战略,在新加波、马耳他等多地全面绽放,于2019年10月份BitCherry将区块链技术落地南非刚果。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0108001.jpg'),
        title: "BitCherry 社区大使全球招募",
        time: '2019.06.12',
        links: 'https://nulltx.com/global-recruitment-of-bitcherry-community-ambassadors/',
        infos: [
          {
            info: 'BitCherry已在美国、俄罗斯、韩国、越南、新加坡、菲律宾、土耳其等众多国家开始战略布局，为了让更多与我们有共识的社区用户能够参与到BitCherry的共建之路，共享发展价值，现面对全球各大社区招募社区大使。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1225002.png'),
        title: "BitCherry BCHC提出“区块链 4.0”",
        time: '2019.06.12',
        links: 'http://mini.eastday.com/a/191201172525347.html',
        infos: [
          {
            info: 'BitCherry 是全球首家分布式电商平台,它以区块链技术为基础,致力于打造一个囊括衣食住行各个方面的超级生态。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1225001.png'),
        title: "外交部部长王毅：“17+1合作”将推动中国与中东欧国的发展战略更好地融合对接",
        time: '2019.06.12',
        links: 'https://www.jianshu.com/p/f14d994b0e1d',
        infos: [
          {
            info: 'BitCherry创始人&CEO Paul等区块链行业精英受邀出席，会议期间，中国-中东欧区块链卓越中心与分布式商业公链项目BitCherry签署了《中国-中东欧区块链卓越中心谅解备忘录》，双方就如何将区块链技术落地应用于能源、金融、航空航天、制造业、物流、商业等行业，促进中国与中东欧国家的国际合作等方面交换意见。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1218001.png'),
        title: "BitCherry创始人：利用区块链技术赋能传统商业可有效改变现状",
        time: '2019.06.12',
        links: 'https://m.tech.china.com/redian/2019/1217/433529.html',
        infos: [
          {
            info: '区块链技术目前已成为全球许多大型企业的首选，虽然中心化的传统数据集多年来持续创新，实现了数据仓库和负载平衡，同时区块链仍处于起步阶段，目前尚无法满足我们所习惯的高速处理速度，但未来的趋势仍倾向于分布式数据存储。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1211001.png'),
        title: "BCHC分布式区块链数据库储存的核心优势你知道吗？",
        time: '2019.06.12',
        links: 'http://zhtieba.cn/forum.php?mod=viewthread&tid=259292&extra=',
        infos: [
          {
            info: 'BitCherry作为己经部署国际市场的分布式存储商业服务网络，依靠自己区块链技术领域，搭建分布式商业服务体系,造就行业领域新时代,构造完美的分布式存储服务业商业模式……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1204002.png'),
        title: "BCHC适用多种商业场景需求，开启分布式商业新业态",
        time: '2019.06.12',
        links: 'http://www.beijingleather.com.cn/cj/cj110532215.html',
        infos: [
          {
            info: '区块链的成熟离不开行业的科技创新精神，从区块链应用技术裂变的过程中，BitCherry也正是为庞大的分布式商业拓展蓝图，探索整个区块链之中的奥秘……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1204001.png'),
        title: "未来电商如何决策“二选一”BCHC分布式电商崛起",
        time: '2019.06.12',
        links: 'http://www.pashu.org/hq/2019/1126/hq111876001.html',
        infos: [
          {
            info: 'BitCherry系统将通过建立去中心化的分布式商业组织，利用社群自传播特点，保证无边界电商网络生态的实现……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1127004.png'),
        title: "BCHC价值赋予分布式电商的新生命力",
        time: '2019.06.12',
        links: 'http://www.3news.cn/pindao/2019/1115/1004376898.html',
        infos: [
          {
            info: '为了更好地理解第四次零售电商革命向第五次零售区块链革命的过渡，我们需要清晰的辨别BCHC作为分布式电商价值载体是否能带来新生命力。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1127003.png'),
        title: "BCHC重磅发声 引领网络数据存储安全的大改革",
        time: '2019.06.12',
        links: 'https://www.sohu.com/a/354719262_120161927',
        infos: [
          {
            info: '自区块链应用问世来,为数字经济的新时代带来了巨大变化的曙光,分布式系统经济发展也逐步形成深受社会各界关注的新经济发展趋势。BCHC作为BitCherry 分布式系统电子商务互联网价值载体,在数据创新及安全方向得到公众认可。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1127002.png'),
        title: "科技引领未来 BCHC通证赋予分布式商业全新价值",
        time: '2019.06.12',
        links: 'http://finance.jxyuging.com/finance/2019/1120/56690.html',
        infos: [
          {
            info: 'BCHC是BitCherry分布式电商网络利用区块链底层技术及通证属性发行的平台通用通证，其价值与BitCherry分布式生态息息相关。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1127001.png'),
        title: "BCHC的分布式理念如何引领全球经济变革",
        time: '2019.06.12',
        links: 'http://www.jpm.cn/article-88934-1.html',
        infos: [
          {
            info: '分布式商业网络BitCherry将基于全球商业需求，构建分布式商业基础设施，从商业、产品、技术三个方面给予赋能，完善分布式经济生态体系。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1120004.jpg'),
        title: "解密BCHC与互联网信息安全技术关联性",
        time: '2019.06.12',
        links: 'http://www.3news.cn/pindao/2019/1115/1004376898.html',
        infos: [
          {
            info: '分布式系统与共识计算方法为BitCherry公链建立起了一个安全的大数据架构，在这个基础之中，还需要确保各种应用的安全，而区块链智能合约模块肩负起了这个使命。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1120003.jpg'),
        title: "第四届新加坡金融科技节隆重举行 BCHC价值呈现",
        time: '2019.06.12',
        links: 'http://www.hidongbei.com/v-1-605754.aspx',
        infos: [
          {
            info: 'BitCherry团队作为全球首个分布式商业项目受邀参加“Singapore FinTech Festival”金融科技节展会活动。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1120002.jpg'),
        title: "解密BCHC与互联网信息安全技术关联性",
        time: '2019.06.12',
        links: 'http://use.17car.com.cn/use/2019/1115/30631.html',
        infos: [
          {
            info: '分布式系统与共识计算方法为BitCherry公链建立起了一个安全的大数据架构，在这个基础之中，还需要确保各种应用的安全，而区块链智能合约模块肩负起了这个使命。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1120001.jpg'),
        title: "稿件中文名称：第四届新加坡金融科技节隆重举行 BCHC价值呈现",
        time: '2019.06.12',
        links: 'http://www.wanjiquan.com/zixun/201911/5452.html',
        infos: [
          {
            info: 'BitCherry团队作为全球首个分布式商业项目受邀参加“Singapore FinTech Festival”金融科技节展会活动。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1113002.jpg'),
        title: "BitCherry落地马耳他，掀起分布式商业的范式革命",
        time: '2019.06.12',
        links: 'http://m.cnbzol.com/www_news/20191107/1002185789.html',
        infos: [
          {
            info: '由全球首个分布式商业项目BitCherry主办的“AIBC Summit Malta”区块链峰会落地马耳他，这是BitCherry继广受欢迎的开幕站“城市赋能——旧金山站”后，推出的又一个关注分布式商业、区块链技术的重磅会议。会议致力于探索商业、科技与人类社会发展之间的关系，审视和思考颠覆了每个人的日常和未来的数字革命，想象并共同塑造人与科技共生的未来世界。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1113001.jpg'),
        title: "BitCherry落地马耳他，掀起分布式商业的范式革命",
        time: '2019.06.12',
        links: 'http://wap.yesky.com/446/638006446.shtml',
        infos: [
          {
            info: '由全球首个分布式商业项目BitCherry主办的“AIBC Summit Malta”区块链峰会落地马耳他，这是BitCherry继广受欢迎的开幕站“城市赋能——旧金山站”后，推出的又一个关注分布式商业、区块链技术的重磅会议。会议致力于探索商业、科技与人类社会发展之间的关系，审视和思考颠覆了每个人的日常和未来的数字革命，想象并共同塑造人与科技共生的未来世界。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1030004.jpg'),
        title: "BitCherry——“城”就分布式商业之路BitCherry",
        time: '2019.06.12',
        links: 'https://www.coinspeaker.com/BitCherry-the-road-of-building-a-distributed-commerce-city/',
        infos: [
          {
            info: '分布式电商公链项目BitCherry将于10月28日开启“城市赋能——打造分布式商业城市”全球活动。这是BitCherry继10月12日，与刚果民主共和国国家青年部签订战略合作协议后的又一重大活动。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1030003.jpg'),
        title: "DeFi、科技、商业，从全球活动中读懂BitCherry的全球化之路",
        time: '2019.06.12',
        links: 'https://shandong.hexun.com/2019-10-22/198951718.html',
        infos: [
          {
            info: '全球首个服务于商业领域的分布式网络BitCherry欲将通过主题为“城市赋能——打造分布式商业城市”的全球活动为多个国家及城市带去前沿的分布式技术理念以及先进的分布式商业模式。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1030002.jpg'),
        title: "BitCherry——“城”就分布式商业之路BitCherry",
        time: '2019.06.12',
        links: 'https://coinvestasi.com/press-releases/BitCherry-membangun-kota-perdagangan-terdistribusi/',
        infos: [
          {
            info: '分布式电商公链项目BitCherry将于10月28日开启“城市赋能——打造分布式商业城市”全球活动。这是BitCherry继10月12日，与刚果民主共和国国家青年部签订战略合作协议后的又一重大活动。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1030001.jpg'),
        title: "DeFi、科技、商业，从全球活动中读懂BitCherry的全球化之路",
        time: '2019.06.12',
        links: 'http://www.southmoney.com/caijing/gongsixinwen/201910/4221579.html',
        infos: [
          {
            info: '全球首个服务于商业领域的分布式网络BitCherry欲将通过主题为“城市赋能——打造分布式商业城市”的全球活动为多个国家及城市带去前沿的分布式技术理念以及先进的分布式商业模式。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1021004.jpg'),
        title: "将区块链带到第三世界：分布式电商BitCherry的格局与野心",
        time: '2019.06.12',
        links: 'http://www.lsjinfo.com/index/news/info/id/1478.html',
        infos: [
          {
            info: '2019年10月9日，BitCherry创始人及CEO Paul、BitCherry顾问WTIA主席Kim等人一行与刚果（金）总统办公室主任、国家基建部长、财政部部长以及能源矿业部长就刚果（金）地区“大宗电商、矿产溯源、跨境支付、资源登记、商业登记”等多个区块链应用领域交换意见，并将对此展开区域化合作。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1021003.jpg'),
        title: "BitCherry与刚果民主共和国达成战略合作，推进新能源经济化发展",
        time: '2019.06.12',
        links: 'https://www.kuam.com/story/41180491/news',
        infos: [
          {
            info: '2019年10月10日至12日，BitCherry与刚果(金)青年部部长签订了《刚果民主共和国国家基础设施建设及能源经济化转型战略合作谅解备忘录》。BitCherry创始人及CEO Paul、BitCherry顾问WTIA主席Kim等人一行与刚果(金)总统办公室主任、国家基建部长、财政部部长以及能源矿业部长就刚果(金)地区“大宗电商、矿产溯源、跨境支付、资源登记、商业登记”等多个区块链应用领域交换意见，双方将就刚果(金)的国家基础设施、采矿业及矿产资源、能源、信息通讯技术、农业、教育卫生等多领域展开技术合作。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1021002.jpg'),
        title: "将区块链带到第三世界：分布式电商BitCherry的格局与野心",
        time: '2019.06.12',
        links: 'http://www.cnhan.com/html/biz/20191016/1052018.htm',
        infos: [
          {
            info: '2019年10月9日，BitCherry创始人及CEO Paul、BitCherry顾问WTIA主席Kim等人一行与刚果（金）总统办公室主任、国家基建部长、财政部部长以及能源矿业部长就刚果（金）地区“大宗电商、矿产溯源、跨境支付、资源登记、商业登记”等多个区块链应用领域交换意见，并将对此展开区域化合作。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1021001.jpg'),
        title: "BitCherry与刚果民主共和国达成战略合作，推进新能源经济化发展",
        time: '2019.06.12',
        links: 'http://markets.financialcontent.com/stocks/news/read/38981541',
        infos: [
          {
            info: '2019年10月10日至12日，BitCherry与刚果(金)青年部部长签订了《刚果民主共和国国家基础设施建设及能源经济化转型战略合作谅解备忘录》。BitCherry创始人及CEO Paul、BitCherry顾问WTIA主席Kim等人一行与刚果(金)总统办公室主任、国家基建部长、财政部部长以及能源矿业部长就刚果(金)地区“大宗电商、矿产溯源、跨境支付、资源登记、商业登记”等多个区块链应用领域交换意见，双方将就刚果(金)的国家基础设施、采矿业及矿产资源、能源、信息通讯技术、农业、教育卫生等多领域展开技术合作。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1016004.jpg'),
        title: "刚果（金）政府与分布式商业项目BitCherry签订战略合作协议，步入能源转型快车道",
        time: '2019.06.12',
        links: 'http://www.zmke.com/post/201910/10257.html',
        infos: [
          {
            info: '2019年10月10日至12日，全球首家基于区块链技术，服务于电商的分布式网络系统BitCherry与刚果(金)国家青年发展部部长达成深度共识，并签订了《刚果民主共和国国家基础设施建设及能源经济化转型战略合作谅解备忘录》。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1016003.jpg'),
        title: "Cumlaude Crypto 2019点燃印尼学生区块链热情",
        time: '2019.06.12',
        links: 'https://www.beritasatu.com/digital/577590/kalangan-mahasiswa-sambut-antusias-cumlaude-crypto-2019https://jpnn.com/news/cumlaude-crypto-edukasi-teknologi-blockchain-dan-aset-kripto-untuk-mahasiswa',
        infos: [
          {
            info: '雅加达-Cumlaude加密货币路演是一系列活动，旨在向印度尼西亚的学生介绍有关区块链技术和加密资产的知识。在此次活动之上，BitCherry介绍了有关“区块链技术在电子商务行业中的应用”的主题。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1016002.jpg'),
        title: "刚果（金）政府与分布式商业项目BitCherry签订战略合作协议，步入能源转型快车道",
        time: '2019.06.12',
        links: 'http://www.chinaweekly.cn/24757.htm',
        infos: [
          {
            info: '2019年10月10日至12日，全球首家基于区块链技术，服务于电商的分布式网络系统BitCherry与刚果(金)国家青年发展部部长达成深度共识，并签订了《刚果民主共和国国家基础设施建设及能源经济化转型战略合作谅解备忘录》。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1016001.jpg'),
        title: "Cumlaude Crypto 2019点燃印尼学生区块链热情",
        time: '2019.06.12',
        links: 'https://www.tribunnews.com/techno/2019/10/01/cumlaude-crypto-2019-edukasi-teknologi-blockchain-dan-aset-kripto-untuk-mahasiswa',
        infos: [
          {
            info: "雅加达-Cumlaude加密货币路演是一系列活动，旨在向印度尼西亚的学生介绍有关区块链技术和加密资产的知识。在此次活动之上，BitCherry介绍了有关“区块链技术在电子商务行业中的应用”的主题。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1008003.jpg'),
        title: "风口上的印尼大学：BitCherry助力印尼区块链教育实现弯道超车",
        time: '2019.06.12',
        links: 'https://www.yunhe24.com/article/24593',
        infos: [
          {
            info: "印尼六城之旅路演活动圆满结束，分布式网络公链项目BitCherry作为活动赞助方，受邀参加了全程路演活动。此次路演中，BitCherry等项目在本次演活动中受到了印尼当地区块链爱好者们的广泛关注。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1008002.jpg'),
        title: "区块链项目在印尼获得垂青 BCHC帮助当地经济弯道超车",
        time: '2019.06.12',
        links: 'http://www.chinatradenews.com.cn/content/201909/23/c84806.html',
        infos: [
          {
            info: "印尼六城之旅路演活动圆满结束，分布式网络公链项目BitCherry作为活动赞助方，受邀参加了全程路演活动。此次路演中，BitCherry等项目在本次演活动中受到了印尼当地区块链爱好者们的广泛关注。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1008001.jpg'),
        title: "风口上的印尼大学：BitCherry助力印尼区块链教育实现弯道超车",
        time: '2019.06.12',
        links: 'http://www.qingtingcj.com/tnews/75378.html',
        infos: [
          {
            info: "印尼六城之旅路演活动圆满结束，分布式网络公链项目BitCherry作为活动赞助方，受邀参加了全程路演活动。此次路演中，BitCherry等项目在本次演活动中受到了印尼当地区块链爱好者们的广泛关注。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0925004.jpg'),
        title: "BitCherry：第一个使用区块链技术的电子商务平台",
        time: '2019.06.12',
        links: 'https://nhipcaudautu.vn/doanh-nghiep/BitCherry-san-thuong-mai-dien-tu-dau-tien-su-dung-cong-nghe-chuoi-khoi-3330336/',
        infos: [
          {
            info: "BitCherry在越南的社区代表在“Hanoi Innovation Summit 2019”创新峰会上，做了题为“ABCD的力量”的主题演讲。其中A是指人工智能，B是指区块链，C是指云计算，D是指大数据。这些技术将优化运营流程和成本，通过创建新的业务模式以适应未来的客户。ABCD的力量将决定数字经济的道路。BitCherry首席执行官Paul也在会议期间更为详细地解释了这一新的技术趋势。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0925003.jpg'),
        title: "用分布式商业理念与技术引领分布式经济变革",
        time: '2019.06.12',
        links: 'https://bitcoinnewsindo.com/BitCherry-memimpin-transformasi-ekonomi-terdistribusi-dengan-ide-dan-teknologi-bisnis-terdistribusi/',
        infos: [
          {
            info: "BitCherry选择从业务需求出发，以自己的技术理念，不断平衡“不可能的三角”关系，构建分布式电子商务基础设施，完善分布式电子商务生态系统，逐步构建分布式经济下的超级生态网络。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0925002.jpg'),
        title: "BitCherry丨用分布式商业理念与技术引领分布式经济变革",
        time: '2019.06.12',
        links: 'https://www.jinse.com/news/blockchain/468054.html',
        infos: [
          {
            info: "BitCherry选择切入的电商领域，正是急需分布式革命的领域。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0925001.jpg'),
        title: "用分布式商业理念与技术引领分布式经济变革",
        time: '2019.06.12',
        links: 'https://www.erienewsnow.com/story/41071368/BitCherry-leading-the-transformation-of-the-distributed-economy-with-distributed-business-ideas-and-technologies',
        infos: [
          {
            info: "BitCherry选择从业务需求出发，以自己的技术理念，不断平衡“不可能的三角”关系，构建分布式电子商务基础设施，完善分布式电子商务生态系统，逐步构建分布式经济下的超级生态网络。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0918004.jpg'),
        title: "迈向区块链4.0时代：BCHC为分布式电商平台赋能",
        time: '2019.06.12',
        links: 'http://www.zmke.com/post/201909/8833.html',
        infos: [
          {
            info: "区块链 4.0时代渐行渐近。为推动区块链落地实际商业场景，全球最大的分布式电商平台——BitCherry拟通过两个阶段实现“区块链 4.0”的目标。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0918003.jpg'),
        title: "数字金融新势力：BCHC 深度塑造通证经济生态",
        time: '2019.06.12',
        links: 'http://www.chinatradenews.com.cn/content/201909/16/c84017.html',
        infos: [
          {
            info: "业界对“区块链4.0”时代的到来抱以积极乐观的态度，摆脱中心化电商的束缚，已经成为一种不可逆转的趋势，因此分布式电商网络平台的潜力将会进一步凸显，作为数字通证的BCHC的价值将得到更为广泛的共识。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0918002.jpg'),
        title: "迈向区块链4.0时代：BCHC为分布式电商平台赋能",
        time: '2019.06.12',
        links: 'http://www.chinaweekly.cn/19708.html',
        infos: [
          {
            info: "区块链 4.0时代渐行渐近。为推动区块链落地实际商业场景，全球最大的分布式电商平台——BitCherry拟通过两个阶段实现“区块链 4.0”的目标。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0918001.jpg'),
        title: "稿件中文名称：数字金融新势力：BCHC 深度塑造通证经济生态",
        time: '2019.06.12',
        links: 'http://www.ceoim.com/article-146341-1.html',
        infos: [
          {
            info: " 业界对“区块链4.0”时代的到来抱以积极乐观的态度，摆脱中心化电商的束缚，已经成为一种不可逆转的趋势，因此分布式电商网络平台的潜力将会进一步凸显，作为数字通证的BCHC的价值将得到更为广泛的共识。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0910005.jpg'),
        title: "分布式电商网络项目BitCherry受邀出席越南河内创新峰会，积极建设越南社区",
        time: '2019.06.12',
        links: 'https://nulltx.com/distributed-e-commerce-network-project-BitCherry-was-invited-to-attend-the-hanoi-innovation-summit-in-vietnam-to-actively-build-the-community-in-vietnam/',
        infos: [
          {
            info: " BitCherry是一个分布式电子商务网络项目，也是唯一一个受邀参加2019年河内创新峰会的区块链电子商务项目，BitCherry的越南社区代表和意见领袖出席了会议。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0910004.jpg'),
        title: "BCHC频频受邀区块链大会 行业肯定其价值和成长性",
        time: '2019.06.12',
        links: 'http://news.flyxg.com/2019/qiqu_0904/158912.html',
        infos: [
          {
            info: "无论是在北京、越南,还是在印度尼西亚,BCHC俨然成了分布式经济领域内的一支新秀,它所倡导的新理念、采取的新方式为当地的经济转型提供了广阔的想象空间,其价值和成长性得到了行业的深度肯定。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0910003.jpg'),
        title: "分布式电商网络项目BitCherry受邀出席越南河内创新峰会，积极建设越南社区",
        time: '2019.06.12',
        links: 'https://vnbit.net/press-release-du-an-mang-thuong-mai-dien-tu-phan-tan-BitCherry-duoc-moi-tham-du-hoi-nghi-thuong-dinh-doi-moi-ha-noi-tai-viet-nam/',
        infos: [
          {
            info: "BitCherry是一个分布式电子商务网络项目，也是唯一一个受邀参加2019年河内创新峰会的区块链电子商务项目，BitCherry的越南社区代表和意见领袖出席了会议。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0910002.jpg'),
        title: "分布式经济引爆越南  BitCherry赋能传统零售服务业",
        time: '2019.06.12',
        links: 'https://coinvestasi.com/press-releases/BitCherry-memberdayakan-industri-layanan-ritel-tradisional-di-vietnam/',
        infos: [
          {
            info: "由越南最著名、权威的商业杂志之一《Business Review》组织的“Vietnam Tech Summit 2019”技术峰会在越南胡志明市正式举行。 BitCherry不仅被邀请作为会议赞助商之一参加峰会，而且创始人兼首席执行官Paul也应邀作为特邀嘉宾发表演讲，为在越南实施分布式经济奠定了坚实的基础。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0910001.jpg'),
        title: "BitCherry首席科学家Bob Qin：2019年了，不要纠结公链和私链了",
        time: '2019.06.12',
        links: 'https://news.huoxing24.com/20190903213359377881.html',
        infos: [
          {
            info: "北美区块链基金会主席、BitCherry首席科学家Bob Qin表示，去中心化、中心化，那是政治意义上或者商业意义上的概念，从工程的角度来讲是一个双向渐进的过程。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0903007.jpg'),
        title: "分布式经济引爆越南：BCHC为传统零售服务业赋能",
        time: '2019.06.12',
        links: 'http://www.chinaweekly.cn/17859.html',
        infos: [
          {
            info: "  以“ABCD Power”为主题，由越南最著名、权威的商业杂志之一《Business Review》主办的“Vietnam Tech Summit 2019”技术峰会正式召开，以BCHC为数字通证的BitCherry作为会议的赞助方之一受邀出席本次峰会，其创始人和CEO也发表了演讲。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0903006.jpg'),
        title: "分布式经济引爆越南  BitCherry赋能传统零售服务业",
        time: '2019.06.12',
        links: 'https://www.coinspeaker.com/distributed-economy-outbreaks-in-vietnam-BitCherry-is-empowering-traditional-retail-service-industry/',
        infos: [
          {
            info: " 2019年8月29日，“Vietnam Tech Summit 2019”技术峰会在越南胡志明市正式举行。BitCherry不仅作为会议赞助方之一受邀参加峰会，公司创始人兼首席执行官Paul还作为特邀嘉宾发表演讲，为越南分布式经济的实施奠定了坚实的基础。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0903005.jpg'),
        title: "分布式经济引爆越南：BCHC为传统零售服务业赋能",
        time: '2019.06.12',
        links: 'https://tech.china.com/article/20190829/kejiyuan0129356269.html',
        infos: [
          {
            info: " 以“ABCD Power”为主题，由越南最著名、权威的商业杂志之一《Business Review》主办的“Vietnam Tech Summit 2019”技术峰会正式召开，以BCHC为数字通证的BitCherry作为会议的赞助方之一受邀出席本次峰会，其创始人和CEO也发表了演讲。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0903004.jpg'),
        title: "以技术赋能行业，用生态传递价值——BitCherry对于分布式经济的探索",
        time: '2019.06.12',
        links: 'https://www.kitv.com/story/40957227/news',
        infos: [
          {
            info: "经过8月20日至21日两天的深入讨论，“ POW’ER 2019全球开发者大会”在中国北京圆满闭幕。本次会议以区块链分布式经济未来发展趋势为主题，引起了与会各方的广泛关注。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0903003.jpg'),
        title: "分布式经济引爆越南  BitCherry赋能传统零售服务业",
        time: '2019.06.12',
        links: 'https://vnbit.net/press-release-kinh-te-phan-tan-dang-bung-no-tai-viet-nam-BitCherry-dang-trao-quyen-cho-nganh-dich-vu-ban-le-truyen-thong/',
        infos: [
          {
            info: "2019年8月29日，“Vietnam Tech Summit 2019”技术峰会在越南胡志明市正式举行。BitCherry不仅作为会议赞助方之一受邀参加峰会，公司创始人兼首席执行官Paul还作为特邀嘉宾发表演讲，为越南分布式经济的实施奠定了坚实的基础。",
            img: '',

          },


        ]
      },

      {
        img: require('../assets/img/media1/0903002.jpg'),
        title: "以技术赋能行业，用生态传递价值——BitCherry对于分布式经济的探索",
        time: '2019.06.12',
        links: 'http://www.kuam.com/story/40969825/news',
        infos: [
          {
            info: "经过8月20日至21日两天的深入讨论，“ POW’ER 2019全球开发者大会”在中国北京圆满闭幕。本次会议以区块链分布式经济未来发展趋势为主题，引起了与会各方的广泛关注。",
            img: '',

          },


        ]
      },

      {
        img: require('../assets/img/media1/0903001.jpg'),
        title: "BCHC亮相越南河内创新峰会，深度建设越南社区",
        time: '2019.06.12',
        links: 'http://www.3news.cn/pindao/2019/0830/0613363179.html',
        infos: [
          {
            info: "2019年8月29日至8月30日，为期两天的“Hanoi Innovation Summit 2019”创新峰会在越南河内国家会议中心召开。分布式电商网络项目BitCherry作为唯一一家区块链电商项目受邀出席峰会，其越南社区代表及意见领袖们参加了此次会议。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827009.jpg'),
        title: "BitCherry首席科学家Bob Qin：智能合约并不局限于某一技术，应根据商业场景来构建",
        time: '2019.06.12',
        links: 'http://www.abc6.com/story/40957227/news',
        infos: [
          {
            info: "BitCherry首席科学家兼北美区块链基金会主席Bob Qin受邀以特邀嘉宾的身份发表题为《分散式分布式计算:分布式电子商务网络公共链创新》的重要演讲。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827008.jpg'),
        title: "BitCherry基于分布式计算构建全新分布式电商网络公链",
        time: '2019.06.12',
        links: 'http://www.enet.com.cn/article/2019/0823/A20190823984241.html',
        infos: [
          {
            info: "作为分布式商业的前沿实践，分布式电商网络平台BitCherry参与了分布式经济分会场的讨论。BitCherry首席科学家、北美区块链基金会主席Bob Qin在会上发表主题演讲“去中心化分布式计算，分布式电商网络BitCherry公链创新”，从分布式计算的角度讲解BitCherry公链对于分布式电商应用的重要意义。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827007.jpg'),
        title: "BitCherry首席科学家Bob Qin：智能合约并不局限于某一技术，应根据商业场景来构建",
        time: '2019.06.12',
        links: 'https://www.kitv.com/story/40957227/news',
        infos: [
          {
            info: "BitCherry首席科学家兼北美区块链基金会主席Bob Qin受邀以特邀嘉宾的身份发表题为《分散式分布式计算:分布式电子商务网络公共链创新》的重要演讲。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827006.jpg'),
        title: "POW'ER 2019全球开发者大会圆满收官 BitCherry基于分布式计算构建全新分布式电商网络公链",
        time: '2019.06.12',
        links: 'http://www.digitaljournal.com/pr/4426158',
        infos: [
          {
            info: "BitCherry首席科学家兼北美区块链基金会主席Bob Qin就“分散式分布式计算:BitCherry分布式电子商务公共链创新”发表了重要演讲，从分布式计算的角度阐述了BitCherry公共链对分布式电子商务应用的重要性。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827005.jpg'),
        title: "BitCherry基于分布式计算构建全新分布式电商网络公链",
        time: '2019.06.12',
        links: 'http://www.ciotimes.com/IT/178948.html',
        infos: [
          {
            info: "作为分布式商业的前沿实践，分布式电商网络平台BitCherry参与了分布式经济分会场的讨论。BitCherry首席科学家、北美区块链基金会主席Bob Qin在会上发表主题演讲“去中心化分布式计算，分布式电商网络BitCherry公链创新”，从分布式计算的角度讲解BitCherry公链对于分布式电商应用的重要意义。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827004.jpg'),
        title: "POW'ER 2019全球开发者大会圆满收官 BitCherry基于分布式计算构建全新分布式电商网络公链",
        time: '2019.06.12',
        links: 'https://www.kuam.com/story/40957160/news',
        infos: [
          {
            info: "BitCherry首席科学家兼北美区块链基金会主席Bob Qin就“分散式分布式计算:BitCherry分布式电子商务公共链创新”发表了重要演讲，从分布式计算的角度阐述了BitCherry公共链对分布式电子商务应用的重要性。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827003.jpg'),
        title: "BitCherry首席科学家、北美区块链基金会主席Bob Qin：去中心化分布式计算，分布式电商网络公链创新",
        time: '2019.06.12',
        links: 'https://www.lubbockcw.com/story/40964859/BitCherry-chief-scientist-and-chairman-of-north-american-blockchain-foundation-bob-qin-talks-about-decentralized-distributed-computing-distributed-ecommerce-network-public-chain-innovation',
        infos: [
          {
            info: "2019年8月21日，由Mars Finance主办的POW - er 2019全球开发者大会在北京举行，BitCherry首席科学家、北美区块链基金会主席Bob Qin就“分散式分布式计算:分布式电子商务网络公共链创新”做了重要演讲。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827002.jpg'),
        title: "分布式经济引发热议 BCHC顺势而为创设更多支付场景",
        time: '2019.06.12',
        links: 'http://www.chinablockchainnews.cn/blockbhainapplication/2019/08/26/12919.html',
        infos: [
          {
            info: "BitCherry为代表的分布式电商既有来自用户需求的拉动力量，又有因区块链等技术变革而产生的推动力量，而BCHC就依托于这样的平台实现其自身价值。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827001.jpg'),
        title: "BitCherry首席科学家、北美区块链基金会主席Bob Qin：去中心化分布式计算，分布式电商网络公链创新",
        time: '2019.06.12',
        links: 'http://markets.financialcontent.com/stocks/news/read/38758934',
        infos: [
          {
            info: "2019年8月21日，由Mars Finance主办的POW - er 2019全球开发者大会在北京举行，BitCherry首席科学家、北美区块链基金会主席Bob Qin就“分散式分布式计算:分布式电子商务网络公共链创新”做了重要演讲。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0823004.jpg'),
        title: "稿件中文名称：BCHC亮相全球区块链大会 欲将破解区块链“不可能三角”",
        time: '2019.06.12',
        links: 'http://www.dzshbw.com/news/2019/shuma_0819/221364.html',
        infos: [
          {
            info: "2019全球区块链开发者大会于8月20至22日在北京举行，作为全球首家分布式电商网络的数字通证，BCHC亮相于此次会场。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0823003.jpg'),
        title: "BitCherry链网：从技术架构分层打破区块链“不可能三角”限制",
        time: '2019.06.12',
        links: 'http://www.weilaicaijing.com/article/37017',
        infos: [
          {
            info: "面对这些技术创新做出选择时，BitCherry选择了从一切从业务需求出发，去平衡“不可能三角”的关系。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0823002.jpg'),
        title: "电商生态的大公链环境日臻成熟  BCHC将为更多领域提供落地支持",
        time: '2019.06.12',
        links: 'http://www.afinance.cn/lc/lcjj/201908/2429125.html',
        infos: [
          {
            info: "“POW'ER 2019 全球区块链开发者大会”的分布式经济论坛正式召开，BCHC首席科学家、北美区块链基金会主席Bob Qin作为特邀嘉宾进行了主题为“去中心化分布式计算：分布式电商网络公链创新”的演讲。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0823001.jpg'),
        title: "BitCherry链网：从技术架构分层打破区块链“不可能三角”限制",
        time: '2019.06.12',
        links: 'https://www.csdn.net/article/a/2019-08-19/15979627',
        infos: [
          {
            info: "面对这些技术创新做出选择时，BitCherry选择了从一切从业务需求出发，去平衡“不可能三角”的关系。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0819001.jpg'),
        title: "POW'ER 2019聚焦分布式经济 BitCherry公链赋能分布式电商发展",
        time: '2019.06.12',
        links: 'https://news.huoxing24.com/20190816173504754556.html',
        infos: [
          {
            info: "2019年8月20日-21日，火星财经将于北京举办“POW'ER 2019全球开发者大会”，北美区块链基金会主席、BitCherry首席科学家Bob Qin将发表题为“去中心化分布式计算：分布式电商网络公链创新”的演讲。",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0819002.jpg'),
        title: '分布式电商的生命力从哪里来?',
        time: '2019.06.12',
        links: 'http://m.zhoukan.cc/jujiao/2019/0816/jujiao08042723.html',
        infos: [
          {
            info: '分布式电商的生命力来自于其内在机制，同时又在这种内在机制的促进之下，通过功能扩展，让分布式电商最终踏上繁荣之路。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0819003.jpg'),
        title: '零售业的第五次革命，分布式电子商务的崛起之路',
        time: '2019.06.12',
        links: 'http://www.northcarolinaheadlines.com/news/story/145784/the-fifth-revolutionary-in-retail-industry-the-road-to-the-rise-of-distributed-ecommerce.html',
        infos: [
          {
            info: '分布式电子商务的生命力来自于其内在机制。同时，在这一内部机制的增强和功能的拓展下，分布式电子商务最终走上了繁荣之路。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0819004.jpg'),
        title: '分布式电商的生命力从哪里来?',
        time: '2019.06.12',
        links: 'http://www.hscbw.com/a/zonghe/2019/0816/138187.html?1565953635',
        infos: [
          {
            info: '分布式电商的生命力来自于其内在机制，同时又在这种内在机制的促进之下，通过功能扩展，让分布式电商最终踏上繁荣之路。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0819005.jpg'),
        title: '零售业的第五次革命，分布式电子商务的崛起之路',
        time: '2019.06.12',
        links: 'http://news.salemnewsheadlines.com/story/407876/the-fifth-revolutionary-in-retail-industry-the-road-to-the-rise-of-distributed-ecommerce.html',
        infos: [
          {
            info: '分布式电子商务的生命力来自于其内在机制。同时，在这一内部机制的增强和功能的拓展下，分布式电子商务最终走上了繁荣之路。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0816002.jpg'),
        title: 'BitCherry无边界生态会激发出BCHC多大潜力?',
        time: '2019.06.12',
        links: 'http://www.hzfc.cc/zx/shangye/19612.html',
        infos: [
          {
            info: 'BitCherry项目继成功进入新加坡市场之后，又顺利开拓印尼市场。这很自然让BCHC的拥趸猜想，作为BitCherry项目的通证，BCHC究竟还有多大潜力?',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0816001.jpg'),
        title: 'BCHC与SCC等达成战略合作 打造百亿美金智慧城市',
        time: '2019.06.12',
        links: 'https://tech.china.com/article/20190809/kejiyuan0129342708.html',
        infos: [
          {
            info: 'BCHC宣布与SCC、WTIA签订战略合作协议。此次战略合作的签署标志着三方将在项目孵化、技术研发、推广活动、以及促进智慧城市和区块链发展等多个关键领域深入合作。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0809004.jpg'),
        title: 'BCHC为分布式电商平台赋能 解决平台信任危机',
        time: '2019.06.12',
        links: 'http://www.3news.cn/pindao/2019/0807/0613358346.html',
        infos: [
          {
            info: 'BitCherry是全球首家分布式电商平台，它以区块链技术为基础，致力于打造一个囊括衣食住行各个方面的超级生态。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0809003.jpg'),
        title: 'BitCherry宣布与SCC、WTIA达成战略合作，携手共建420亿美元智慧城市',
        time: '2019.06.12',
        links: 'https://coins.group/BitCherry-announce-their-strategic-collaboration-achievement-with-scc-and-wita-on-building-a-42-billion-smart-city/',
        infos: [
          {
            info: '2019年7月30日，分布式电商网络BitCherry于印度尼西亚宣布与SCC、WTIA签订战略合作协议。此次战略合作的签署标志着三方将在项目孵化、技术研发、推广活动、以及促进智慧城市和区块链发展等多个关键领域深入合作。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0809002.jpg'),
        title: 'BCHC为分布式电商平台赋能 解决平台信任危机',
        time: '2019.06.12',
        links: 'http://news.flyxg.com/2019/qiqu_0807/156589.html',
        infos: [
          {
            info: 'BitCherry是全球首家分布式电商平台，它以区块链技术为基础，致力于打造一个囊括衣食住行各个方面的超级生态。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0809001.jpg'),
        title: 'BitCherry宣布与SCC、WTIA达成战略合作，携手共建420亿美元智慧城市',
        time: '2019.06.12',
        links: 'https://vnbit.net/press-release-BitCherry-cong-bo-thanh-tuu-hop-tac-chien-luoc-cua-ho-voi-scc-va-wita-ve-viec-xay-dung-thanh-pho-thong-minh-tri-gia-42-ty-do-la/',
        infos: [
          {
            info: '2019年7月30日，分布式电商网络BitCherry于印度尼西亚宣布与SCC、WTIA签订战略合作协议。此次战略合作的签署标志着三方将在项目孵化、技术研发、推广活动、以及促进智慧城市和区块链发展等多个关键领域深入合作。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806003.jpg'),
        title: 'BitCherry宣布与SCC、WTIA达成战略合作，携手共建420亿美元智慧城市',
        time: '2019.06.12',
        links: 'https://www.liantuan.vip/investment/detail?home=%25E8%25B5%2584%25E8%25AE%25AF&id=30576',
        infos: [
          {
            info: '2019年7月30日，分布式电商网络BitCherry于印度尼西亚宣布与SCC、WTIA签订战略合作协议。此次战略合作的签署标志着三方将在项目孵化、技术研发、推广活动、以及促进智慧城市和区块链发展等多个关键领域深入合作。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806008.jpg'),
        title: 'BitCherry与印度尼西亚政府和全球区块链投资联盟签署战略合作协议',
        time: '2019.06.12',
        links: 'https://bitcoinnewsindo.com/BitCherry-menandatangani-perjanjian-kerja-sama-strategis-dengan-pemerintah-indonesia/',
        infos: [
          {
            info: '2019年7月29日，印尼工商会(KADIN)、全球区块链投资联盟GBIA、香港区块链协会与BitCherry签订了战略合作协议。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806002.jpg'),
        title: 'BitCherry宣布与SCC、WTIA达成战略合作，携手共建420亿美元智慧城市',
        time: '2019.06.12',
        links: 'http://www.qingtingcj.com/tnews/70144.html',
        infos: [
          {
            info: '2019年7月30日，分布式电商网络BitCherry于印度尼西亚宣布与SCC、WTIA签订战略合作协议。此次战略合作的签署标志着三方将在项目孵化、技术研发、推广活动、以及促进智慧城市和区块链发展等多个关键领域深入合作。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806004.jpg'),
        title: 'CherryWallet启动预售，实现全球商业级安全钱包服务',
        time: '2019.06.12',
        links: 'https://bihu.com/article/1942687320 ',
        infos: [
          {
            info: '7月30日，在东南亚区块链峰会GBIS 2019（印尼站）上，区块链数字资产安全存储项目CherryWallet正式召开了全球启动仪式。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806007.jpg'),
        title: 'BitCherry结盟印尼政府和全球区块链投资联盟 助力印尼经济转型',
        time: '2019.06.12',
        links: 'https://coinvestasi.com/press-releases/BitCherry-restrukturisasi-ekonomi-indonesia-bersama-pemerintah-indonesia-dan-global-blockchain-investment-alliance/',
        infos: [
          {
            info: '2019年7月29日，印尼工商会(KADIN)、全球区块链投资联盟GBIA、香港区块链协会与BitCherry签订了战略合作协议。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806005.jpg'),
        title: '全球区块链投资峰会即将在印度尼西亚举行 探索印尼数字经济的转型',
        time: '2019.06.12',
        links: 'https://nulltx.com/the-global-blockchain-investment-summit-gbis-is-about-to-happen-in-indonesia-explore-the-transformation-of-indonesias-digital-economy/',
        infos: [
          {
            info: '4月24日，2019年全球区块链投资峰会在新加坡成功举行。在这次峰会上，来自不同国家的监管机构和技术专家齐聚新加坡，讨论了区块链技术和分布式商务的现状、发展和未来。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806006.jpg'),
        title: '全球区块链投资峰会即将在印度尼西亚举行',
        time: '2019.06.12',
        links: 'https://digitalnotice.in/?p=1586',
        infos: [
          {
            info: '4月24日，2019年全球区块链投资峰会在新加坡成功举行。在这次峰会上，来自不同国家的监管机构和技术专家齐聚新加坡，讨论了区块链技术和分布式商务的现状、发展和未来。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806001.jpg'),
        title: 'BitCherry宣布与SCC、WTIA达成战略合作，携手共建420亿美元智慧城市',
        time: '2019.06.12',
        links: 'http://uzb.com.cn/Blockchain/1304675.html',
        infos: [
          {
            info: '2019年7月30日，分布式电商网络BitCherry于印度尼西亚宣布与SCC、WTIA签订战略合作协议。此次战略合作的签署标志着三方将在项目孵化、技术研发、推广活动、以及促进智慧城市和区块链发展等多个关键领域深入合作。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/073105.jpg'),
        title: 'BitCherry与印尼政府和全球区块链投资公司签署战略合作协议，共同推动印尼经济结构调整',
        time: '2019.06.12',
        links: 'http://www.blockchainno1.io/news/17057',
        infos: [
          {
            info: '未来，BitCherry将继续为区块链和分布式电力供应商建立一个全球平台，这些供应商可以分发到商业应用，连接全球业务资源，实现数字经济学的发展，建立全球分布的业务系统。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/073104.jpg'),
        title: '全球区块链投资峰会即将在印度尼西亚举行，探索印尼数字经济的转型',
        time: '2019.06.12',
        links: 'https://www.coinspeaker.com/the-global-blockchain-investment-summit-gbis-is-about-to-happen-in-indonesia-explore-the-transformation-of-indonesias-digital-economy/',
        infos: [
          {
            info: '在为期两天的峰会期间，数十场主题演讲和专题讨论将深入探讨区块链技术的发展趋势和应用前景，为企业、行业和政府提供经济转型的前沿思维和创新解决方案。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/073103.jpg'),
        title: '全球区块链投资峰会（GBIS）即将在印度尼西亚举行',
        time: '2019.06.12',
        links: 'https://vnbit.net/press-release-hoi-nghi-thuong-dinh-dau-tu-blockchain-toan-cau-gbis-sap-dien-ra-tai-indonesia/',
        infos: [
          {
            info: '近年来，东南亚的区块链技术和应用在过去迅速发展。世界各国政府和协会以及区块链联盟正在积极探索区块链技术。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/073102.jpg'),
        title: 'BCHC的打造者与印尼政府签订战略合作协议',
        time: '2019.06.12',
        links: 'http://www.dzshbw.com/news/2019/baoguang_0730/217811.html',
        infos: [
          {
            info: 'BitCherry将与印尼政府展开区域化合作，共同推动印尼经济高速转型。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/073101.jpg'),
        title: 'BitCherry与印尼政府签订三大战略合作协议，共同推进印尼经济转型',
        time: '2019.06.12',
        links: 'http://www.chinaweekly.cn/13971.html',
        infos: [
          {
            info: 'BitCherry开发团队正式召开了公链项目开发全球启动仪式，并与印尼工商会(KADIN)、全球区块链投资联盟GBIA、香港区块链协会签订了战略合作协议。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/073001.jpg'),
        title: 'BitCherry与印尼政府签订三大战略合作协议，共同推进印尼经济转型',
        time: '2019.06.12',
        links: 'http://www.zmke.com/post/201907/6227.html',
        infos: [
          {
            info: 'BitCherry将携手印尼政府帮助印尼企业及项目打造分布式商业生态体系，双方分别签署了《区块链与分布式商业投资推动印尼经济转型战略合作谅解备忘录》、《建立区块链解决方案以降低印尼的物流成本战略合作谅解备忘录》、《提供区块链解决方案以推动印尼的经融效率战略合作谅解备忘录》。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/073000.jpg'),
        title: 'BitCherry（BCHC）独家赞助并出席全球区块链投资峰会(GBIS)晚宴',
        time: '2019.06.12',
        links: 'http://www.newyorkcj.com/blockchain/2019/07/26/6720.html',
        infos: [
          {
            info: '全球首家分布式电商平台BitCherry作为本次峰会(GBIS)晚宴的独家赞助方出席此次晚宴。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/072902.jpg'),
        title: '全球区块链投资峰会举行在即  BCHC助力分布式电商发展',
        time: '2019.06.12',
        links: 'http://www.zmke.com/post/201907/6027.html',
        infos: [
          {
            info: '作为全球首家分布式电商网络，BCHC背后的BitCherry致力于通过区块链技术赋能传统零售服务业，打造全新的共享电商超级生态。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/072901.jpg'),
        title: '触动千亿电商市场 BitCherry星耀印尼',
        time: '2019.06.12',
        links: 'https://coinvestasi.com/press-releases/ecommerce-BitCherry-indonesia/',
        infos: [
          {
            info: '作为全球首家分布式电商网络，并且将对千亿美元规模的印尼电商市场产生影响的BitCherry项目，潜力到底有多大?',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0729.jpg'),
        title: '全球区块链投资峰会即将在印度尼西亚举行',
        time: '2019.06.12',
        links: 'https://www.cryptonewsz.com/the-global-blockchain-investment-summit-gbis-is-about-to-happen-in-indonesia-explore-the-transformation-of-indonesias-digital-economy/32586/',
        infos: [
          {
            info: '全球区块链投资峰会共同探讨区块链技术与应用发展前景，探索各国政府及传统商业如何融入数字经济转型大潮，把握技术与经济发展命脉。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/caijing.jpg'),
        title: '格兰仕怒了 中心化交易平台之外是否还有其他选择?',
        time: '2019.06.12',
        links: 'https://www.jinse.com/blockchain/392601.html',
        infos: [
          {
            info: '在中心化交易平台厂商几家独大的今天，店大了，必然要欺客。',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/nulltx.jpg'),
        title: '分布式电商：新式商业文明',
        time: '2019.05.29',
        links: 'https://nulltx.com/distributed-e-commerce-a-new-commercial-civilization/',
        infos: [
          {
            info: '与中心化交易平台不同，分布式电商网络由于具备去中心化的网状组织结构，每一个主体都具有对等的地位，共同组成一个去中心化的商业生态，使得它有能力走出中心化交易平台的困境。',
            img: ''
          },

        ]
      },
      {
        img: require('../assets/img/media1/zhonghua.jpg'),
        title: '为什么分布式电商会成为电商行业的明日希望?',
        time: '2019.05.23',
        links: 'https://tech.china.com/article/20190709/kejiyuan0129324421.html',
        infos: [
          {
            info: 'BitCherry分布式电商，就是在强强联合、壮大生态、共同成长、彼此贡献的过程中实现价值创造。',
            img: ''
          },

        ]
      },
      {
        img: require('../assets/img/media1/dailyherald.jpg'),
        title: 'BitCherry将在新加坡首次举办全球区块链峰会',
        time: '2019.05.22',
        links: 'http://finance.dailyherald.com/dailyherald/news/read/38090497/',
        infos: [
          {
            info: '4月24日，全球区块链投资峰会将在新加坡举行。BitCherry将作为峰会的一个特殊项目启动。',
            img: ''
          },

        ]
      },
      {
        img: require('../assets/img/media1/tianji.jpg'),
        title: '推倒企业围墙，BitCherry让商业参与者及时、公平获益',
        time: '2019.05.21',
        links: 'http://news.yesky.com/hotnews/402/851294902.shtml',
        infos: [
          {
            info: '让人们像微信、支付宝那样，方便的用数字资产付款，离不开BitCherry这样的分布式电商底层基础设施提供商的支持，以及建构其上的链商',
            img: ''
          },


        ]
      },
      {
        img: require('../assets/img/media1/souhu.jpg'),
        title: 'BitCherry：区块链领域亚马逊、阿里巴巴的孕育者 ',
        time: '2019.05.05',
        links: 'http://www.sohu.com/a/315971361_120022803',
        infos: [
          {
            info: '区块链等新兴技术的实现，能否接过互联网大旗，续写新的商业传奇？',
            img: ''
          },


        ]
      },
      {
        img: require('../assets/img/media1/pitt.jpg'),
        title: 'BitCherry分布式电商平台正式发布',
        time: '2019.04.24',
        links: 'http://markets.post-gazette.com/postgazette/news/read/38090497',
        infos: [
          {
            info: '全球区块链投资峰会在新加坡举行，BitCherry分布式电商平台在会上举办了发布仪式。',
            img: ''
          },

        ]
      },
      {
        img: require('../assets/img/media1/lanchao.jpg'),
        title: '格兰仕事件发酵，BitCherry 解答中心化交易平台外的分布式电商',
        time: '2019.04.21',
        links: 'http://www.fjii.com/sy/qysy/2019/0620/218515.shtml',
        infos: [
          {
            info: '当参与平台的产品和服务提供商不再受困于高昂的获客和营销成本，消费者不再受困于假冒伪劣的商品，分布式电商网络内部的电商生态走向一个良性循环时，我们还有什么理由不给BitCherry 分布式电商网络的成功，投信认票呢?',
            img: ''
          },

        ]
      },

    ]
  },
  Notice: {
    banner: {
      title: '公告',
      infos: [
        ' '
      ]
    },
    noticeList: [
      {
        img: require('../assets/img/media/0531001ch.png'),
        title: "项目周报第88期 ",
        time: '2021.05.31',
        infos: [

          {
            info: '',
            img: require('../assets/img/media/0531002ch.png'),
            strong: '1'
          },



        ]
      },
      {
        img: require('../assets/img/media/0518001ch.png'),
        title: "项目周报第87期（2021.05.10-05.16） ",
        time: '2021.05.10-05.16',
        infos: [

          {
            info: '',
            img: require('../assets/img/media/0518002ch.png'),
            strong: '1'
          },



        ]
      },
      {
        img: require('../assets/img/media/0505001ch.png'),
        title: "BitCherry两周年，创始人&CEO Paul致社区的一封信 ",
        time: '2021.05.05',
        infos: [

          {
            info: '致BitCherry社区的一封信',
            img: '',
            strong: '1'
          },
          {
            info: '2021年5月5日是BitCherry成立两周年的日子，两年的砥砺前行，很高兴和您分享BitCherry两年以来的发展历程，技术进展，生态构建以及接下来的项目规划。',
            img: '',
            strong: '0'
          },
          {
            info: '当我们开始构建BitCherry平台，我们希望它为全球商企提供友好易用的区块链基础设施，并可以支持不同商业场景的定制化区块链应用。于是2020年5月，在我与团队在经过深思熟虑后修改了BitCherry的发展方向，将BitCherry重新定义为基于IPv8技术服务于分布式商业的可扩容区块链基础设施，同时我们希望BitCherry可以重新定义商业公链，重新定义DApp生态，重新定义共识和社区。我们一直在努力使这一愿景成为现实，经过这两年来的不懈努力，凝聚了整个团队的智慧和全部心血，BitCherry主网（BitCherry Chain）也终于临近上线，“高可用的区块链基础设施+分布式商业生态”的组合将更好的向大家展示出一个全新的分布式商业经济体系。',
            img: '',
            strong: '0'
          },
          {
            info: '高性能、高安全、高可用是产品的真实价值观',
            img: '',
            strong: '1'
          },
          {
            info: '我们认为，建立一个分布式商业生态体系，就是建立一个完整的链上商业世界，而基础设施将是这个商业世界的支点。从BitCherry测试网v1.0.1版本上线，再到v2.0.1版本的更新升级，BitCherry团队稳中求进，保持对技术持续创新的态度，不断更新及优化BitCherry的网络环境，使我们的网络更加安全、易用、快速、去中心化且拥有高扩容性能。',
            img: '',
            strong: '0'
          },
          {
            info: '作为一个技术为核心公司，在BitCherry主网上线之前，我们将继续按照官方既定的技术开发路线，持续完善各大技术节点，在受控环境中完成所有关键步骤，以确保整个区块链系统运行稳定和安全，并在交付每一个技术节点后，在实时环境中同步测试。随着技术的不断完善以及被有效验证之后，我们将更加重视开发者社区的建设，让开发者能够更简单的构建应用，这将成为我们构建强大开发者社区以及分布式商业生态的基础。',
            img: '',
            strong: '0'
          },
          {
            info: '推动商业数字化发展是生态发展的核心',
            img: '',
            strong: '0'
          },
          {
            info: '我们始终相信全球商业将在不久的将来逐步数字化，而区块链技术就是承载商业数字化转型的介质。这也是我们构建BitCherry的原因，一个服务于分布式商业的可扩容区块链基础设施。我们同样坚信，未来的区块链应用一定是根据实际应用场景去解决实际问题的，BitCherry链上的应用也一定是解决现实商业的痛点，可以创造新兴价值的。所以，我们需要志同道合的人，与我们共同实现这一目标，共同建立一个完整的分布式商业生态。',
            img: '',
            strong: '0'
          },
          {
            info: '2021年3月，我们正式成立BitCherry分布式生态基金，在第一期投入1000万美金的专项资金用于生态上项目的开发、竞赛、孵化以及投资，并在全球范围内开启“火种源计划”，来完善BitCherry的分布式商业生态。BitCherry也获得了全球社区成员的支持，影响力辐射至全球10多个国家，短短两个月时间，便有数十个开发者团队参与报名，在各自领域持续为BitCherry分布式商业生态添砖加瓦，带来了各领域各类型的去中心化商业应用。接下来，我们将认真筛选BitCherry分布式生态内的每一个项目，旨在培养更多有价值、有潜力区块链商业应用，打造一个年轻、充满活力、无限可能的链上分布式商业生态体系。',
            img: '',
            strong: '0'
          },
          {
            info: '而在全球生态合规布局方面，我们已经在迪拜、非洲、印尼、越南等国家与政府及当地企业展开区域化合作，共同推动当地经济高速转型。下一步，我们将会保持在这些国家的优势地位，与已合作企业建立更深度的合作，并将继续推进美国、新加坡、俄罗斯、法国、日本等国的合规化进展，持续提升BitCherry分布式商业生态的全球牵引力，切实做到用区块链技术赋能全球实体经济。',
            img: '',
            strong: '0'
          },
          {
            info: '“恪守初心 砥砺前行”是我们前进的准则',
            img: '',
            strong: '0'
          },
          {
            info: '一个项目最怕随波逐流，做的每一个决策都不过是当时环境下的应激反应。区块链技术发展不过十余年，将视野放大到整个数字经济的浪潮里，数字世界映射现实世界还处于初级阶段上，但即使环境时刻在变，BitCherry信守的原则却始终不变，除了对分布式商业世界的追求，还有对初心的恪守，我们希望BitCherry是数字商业世界中最坚固的基石。我们不会只图短暂的热度，我们将会持续积累，砥砺前行，平衡每一次发展的决策，给予社区最稳健、持续的回馈，为各领域商企业提供可落地的区块链解决方案，让全球组织和个人轻松享受区块链技术为世界带来的良好改变。',
            img: '',
            strong: '0'
          },
          {
            info: '最后，我在此真诚的感谢每一位信任我们的社区用户，感谢每一位为BitCherry贡献代码及链上应用的开发者，感谢BitCherry团队每一位伙伴，希望大家可以在新的一年里陪我们继续前行，并见证BitCherry的成长。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry创始人&CEO Paul',
            img: '',
            strong: '0'
          },
          {
            info: '2021年5月5日',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/1224001ch.png'),
        title: "BitCherry开启“火种源计划”设立1000万美金专项生态扶持基金 ",
        time: '2021.03.29',
        infos: [

          {
            info: '3月29日，BitCherry正式开启“火种源计划”，以期培养更多的有价值、有潜力的区块链商业应用，并丰富BitCherry分布式商业生态。BitCherry分布式生态基金也宣布将正式投入1000万美金的专项资金用于生态上项目的开发、竞赛、孵化以及投资，并为开发者们提供资金、技术及运营推广等多方位的支持。',
            img: '',
            strong: '0'
          },
          {
            info: '“火种（Spark）是变形金刚的灵魂，而火种源（AllSpark）则是变形金刚火种的来源和归宿地。” ',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry作为全球首家基于IPv8的分布式商业基础设施，励志成为区块链世界中的 “火种源”，而“火种源”也代表着BitCherry未来生态的多样性和持续性。BitCherry将通过凝聚技术、社区和生态资源赋能分布式商业应用发展，激活一颗颗“火种”，打造一个年轻、充满活力、无限可能的区块链世界。本次“火种源计划”，开发者可以在BitCherry上部署、运行DApp，我们将根据报名项目的运行情况评定出Top 10并给予“超级火种”的孵化奖励。 ',
            img: '',
            strong: '0'
          },
          {
            info: '',
            img: require('../assets/img/media/0329001ch.png'),
            strong: '0'
          },
          {
            info: '报名开启时间：2021年3月29日（新加坡时间）',
            img: '',
            strong: '1'
          },


          {
            info: '所有被评定为“超级火种”（Super Spark）的项目，都将有机会获得以下至少一项权益：',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry分布式生态基金的投资机会',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry分布式生态商业的资源支持 ',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry全球营销矩阵资源倾斜 ',
            img: '',
            strong: '0'
          },
          {
            info: '项目战略运营/咨询分析 ',
            img: '',
            strong: '0'
          },
          {
            info: '此外，将为入选的Top 3的项目/团队提供资金扶持、技术支持、战略制定、资源对接、团队培养等全方位的支持。具体包括： ',
            img: '',
            strong: '0'
          },
          {
            info: '资金支持：为项目提供专项基金及后续的融资对接； ',
            img: '',
            strong: '0'
          },
          {
            info: '技术指导：帮助项目团队梳理技术路线图，以及各类技术支持； ',
            img: '',
            strong: '0'
          },
          {
            info: '资源对接：提供BitCherry商业生态联盟中巨头项目的资源对接，全方位加速孵化项目应用及落地； ',
            img: '',
            strong: '0'
          },
          {
            info: '搭建内容矩阵：通过全球内容矩阵，帮助项目进行品牌宣传，获取用户及建立全球社区。 ',
            img: '',
            strong: '0'
          },
          {
            info: '报名通道 ',
            img: '',
            strong: '0'
          },
          {
            info: '请项目开发者通过BitCherry官网（www.bitcherry.io）进行报名或通过以下报名链接提交申请。 ',
            img: '',
            strong: '0'
          },
          {
            info: '中文报名链接： http://fw3nuu919ozea7cr.mikecrm.com/A15kHaV ',
            img: '',
            strong: '0'
          },
          {
            info: '英文报名链接： https://forms.gle/CEKC9PK2vsGf2JQ57 ',
            img: '',
            strong: '0'
          },
          {
            info: '评分规则： 本次《火种源计划》将更注重BitCherry生态基础设施的建设，因此以下赛道项目将更有可能在最终评分中获得加分：DeFi类应用、游戏、拍卖平台、分布式云计算、预言机、衍生品交易、数据服务等。 ',
            img: '',
            strong: '0'
          },
          {
            info: '本活动将遵循公平公正原则进行项目评分，评分维度包括：所属赛道特点和产品属性、链上数据、市场口碑、项目潜力四大类别，不同赛道的项目将从不同的维度开展评定。 ',
            img: '',
            strong: '0'
          },
          {
            info: '风险提示',
            img: '',
            strong: '0'
          },
          {
            info: '1.BitCherry将通过官方社交平台公布授权、推介等合作，开发者和用户谨慎核对，以免被虚假信息蒙骗造成损失；',
            img: '',
            strong: '0'
          },
          {
            info: '2. 所有用户及开发者都可以免费参与BitCherry链的测试环境与后续阶段，不存在收费场景；',
            img: '',
            strong: '0'
          },
          {
            info: '3. 大家务必区分好测试环境和主网，测试环境所产生的资产不具备任何价值，谨防假币诈骗； ',
            img: '',
            strong: '0'
          },
          {
            info: '4. 认准官方网址（www.bitcherry.io），避免出现被私钥钓鱼等情况。 ',
            img: '',
            strong: '0'
          },
          {
            info: '5. 此次活动最终解释权归BitCherry所有。 ',
            img: '',
            strong: '0'
          },
          {
            info: '联系我们',
            img: '',
            strong: '1'
          },
          {
            info: 'BitCherry官网：https://www.bitcherry.io/ ',
            img: '',
            strong: '0'
          },
          {
            info: 'Twitter：http://bit.ly/BitCherryTwitter ',
            img: '',
            strong: '0'
          },
          {
            info: 'Telegram：http://bit.ly/BitCherryTelegram ',
            img: '',
            strong: '0'
          },
          {
            info: 'Discord：http://bit.ly/BitCherryDiscord ',
            img: '',
            strong: '0'
          },




        ]
      },
      {
        img: require('../assets/img/media/1224001ch.png'),
        title: "BitCherry分布式生态基金正式成立，千万美金助力链上生态发展",
        time: '2021.03.26',
        infos: [

          {
            info: '区块链行业全球认知提高，技术已进入快速发展时期，但区块链技术在各产业的应用发展仍存在着诸多瓶颈。性能低、资源昂贵、链上应用受协议限制，发展空间有限等问题深深限制着区块链产业和实体商业的融合发展。随着BitCherry区块链网络的成熟，BitCherry将领携新技术补足当前商业级区块链底层架构存在的不足，为全球商业提供高性能、高安全、高可用的底层技术支持及解决方案。',
            img: '',
            strong: '0'
          },
          {
            info: '2021年3月26日，BitCherry官方宣布正式成立“BitCherry分布式生态基金”，旨在培养更多有价值、有潜力区块链商业应用，来完善BitCherry分布式商业生态。BitCherry分布式生态基金决定投入1000万美金用于链上生态应用的开发、竞赛、孵化以及投资，为生态上的开发者们提供资金、技术及运营推广等多方位的支持。',
            img: '',
            strong: '0'
          },
          {
            info: '',
            img: require('../assets/img/media/0326001ch.png'),
            strong: '0'
          },
          {
            info: '加入BitCherry分布式商业生态可以获得什么？',
            img: '',
            strong: '1'
          },


          {
            info: '在技术方面，BitCherry基于IPv8技术创建的区块链底层框架能够帮助开发者轻松创建并定制一条属于自己的完整区块链，使开发者从复杂的底层技术中解放出来，从而只需要关注自己的业务逻辑，同时，BitCherry分布式生态基金也将为入选的优质项目/团队提供资金扶持、技术支持、战略制定、资源对接、团队培养等全方位的支持。具体包括：',
            img: '',
            strong: '0'
          },
          {
            info: '资金支持：设置专项孵化基金、为优质创业项目提供启动资金以及后续的融资对接；',
            img: '',
            strong: '0'
          },
          {
            info: '技术指导：帮助区块链创业团队梳理技术路线图、帮助项目上链及一键迁移DApp应用等等； ',
            img: '',
            strong: '0'
          },
          {
            info: '资源对接：提供BitCherry商业生态联盟中巨头项目的资源对接，全方位加速孵化项目应用及落地； ',
            img: '',
            strong: '0'
          },
          {
            info: '运营/战略咨询：区块链领域资深运营团队分享战略运营、团队搭建、商业及法律咨询等方面的经验； ',
            img: '',
            strong: '0'
          },
          {
            info: '搭建内容矩阵：建立全球内容矩阵，帮助创业团队进行品牌宣传，获取用户及建立全球社区。 ',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry分布式生态基金在此欢迎所有优秀/有潜力的区块链项目团队加入我们，你可以是： ',
            img: '',
            strong: '0'
          },
          {
            info: '1、已经有完善的区块链项目方案，看好BitCherry分布式商业生态，想在生态中寻找合适的技术、团队或资金支持的团队或个人； ',
            img: '',
            strong: '0'
          },
          {
            info: '2、正在使用BitCherry区块链网络进行开发的早期团队或个人； ',
            img: '',
            strong: '0'
          },
          {
            info: '3、在以太坊等公链上做过项目，对BitCherry生态有信心的团队或个人； ',
            img: '',
            strong: '0'
          },
          {
            info: '4、将优先孵化在BitCherry区块链网络上进行开发的项目。 ',
            img: '',
            strong: '0'
          },
          {
            info: '联系我们',
            img: '',
            strong: '1'
          },
          {
            info: 'BitCherry官网：https://www.bitcherry.io/ ',
            img: '',
            strong: '0'
          },
          {
            info: 'Twitter：http://bit.ly/BitCherryTwitter ',
            img: '',
            strong: '0'
          },
          {
            info: 'Telegram：http://bit.ly/BitCherryTelegram ',
            img: '',
            strong: '0'
          },
          {
            info: 'Discord：http://bit.ly/BitCherryDiscord ',
            img: '',
            strong: '0'
          },




        ]
      },
      {
        img: require('../assets/img/media/0322001ch.png'),
        title: "公告 | BitCherry测试网络v2.0.1版本正式上线",
        time: '2021.03.22',
        infos: [
          {
            info: 'BitCherry测试网v1.0.1版本已顺利运行3个月。在此期间，BitCherry举办了各类开发者活动，鼓励全球开发者用户一同参与测试，为我们带来了许多宝贵的意见，同时BitCherry技术团队也在不断完善和丰富技术内容。',
            img: '',
            strong: '0'
          },
          {
            info: '',
            img: require('../assets/img/media/0322001ch.png'),
            strong: '0'
          },

          {
            info: '新加坡时间2021年3月22日，BitCherry测试网络v2.0.1版本正式上线。BitCherry测试网络v2.0.1版本是BitCherry测试网络v1.0.1版本的升级版本。优化了共识算法区块同步和稳定性，新增了验证者功能、验证者惩罚机制、用户Staking功能、子账户管理功能以及ERC20标准的子币种，可为开发者提供一个安全、稳定、高效的开发环境。',
            img: '',
            strong: '0'
          },

          {
            info: 'BitCherry旨在打造可服务于各类型商业的高性能、高安全、高可用的可扩容IPv8区块链基础设施平台。自路线图发布以来，项目进展良多，目前，BitCherry测试网v2.0.1版本已实现主网的大部分功能，此次BitCherry测试网络v2.0.1版本的正式发布也意味着距离BitCherry主网上线更近了一步。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry测试网络v2.0.1版本更新详细内容 ',
            img: '',
            strong: '1'
          },
          {
            info: '1、新增验证者加入功能 ',
            img: '',
            strong: '1'
          },
          {
            info: '社区用户可以根据手册搭建自己的验证者节点，抵押Token达到前100名的验证者可以挖矿。 ',
            img: '',
            strong: '0'
          },
          {
            info: '2、新增验证者惩罚机制 ',
            img: '',
            strong: '1'
          },
          {
            info: ' 验证者掉线超过100块之后，失去挖矿资格；',
            img: '',
            strong: '0'
          },
          {
            info: '惩罚200个区块之后，可以重新投票恢复验证者的挖矿权限； ',
            img: '',
            strong: '0'
          },
          {
            info: '3、新增用户Staking功能 ',
            img: '',
            strong: '1'
          },
          {
            info: '用户可以将持有的token抵押给验证者，获取抵押奖励； ',
            img: '',
            strong: '0'
          },
          {
            info: '用户可以将已经抵押的Token通过解抵押进行赎回； ',
            img: '',
            strong: '0'
          },
          {
            info: '4、新增ERC20标准的子币种 ',
            img: '',
            strong: '0'
          },
          {
            info: 'BTC 合约地址：0x0000000000000000000000000000000000000010 ',
            img: '',
            strong: '0'
          },
          {
            info: 'EOS 合约地址：0x0000000000000000000000000000000000000011 ',
            img: '',
            strong: '0'
          },
          {
            info: 'ETH 合约地址：0x0000000000000000000000000000000000000012 ',
            img: '',
            strong: '0'
          },
          {
            info: 'ONT 合约地址：0x0000000000000000000000000000000000000013 ',
            img: '',
            strong: '0'
          },
          {
            info: 'USDT 合约地址：0x0000000000000000000000000000000000000014 ',
            img: '',
            strong: '0'
          },
          {
            info: '5、新增子账户管理功能 ',
            img: '',
            strong: '1'
          },
          {
            info: '用户可以创建/删除多个子账户，并且赋予不同的子账户不同的权限组合 ',
            img: '',
            strong: '0'
          },
          {
            info: '· 权限 1：交易主资产 ',
            img: '',
            strong: '0'
          },
          {
            info: '· 权限 2：Staking ',
            img: '',
            strong: '0'
          },
          {
            info: '· 权限 3：交易子资产 BTC ',
            img: '',
            strong: '0'
          },
          {
            info: '· 权限 4：交易子资产 EOS ',
            img: '',
            strong: '0'
          },
          {
            info: '· 权限 5：交易子资产 ETH ',
            img: '',
            strong: '0'
          },
          {
            info: '· 权限 6：交易子资产 ONT ',
            img: '',
            strong: '0'
          },
          {
            info: '· 权限 7：交易子资产 USDT ',
            img: '',
            strong: '0'
          },
          {
            info: '6、优化了共识算法区块同步和稳定性 ',
            img: '',
            strong: '0'
          },
          {
            info: '在BitCherry主网正式上线之前，我们将持续进行多阶段的测试网迭代，按照固定的阶段进行功能的交付更新。接下来，BitCherry重点工作方向将是基于BitCherry测试网络v2.0.1版本的运行情况，优化共识算法的速度和数据冗余，在基础设施、系统稳定性、安全性等方面不断进行优化，此外，还将在跨链和IPFS模块等方面进行迭代和完善。 ',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry将不断追求卓越，为开发者提供一个安全、稳定、高效的开发环境，为用户打造可服务于各类型商业的高性能、高安全、高可用的可扩容IPv8区块链基础设施平台。 ',
            img: '',
            strong: '0'
          },
          {
            info: ' 开发者用户可以持续关注BitCherry开发者社区，通过我们最新发布的开发者任务，亲自参与到BitCherry网络测试的环境中，并赢取高达1000万BCHC的任务奖励。我们也将鼓励各位区块链爱好者及开发者们与我们一同参与测试，为我们带来更多宝贵的意见。',
            img: '',
            strong: '0'
          },
          {
            info: '开发者社区 ',
            img: '',
            strong: '1'
          },
          {
            info: 'Discord Group： https://discord.gg/nNY9A9tn2D ',
            img: '',
            strong: '0'
          },
          {
            info: 'Telegram Group：@BitCherryDevelopersGroupEN ',
            img: '',
            strong: '0'
          },




        ]
      },
      {
        img: require('../assets/img/media/1224001ch.png'),
        title: "近期有人员以“BitCherry”名义发布不实活动",
        time: '2021.02.03',
        infos: [

          {
            info: '近日，有社区粉丝反映有人员以“BitCherry”名义搭建社区，并组织一系列活动，譬如在imtoken钱包添加链接即可获得代币DAPP空投等。在此申明，BitCherry遵守各个地区法律法规，BitCherry基金会暂未授权任何人在中国、韩国、加拿大等国开展社区宣传工作，请大家清晰辨别，以免财产受到不必要的损失。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry公链目前在有条不紊的测试中，v2.0测试版本也即将上线。通过不懈努力，BitCherry公链的关注度和全球社区流量得到了很大提高。在此过程中，大家一定要以官方公告为准，善于鉴别各类信息，不信谣，不传谣。感谢大家的信任和支持，2021年，我们会做到最好。',
            img: '',
            strong: '0'
          },

          {
            info: 'BitCherry基金会',
            img: '',
            strong: '0'
          },
          {
            info: '2021年02月03日 ',
            img: '',
            strong: '0'
          },




        ]
      },
      {
        img: require('../assets/img/media/1224001ch.png'),
        title: "BitCherry测试网络v1.0.1版本正式开启测试",
        time: '2020.12.24',
        infos: [
          // {
          //   info: ' ',
          //   img: require('../assets/img/media/0704001.png'),
          //   strong: '0'
          // },
          {
            info: '各位关注BitCherry的社区朋友们，大家好！',
            img: '',
            strong: '0'
          },
          {
            info: '新加坡时间2020年12月24日12:00点，BitCherry测试网络v1.0.1版本正式开启测试。BitCherry作为全球首个基于IPv8技术服务于商业的可扩容区块链基础设施，致力于构建区块链技术与现实社会链接的商业通衢，并帮助传统企业构造可信任分布式商业生态环境。为此BitCherry一直以来专注于定义和提供最基础的、关键的系统组件，通过改进区块链现有技术痛点，打造可服务于各类型商业的高性能、高安全、高可用的可扩容IPv8区块链基础设施平台。',
            img: '',
            strong: '0'
          },
          {
            info: '此次开启BitCherry测试网络v1.0.1版本的测试，标志着BitCherry网络的基础设施已初具规模，BitCherry的开发将步入新的阶段，这也是过渡至主网的一个重要里程碑，我们也会持续不断的完善和丰富技术内容，从而打造一个可以完全支撑各类商业的区块链底层架构。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry v1.0.1版本测试网络实现功能',
            img: '',
            strong: '5'
          },
          {
            info: '采用主帐号+子帐号的复合账户模型',
            img: '',
            strong: '5'
          },
          {
            info: '每个子帐号可独立配置转账、执行智能合约等权限，充分保护帐号安全。帐号地址生成符合BIP44标准，支持将以太坊地址无缝转化为系统内部地址，降低开发者使用门槛。',
            img: '',
            strong: '0'
          },
          {
            info: '执行交易',
            img: '',
            strong: '5'
          },
          {
            info: '可以创建钱包账户并通过测试币来进行基础交易及通证转账。每个账户中除了主币外，还可原生内置ERC20标准的资产（子币），比如：UDST/ETH/BTC/TRX/EOS等，无需编写和部署智能合约，并节省虚拟机调用的损耗，性能更高，同时将支持超低手续费转账。',
            img: '',
            strong: '5'
          },

          {
            info: '智能合约',
            img: '',
            strong: '5'
          },
          {
            info: '采用EVM虚拟机，为智能合约字节码提供安全的执行环节，支持Solidity语言开发智能合约。在第二阶段将采用虚拟机WASM，并支持Go/Rust/C/C++/JS/TS等其它高级开发语言，还将支持以太坊接口，开发者可以将合约平滑的迁移到BitCherry网络上，实现无缝迁移。',
            img: '',
            strong: '0'
          },
          {
            info: '数据结构',
            img: '',
            strong: '5'
          },
          {
            info: '基于哈希关系图谱HashRelationshipSpectrum为数据结构，底层共识机制异步ABFT，实现高效率的交易。',
            img: '',
            strong: '0'
          },
          {
            info: '区块浏览器',
            img: '',
            strong: '5'
          },
          {
            info: '提供可媲美Etherscan的区块浏览器BitCherryScan，以直观可视化的方式，展现区块链的运行情况，并且支持区块、交易、账户、智能合约的查询。',
            img: '',
            strong: '0'
          },
          {
            info: '钱包',
            img: '',
            strong: '5'
          },
          {
            info: '提供钱包浏览器插件BitCherryWalletConnect，支持在网页中通过JS直接调用钱包功能进行交易签名、转账及智能合约调用。',
            img: '',
            strong: '0'
          },
          {
            info: '基于PoUc共识的挖矿',
            img: '',
            strong: '5'
          },
          {
            info: '基于PoUc激励体系，同时具有代理投票和抵押资产功能，来获取对应的挖矿奖励。出块奖励机制设有自动调节算法，以鼓励更多的用户参与挖矿。（挖矿、抵押和投票功能将于测试网第二阶段开放）',
            img: '',
            strong: '0'
          },
          {
            info: '更合理的架构体系',
            img: '',
            strong: '5'
          },
          {
            info: '采用底层可插拔共识模块+上层应用层的松耦合架构，扩展通用的ABCI（Application BlockChain Interface）接口，实现了更强的共识功能。',
            img: '',
            strong: '0'
          },
          {
            info: '完备的开发者工具',
            img: '',
            strong: '5'
          },
          {
            info: '提供开发者工具BitCherryTools，支持开发者非常方便地编写、部署、运行、测试、调试智能合约。',
            img: '',
            strong: '0'
          },
          {
            info: '我们还为开发者提供基本使用文档，供社区参阅。其中包括：',
            img: '',
            strong: '5'
          },
          {
            info: '1.如何创建BCWallet钱包地址和相应交易的发送；',
            img: '',
            strong: '0'
          },
          {
            info: '2.如何使用IDE编写合约，发布合约，执行合约。',
            img: '',
            strong: '0'
          },
          {
            info: '在BitCherry主网正式上线之前，我们将会进行多阶段的测试网迭代，按照固定的阶段进行功能的交付更新，将实现创建/竞选节点、共识选举/验证、跨链交互、完成网络经济体系及外围工具、完善开发者体系等。',
            img: '',
            strong: '0'
          },
          {
            info: '开发者用户可以通过我们后续发布的开发者任务，亲自参与到BitCherry网络测试的环境中，并赢取高达1000万BCHC的任务奖励。我们也将鼓励各位区块链爱好者及开发者们与我们一同参与测试，为我们带来更多宝贵的意见。如您有任何宝贵的建议，可以通过邮件或者GitHub issue与BitCherry技术团队进行交流。',
            img: '',
            strong: '0'
          },
          {
            info: '开发者邮箱：developergroup@bitcherry.io',
            img: '',
            strong: '0'
          },
          {
            info: 'GitHub issue：https://github.com/BitcherryFoundation/blockchain/issues',
            img: '',
            strong: '0'
          },
          {
            info: ' ',
            img: require('../assets/img/media/1224002ch.jpg'),
            strong: '0'
          },




        ]
      },
      {
        img: require('../assets/img/media/0708001.png'),
        title: "关于有人员盗用BCHC名义策划与IPFS不实活动行骗的提醒",
        time: '2020.07.08',
        infos: [
          // {
          //   info: ' ',
          //   img: require('../assets/img/media/0704001.png'),
          //   strong: '0'
          // },
          {
            info: '近期BitCherry社区用户反馈，有人员冒充BitCherry客服发布“关于BCHC&IPFS挖矿资产包”的活动信息，让用户存BCHC和USDT。',
            img: '',
            strong: '0'
          },
          {
            info: '针对此消息，BitCherry官方在此申明：BitCherry目前未联合IPFS策划过任何形式的活动。对于盗用BCHC名义行使诈骗的不法分子，BitChery予以严厉谴责并保留追究其相关法律责任的权利。',
            img: '',
            strong: '0'
          },
          {
            info: '在此，再次提醒大家，请广大BitCherry社区成员树立良好的安全防范意识，涉及不法人员要求BCHC、USDT转账，一定要慎之又慎，一切活动一定以官方渠道相关公告为准，保护自己的隐私和资产不受侵害。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry运营团队',
            img: '',
            strong: '5'
          },

          {
            info: '2020年7月8日',
            img: '',
            strong: '5'
          },





        ]
      },
      {
        img: require('../assets/img/media/0704001.png'),
        title: "BitCherry成功登陆全球最大行情网站CoinMarketCap（CMC）",
        time: '2020.07.04',
        infos: [
          // {
          //   info: ' ',
          //   img: require('../assets/img/media/0704001.png'),
          //   strong: '0'
          // },
          {
            info: '7月4日，基于IPv8技术服务于全球商业的可扩容区块链基础设施BitCherry正式登陆CoinMarketCap。作为成立仅一年的分布式公链项目，BitCherry的全球化社区已覆盖用户多达100余万，其数字通证BCHC上线成交量突破100亿，BitCherry的分布式商业理念也已经遍布全球包括美国、俄罗斯、中国、英国、中东、法国等10多个国家。同时，BitCherry在CoinGecko、Neironix、Coincodex、CryptoCompare、Coindar、WorldCoinIndex等全球数字资产交易信息查询网站也都是具有排名的优质公链项目。',
            img: '',
            strong: '0'
          },
          {
            info: 'CoinMarketCap（CMC）创立于2013年，目前已成为全球最大的数字资产交易信息查询网站之一。该平台不聚合新闻快讯和报告分析等，只专注于数据本身，CoinMarketCap致力于为用户提供每种资产真实的信息，让理解数字资产变得高效便捷。截止目前，CoinMarketCap共收录了2600多种数字货币，260多家交易所信息，并拥有6500万次的月独立访问量。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry此次被CoinMarketCap录入，意味着BitCherry在技术、社区、生态等方面已经获得了全球市场最权威的认可。面对全球商业多元化的需求，BitCherry集合了新技术，新金融，新产业以及创新型企业，运用“全球化+商业化+生态化”三维一体的多元化发展战略，采用“区块链+分布式商业”的革新手段，锻造全球最具商业价值的公链平台，为全球商业用户打造分布式商业生态体系，真正做到可落地的“区块链+”来服务于全球商业的革新。',
            img: '',
            strong: '0'
          },

          {
            info: '同时，BitCherry的软实力、影响力在全球市场中也早已不容小觑。BitCherry认为的产业创新需要的是一个开放、平等、自由、协作的生态，为此，BitCherry将持续利用技术实力和品牌优势，为链上分布式商业应用提供高性能、高安全、高可用的底层技术支持，并为全球用户提供安全、专业、高效、便捷、可信赖的链上服务，BitCherry正通过自身的努力成为区块链商业生态中最值得信赖的区块链基础设施平台。',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/0618001.png'),
        title: "BCHC（BitCherry项目通证）正式登陆登陆数字资产交易平台BTEX",
        time: '2020.06.18',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/0618001.png'),
            strong: '0'
          },
          {
            info: 'BitCherry项目通证BCHC将于新加坡时间2020年6月18日11点正式上线数字资产交易平台BTEX，同时开放充提服务，并将于19日11点开放BCHC/USDT交易。作为全球级的商业公链平台，BitCherry以革新物理层的全新思维搭建P2Plus点对点加密网络协议，通过由关系图谱改进的哈希图实现高度可扩展的数据架构，提供智能合约、跨链共识等运行机制，降低开发成本，将为链上企业提供一个整体的区块链分布式商业化解决方案。',
            img: '',
            strong: '0'
          },
          {
            info: 'BTEX交易所是一家成立于2017年的知名老牌数字资产交易平台，拥有来自全球超过100万的注册交易用户，目前支持9种语言，公司成员均是在业内一流交易所、各大知名金融机构的专业精英。BTEX将凭借丰富的行业资源和优秀技术研发队伍将自身打造成行业内领先级焦点，将引领全球数字资产交易平台新潮流。',
            img: '',
            strong: '0'
          },
          {
            info: '此次上线BTEX，将进一步加快BitCherry以技术推动全球区块链行业应用落地的节奏。作为基于IPv8技术服务于分布式商业的可扩容区块链基础设施，BitCherry致力于打造区块链技术与现实社会链接的商业通衢，并将继续以落地全球商业化应用，链接全球资源，赋能经济发展为使命，构建全球商业生态体系。',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabg0613001.png'),
        title: "关于BitCherry基金会 与BitTok公司首次达成重要战略合作的公告",
        time: '2020.06.16',
        infos: [

          {
            info: '根据BitCherry基金会管理章程的有关规定，经BitCherry基金会讨论、审议并决定与数字资产管理平台BitTok达成深度战略合作。双方将在区块链应用、数字资产交易、公链技术三个维度展开，为未来资产数字化建立可行性方案。',
            img: '',
            strong: '0'
          },
          {
            info: '从模式实践到范式落地，BitCherry自立项以来，始终在技术架构上把对商业性的满足放到了首位。结合自主研发的IPv8技术，分片式应用，跨链协议技术，图灵完备技术，成功实现了TPS的间接性突破，为落地应用提供了可行性的理论和实践基础。未来，BitCherry将继续履行“三大战略”，积极推动发现区块链的更多使用场景与商业落地，打造一个包含技术、社群、商业多方面要素的完整生态体系。BitCherry的总体目标就是实现对BlockChain Plus时代的价值主张，为下一代价值互联网构建基于区块链的底层核心基础设施平台，并为实体经济以及各行业应用提供可靠实用的区块链工具。',
            img: '',
            strong: '0'
          },
          {
            info: '此次合作，双方利用各自的资源优势，为全球广大BCHC持有用户创造更大的价值。具体合作细则详见BitTok官方公告。',
            img: '',
            strong: '0'
          },


          {
            info: '2020年06月16日',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/0616001.png'),
        title: "BCHC正式登陆东欧最大的数字资产交易平台Coinsbit",
        time: '2020.06.16',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/0616001.png'),
            strong: '0'
          },
          {
            info: '据官方消息，BitCherry项目通证BCHC将于新加坡时间2020年6月16日20点正式上线东欧最大的数字资产交易平台Coinsbit，同时开放充值服务，并将于17日20点开放BCHC/USDT交易。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry是基于IPv8技术服务于分布式商业的可扩容区块链基础设施，具备性能高效、数据安全、共识治理的等三大特征，致力于打造区块链技术与现实社会链接的商业通衢，帮助传统企业构造可信任分布式商业生态环境，实现生态价值高速流转。自上线UPEX、Poloniex两大交易所以来，BitCherry已获得众多国际媒体的关注报道，此次又在短时间内上线东欧最大的交易平台Coinsbit，标准着其全球化版图升级到新的高度。',
            img: '',
            strong: '0'
          },
          {
            info: 'Coinsbit作为东欧最大数字资产交易平台，拥有来自200多个国家/地区的220万注册用户，Coinsbit接受 Visa /MasterCard进行存提款，平台支持14种语言，在Coinmarketcap长期排名前20, 其24小时交易量超过8亿美元。',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabg0613001.png'),
        title: "关于WBF上线BCHC相关问题公告",
        time: '2020.06.13',
        infos: [

          {
            info: '近日，BitCherry基金会接到社区反馈，WBF交易所发布了上线BCHC通告，在此申明，截止目前，我方并未与该交易所有过任何形式的交流和商务合作，该所行为属于自发行为。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry今年以来，市场战略以中东、欧美市场为主攻方向，已在全球多个国家建立社区，逐渐凝聚共识力量，稳步推进市场。近期BitCherry基金会通证BCHC相继上线中东地区知名的交易平台UPEX以及美国知名老牌交易平台P网（Poloniex）。对于上线交易所问题，BitCherry基金会坚持精益求精，慎之又慎的原则，会对相关交易所以及用户市场详细调研，只有符合BitCherry战略市场规划的交易所才会进行合作。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry基金会无法避免第三方交易所自发上币行为，在此，提醒广大BitCherry社区成员，关于BitCherry上所问题，以官方通告为主，也谨慎将BCHC转到没有经过官方公告的交易所，避免资产的不必要流失。',
            img: '',
            strong: '0'
          },
          {
            info: '最后，感谢大家一直以来对BitCherry的关注与支持。',
            img: '',
            strong: '0'
          },

          {
            info: '2020年06月13日',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabg0611001.png'),
        title: "BCHC（BitCherry项目通证）上线美国老牌数字资产交易平台Poloniex",
        time: '2020.06.11',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/mediabg0611001.png'),
            strong: '0'
          },
          {
            info: '新加坡时间6月11日10:00，BCHC（BitCherry项目通证）正式上线美国数字资产交易平台Poloniex（P网），并将于新加坡时间6月13日10:00开通BCHC/USDT交易对。Poloniex（P网）成立于2014年，是美国一家老牌加密资产交易平台，交易量曾一度登顶全球TOP3，被获得高盛、百度、比特大陆投资的Circle公司收购，后又获得波场等投资人的注资。其愿景是重构全球信任体系，让交易触达每个角落，解放价值，使价值自由流动。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry作为全球首家基于IPv8技术服务于分布式商业的可扩容区块链基础设施，针对目前区块链公链领域所面临的技术性能低、主链拥堵问题严重、开发门槛高、Token使用场景单一等问题提出解决方案。BitCherry提出将利用“Blockchain Plus”来构造一个自由度极高的可信任分布式商业生态环境，为更多企业打造便捷易用的通用型区块链基础设施，并为他们提供高效、便捷的区块链技术解决方案。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry积极拓展全球市场，已持续拓展全球20多个国家。而项目通证BCHC在近期上线中东最大数字资产交易平台UPEX并深化布局中东市场后，更是多次被国际知名区块链媒体所报导，同时合作的多家知名机构及企业更是对BitCherry给予了高度的评价。BitCherry项目通证BCHC此次上线世界级老牌数字资产交易平台Poloniex，将进一步深化BitCherry欧美及全球市场的战略布局，构造可信任的分布式商业生态环境，扩大商业闭环。',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/0527001.png'),
        title: "BCHC（BitCherry项目通证）正式上线中东最大数字资产交易平台UPEX",
        time: '2020.05.27',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/0527001.png'),
            strong: '0'
          },
          {
            info: '新加坡时间2020年5月27日，BCHC（BitCherry项目通证）正式上线中东著名数字资产交易平台UPEX，并将在5月28日18:00时开通BCHC/USDT交易对。UPEX作为中东区域最大的数字货币交易平台，目前已获得巴林政府颁发的合法牌照，在当地央行的监管下支持美金的合法出入，同时支持沙特、巴林、阿联酋等本地货币，日均交易量超过三亿美金。UPEX独特的精细化、本地化的运营也更符合中东用户的使用需求，同时为中东用户提供阿拉伯语支持、并支持多国法币、多种数字货币的现货交易服务。',
            img: '',
            strong: '0'
          },
          {
            info: '中东地区以丰富的石油储量闻名全球，而近几年，中东地区国家政府一直在寻求用以优化石油经济的新兴技术，中东各国政府及企业对引进区块链技术表示出积极支持的态度。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry始终秉承着开放融合的态度与全球优质区块链项目进行价值交互，创造新的商业结构，此次BCHC上线UPEX，将促进BCHC价值获得中东市场的认可，而在BitCherry的生态系统和信用体系的支持下，中东投资者们的权益也将受到更多保障。',
            img: '',
            strong: '0'
          },
          {
            info: '未来，BitCherry将会与已合作企业建立更深度的合作，帮助他们实现区块链化转型，同时将积极进行商务拓展，帮助更有影响力的公司构建分布式商业架构，相信在BitCherry打造的区块链商业网络的加持下，中东的经济必将完成战略级别的迭代与转型升级。',
            img: '',
            strong: '0'
          },
          {
            info: '作为早已战略布局中东市场的业内技术先驱，BitCherry将重点开发中东地区，与当地更多企业建立深度合作，将共同推动中东区块链技术发展，拓宽中东商业应用场景，打造中东区块链商业新生态，相信在BitCherry打造的区块链商业网络的加持下，中东的经济必将完成战略级别的迭代与转型升级。',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabg0505003.png'),
        title: "公开信 | 在未知中收获惊喜，BitCherry一周年，创始人Paul致社区的一封信",
        time: '2020.05.05',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/mediabg0505003.png'),
            strong: '0'
          },
          {
            info: '时光如斯,然终不负伊始，经过近一年的高效开发及技术迭代，BitCherry由过去的分布式电商公链进化成为了全球首家基于IPv8服务于分布式商业的可扩容区块链基础设施。',
            img: '',
            strong: '0'
          },
          {
            info: '回顾立项至今的12个月以来BitCherry已经成长为一个优秀成熟的团队，并且在既定的计划之内完成了一个又一个里程碑。截止到今日，我们成功举办了超过100次的社区活动，吸引了200余名社区合伙人加入我们，先后与四个国家政企签署了战略合作协议，并参加了超10场国际大会，我们的分布式商业理念所致美国、印尼、土耳其、迪拜、越南等10多个国家。这一年间，我们的脚步从未停下。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry团队向来以稳中求进要求自己，这也得以使我们从初版白皮书的发布，再到如今技术的升级迭代都能够按计划如期交付。得益于此，BitCherry也收获了一批又一批忠实的粉丝，这100多万个社区用户都无不见证着我们的成长。尽管2019年的市场激荡，但就是在这样的环境中，BitCherry做到了我们想做的一切，然而在我看来这还远远不够，如果说将区块链的发展当做一本编年史，那2019年只是BitCherry的第一章。',
            img: '',
            strong: '0'
          },
          {
            info: '区块链的未来究竟在哪里？',
            img: '',
            strong: '0'
          },
          {
            info: '正如上文所述，过去的几年间，区块链行业在风口处一鸣惊人，又在低谷间夹缝求生，但大浪终会淘沙，市场一定会选择一个兼备技术、商业、以及生态完善的项目作为着眼点，而随着大众对区块链认知的提升，良币驱逐劣币也只是时间问题。',
            img: '',
            strong: '0'
          },
          {
            info: '就我们眼前的案例来说，FaceBook、摩根大通、微软、IBM等头部企业近几年在区块链上所做的尝试，都足以佐证，去中心化技术、分布式商业模式的可行性与必要性，这也意味着这场技术革命正在悄然的改变着我们的生活。',
            img: '',
            strong: '0'
          },
          {
            info: '回归到主题，区块链项目从几年前的野蛮发展，到如今的合规部署，“绝地求生”的状态早已成为历史，“稳步发展”的使命渐渐成为主流，也正因如此，市面上的确出现了一些令人耳目一新的行业领军者。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry升级后的技术方向',
            img: '',
            strong: '0'
          },
          {
            info: '没错，BitCherry就是这样一个走在前沿的分布式商业项目。如你所见，在过去的12个月当中，BitCherry厚积薄发，经过数月的技术调整及优化，我们以全新的姿态跑步进入公链这条赛道上。也许在众人看来，踏入公链这片红海或许不是一个正确的选择。但在我看来，公链的机遇与潜力远远超过了我们的想象。',
            img: '',
            strong: '0'
          },
          {
            info: '众所周知，网络应用的运行离不开基础网络架构，但纵观当前公链赛道中，一个能够真正支撑大规模网络效应的公链还没有开发出来。要知道，概念和实现永远是两种完全不同的东西，这也就说明了为什么我们已经在分布式电商领域小有成就的时候，却将视角移步至公链上。',
            img: '',
            strong: '0'
          },
          {
            info: '接下来，我将就BitCherry的如今所为，简要的为大家做一个概述。',
            img: '',
            strong: '0'
          },
          {
            info: '理论方面，技术团队综合了互联网多年的工程经验，首次提出了BlockChain Plus，意在通过可执行的技术手段，改善当前公链扩容性差、性能不足、治理结构不合理、安全性低等问题。首先在网络协议上，我们提出了P2Plus，简而言之就是将网络协议工作下沉至更为底层的IP网络层和数据链路层以确保点对点传输数据的可达性与安全性。其次在数据结构方面，我们采用了HashRelationshipSpectrum技术，使共识层算法和底层P2P算法高度一致，并且带来了高速的网络连接效率和高安全的账本共识。针对治理结构，我们创新型的设计了用户价值度证明PoUc，参考用户行为权重进行虚拟投票，选中节点获得记账奖励，让通证持有者有权参与项目发展及链上治理工作，提升节点效率。在扩容方向，BitCherry融入了分片以及侧链的概念，以此来提升Dapp的性能，优化通证经济网络。',
            img: '',
            strong: '0'
          },
          {
            info: '以下是此次升级后，我们的部分开发计划：',
            img: '',
            strong: '0'
          },
          {
            info: '技术实现以及测试：我们会在拓展商业生态体系之外，按照官方既定的技术路线，完善各大技术节点，在受控环境中完成所有关键步骤，以确保整个区块链系统运行稳定和安全，此外，每一个技术节点交付后，我们会在实时环境中同步测试。',
            img: '',
            strong: '0'
          },
          {
            info: '技术社区建设：项目代码同步GitHub，实时开源，并且团队将公开，有效地建立开发者社区，招募优秀的个人开发者，与我们一起工作，期间会设立合理的开发者激励计划，以推动我们的技术进展，以及纠错、BUG修复等。',
            img: '',
            strong: '0'
          },
          {
            info: '经济治理结构规划：我们珍惜每一个用户，无论是节点运营商又或是个人持币用户。随着技术的不断完善以及被有效验证之后，我们将逐步披露我们的经济治理结构，并在重视长期网络稳定性，安全性，完整的生态系统一致性的基础上公开投票。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry 升级后的商业视角',
            img: '',
            strong: '0'
          },
          {
            info: '拓展分布式商业版图：针对传统商业，我们将会积极拓展应用场景，为更多企业打造便捷易用的通用型区块链基础设施，并为他们提供高效、便捷的区块链技术解决方案。下一步我们将会持续提升BitCherry在全球市场的影响力，通过寻找头部企业、政府机构背书，来使BitCherry成为一个更优秀的公司。当前，我们已经在美国、印尼、迪拜、土耳其、越南等地崭露头角，未来，我们会持续保持在这些地方的优势地位，并在其他地方获得更大的牵引力。',
            img: '',
            strong: '0'
          },
          {
            info: '深度赋能传统企业：自项目成立以来，我们一直在寻找合适的机会来教育企业和探索合作，涉及的交流合作包括供应链金融、电商、基础能源等公司。下一步，我们将会与已合作企业建立更深度的合作，帮助他们实现区块链化转型。此外我们也会积极进行商务拓展，帮助更有影响力的公司构建分布式商业架构。',
            img: '',
            strong: '0'
          },
          {
            info: '提升社区综合素质：除了商业、技术之外，社区始终是一个项目的关键节点，更是一个项目生态的重要组成部分，接下来，我们会更加注重社区中的内容质量，以及组成形态，目前计划内的是建立开发者社区，以便让爱好者们通过不同的维度了解我们，此外，我们会积极的与各大教育机构、组织达成合作，通过举办讲座、线下MeetUp等活动形式，为社区注入更多新鲜的血液。',
            img: '',
            strong: '0'
          },
          {
            info: '写在最后',
            img: '',
            strong: '0'
          },
          {
            info: '推翻从来都不是一件易事，“下一步”也永远都是未知，但尝试和探索却是一种习惯，机遇和未来也总藏在未知之后，因此，接下来的旅途中，我们邀请您与我们共同迈出下一步，在未知中收获惊喜，也请您与我们共同见证，BitCherry的未来成长。',
            img: '',
            strong: '0'
          },




        ]
      },
      {
        img: require('../assets/img/media/mediabg0505002.png'),
        title: "BitCherry X BitTok一周年庆典活动，买BCHC送USDT",
        time: '2020.05.05',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/mediabg0505002.png'),
            strong: '0'
          },
          {
            info: '2020年5月5日，分布式商业公链项目BitCherry迎来一周年庆典，并于当天正式发布了新版白皮书《BitCherry——全球首个基于IPv8技术服务于分布式商业的可扩容区块链基础设施白皮书v2.0.1》。',
            img: '',
            strong: '0'
          },
          {
            info: '值此之际，BitCherry于2020年5月5日00:00——5月11日24:00 (GMT+8)与BitTok交易所联合推出“周年庆”活动。活动期间，交易、持仓一定数量BCHC即可获取USDT奖励。',
            img: '',
            strong: '0'
          },
          {
            info: '报名时间：',
            img: '',
            strong: '1'
          },
          {
            info: '2020年5月5日00:00–5月11日24:00(GMT+8)',
            img: '',
            strong: '0'
          },
          {
            info: '活动周期：',
            img: '',
            strong: '1'
          },
          {
            info: '2020年5月5日00:00–5月17日24:00(GMT+8)',
            img: '',
            strong: '0'
          },
          {
            info: '活动规则：',
            img: '',
            strong: '1'
          },
          {
            info: '在 “报名时间” 内，净买入BCHC大于等于10000个，并且连续持仓超过7日的用户，即可获得50个USDT的奖励，奖励将于持仓期满后的3个工作日内发放到用户的钱包中。',
            img: '',
            strong: '0'
          },
          {
            info: '注意事项：',
            img: '',
            strong: '1'
          },
          {
            info: '1、净买入=买入-卖出；',
            img: '',
            strong: '0'
          },
          {
            info: '2、每天00：00至 03：00之间对用户的持仓账户进行随机快照，以计算净买入量是否大于等于10000个BCHC为准，来判断前一日持仓是否达标；',
            img: '',
            strong: '0'
          },
          {
            info: '3、持仓期间有一天不达标，则之前累积的持仓天数将归零，补齐规定持仓数后，将重新开始累计持仓天数；',
            img: '',
            strong: '0'
          },
          {
            info: '4、用户账户中2020年5月4日24:00（GMT+8）之前所持有的BCHC，其数量将不会被计入活动中的净买入量；',
            img: '',
            strong: '0'
          },
          {
            info: '5、用户可以选择在活动期间选择任何一天参加活动，但只有报名参加，并且连续7天持仓达标，才能获得奖励。',
            img: '',
            strong: '0'
          },




        ]
      },
      {
        img: require('../assets/img/media/mediabg0505.png'),
        title: "《BitCherry—全球首个基于IPv8技术服务于分布式商业的可扩容区块链基础设施白皮书v2.0.1》正式发布",
        time: '2020.05.05',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/mediabg0505.png'),
            strong: '0'
          },
          {
            info: '2020年5月5日，BitCherry正式发布《BitCherry——全球首个基于IPv8技术服务于分布式商业的可扩容区块链基础设施白皮书v2.0.1》，这是继BitCherry在分布式电商领域涉足后的又一次重大技术更新。',
            img: '',
            strong: '0'
          },
          {
            info: '站在经历过数十年工程验证的互联网角度去审视，如今的区块链技术仅仅是其商业化进展中的开端，一个可实现真正意义上商业落地的区块链基础设施至今还仍未出现。',
            img: '',
            strong: '0'
          },
          {
            info: '有鉴于此，在该篇白皮书中，我们将为您介绍一个满足于未来分布式商业的价值公链。更新后的BitCherry技术生态中包涵了P2Plus加密协议（网络协议）、哈希关系图谱HashRelationshipSpectrum（数据结构）、aBFT+PoUc（共识机制）、横向分片技术、纵向侧链技术、以及跨链协议。我们相信，BitCherry通过此次革命性的升级将极大地推动未来社会的商业价值，并通过技术实现来进一步推动区块链技术在商业社会中的影响力。',
            img: '',
            strong: '0'
          },
          {
            info: '未来，随着技术的不断完善，BitCherry作为全球首个基于IPv8技术服务于分布式商业的可扩容区块链基础设施，将以革新物理层的全新思维搭建点对点加密网络协议，为链上分布式商业应用提供高性能、高安全、高可用的底层公链支持，服务也将涉及供应链金融、资产数字化、商业消费、电商、分布式云计算等更多的商业领域。',
            img: '',
            strong: '0'
          },




        ]
      },
      {
        img: require('../assets/img/media/mediabg0504.png'),
        title: "ONE YEAR直播 | 初心回顾，BitCherry CEO Paul为您解读分布式商业公链",
        time: '2020.05.04',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/mediabg0504.png'),
            strong: '0'
          },
          {
            info: '2020年5月5日是BitCherry一周年，BitCherry将于5月5日晚8点在telegram 平台BitCherry 英语社区开启直播活动，BitCherry CEO Paul 将作为本次直播嘉宾。ONE YEAR直播，初心回顾，解读分布式商业公链。在直播尾声我们将开启AMA问答模式，提问关于BitCherry 的问题，Paul将任意选择5个进行回答，被选中的每人将获得50BCHC奖励。加入社区（t.me/BitCherryEnglish）等待开启！！！ ',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabg0430.png'),
        title: "活动 | BitCherry一周年，开启“我与BitCherry”有奖征文活动",
        time: '2020.04.30',
        infos: [
          {
            info: ' 2020年5月5日，BitCherry正式迎来一周年庆典。在过去的一年中，来自全球40多个国家的爱好者们给予了BitCherry全方位的支持，这帮助着我们不断扩大着生态的发展。目前，BitCherry全球爱好者已突破100w+，BitCherry的商业理念也已落地全球10多个国家，包括中国、美国、迪拜、土耳其、迪拜、印尼、越南….BitCherry与4个国家签署战略合作协议，共同打造全球“区块链+”的经济生态。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry的技术也迭代升级2.0，作为全球首个基于IPv8技术服务于分布式商业的可扩容区块链基础设施，首次提出了BlockChain Plus概念，以革新物理层的全新思维搭建点对点加密网络协议，将为链上分布式商业应用提供高性能、高安全、高可用的底层公链支持。',
            img: '',
            strong: '0'
          },
          {
            info: '值此一周年来临之际，BitCherry面向全球社区用户开启“我与BitCherry的故事”主题征文活动，活动期间，将征文发送至邮箱（contact@bitcherry.io），即可参与活动并赢取奖励，参与者每人可获得55个BCHC的奖励，同时BitCherry将选出10篇优秀征文，其内容贡献者每人可额外获得550个BCHC的奖励。',
            img: '',
            strong: '0'
          },
          {
            info: '征文要求：',
            img: '',
            strong: '1'
          },
          {
            info: '发送邮件主题统一命名为：微信号+“我与BitCherry的故事”+ BitTok交易所注册邮箱，投稿内容不低于200字。',
            img: '',
            strong: '0'
          },
          {
            info: '活动结束后, BitCherry将对投稿内容进行审核，优秀征文将会在BitCherry公众号统一展示。',
            img: '',
            strong: '0'
          },
          {
            info: '参与步骤：',
            img: '',
            strong: '1'
          },
          {
            info: '1、关注BitCherry Twitter账号 (twitter.com/BitCherryGlobal)',
            img: '',
            strong: '0'
          },
          {
            info: '2、转发活动帖子',
            img: '',
            strong: '0'
          },
          {
            info: '3、加BitCherry英文电报群 (t.me/BitCherryEnglish)',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabg38.png'),
        title: "活动 | BitCherry愚人节活动限时开启，5000BCHC等你来拿!",
        time: '2020.04.01',
        infos: [
          {
            info: ' 4月1日，BitCherry官方将在Twitter及Telegram Global社区开启愚人节限时活动。5000BCHC等你来拿!',
            img: '',
            strong: '1'
          },
          {
            info: '活动时间：2020年4月1日~7日',
            img: '',
            strong: '0'
          },
          {
            info: '参与方式：',
            img: '',
            strong: '1'
          },
          {
            info: '1、关注官方Twitter账号+转发“愚人节推文”',
            img: '',
            strong: '0'
          },
          {
            info: '2、加入官方Telegram Global社区',
            img: '',
            strong: '0'
          },
          {
            info: '3、填写活动表单',
            img: '',
            strong: '0'
          },
          {
            info: '官方Twitter账号：@BitCherryGlobal',
            img: '',
            strong: '0'
          },
          {
            info: '官方Telegram Global社区：@BitCherryGlobal',
            img: '',
            strong: '0'
          },
          {
            info: '活动奖励：',
            img: '',
            strong: '1'
          },
          {
            info: '1、活动奖励总数为5000个BCHC，前500名参与，每人可获得5个BCHC；',
            img: '',
            strong: '0'
          },
          {
            info: '2、如果参与人数达到500人及以上，将会从中抽取10名幸运用户分别发放额外奖励。',
            img: '',
            strong: '0'
          },
          {
            info: 'Top1: 500个BCHC',
            img: '',
            strong: '0'
          },
          {
            info: 'Top2: 300个BCHC',
            img: '',
            strong: '0'
          },
          {
            info: 'Top3: 200个BCHC',
            img: '',
            strong: '0'
          },
          {
            info: 'Top4~10: 100个BCHC',
            img: '',
            strong: '0'
          },
          {
            info: '注：幸运用户抽取规则',
            img: '',
            strong: '1'
          },
          {
            info: '1、需要评论区分享你关于愚人节的有趣故事；',
            img: '',
            strong: '0'
          },
          {
            info: '2、转发并@好友，获赞数越多，中奖率越高。',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabg35.png'),
        title: "BCHC正式上线中东地区知名钱包TrustWallet",
        time: '2020.03.10',
        infos: [
          {
            info: ' BitCherry官方数字货币 BCHC已通过土耳其及中东知名钱包Trust Wallet的审核，正式上线。BCHC正式登陆Trust Wallet，将加快BitCherry在土耳其及中东市场的战略布局，进一步推进区块链技术在土耳其及中东市场的商业化发展进程。',
            img: '',
            strong: '0'
          },
          {
            info: 'Trust Wallet（trustwallet.com ）是一个安全，开源，去中心化和匿名的以太坊钱包应用程序，支持以太坊和20,000多种基于以太坊的不同代币（ERC20，ERC223和ERC721），它提供了高级别的安全审计系统，用于发送、接收和存储数字资产。自2017年11月推出以来，受到了广泛的用户欢迎。该公司在安全性方面建立了声誉，并坚持其指导原则，即永远不会访问用户的钱包，持有私钥和索取个人信息。',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabg34.png'),
        title: "BitCherry全新升级为全球首个基于IPv8技术的分布式商业公链",
        time: '2020.02.20',
        infos: [
          {
            info: ' BitCherry的社区爱好者们：',
            img: '',
            strong: '1'
          },
          {
            info: '信任是商业社会的基础，为更好解决商业社会中信任成本高昂、流动性弱及金融资源垄断等问题，BitCherry基于全球共识的商业场景对技术进行了全面优化，将于2020年2月20日起对技术进行迭代升级，将构建一个真正满足未来商业应用需求可落地的区块链基础设施，近期将会发布最新版技术白皮书。',
            img: '',
            strong: '0'
          },
          {
            info: '技术升级后，BitCherry将成为全球首个基于IPv8技术服务于分布式商业的可扩容区块链基础设施，此次升级以革新物理层的全新思维搭建P2Plus点对点加密网络协议，将具备性能高效，数据安全等特性以及更合理完善的共识治理机制，服务涉及供应链金融、资产数字化、商业消费、电商、分布式云计算等众多商业领域，并致力于为链上分布式商业应用提供高性能、高安全、高可用的底层公链支持。',
            img: '',
            strong: '0'
          },
          {
            info: '随着技术的全面革新升级，BitCherry官方网站也进行了全面改版，将更新技术部分内容，新增了技术特点及商业应用场景等板块。',
            img: '',
            strong: '0'
          },
          {
            info: '网址：www.bitcherry.io',
            img: '',
            strong: '0'
          },
          {
            info: '目前，网站改版已进入试运行阶段，网站信息正在完善中，若造成浏览不便，敬请谅解。 最后，衷心感谢您一直以来对BitCherry的关注与支持！',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry',
            img: '',
            strong: '1'
          },
          {
            info: '2020年2月20日',
            img: '',
            strong: '1'
          },




        ]
      },
      {
        img: require('../assets/img/media/mediabg33.png'),
        title: "门票免费送！土耳其Blockchain Economy 2020 峰会盛大来袭",
        time: '2020.02.04',
        infos: [
          {
            info: ' 为了庆祝情人节，BitCherry决定举办免费赠票活动，在Twitter/Facebook/Instagram/LinkedIn/Telegram上参与活动，你将有机会赢得在伊斯坦布尔举办的Blockchain Economy 2020 门票 !活动详情请关注Twitter/Facebook/Instagram/LinkedIn或加入Telegram社区!',
            img: '',
            strong: '0'
          },




        ]
      },
      {
        img: require('../assets/img/media/mediabg32.png'),
        title: "BitCherry 社区大使全球招募",
        time: '2019.12.31',
        infos: [
          {
            info: ' BitCherry 社区大使全球招募',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry项目发展至今，离不开社区成员的大力支持及鼓励。我们坚信优质社区是项目发展不可或缺的助力之一。BitCherry已在美国、俄罗斯、韩国、越南、新加坡、菲律宾、土耳其等众多国家开始战略布局，为了让更多与我们有共识的社区用户能够参与到BitCherry的共建之路，共享发展价值，现面对全球各大社区招募社区大使。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry项目全称为BITCHERRY FOUNDATION LTD.，成立于新加坡，是一个符合未来商业社会，基于分布式账本技术的高性能底层网络架构。BitCherry致力于打造区块链技术与现实社会链接的商业通衢，通过将资产登记上链、高效流通来建立一个满足商业需求的去中心化网络，并与万亿规模的消费场景深度结合，利用通证经济优势链接全球电商运营者和消费者，共同打造一个自由、安全、互治、互信、公平、无国界的电商生态，在其生态中，所有价值自由流通、信息高度耦合。为扩大BitCherry社区全球影响力，现BitCherry正式启动“全球社区大使”招募计划！',
            img: '',
            strong: '0'
          },
          {
            info: '在BitCherry全球社区中，我们将万里挑一，pick最有能量的你占据社区未来发展的核心C位，为项目提供持续、积极的贡献，为技术社区的壮大添砖加瓦，与BitCherry一同更高效地打造最优社群！',
            img: '',
            strong: '0'
          },
          {
            info: '要求：',
            img: '',
            strong: '0'
          },
          {
            info: '•	认同BitCherry的愿景及价值理念，了解BitCherry核心技术以及项目进展；',
            img: '',
            strong: '0'
          },
          {
            info: '•	有准备、有能力、有动力来加深对BitCherry的认识，扩大BitCherry在全球区块链行业的影响力；',
            img: '',
            strong: '0'
          },

          {
            info: '•	具有一定数量的活跃社群或自媒体粉丝；',
            img: '',
            strong: '0'
          },
          {
            info: '•	擅长运营Telegram 、Facebook、WhatsApp等社群；',
            img: '',
            strong: '0'
          },
          {
            info: '•	对区块链行业有一定的理解与思考；',
            img: '',
            strong: '0'
          },
          {
            info: '•	参选前请确保能够坚持在任至少一个季度，并保持活跃（中途退出、失联或未完成职责将无法获得相应奖励）；',
            img: '',
            strong: '0'
          },
          {
            info: '•	英语水平良好，有区块链或社区的运营经验。',
            img: '',
            strong: '0'
          },
          {
            info: '职责：',
            img: '',
            strong: '0'
          },
          {
            info: '•	协助进行BitCherry官方电报群管理（解答用户疑问，保持群内氛围活跃，维护群内容等）；',
            img: '',
            strong: '0'
          },
          {
            info: '•	在BitCherry官方电报群内发起话题并参与讨论；',
            img: '',
            strong: '0'
          },
          {
            info: '•	与BitCherry共同建设流量生态；',
            img: '',
            strong: '0'
          },
          {
            info: '•	关注、转发、点赞并评论BitCherry社交媒体上的材料及内容；',
            img: '',
            strong: '0'
          },
          {
            info: '•	及时分享BitCherry项目的进展、规划、新闻等内容至社交媒体平台及社区；',
            img: '',
            strong: '0'
          },
          {
            info: '•	每月向社区提交管理员工作小结，分享心得与体会。',
            img: '',
            strong: '0'
          },
          {
            info: '加分项：',
            img: '',
            strong: '0'
          },
          {
            info: '•	将BitCherry官方内容翻译成不同语言并分享至社区；',
            img: '',
            strong: '0'
          },
          {
            info: '•	制作关于BitCherry的原创内容（文章、视频、表情包等）；',
            img: '',
            strong: '0'
          },
          {
            info: '•	建立并管理200–300人的多语种线上社区（视具体社区发展需求）；',
            img: '',
            strong: '0'
          },
          {
            info: '•	为BitCherry全球社区发展提供有价值的创意与建议；',
            img: '',
            strong: '0'
          },
          {
            info: '•	有丰富的市场资源，可对接当地主流交易所、知名媒体平台、具有线下零售消费场景应用资源。',
            img: '',
            strong: '0'
          },
          {
            info: '•	奖励及权益：',
            img: '',
            strong: '0'
          },

          {
            info: '•	丰厚的通证及其他奖励；',
            img: '',
            strong: '0'
          },
          {
            info: '•	BitCherry专属精美礼品；',
            img: '',
            strong: '0'
          },
          {
            info: '•	率先获得最新项目资讯以及技术进展；',
            img: '',
            strong: '0'
          },
          {
            info: '•	有机会与BitCherry核心技术成员进行线上技术交流；',
            img: '',
            strong: '0'
          },

          {
            info: '•	有机会优先参与BitCherry全球线下见面会；',
            img: '',
            strong: '0'
          },

          {
            info: '•	更多神秘权益。',
            img: '',
            strong: '0'
          },
          {
            info: '申请链接：http://fw3nuu919ozea7cr.mikecrm.com/GyEIAFz',
            img: '',
            strong: '0'
          },
          {
            info: '由于相关法律制约，以下地区用户将无法参与本次”BitCherry社区大使”计划：',
            img: '',
            strong: '0'
          },
          {
            info: '中国、巴尔干半岛，缅甸，科特迪瓦，古巴，伊朗，伊拉克，利比里亚，朝鲜，苏丹，叙利亚，津巴布韦，中非共和国，克里米亚，黎巴嫩，利比亚，索马里，南苏达，委内瑞拉，也门，阿尔巴尼亚，波斯尼亚和黑塞哥维那，科索沃，马其顿，摩尔多瓦，塞尔维亚，布隆迪，埃塞俄比亚，几内亚比绍，阿富汗，吉尔吉斯斯坦，老挝，马拉维，马里，莫桑比克，斯里兰卡，坦桑尼亚，东帝汶，特立尼达和多巴哥，土库曼斯坦，乌干达和乌兹别克斯坦。',
            img: '',
            strong: '0'
          },
          {
            info: '免责声明：',
            img: '',
            strong: '0'
          },
          {
            info: '1. BitCherry保留单方面对本次活动包括奖项设置、评选标准、奖项等规则进行调整的权利；',
            img: '',
            strong: '0'
          },
          {
            info: '2. 涉及违反法律法规、活动规则、侵犯他人利益等行为将被取消参与资格；',
            img: '',
            strong: '0'
          },
          {
            info: '3. 请密切关注BitCherry发布的相关公告，注意甄别虚假消息、钓鱼网站、钓鱼邮件、克隆账号等。BitCherry对用户因个人轻信虚假消息所产生的一切损失免责；',
            img: '',
            strong: '0'
          },
          {
            info: '4. BitCherry保留对本活动的最终解释权；',
            img: '',
            strong: '0'
          },
          {
            info: '5. 如对本次活动有问题请联系 business@bitcherry.io。',
            img: '',
            strong: '0'
          },

          {
            info: '6. 本次招募自2020年1月1日起开始启动，长期有效；',
            img: '',
            strong: '0'
          },
          {
            info: '* 若参选者在任期间对BitCherry品牌造成负面影响（发布垃圾信息、散布不实言论、甚至其他违法言行等），BitCherry团队有权撤销其管理权限及奖励。',
            img: '',
            strong: '0'
          },






        ]
      },
      {
        img: require('../assets/img/media/mediabg31.png'),
        title: "BitCherry 竞选圣诞之星 ，海量空投等你来拿",
        time: '2019.12.20',
        infos: [
          {
            info: ' 活动规则',
            img: '',
            strong: '0'
          },
          {
            info: '12月20-12月31 日，录制一段Merry Christmas BitCherry祝福小视频，发布至facebook或instagram 平台，@BCHCGlobal或@BitCherryofficial，并邀请好友去点赞。',
            img: '',
            strong: '0'
          },
          {
            info: '视频满10个赞，可获得10BCHC奖励（限100名）；满30个赞，获得30BCHC（限50名）。',
            img: '',
            strong: '0'
          },
          {
            info: '集赞数最多的前10名将成为BitCherry 2019 圣诞之星，根据集赞量排名，为三个奖项：',
            img: '',
            strong: '0'
          },
          {
            info: '一等奖：1名 888BCHC  价值约4200元',
            img: '',
            strong: '0'
          },
          {
            info: '二等奖：3名 88BCHC   价值约420元',
            img: '',
            strong: '0'
          },
          {
            info: '三等奖:  6名 66BCHC   价值约 320元',
            img: '',
            strong: '0'
          },

          {
            info: '集赞完毕后请务必加入BitCherry Global群，填写钱包地址等信息并上传点赞截图，经官方核实无误后，将会为您发放奖励！',
            img: '',
            strong: '0'
          },
          {
            info: 'Facebook：@BCHCGlobal',
            img: '',
            strong: '0'
          },
          {
            info: 'Instagram：BitCherryofficial',
            img: '',
            strong: '0'
          },
          {
            info: 'Telegram:BitCherryGlobal BitCherryIndonesia BitCherryVietnam',
            img: '',
            strong: '0'
          },
          {
            info: 'BitTok：www.BitCherry.io',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabg26.jpg'),
        title: "BitCherry与中国-中东欧区块链卓越中心签订战略合作协议",
        time: '2019.12.04',
        infos: [
          {
            info: ' 2019年12月13日，由中国-中东欧区块链卓越中心及斯洛伐克政府举办的“第一届中国-中东欧区块链峰会”在斯洛伐克布拉迪斯拉发顺利举行。',
            img: '',
            strong: '0'
          },
          {
            info: '在本次峰会专家研讨会上，BitCherry创始人及CEO Paul以“分布式商业未来”为主题发表了演讲。会议中，中国-中东欧区块链卓越中心与BitCherry签署了《中国-中东欧区块链卓越中心谅解备忘录》，双方就如何将区块链技术落地应用于能源、金融、航空航天、制造业、物流、商业等领域，加深中国与中东欧国家的国际合作等方面交换意见。 ',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry持续布局全球分布式经济生态，打造商业场景落地应用，帮助更多开发者和企业快速、安全、合规地部署区块链商业化应用。未来，BitCherry也将继续凭借深厚的技术实力，积极探索传统经济向数字化商业转型的更多可能性，为全球企业带来更为前瞻性的分布式商业应用，推动全球经济的数字化进程。 ',
            img: '',
            strong: '0'
          },

        ]
      },
      {
        img: require('../assets/img/media/mediabg30.png'),
        title: "BitCherry感恩节社区空投活动顺利结束",
        time: '2019.12.04',
        infos: [
          {
            info: ' 活动详情：11月28日-12月3日期间，限时加入BitCherry Telegram社区，并关注Facebook、Instagram、Twitter、LinkedIn等任意两个账号，并上传截图。BitCherry 将随机抽取10名幸运用户，每人奖励20个BCHC。',
            img: '',
            strong: '0'
          },
          {
            info: 'Telegram：https://t.me/BitCherryGlobal ',
            img: '',
            strong: '0'
          },
          {
            info: 'facebook:https://www.facebook.com/BCHCGlobal/ ',
            img: '',
            strong: '0'
          },
          {
            info: 'Twitter：https://twitter.com/BitCherryGlobal ',
            img: '',
            strong: '0'
          },
          {
            info: 'Instagram：https://www.instagram.com/BitCherryofficial/ ',
            img: '',
            strong: '0'
          },
          {
            info: 'Linkedin:https://www.linkedin.com/company/BitCherry/ ',
            img: '',
            strong: '0'
          },
          {
            info: '本次活动获奖名单将于本周在BitCherry 各大社区进行公示，请留意获奖信息。 ',
            img: '',
            strong: '0'
          },

        ]
      },
      {
        img: require('../assets/img/media/mediabg25.jpg'),
        title: "BitCherry项目团队受邀参加“Singapore FinTech Festival”金融科技节",
        time: '2019.11.20',
        infos: [
          {
            info: ' 11月11日，BitCherry全球之行的新加坡站正式开启，BitCherry项目团队也受邀参加了“Singapore FinTech Festival”金融科技节。新加坡金融科技节举办至今已经是第四届，由新加坡金融管理局（MAS）和新加坡银行协会联合主办，被誉为是全球最大的金融科技盛会。本届金融科技节邀请嘉宾共计四万余人，包括全球金融机构，科技企业，投资公司，中央银行，监管机构及学术界的精英代表和意见领袖。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry项目团队在本届金融科技节上，与来自全球的数万名金融科技精英们以“可持续发展”、“金融与科技”、“金融的未来”及“投资全球市场的机会和指数技术”为主题进行了交流讨论，探讨如何帮助全球国家以改革金融模式为目标充分利用区块链技术的潜力，并与监管机构和全球政策制定者交换信息，共同探讨与数字货币相关的潜在法律问题。',
            img: '',
            strong: '0'
          },
          {
            info: '未来，BitCherry将继续以区块链技术赋能传统经济为理念，探寻区块链基础设施建设，维护网络安全以及跨境交易安全，以多个维度并行推动商业经济数字化、规范化发展，并为推动产业升级和生态智能化发展持续作出贡献。',
            img: '',
            strong: '0'
          },

        ]
      },
      {
        img: require('../assets/img/media/mediabg24.jpg'),
        title: "BitCherry马耳他站圆满落幕",
        time: '2019.11.13',
        infos: [
          {
            info: ' 11月7日，BitCherry全球之行的马耳他站正式开启，BitCherry的项目团队也受邀参加了马耳他“AIBC Summit Malta”区块链峰会，本次峰会共吸引了来自全球超过8500名区块链行业的学者、开发人员以及投资者。会议现场，主办方为BitCherry项目团队特设了“分布式商业”主题专场，BitCherry项目负责人也针对区块链技术、分布式商业等主题，与现场嘉宾进行了深度讨论，探索如何在聚焦区块链技术融合实际应用的同时，打造全球未来分布式经济。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry的分布式商业专场也在短短两天时间里，收获了来自80多个国家几千名区块链精英的关注。会议期间，BitCherry创始人&CEO Paul受邀出席了主题圆桌论坛并接受多家欧洲媒体专访。',
            img: '',
            strong: '0'
          },
          {
            info: '在主题为“分布式商业对世界经济的影响”的圆桌论坛中，BitCherry创始人&CEO Paul表示分布式经济已经成为区块链改造传统经济的根本性动力。通过分布式记账和奖励机制结合，分布式经济将实现生产者、所有者和使用者的有机统一，促进整个经济体的良性循环。凭借高效、安全、透明的特性，分布式经济将成为未来商业组织的发展趋势，逐渐应用于更广泛多元的领域。',
            img: '',
            strong: '0'
          },
          {
            info: '在圆桌论坛中，BitCherry首席科学家Bob Qin强调分布式经济的作用是重构金融，其特点是不可被垄断。BitCherry亚太区COO Kayla称分布式商业并不是再造一个商业社会，而是商业社会的数字化。BitCherry目的是打造一个开放式的商业生态，利用遍布全球的运营节点，使节点参与到价值创造之中，最终导致价值网络发生变化。',
            img: '',
            strong: '0'
          },
          {
            info: '本次马耳他峰会促进了BitCherry与行业内精英们的沟通，进一步深化了BitCherry的商业地位。未来，BitCherry将会通过不断提升技术实力、拓展商业应用领域等方式，为全球企业提供更为公平的商业环境。',
            img: '',
            strong: '0'
          },
        ]
      },
      {
        img: require('../assets/img/media/mediabg23.jpg'),
        title: "BitCherry旧金山站圆满落幕",
        time: '2019.11.06',
        infos: [
          {
            info: '2019年10月28日，SF BLOCKCHAIN WEEK 2019区块链周在美国旧金山正式开幕。作为“城市赋能—打造分布式商业城市”全球之行的美国站，BitCherry应邀出席了本届旧金山区块链周。10月31日，BitCherry创始人及CEO Paul受邀出席了主题为“适用性数字货币的未来”的圆桌会议，并与现场嘉宾分别就“数字货币监管现状和未来发展”及“如何通过分布式技术赋能全球商业”为题进行了分享。',
            img: '',
            strong: '0'
          },
          {
            info: '本次大会由Libra、Facebook、Huobi赞助，汇集了来自世界各地的顶级区块链公司、学者、开发者人员和投资者，共邀请超过5000人、350家区块链企业参加，超过240位演讲嘉宾。本次会议，与会嘉宾们深入探讨了全球经济新模式、梳理了行业最新趋势，分布式经济的价值开始凸显并逐渐走向全球视野。BitCherry此次与众多业内精英进行深入交流，在进一步开拓全球市场的同时，强化了与会者对分布式经济的认知。未来，BitCherry将继续深耕技术研发、积极参与生态分享、持续深化行业地位，为分布式经济的落地发展提供高效、安全的技术沃土。',
            img: '',
            strong: '0'
          },
        ]
      },
      {
        img: require('../assets/img/media/mediabg22.jpg'),
        title: "BitCherry CEO Paul接受刚果民主共和国国家电视台专访",
        time: '2019.10.16',
        infos: [
          {
            info: '2019年10月11日，刚果民主共和国国家电视台对“分布式电商公链项目BitCherry代表团前往非洲与刚果政府展开合作洽谈”一事进行了报道。报道中，刚果政府表示，此次合作将整合刚果民主共和国的优势资源，推动刚果区块链经济的发展。随后，BitCherry创始人及CEO Paul，BitCherry顾问 WTIA主席 Kim等代表还接受了刚果国家电视台的专访。专访中，BitCherry创始人及CEO Paul表示：“此次与刚果政府达成战略合作，将从‘区块链+新能源领域’生态切入，加速实现区块链行业发展，实现资产通证化，从根本解决刚果经济落后等问题。接下来，我们将逐步进入全球市场，让全球新能源行业参与到BitCherry的生态建设中。”',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabg21.jpg'),
        title: "BitCherry与刚果民主共和国政府正式签订战略合作协议",
        time: '2019.10.16',
        infos: [
          {
            info: '2019年10月10日至12日，BitCherry与刚果国家青年发展部签订了《刚果民主共和国国家基础设施建设及能源经济化转型战略合作谅解备忘录》。在合作中，BitCherry创始人及CEO Paul，BitCherry顾问 WTIA主席 Kim与刚果总统办公室主任、国家基建部部长、财政部部长、青年发展部部长、能源矿业部长等就跨境支付、大宗电商、新能源原材料数据上链、新能源资产上链、新能源资产交易等多个关键领域的合作进行了交流。',
            img: '',
            strong: '0'
          },
          {
            info: '刚果坐落于非洲的中西部，拥有国土总面积34.2万平方公里。根据美国地质调查局统计，全球已探明陆地钴资源储量约710万吨，其中刚果金的钴储量为全球第一，占全球钴总储量的47.9%。这次合作将整合新能源资源，将从三个可商业落地的方向出发，打造全球范围的“区块链+新能源领域”生态经济。',
            img: '',
            strong: '0'
          },
          {
            info: '由于刚果当地有很多童工参与非法钴矿开采，导致很多材料的来源并不合法。如果将新能源原材料的数据上链，把包括原材料来源、材料储存、资源管理、资源供应等数据全部上链，将确保原材料来源渠道是完整并合法的。而通过利用区块链底层架构技术，将新能源资产上链，使能源资产通证化，将实现能源资产的流通交易。能源将作为一种金融资产在资本市场上进行流通，这将实现刚果经济的全方位转型，强化刚果整体经济的发展。',
            img: '',
            strong: '0'
          },
          {
            info: '此次合作将整合双方的优势资源，以实现刚果民主共和国在区块链领域的“弯道超车”为终极合作目标，持续为刚果及整个非洲提供更加高效的区块链资产解决方案，方便资产全球流通，积极打造具有全球规模和经济效益的区块链新能源经济生态。',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabg20.jpg'),
        title: "BitCherry受邀请参加印尼六城之旅路演活动",
        time: '2019.09.18',
        infos: [
          {
            info: '2019年9月5日至20日，印尼“六城之旅”路演活动正式启动，分布式网络公链项目BitCherry作为活动赞助方受邀参加全程路演活动。BitCherry与其他全球优秀的区块链项目方们一起，携自己的特色技术和应用，走进Brawijaya Malang、Teknologi Surabaya、Gadjah Mada Jogja、Telkom Bandung、Teknologi Bandung、Atma Jaya Jakarta等六所大学，通过展览、讲座、演讨会等多种形式，普及区块链基础知识，传播区块链技术应用于传统商业的理念和方法。',
            img: '',
            strong: '0'
          },
          {
            info: '截至目前，BitCherry“六城之旅”在印尼广受好评，学院负责人们纷纷表示：“此次路演普及了区块链技术及应用场景等相关知识，非常有助于挖掘和培养印尼区块链工程的人才，这将间接或直接的帮助印尼经济更好地发展及转型。”',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabg19.jpg'),
        title: "BitCherry受邀出席越南河内创新峰会",
        time: '2019.08.30',
        infos: [
          {
            info: '2019年8月29日至8月30日，为期两天的“Hanoi Innovation Summit 2019”创新峰会在越南河内国家会议中心召开。BitCherry作为唯一一家区块链电商项目受邀出席峰会，BitCherry越南社区代表及意见领袖们参加了此次会议。作为BitCherry的坚定拥趸，BitCherry越南社区代表们在现场分享了BitCherry的分布式技术特性以及共建社区生态的理念，围绕“BitCherry如何在经济下行和巨头垄断的电商市场中降低边际成本，创造新的商业模式”展开详细介绍。在交流中，众多与会者对BitCherry的分布式技术理念表示认同。',
            img: '',
            strong: '0'
          },
          {
            info: '本次峰会以新兴经济体为题，由越南计划投资部发起，SCHOOLAB、STARTUP SESAME协办，围绕“消费技术、环境、生命科学、智慧城市、工作机遇以及物流”六大主题展开，近3000位来自世界各地的企业家、投资者、行业学者等共同分享了相关领域的专业洞察。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry在此次峰会中，与众多业者进行沟通交流，共同捕捉当前经济形势下新的商业趋势，为越南市场找寻新的经济突破点。',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabg18.jpg'),
        title: "BitCherry受邀出席越南\"VTS 2019\"技术峰会",
        time: '2019.08.29',
        infos: [
          {
            info: '2019年8月29日，由越南最著名、权威的商业杂志之一《Business Review》（Nhịp Cầu Đầu Tư）主办的“Vietnam Tech Summit 2019”技术峰会于越南胡志明市正式召开。BitCherry作为会议的赞助方之一受邀出席了本次峰会。作为《Business Review》最重要的年度投资会议之一，会议邀请了越南排名前50位的企业及公司参加，包括多个领域的技术领袖、经济学家、知名企业CEO及创业者等300多位业内精英，以“ABCD Power”为主题，探讨如何运用AI、Blockchain、Cloud（云计算）、Data(大数据)等技术改变传统商业模式，实现落地级商业应用。BitCherry创始人及CEO Paul作为会议演讲嘉宾就“分布式经济将如何颠覆传统商业模式”及“BitCherry如何以生态践行分布式经济”等议题与现场嘉宾进行了深度讨论与交流。',
            img: '',
            strong: '0'
          },
          {
            info: '会议期间，越南知名金融及商业新闻媒体FBNC对Paul进行了个人专访。Paul表示：“BitCherry将通过构建透明、可信、不可篡改的分布式网络平台，实现公平的点对点共享和交易，为核心生态构建基础。并以基于信用共识的分布式生态完成价值创造，升级到生态即金融。未来将以技术为基础，生态为核心，跨领域颠覆包括银行业、会计（审计）业、保险业、医疗保健业、政务、交通运输、税务、食品、农业、汽车等领域。”',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabg17.jpg'),
        title: "BitCherry受邀出席POW'ER 2019全球区块链开发者大会",
        time: '2019.08.23',
        infos: [
          {
            info: '2019年8月21至22日，“POW`ER 2019 全球区块链开发者大会”于北京召开，BitCherry作为全球首家分布式电商项目受邀出席大会。本次会议邀请了近1500位来自全球各地的技术领袖、投资机构代表、研究所代表及精英学者，就数字经济、分布式产业、人工智能、物联网等新技术发展进行深入探讨，分享对新技术趋势的判断和展望。会上，BitCherry与众多业内精英探讨如何发展分布式经济并构建分布式共享生态，为实现全球商业一体化的价值互联贡献力量。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry首席科学家、北美区块链基金会主席Bob Qin作为特邀嘉宾出席“分布式经济”主题论坛，并就“分布式电商网络公链如何创新”等议题与现场嘉宾进行了深度讨论。他表示：“分布式计算发展至今，已经呈现出通过分布式计算技术把整个系统打造成一台超级计算机的趋势，而BitCherry的愿景是成为电商业的超级计算机。”',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabg15.jpg'),
        title: 'BitCherry分布式网络项目开发宣布正式启动',
        time: '2019.08.06',
        infos: [
          {
            info: '2019年7月29日，在全球区块链峰会GBIS 2019(印尼站)上，全球首家分布式电商网络BitCherry的创始人&CEO Paul宣布，BitCherry分布式电商公链项目开发正式启动。BitCherry公链将结合区块链和DAG技术提供一个链网体系，支撑电商生态的公链环境，打造新一代的共享电商全球生态。',
            img: '',
            strong: '0'
          },
          {
            info: '在BitCherry公链全球启动仪式上，拥有多年行业项目开发经验的BitCherry首席科学家 Bob发表了主题为“公链的迭代与未来趋势”的演讲，着重介绍了BitCherry公链的技术特性、共识机制设计及链网体系设计等。',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabg16.jpg'),
        title: 'BitCherry宣布与SCC、WTIA达成战略合作，携手共建420亿美元智慧城市',
        time: '2019.08.06',
        infos: [
          {
            info: '2019年7月30日，分布式电商网络BitCherry于印度尼西亚宣布与SCC、WTIA签订战略合作协议。此次战略合作的签署标志着三方将在项目孵化、技术研发、推广活动、以及促进智慧城市和区块链发展等多个关键领域深入合作。   ',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry与SCC、WTIA将共同建设，打造价值420亿美元的综合智慧城市项目——“突尼斯经济城”。“突尼斯经济城”是位于突尼斯国家东海岸的大型新经济城市综合体，作为BitCherry与SCC、WTIA联盟合作的首个项目，“突尼斯经济城“的开发设计已按计划顺利推进中。从战略合作的成员架构可以看到，SCC专业从事智能城市建设、运用先进技术为可持续发展的城市提供高品质服务;WTIA为大型区块链项目提供咨询及资本市场解决方案;而BitCherry作为全球首家分布式电商网络，则更侧重打造便捷、安全、高效的区块链分布式商用基础设施网络。在智慧城市综合体中，BitCherry分布式网络将对城市生活场景进行全覆盖，其生态通证BCHC未来也将通用于城市生态中的所有支付场景，满足人们在综合体中的支付需求。',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabg14.jpg'),
        title: '空投活动已经开始，你还不抓紧时间进来',
        time: '2019.08.01',
        infos: [
          {
            info: 'BitCherry telegram社群的空投活动正式开始。8月1日至7日，加入BitCherry telegram社群或邀请你的朋友加入BitCherry telegram社群,就有机会获得BCHC奖励。活动规则如下：',
            img: '',
            strong: '0'
          },
          {
            info: '达到300人：抽取10名幸运用户，分享50个BCHC。',
            img: '',
            strong: '0'
          },
          {
            info: '达到500人：抽取20名幸运用户，分享150个BCHC。',
            img: '',
            strong: '0'
          },
          {
            info: '达到1000人：抽取30名幸运用户，分享300个BCHC。',
            img: '',
            strong: '0'
          },
          {
            info: '达到2000人：抽取40名幸运用户，分享500个BCHC。',
            img: '',
            strong: '0'
          },
          {
            info: '我们同时在我们的Facebook和Instagram上举办BCHC空投活动。活动地址如下，欢迎参与。 Instragram: https://www.instagram.com/BitCherryofficial/  Facebook: https://www.facebook.com/BCHCGlobal/?modal=admin_todo_tour',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabg13.jpg'),
        title: 'CherryWallet冷钱包App正式上线并推出预售 实现全球商业级安全钱包服务',
        time: '2019.07.30',
        infos: [
          {
            info: '7月30日，区块链数字资产安全存储项目CherryWallet正式召开了全球启动仪式。在启动仪式上，CherryWallet首席商务官 Aaron宣布CherryWallet App于当日正式上线，并且其产品CherryWallet Pro冷钱包也正式推出预售。',
            img: '',
            strong: '0'
          },
          {
            info: 'CherryWallet作为区块链数字资产安全存储方案，通过首创加密二维码+近场通讯传输（NFC）的方式实现冷热端交互，独创semi-password技术，由种子密码+支付密码共同合成私钥，采用二维码及NFC的通信方式让私钥永不触网，从根本上杜绝了私钥被黑客窃取的风险。并首创了situation-generation技术，将根据用户所处的应用情景不同，即时生成随机性密码，提高密码安全性。CherryWallet Pro冷钱包目前可支持BTC、ETH、BCHC、USDT、EOS等主流数字资产，伴随着钱包功能的不断迭代升级，CherryWallet未来将为交易所、区块链企业及交易用户提供更多公链的数字资产种类支持。',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabg12.jpg'),
        title: 'BitCherry与印尼政府签订三大战略合作协议',
        time: '2019.07.29',
        infos: [
          {
            info: '2019年7月29日，印尼工商会（KADIN）、全球区块链投资联盟GBIA、香港区块链协会与BitCherry签订了战略合作协议。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry将携手印尼政府帮助印尼企业及项目打造分布式商业生态体系，双方就印尼地区“区块链商业投资、解决方案、分布式电商应用”等多个环节交换意见，并分别签署了《区块链与分布式商业投资推动印尼经济转型战略合作谅解备忘录》、《建立区块链解决方案以降低印尼的物流成本战略合作谅解备忘录》、《提供区块链解决方案以推动印尼的经融效率战略合作谅解备忘录》，与此同时，BitCherry将与印尼政府展开区域化合作，共同推动印尼经济高速转型。',
            img: '',
            strong: '0'
          },

          {
            info: '此次与印尼政府签订战略合作的协议，也成为了BitCherry布局东南亚市场的又一里程碑。此次合作将进一步加快BitCherry以技术推动全球分布式电商实现区块链落地的节奏。未来，BitCherry也将继续构建全球分布式电商区块链平台，并以落地分布式商业化应用，链接全球商业资源，赋能数字经济发展为使命，构建全球分布式商业生态体系。',
            img: '',
            strong: '0'
          },

        ]
      },
      {
        img: require('../assets/img/media/mediabg7en.jpg'),
        title: 'BitCherry品牌 LOGO全新升级，“樱桃红”见证分布式电商春天',
        time: '2019.05.23',
        infos: [
          {
            info: '近日，BitCherry 正式升级了品牌LOGO。相比原版 LOGO，新版LOGO整体色调进化为纯正“樱桃红”。',
            img: '',
            strong: '0'
          },
          {
            info: '从整体上看，BitCherry 品牌LOGO由樱桃具象化而来，是樱桃的抽象表达。',
            img: '',
            strong: '0'
          },

          {
            info: '在传统语境中，樱桃象征着美好的事物，青春的活力。樱桃的英文是cherry，而念起来很象cherish，cherish的意思就是珍惜。BitCherry 构建的去中心化超级电商生态也将像盛果期的樱桃一样枝繁叶茂。BitCherry 也会格外珍惜每一名生态参与者的权益，让每个用户在去中心化交易中公平获益。',
            img: '',
            strong: '0'
          },
          {
            info: '从细节上看，BitCherry LOGO主体由BitCherry两个首字母“B”“C”组成，”B”代表着数字资产，”C”象征着实体商业。“B”与”C”相链接，映射出BitCherry全球首家分布式电商网络的优势：',
            img: '',
            strong: '0'
          },
          {
            info: '通过打通数字资产与实体商业的价值对接，将强大的线下体验式服务与高效的区块链技术、分布式商业形态完美结合，为广大消费者、商户以及生态社群各方参与者提供更具效率、更富价值的生态圈。',
            img: '',
            strong: '0'
          },
          {
            info: '   ',
            img: require('../assets/img/media/mediabg7enInfo1.png'),

            strong: '0'
          },
          {
            info: '   ',
            img: require('../assets/img/media/mediabg7enInfo2.png'),
            strong: '0'
          },
          {
            info: '   ',
            img: require('../assets/img/media/mediabg7enInfo3.png'),
            strong: '0'
          },
          {
            info: '另外，升级后的LOGO，摒弃原来的暗红色，大胆采用了鲜亮红。在感官视觉上，鲜亮红更加醒目突出，也契合樱桃的本色，具有视觉冲击力。同时，也容易进行颜色搭配，具备优良的延展性和包容性。',
            img: '',
            strong: '0'
          },
          {
            info: '当前，人们的生活与电商联系日益紧密。在传统中心化电商制定的游戏规则中，无论商家，还是消费者，自身权益都得不到保障，也不能分享平台生态增长的价值。在BitCherry全球首家分布式电商网络中，BitCherry 新增的价值，本质来源于各生态参与者的共同贡献，这些价值也会公平的分配给各方生态参与者。',
            img: '',
            strong: '0'
          },
          {
            info: '品牌LOGO升级后，BitCherry 将继续秉承初心，构建可持续升级良性发展的全球首家分布式电商网络。最终实现，承载百万级商户，将分布式电商业务拓展至全世界，成为全球分布式电商引领者，最终实现全球一体化的商业价值互联的目标。',
            img: '',
            strong: '0'
          },
        ]
      },
      {
        img: require('../assets/img/media/mediabg1.png'),
        title: 'BitCherry受邀参加全球区块链投资峰会',
        time: '2019.04.21',
        infos: [
          {
            info: '4月24日，全球区块链投资峰会新加坡站将盛大开启。 BitCherry作为特邀项目，预定在此首发亮相。 BitCherry是全球首家分布式电商平台致力于通过区块链技术赋能零售服务业，打造新一代的共享电商超级生态。BitCherry的生态体系包括：超级节点、运营节点、商家，消费者和平台。多方参与整个商品价值流通的生命周期，进行价值的共同分享。Bitchery采用消费即挖矿的方式加速平台通证的流通与价值传递。利用底层区块链技术及智能合约，保障生态体系的诚信度。',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabg3.jpg'),
        title: 'BitCherry在全球区块链投资峰会举办发布仪式',
        time: '2019.04.24',
        infos: [
          {
            info: '时至今日，电商行业仍然是一个万亿美元的蓝海市场。近年来，电商市场保持着快速增长的势头，2017年全球电商市场交易额达2.3万亿美元，中国以29.16万亿元位居全球首位。但与此同时，电商市场长期以来面临着几大痛点。对于消费者来说，失信问题严重，商品质量参差不齐，个人信息泄露。对于平台来说：市场基本稳定，新兴平台生存艰难。想要解决以上种种问题，依靠现在火热的区块链技术是否可行？',
            img: '',
            strong: '0'
          },
          {
            info: '答案是肯定的。BitCherry 的问世即将改变目前的电商格局。BitCherry（BitCherry.io）BitCherry是全球首家分布式电商平台，致力于通过区块链技术赋能零售服务业，打造新一代的共享电商超级生态。4月24日，2019 全球区块链投资峰会在新加坡盛大召开，BitCherry作为特邀项目在举办了全球发布仪式。一经亮相，便受到了参会嘉宾和业界同行的关注。',
            img: '',
            strong: '0'
          },
          {
            info: '传统的电商平台，虽然名义上称为是共享社区，但基本上都是独立存在。并且由于有固定的强大中心存在，用户虽然拥有属于自己的账户，却并不能直接管理账户中的数据，操作上处于被动状态。而在区块链分布式的特性下，BitCherry有效的解决了这一问题。在BitCherry的生态体系中，超级节点，运营节点，商家，消费者和平台环环相扣，一起参与了整个商品价值流通的生命周期。平台底层区块链作为技术框架核心，通过通证激励，让每个生态参与者都能安全交易，利益共享。',
            img: '',
            strong: '0'
          },
          {
            info: '随着互联网发展进入深水区，传统电商流量被淘宝、京东等电商巨头垄断，中小型电商客流量小，且运营成本上升，传统的流量红利逐渐消失，商家们不得不去寻找低成本的流量获取方式。现如今，区块链技术又为电商的发展带来了新的技术驱动点。如何将颠覆传统的分布式思维与现有的商业结合，重构出一种全新的共享电商模式，是很多企业都在探索的方向。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry创新性的采用消费即挖矿的方式，加速平台通证生态的流通与价值传递。以人人参与、行为挖矿的方式，打通线上线下O2O电商体系。使用数字通证搭配传统支付手段的方式，利用区块链技术及智能合约，确保交易稳定，提高交易效率。',
            img: '',
            strong: '0'
          },
          {
            info: '作为区块链+电商这一模式的先行者，BitCherry为未来区块链大规模应用打下了坚实的行业基础。相信包括节点，商家，消费者和平台在内的每一个参与者，都会在这场变革浪潮中获取价值红利。因此，我们有理由期待BitCherry将分布式电商的业务版图扩展至全世界的那一天',
            img: '',
            strong: '0'
          },

        ]
      },

      {
        img: require('../assets/img/media/mediabg10.jpg'),
        title: 'BitCherry助力GBIS 2019全球区块链投资峰会',
        time: '2019.04.24',
        infos: [
          {
            info: ' 2019年7月29日，东南亚首屈一指的区块链盛会Global Blockchain Investment Summit 2019将于印度尼西亚雅加达太古广场盛大召开。此次峰会由全球区块链投资联盟GBIA与香港区块链联盟HKBA、印度尼西亚工商会、BCEE联合主办。全球首家分布式电商网络BitCherry作为本次会议的协办方及赞助方之一，BitCherry创始人&CEO PAUL及BitCherry 联合创始人&COO Kilian Hussmann将作为特邀嘉宾出席圆桌论坛，并分别将就“全球区块链投资产业链生态、如何挖掘区块链价值投资之道”等话题与现场嘉宾进行深度讨论。',
            img: '',
            strong: '0'
          },
          {
            info: ' BitCherry创始人&CEO PAUL将在会议现场召开BitCherry公链的启动仪式。未来将分布式电商区块链全生态发展战略的红利及未来价值代入印尼并辐射整个东南亚，在更多的场景下实现共赢。',
            img: '',
            strong: '0'
          },

        ]
      },

      {
        img: require('../assets/img/media/mediabg11.jpg'),
        title: 'BitCherry价值1.3亿印尼盾空投引燃印尼市场',
        time: '2019.04.24',
        infos: [
          {
            info: '2019年7月29日，东南亚首屈一指的区块链盛会Global Blockchain Investment Summit 2019将于印度尼西亚雅加达太古广场盛大召开。本次峰会将邀请到包括印尼国家领导人、联合国区块链委员会、阿联酋王子殿下、印尼工商会、印尼中央银行、印尼国家投资部、菲律宾联合银行及上百位业内精英。旨在促进各国家领导者、各部门及各国家区块链从业者进行深入交流，互享资源，就诸多领域的战略合作达成共识。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry作为大会的赞助商，将继续输出品牌影响力，进一步拓展印尼市场，特此在会议期间准备了价值130,000,000印尼卢比的BCHC，此次社区空投活动将面对所有参会人员及整个印尼市场。',
            img: '',
            strong: '0'
          },

        ]
      },
      {
        img: require('../assets/img/media/mediabg9.jpg'),
        title: '网商、微商、链商，再不知道这些你就OUT了',
        time: '2019.06.12',
        infos: [
          {
            info: '说起网商，人们自然会想到马云的淘宝；提起微商，你肯定也感受过朋友圈卖面膜的励志话术。但对于大部分人来说，链商都是一个长期“保鲜”的词汇。当你面对已成红海的网商、微商，链商的蓝海故事才刚刚讲起。那么网商、微商和链商到底有何不同呢？',
            img: '',
            strong: '0'

          },
          {
            info: '“让天下没有难做的生意”，网商成就了多少人开店创业的梦想，繁荣了一个崭新的物流行业。微商依托社交软件，更让做买卖触手可及。人们只需一部手机，就可在家里卖东西。以BitCherry为代表的分布式电商网络，也将成为分布式商业商机的孕育者。',
            img: '',
            strong: '0'

          },
          {
            info: '无论是网商、还是微商，都绕不开中心化的组织方式。发展中后期，光鲜亮丽的表面下，商家消费者权益、平台利益往往是零和博弈。这也是目前，电商巨头、微商平台的发展困境，并伴生出假货横行、消费者隐私暴露、不合理的流量分配等问题。',
            img: '',
            strong: '0'

          },
          {
            info: '例如，网商平台垄断生态大部分利润，随意分配平台流量，价值与收益并不能真正匹配，商家运营成本越来越高，消费者丧失议价能力等。而微商一般通过质押，平台将风险转移给商家，并从中抽取巨额利润。很多微商并不能持续的发展下去。简单的说，网商、微商价值的流动，都绕不过中心化平台，并不能实现价值无障碍流通。',
            img: '',
            strong: '0'

          },
          {
            info: 'BitCherry在于为链商打造一个基础设施，依托区块链技术，通过智能合约瞬间分配，省略了中间留存和转移的环节，实现价值直接分配。',
            img: '',
            strong: '0'

          },
          {
            info: '',
            img: require('../assets/img/media/mediabg9Info.jpg'),
            strong: '0'

          },
          {
            info: '真正实现了“没有中间商赚差价，卖家多挣钱，买家少花钱。”BitCherry本身不会向用户收取中间费用，同时通过消费挖矿，消费者一边在京东消费，一边获得“京东股票（BitCherry平台通证）”，共享平台生态溢价。',
            img: '',
            strong: '0'

          },
          {
            info: '现在，世界上只有两种人：上网的人和不上网的人。',
            img: '',
            strong: '0'

          },
          {
            info: '未来，世界上也只有两种人：上链的人和不上链的人。并且上链的人会越来越多，产生的上链信息，也会产生全新的商业业态。BitCherry服务的就是上链的人，带来分布式商业的更多价值实现。错过了网商、错过了微商，难道你还要错过BitCherry吗？',
            img: '',
            strong: '0'

          },



        ]
      },
      {
        img: require('../assets/img/media/mediabg8.jpg'),
        title: '推倒企业围墙，BitCherry让商业参与者及时、公平获益',
        time: '2019.05.29',
        infos: [
          {
            info: '未来某一天，当你任意走进一家餐馆，酒足饭饱之余，拿出手机点亮屏幕，通过扫码就能轻松使用数字资产完成付款，并由此获得一笔通证激励。',
            img: '',
            strong: '0'
          },
          {
            info: '这距离我们并不遥远。可以肯定的是：现在，数字资产已日渐被主流社会接受。前不久，初创公司Moon宣布，只要通过其谷歌插件，所有支持闪电网络的钱包，都能在亚马逊上消费。另据BLOCKDATA.TECH数据显示，年收入超过1亿美元的在线零售服务商中，支持数字资产支付的有亚马逊、微软、newegg、FLIGHT、overstock等数十家公司。',
            img: '',
            strong: '0'
          },
          {
            info: '这些只是打通数字资产和传统电商，迈出的第一步。真正要使“价值流动起来”，让人们像微信、支付宝那样，方便的用数字资产付款，离不开BitCherry这样的分布式电商底层基础设施提供商的支持，以及建构其上的链商。待BitCherry全球首家分布式电商网络技术上得以完善，链商的大规模落地涌现，只是时间问题。',
            img: '',
            strong: '0'
          },
          {
            info: ' 相比传统网商、微商，新兴的链商具有独特的优势。传统电商时代，电商巨头一边独享用户增长带来的大部分收益，一边挥动垄断大棒制定不公平的游戏规则，攫取损害商家、用户权益。隐私泄露、竞价流量排名层出不穷，处于弱势地位的商家用户深恶痛绝，但又无可奈何。而以链商为代表的分布式商业模式，将有效祛除传统电商的顽疾，在生态内实现公平的利益分配。 ',
            img: '',
            strong: '0'
          },
          {
            info: ' “新商业模式是区块链社群+产品的模式，区块链用一套通证经济模型，推倒了企业围墙。把产品供应链、利益相关者、用户推广等整个生态打通了，这个链条上的每个参与者都被激励和及时奖励。”',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry打造的囊括衣食住行各个方面的超级生态，去餐厅消费，只是海量应用场景中的一个片段。你会发现，横亘在商家、用户之间的庞然大物不见了。基于BitCherry分布式电商网络，用户、商家、节点可高效的进行价值流转和去中心化协作，并公平、及时分享整个生态新增的价值。同时，基于数据确权、信息上链，消费者数据保护、假冒伪劣产品等问题也将得到很好的解决。可以确定的，一个链商的时代已来。',
            img: '',
            strong: '0'
          },


        ]
      },

      {
        img: require('../assets/img/media/mediabg6.png'),
        title: 'BitCherry：区块链领域亚马逊、阿里巴巴的孕育者',
        time: '2019.05.22',
        infos: [
          {
            info: '后互联网时代，曾经风光无两的电商遭遇天花板，一系列缺点暴露无遗。区块链等新兴技术的实现，能否接过互联网大旗，续写新的商业传奇？',
            img: '',
            strong: '0'
          },
          {
            info: '答案是肯定的。过去10年，传统电商狂飙突进的背后，亮眼数据掩盖的是一些列问题：商家获客成本极速增高，利润越来越薄；假货问题就像癌症一样，无法根本治愈；二八定律下，中小电商生存日益艰难；中心化平台“霸权主义”，竞价模式摊高运营成本；大数据时代，消费者毫无隐私可言；消费升级扑面而来，传统电商难以满足消费者日益个性化的需求……',
            img: '',
            strong: '0'
          },
          {
            info: '而分布式账本技术的出现，为这些问题的解决提供了可能性方案。亚马逊、阿里巴巴、京东等电商巨头也动作频频，持续推进区块链技术在电商领域的应用。京东甚至推出了面向企业的JD Chain，并发布了白皮书，公布了开源代码。',
            img: '',
            strong: '0'
          },

          {
            info: '受制于自身庞大的身躯，巨人的转身总是缓慢的。更近一步，一种新型的去中心化电商网络被提出，并付诸实践。BitCherry 就是其中的典型代表。',
            img: '',
            strong: '0'
          },
          {
            info: '借助区块链底层技术，构建合约和去中心化电商应用平台。辅以必要的智能硬件消费设施。打通线上线下，链上链下的支付网络，实现数字资产与实体商业的无缝价值连接。在BitCherry去中心化电商网络中：',
            img: '',
            strong: '0'
          },
          {
            info: '-	实现数据确权。以密码学保障个人数据所有权，所有数据都经过用户许可才能使用，完美保护个人隐私。',
            img: '',
            strong: '0'
          },
          {
            info: '-	消费即挖矿。BitCherry 生态经济激励模型中，将通过“消费即挖矿”吸引用户大量加入。平台投资者、推荐人以及运营商都能在各个销售环节获得一定的激励。通过更有效的激励，提高生态内用户的参与深度与广度。  ',
            img: '',
            strong: '0'
          },
          {
            info: '-	生态价值保真。通过产品、服务上链促进整个生态价值保真，根本上解决假冒伪劣问题。商家资料、交易记录也将上链，提供信息真实性，保障平台诚信度。',
            img: '',
            strong: '0'
          },
          {
            info: '-	新型价值传递协作关系。相比传统中心化电商平台，BitCherry 分布式商业组织内部，是新型价值传递协作关系。通过传统电商组织关系重构，最终实现让每个用户从去中心化交易中公平获益。',
            img: '',
            strong: '0'
          },
          {
            info: '去中心电商扑面而来，已成为未来重要发展趋势。今年以来，众多大V、知名公号纷纷试水电商，头顶光环流量而来。亚马逊、阿里巴巴们一不小心就成了“传统”。“跑车”很难在水泥地上奔跑，传统电商基础设施并不能满足分布式商业的需求，传统电扇遇到的问题，去中心化电商一个都不少，制约了去中心电商发展。',
            img: '',
            strong: '0'
          },
          {
            info: '',
            img: require('../assets/img/media/mediabg6enInfo.png'),
            strong: '0'
          },

          {
            info: '得益于线上支付的成熟，互联网重构了传统商业模式。从此，沃尔玛们从神坛跌落。而BitCherry对分布式电商基础设施的完善，也必将诞生新一代的电商巨人。',
            img: '',
            strong: '0'
          },
          {
            info: '对区块链行业来说，数字资产两个核心应用场景，一个是交易，另一个是支付。前者成就了币安、火币、OKEx等头部交易所，居于整个数字资产产业链顶端；后者潜力尚待挖掘，这里面有很大的想象空间。</br></br> ' +

              '从这个角度讲，BitCherry 将成为区块链领域亚马逊、阿里巴巴的孕育者。',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabg5.jpg'),
        title: '全球分布式电商网络从概念到落地，只差一个BitCherry',
        time: '2019.05.21',
        infos: [
          {
            info: '摘要：',
            img: '',
            strong: '0'
          },
          {
            info: '2018年，区块链裹挟着巨大的造富效应汹涌而至，吸引着一大批人卷入这个混乱的江湖。如同万有引力规律，拂去杂尘，各种概念终将落地，显露出其真实价值。2019年，狂热褪去，历经牛熊清荡洗礼，人们开始思索整个行业的落地方向，探寻区块链真正应用价值。',
            img: '',
            strong: '0'
          },
          {
            info: '4月24日，新加坡全球区块链投资峰会上，首发亮相的BitCherry受到关注。为分布式电商而生，是BitCherry诞生的核心目标与使命。作为全球首家分布式电商网络，BitCherry致力于为电商打造区块链底层商用基础设施。',
            img: '',
            strong: '0'
          },
          {
            info: '目前区块链领域，最成熟的应用莫过于交易和支付。交易领域已孕育出币安、Bitfinex等头部企业。而支付场景中的电商，尚处于价值洼地。整个区块链不缺优质资产，缺的是如何管理好资产，利用好资产。问题核心在于底层基础设施的缺失。这也恰是BitCherry的缘起。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry搭建的区块链电商底层基础设施，包括商业支付硬件设施，也包括平台商城、数字通证钱包等软件应用，软硬结合，打通线上线下价值流通，构成一整套区块链电商解决方案。在此之上，构建出分布式商业的诸多可能。',
            img: '',
            strong: '0'
          },
          {
            info: '相比传统中心化商业模式，分布式商业最显著的优势在于：降低交易摩擦，减少交易成本，提高交易效率，每个去中心化电商生态参与者都能分享资产增值，由此公平获利。BitCherry去中心化电商生态共包含5个角色：超级节点、商家、消费者、运营节点、基金会。各个角色拥有各自的功能，承担不同的任务，根据自身定位获得生态正向收益，实现人人参与、经济共享。',
            img: '',
            strong: '0'
          },
          {
            info: '其中，超级节点是平台软件授权商，通过部署软件获得通证奖励，共享平台利润。商家基于智能合约与平台签约，依托智能软硬件，纳入BitCherry生态。用户购买商品，即可获得通证奖励。消费者可在线上或线下商家消费，以消费挖矿获得通证激励。运营节点将承担推广、部署超级节点智能软件，生态拓展、部分商务合作等功能。基金会是整个去中心化共享电商生态推动者。',
            img: '',
            strong: '0'
          },
          {
            info: '在看的见的未来，线上，你可以直接在平台商城进行消费，以组合价格购买各种商品，并由此获得通证激励。而在线下，当你进入一家入驻平台的商店，购买商品结算时，就可通过平台钱包完成支付，交易行为、结果即时上链，你和商家都将获得通证激励。',
            img: '',
            strong: '0'
          },
          {
            info: '以上就是BitCherry所描绘的分布式电商网络重要应用图景。在BitCherry构建的去中心化协作电商平台上，每个参与角色，都能可持续公平获得与自身相匹配的收益，协力实现分布式商业的无限潜能。',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabg4.jpg'),
        title: 'BitCherry赋能零售服务业 打造价值生态体系 ',
        time: '2019.05.05',
        infos: [
          {
            info: '电商已经成为日常生活中必不可少的元素，但自从电商行业诞生以来，就从未出现革命性的变化。伴随消费者需求增长，传统电商的弊病也逐渐显露，假冒伪劣产品、安全问责、快递运输、信息安全等等问题，虽然从业者一直在努力解决，但从根本上来看问题依然存在。',
            img: '',
            strong: '0'
          },
          {
            info: '当下区块链技术已经得到众多行业的认可，自从深圳第一张区块链发票诞生至今，越来越多的企业开始将自身业务与区块链技术进行融合。BitCherry作为全球首家将传统电商与区块链成功融合的企业，致力于搭建以区块链技术为基础的分布式电商生态圈，真正利用区块链技术赋能传统电商行业，从而实现公平、公正、透明的经济共享生态。',
            img: '',
            strong: '0'
          },

          {
            info: '整个生态基于区块链技术，通过统一公允有效的共识机制以及采用智能合约架构，将平台交易数据记录在链，确保交易的唯一性，能有效杜绝中心化平台为寻求利益最大化单方面数据造假、篡改，信息泄露以及商户为获得平台资源采用作弊手段刷单记账破坏整个生态公平竞争等问题。不仅如此，商品的物流运输信息也能记录在链，从而能够使出现质量的问题的商品能够快速追根溯源。平台使用智能合约担保交易，所有交易自动化进行，提高交易效率，保证交易系统流畅。同时智能合约担保，提高了交易的可信度。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry通过区块链技术将通证经济模型与实体商业价值打通，实现线上与线下的经济体系的完美融合，为广大消费者与各方面商户提供更具效率、更富有价值的生态体系。通过区块链技术本身所具有的不可篡改、可溯源等属性，消费者的消费痕迹不可篡改，致使消费评论的真实可靠，一方面有利于消费者对商家的信任度，另一方面也利于商品、服务在整个生态中的价值有所保障。',
            img: '',
            strong: '0'
          },
          {
            info: '除此之外，商家提供的基本资料、商品信息、交易记录、消费者评价都记录到区块链上，构建一个商家诚信体系，将使商户信息透明公开，提高了信息的真实可靠性，有利于保障平台的诚信度与入驻平台商家的质量。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry分布式电商平台采用的先进的区块链技术架构将会颠覆当前的电商零售生态，将数据、流量以及价值回归交易的参与方，有效解决中心化平台的各种弊端。不同于传统电商平台的中心化模式，BitCherry底层技术采用分布式记账方式，将所有交易数据、仓储运输、生产制造等环节的数据上链，做到公开透明、不可篡改。',
            img: '',
            strong: '0'
          },
          {
            info: '4月24日，2019年全球区块连投资大会在新加坡盛大召开，BitCherry作为特邀项目方举办了发布仪式，受到业界关注。据会议媒体透露，BitCherry5月5日12点将正式公布生态白皮书，同时上线交易，之后还会有更多、更加具体的内容公布。',
            img: '',
            strong: '0'
          },




        ]
      },

      {
        img: require('../assets/img/media/mediabg2.jpg'),
        title: 'BitCherry:全球首家分布式电商平台',
        time: '2019.04.21',
        infos: [
          {
            info: '近些年来，区块链技术在金融行业、流行业、供应链行业、能源管理行业、医疗行业等不同行业都有广泛应用。然而，它对电商行业产生的影响可能最大。电商行业彻底改变了人们的生活方式。利用微电脑技术和网络通讯技术，实现消费者的网上购物、商户之间的网上交易和在线电子支付。互联网飞速发展20余年间，电商化已成为经济社会发展的重要特征和长期趋势。但是，传统电商平台表面繁华的背后，存在着诸多隐忧，如恶性竞争、隐私泄露、获客成本高、忠诚度低等。现在，这些问题都可以依靠区块链技术得到解决。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry是全球首家分布式电商平台，致力于通过区块链技术赋能零售服务业，打造新一代的共享电商超级生态。4月24日，全球区块链投资峰会新加坡站将隆重举行。届时，BitCherry作为特邀项目将会在此首发亮相。',
            img: '',
            strong: '0'
          },
          {
            info: '由于具有分布式的特点，区块链技术正在颠覆电商网络中的第三方支付平台。以区块链为基础的新型互联网金融体系，买方和卖方可以直接交易，交易基于密码学原理，使得任何达成一致的双方，能够直接支付交易，无需第三方参与，节省了买家和卖家的费用。区块链技术将为电商的发展提供低成本金融交易、高安全标准和令所有客户满意的体验。',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry完美的将区块链技术运用到的自身生态体系中。在这个庞大的生态网络中，超级节点，运营节点，商家，消费者和平台一起参与整个商品价值流通的生命周期。平台以底层区块链账本作为技术框架核心，通过通证激励，让每个生态参与者都能安全交易，利益共享。',
            img: '',
            strong: '0'
          },
          {
            info: '供应链管理也是电商平台必不可缺的一部分。电商业务的生态链由物流、信息流、资金流共同组成，并将行业内的供应商、制造商、分销商、用户串联在一起。结构复杂且中间环节繁多，数据泄露和造假是经常出现的问题。而在区块链网络上验证的数据是不可破坏的，区块链技术可作为一种大规模协同工具，适配供应链管理。许多类型的数据可以通过区块链传输，包括保险、发票、托运和运输以及提货单。BitCherry将提供一个可视化的供应链系统，让消费者可以看到他们购买的产品的订单流，从而帮助增加消费者的信心。提高交易的透明度，从而促进信任。每笔交易都记录在共享分类账中，不能被任何人修改。',
            img: '',
            strong: '0'
          },
          {
            info: '2018 年全球电商行业市场规模达到28 万亿元，较2017 年增长23.4%。近年来，随着移动互联网和在线社交的普及，电商行业的规模也得到了大爆发，2014-2018年年均复合增长率达到90%以上。在这个大背景下，BitCherry的出现意义非凡。它基于一个全新的生态体系，将打造一个无边界的分布式电商平台。赋能生态内的全部参与者，实现价值共享，扩大生态覆盖范畴，最终将分布式电商的业务版图扩展至全世',
            img: '',
            strong: '0'
          },

        ]
      },


    ]
  },
  Project: {
    title: '项目动态',
    btn_info: "查看更多"
  },
  Activity: {
    banner: {
      title: '活动',
      infos: [
        ' '
      ]
    },
    activityList: [
      {
        img: require('../assets/img/activity/0505001ch.png'),
        title: "BitCherry 2周年庆典 开启超2000美金空投盛宴",
        time: '2020-05-05',
        src: ''
      },
      {
        img: require('../assets/img/activity/0505002ch.png'),
        title: "BitCherry两周年好礼大放送，BCHC净买入大赛开启",
        time: '2020-05-05',
        src: ''
      },
      {
        img: require('../assets/img/activity/1224001.png'),
        title: "BitCherry开发者系列任务即将开启",
        time: '2020-12-24',
        src: 'https://www.jinse.com/news/blockchain/956494.html'
      },
      {
        img: require('../assets/img/activity/1.jpg'),
        title: "Bitcherry TPS峰值竞猜活动",
        time: '',
        src: 'https://twitter.com/BitCherryGlobal/status/1323926113094021120?s=20'
      },
      {
        img: require('../assets/img/activity/2.jpg'),
        title: "2020 PBWS峰会门票赠送活动（12月9-10日 法国时间）",
        time: '12.09-12.10',
        src: 'https://twitter.com/BitCherryGlobal/status/1331540855782473728?s=20'
      },
      {
        img: require('../assets/img/activity/3.png'),
        title: "BitCherry技术文章竞赛活动",
        time: '10.23-12.22',
        src: 'https://medium.com/bitcherryglobal/bitcherry-technical-articles-contest-c19843fc895d'
      },
      {
        img: require('../assets/img/activity/4.jpg'),
        title: " BitCherry&俄罗斯Sergey访谈活动",
        time: '12.24',
        src: 'https://twitter.com/BitCherryGlobal/status/1331145692677476353?s=20'
      },
      {
        img: require('../assets/img/activity/5.jpeg'),
        title: "BitCherry 土耳其社区空投活动",
        time: '12.24',
        src: 'https://twitter.com/BitCherryT/status/1338417805956407299'
      },
      {
        img: require('../assets/img/activity/6.jpeg'),
        title: " BitCherry 中东社区空投活动",
        time: '11.28',
        src: 'https://twitter.com/BitcherryArabic/status/1332677836914749440/'
      },

    ]
  },
}