<template>
  <div class='project'>
    <div class="c_banner_1_box">
      <div class="c_b_1_con">
        <div class="info" v-class="'info b'">
          <p class="title">{{$t('Project.title')}}</p>

        </div>

        <img v-class="'b'" src="../assets/img/projectmore/banner_1_icon.png" alt="">
      </div>

    </div>
    <div class="home_banner_4">
      <div class="banner_4_content">
        <div class="tab_btn_box">
          <ul>
            <li :class="typeM=='notice'?'active':''" @click="changeType('notice')">
              {{$t('Notice.banner.title')}}
            </li>
            <li :class="typeM=='notice'?'':'active'" @click="changeType('mide')">
              {{$t('Media.banner.title')}}
            </li>
          </ul>

        </div>

        <ul v-show="typeM=='notice'">
          <li @click=" getDescribe (index)" class="v-hide" v-for="(item,index) in $t('Notice.noticeList')" v-class="index<6?'delay-'+index+1:'b'" v-bind:key="index" v-show="index<noticeshowInd">
            <img :src="item.img" alt="">
            <p class="list_title">{{item.title.slice(0,30)}}...</p>
            <p class="list_info">{{item.infos[0].info.slice(0,85)}}...</p>
            <p class="list_time">{{item.time}}</p>
          </li>

        </ul>

        <ul v-show="typeM!='notice'" class="232">
          <li class="v-hide" v-for="(item,index) in $t('Media.mediaList')" v-class="index<6?'delay-'+index+1:'b'" v-bind:key="index" v-show="index<mediashowInd">
            <a :href="item.links">
              <!-- <img :src="index==0?item.img:require('../assets/img/mediacom.jpg')" alt=""> -->
              <img src="../assets/img/mediacom.jpg" alt="">

              <p class="list_title">{{item.title.slice(0,30)}}...</p>
              <p class="list_info">{{item.infos[0].info.slice(0,85)}}...</p>
              <p class="list_time"></p>
            </a>
          </li>

        </ul>
        <button v-class="'see_m b'" @click="showMore (typeM)">{{$t('Project.btn_info')}} <i class="el-icon-arrow-down"></i></button>
        <!-- <button v-class="'see_less b'">查看更多 <i class="el-icon-arrow-up"></i></button> -->
      </div>
    </div>

  </div>
</template>

<script>
//import BigTile from '../components/BigTitle'
export default {
  components: {},
  name: '',
  data () {
    return {
      typeM: "notice",
      noticeshowInd: 6,
      mediashowInd: 6,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    changeType (str) {
      this.typeM = str
    },
    getDescribe (id) {

      this.$router.push({ path: '/details/', query: { id: id, lan: this.lantype } });

    },
    showMore (types) {
      if (types == "notice") {
        this.noticeshowInd += 6
      } else {
        this.mediashowInd += 6
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.b {
  animation: slideInUp 1s 0s;
}
.v-hide {
  visibility: hidden;
}
.delay-1 {
  animation: slideInUp 1s 0.1s;
  opacity: 1;
  transition-delay: 0.1s;
  visibility: visible;
}
.delay-2 {
  animation: slideInUp 1s 0.2s;
  opacity: 1;
  transition-delay: 0.2s;
  visibility: visible;
}
.delay-3 {
  animation: slideInUp 1s 0.3s;
  opacity: 1;
  transition-delay: 0.3s;
  visibility: visible;
}
.delay-4 {
  animation: slideInUp 1s 0.4s;
  opacity: 1;
  transition-delay: 0.4s;
  visibility: visible;
}
.delay-5 {
  animation: slideInUp 1s 0.5s;
  opacity: 1;
  transition-delay: 0.5s;
  visibility: visible;
}
.delay-6 {
  animation: slideInUp 1s 0.6s;
}
.project {
  width: 100%;
  min-height: 100vh;
  .c_banner_1_box {
    width: 100%;
    min-height: 571px;
    background: #000000;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: flex-end;
    .c_b_1_con {
      width: 1100px;
      display: flex;
      justify-content: space-between;
      align-content: flex-end;
      align-items: center;
      text-align: left;
      .info {
        width: 50%;
        p.title {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 45px;
          padding-bottom: 55px;
          text-align: center;
        }
        p {
          font-size: 18px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 100;
          color: #ffffff;
          line-height: 34px;
        }
      }
      img {
        width: 447px;
        display: inline;
        flex: 0.5;
      }
    }
  }
  .home_banner_4 {
    width: 100%;
    min-height: 50vh;
    background: #ffffff;
    .banner_4_content {
      padding: 0px 0 80px 0;
      width: 1200px;
      margin-left: calc(50% - 600px);
      text-align: center;
      position: relative;
      .tab_btn_box {
        position: absolute;
        height: 88px;
        top: -88px;
        display: flex;
        align-content: flex-end;
        ul {
          padding: 0;
          display: flex;
          align-content: flex-end;
          li {
            padding: 0;
            text-align: center;
            background: #ca1425;
            font-size: 26px;
            color: #ffffff;
            width: 294px;
            height: 74px;
            line-height: 74px;
            margin-top: 14px;
          }
          li.active {
            height: 88px;
            line-height: 88px;
            background: #ffffff;
            color: #333333;
            margin-top: 0;
          }
        }
      }
      p.title {
        color: #333333;
        font-size: 36px;
        padding: 48px 0;
        line-height: 50px;
        font-weight: 600;
        text-align: left;
      }
      ul {
        list-style: none;
        padding-top: 80px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          padding-bottom: 60px;
          width: 380px;
          color: #333333;
          text-align: left;
          a {
            color: #333333;
            text-decoration: none;
            p.list_info {
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
              color: #333333;
            }
            p.list_title {
              font-weight: 500;
              font-size: 26px;
              line-height: 42px;

              padding: 10px 0;
            }
            p.list_time {
              color: #999999;
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
              padding-top: 20px;
            }
            img {
              width: 100%;
            }
          }
          p.list_info {
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
          }
          p.list_title {
            font-weight: 500;
            font-size: 26px;
            line-height: 42px;

            padding: 10px 0;
          }
          p.list_time {
            color: #999999;
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
            padding-top: 20px;
          }
          img {
            width: 100%;
          }
        }
        li:hover {
          color: #888888;
          cursor: pointer;
          p.list_title {
            color: #787878;
          }
          p.list_time {
            color: #c8c8c8;
          }
        }
      }
      button.see_m {
        width: 208px;
        height: 64px;
        border: 1px solid #d6d6d6;
        background: transparent;

        margin-right: 15px;
      }
      button.see_m:hover {
        background: #ca1425;
        color: #ffffff;
        border: 1px solid #ca1425;
      }
      button.see_less {
        background: #ca1425;
        color: #ffffff;
        border: 1px solid #ca1425;
        width: 208px;
        height: 64px;
        margin-left: 15px;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .project {
    width: 100%;
    min-height: 100vh;
    .c_banner_1_box {
      width: 100%;
      min-height: 571px;
      background: #000000;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-content: flex-end;
      .c_b_1_con {
        width: 1000px;
      }
    }
    .home_banner_4 {
      width: 100%;
      min-height: 50vh;
      background: #ffffff;
      .banner_4_content {
        padding: 100px 0 25px 0;
        width: 1000px;
        margin-left: calc(50% - 500px);
        text-align: center;
        p.title {
          color: #333333;
          font-size: 30px;
          padding: 40px 0;
          line-height: 50px;
          font-weight: 600;
          text-align: left;
        }
        ul {
          list-style: none;
          padding-top: 40px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            padding-bottom: 60px;
            width: 310px;
            color: #333333;
            text-align: left;
            p.list_info {
              font-size: 12px;
              line-height: 22px;
              font-weight: 400;
            }
            p.list_title {
              font-weight: 500;
              font-size: 22px;
              line-height: 42px;

              padding: 10px 0;
            }
            p.list_time {
              color: #999999;
              font-size: 12px;
              line-height: 22px;
              font-weight: 400;
              padding-top: 20px;
            }
            img {
              width: 100%;
            }
          }
          li:hover {
            color: #888888;
            cursor: pointer;
            p.list_title {
              color: #787878;
            }
            p.list_time {
              color: #c8c8c8;
            }
          }
        }
        button.see_m {
          width: 208px;
          height: 64px;
          border: 1px solid #d6d6d6;
          background: transparent;

          margin-right: 15px;
        }
        button.see_m:hover {
          background: #ca1425;
          color: #ffffff;
          border: 1px solid #ca1425;
        }
        button.see_less {
          background: #ca1425;
          color: #ffffff;
          border: 1px solid #ca1425;
          width: 208px;
          height: 64px;
          margin-left: 15px;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .project {
    width: 100%;
    min-height: 100vh;
    .c_banner_1_box {
      margin-top: 60px;
      width: 100%;
      min-height: 571px;
      background: #000000;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-content: flex-end;
      .c_b_1_con {
        width: 88%;
        display: flex;
        justify-content: space-between;
        align-content: flex-end;
        align-items: center;
        text-align: left;
        flex-wrap: wrap;
        .info {
          width: 100%;
          p.title {
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 45px;
            padding-bottom: 55px;
            text-align: center;
          }
          p {
            font-size: 18px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 100;
            color: #ffffff;
            line-height: 34px;
          }
        }
        img {
          width: 100%;
          display: inline;
          flex: 1;
        }
      }
    }
    .home_banner_4 {
      width: 100%;
      min-height: 50vh;
      background: #ffffff;
      .banner_4_content {
        padding: 50px 0 25px 0;
        width: 88%;
        margin-left: calc(6%);
        text-align: center;
        .tab_btn_box {
          position: absolute;
          height: 44px;
          top: -44px;
          display: flex;
          align-content: flex-end;
          ul {
            padding: 0;
            display: flex;
            align-content: flex-end;
            li {
              padding: 0;
              text-align: center;
              background: #ca1425;
              font-size: 14px;
              color: #ffffff;
              width: 120px;
              height: 34px;
              line-height: 34px;
              margin-top: 10px;
            }
            li.active {
              height: 44px;
              line-height: 44px;
              background: #ffffff;
              color: #333333;
              margin-top: 0;
            }
          }
        }
        p.title {
          color: #333333;
          font-size: 18px;
          padding: 24px 0;
          line-height: 36px;
          font-weight: 600;
          text-align: left;
        }
        ul {
          list-style: none;
          padding-top: 20px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            padding-bottom: 30px;
            width: 100%;
            color: #333333;
            text-align: left;
            a {
              color: #333333;
              text-decoration: none;
              p.list_info {
                font-size: 12px;
                line-height: 22px;
                font-weight: 400;
              }
              p.list_title {
                font-weight: bold;
                font-size: 13px;
                line-height: 26px;

                padding: 10px 0;
              }
              p.list_time {
                color: #999999;
                font-size: 12px;
                line-height: 22px;
                font-weight: 400;
                padding-top: 10px;
              }
              img {
                width: 100%;
              }
            }
            p.list_info {
              font-size: 12px;
              line-height: 22px;
              font-weight: 400;
            }
            p.list_title {
              font-weight: bold;
              font-size: 13px;
              line-height: 26px;

              padding: 10px 0;
            }
            p.list_time {
              color: #999999;
              font-size: 12px;
              line-height: 22px;
              font-weight: 400;
              padding-top: 10px;
            }
            img {
              width: 100%;
            }
          }
          li:hover {
            color: #888888;
            cursor: pointer;
            p.list_title {
              color: #787878;
            }
            p.list_time {
              color: #c8c8c8;
            }
          }
        }
        button.see_m {
          width: 120px;
          height: 40px;
          border: 1px solid #d6d6d6;
          background: transparent;

          margin-right: 15px;
        }
        button.see_m:hover {
          background: #ca1425;
          color: #ffffff;
          border: 1px solid #ca1425;
        }
        button.see_less {
          background: #ca1425;
          color: #ffffff;
          border: 1px solid #ca1425;
          width: 120px;
          height: 40px;
          margin-left: 15px;
        }
      }
    }
  }
}
</style>