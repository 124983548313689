<template>
  <div class='developer'>
    <div class="c_banner_1_box">
      <div class="c_b_1_con">
        <img v-class="'pc b'" src="../assets/img/developer/banner_1_icon.png" alt="">
        <div class="info" v-class="'info b'">
          <p class="title">{{$t("Developer.banner_top.title")}}</p>
          <p>{{$t("Developer.banner_top.info")}}</p>
          <button @click="goshequ3">{{$t("Developer.banner_top.btn_info")}}</button>
        </div>
        <img v-class="'m b'" src="../assets/img/developer/banner_1_icon.png" alt="">
      </div>

    </div>
    <div class="banner_list_box">
      <div class="cont_l_box">
        <img v-class="'m b'" src="../assets/img/developer/list_icon_1.png" alt="">
        <div class="cont_l_info" v-class="'cont_l_info b'">
          <p class="title">{{$t("Developer.banner_2.title")}}</p>
          <p>{{$t("Developer.banner_2.info")}}</p>
          <button @click="goshequ1">{{$t("Developer.banner_2.btn_info")}}</button>
        </div>
        <img v-class="'pc b'" src="../assets/img/developer/list_icon_1.png" alt="">
      </div>
    </div>
    <div class="banner_list_box block_bg">
      <div class="cont_l_box">
        <img v-class="'b'" src="../assets/img/developer/list_icon_2.png" alt="">
        <div class="cont_l_info" v-class="'cont_l_info b'">
          <p class="title">{{$t("Developer.banner_3.title")}}</p>
          <p>{{$t("Developer.banner_3.info")}}</p>
          <button @click="goshequ2">{{$t("Developer.banner_3.btn_info")}}</button>
        </div>

      </div>
    </div>
    <div class="banner_list_box">
      <div class="cont_l_box">
        <img v-class="'m b'" src="../assets/img/developer/list_icon_3.png" alt="">
        <div class="cont_l_info" v-class="'cont_l_info b'">
          <p class="title">{{$t("Developer.banner_4.title")}}</p>
          <p>{{$t("Developer.banner_4.info")}}</p>
          <button class="g_btn">{{$t("Developer.banner_4.btn_info")}}</button>
        </div>
        <img v-class="'pc b'" src="../assets/img/developer/list_icon_3.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  name: '',
  data () {
    return {

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    goshequ1 () {
      window.location.href = "https://github.com/BitcherryFoundation/blockchain/issues"
    },
    goshequ2 () {
      window.location.href = "https://discord.gg/XDCQqWahf6"
    },
    goshequ3 () {
      window.location.href = "http://developers.bitcherry.io/apiReferenceManual/"
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.b {
  animation: slideInUp 1s 0s;
}
.developer {
  width: 100%;
  min-height: 100vh;
  .c_banner_1_box {
    width: 100%;
    min-height: 750px;
    background: #000000;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: flex-end;
    .c_b_1_con {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      align-content: flex-end;
      align-items: center;
      text-align: left;
      .info {
        width: 44%;
        text-align: right;
        p.title {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 45px;
          padding-bottom: 55px;
        }
        p {
          font-size: 18px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 100;
          color: #ffffff;
          line-height: 34px;
          padding-bottom: 55px;
        }
        button {
          color: #ffffff;
          width: 208px;
          height: 64px;
          border: 1px solid #ca1425;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          background: transparent;
        }
        button:hover {
          background: #ca1425;
          border: 1px solid #ca1425;
          color: #ffffff;
        }
      }
      img {
        width: 618px;
        display: inline;
        flex: 0.5;
      }
      img.m {
        display: none;
      }
    }
  }
  .banner_list_box {
    width: 100%;

    background: #ffffff;
    display: flex;
    justify-content: space-around;
    .cont_l_box {
      width: 1200px;
      padding: 142px 0;
      display: flex;
      justify-content: space-between;
      // flex-wrap: wrap;
      align-items: center;
      text-align: left;
      .cont_l_info {
        width: 40%;
        p.title {
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 50px;
          padding: 0;
        }
        p {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 38px;
          padding: 60px 0;
        }
        button {
          color: #333333;
          width: 208px;
          height: 64px;
          border: 1px solid #d6d6d6;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          background: transparent;
        }
        button:hover {
          background: #ca1425;
          border: 1px solid #ca1425;
          color: #ffffff;
        }

        button.g_btn {
          color: #333333;
          width: 120px;
          height: 40px;
          border: none;
          font-size: 12px;
          font-weight: 500;
          cursor: pointer;
          background: #666666;
          color: #ffffff;
          // border: 1px solid #454545;
        }
        button.g_btn:hover {
          background: #666666;
          border: none;
          color: #ffffff;
        }
        button.g_btn:focus {
          background: #666666;
          border: none;
          margin: 0;
          padding: 0;
          border: 1px solid transparent; //自定义边框
          outline: none;
        }
        button.g_btn.active.focus {
          outline: none;
          // 若是本身具有 border 请删除此属性
          box-shadow: none;
          outline: none;
          border: none;
        }
      }
      img.m {
        display: none;
      }
    }
  }
  .block_bg {
    background: #000000;
    .cont_l_box {
      .cont_l_info {
        text-align: right;
        p.title {
          color: #ffffff;
        }
        p {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #dfdfdf;
          line-height: 38px;
          padding: 60px 0;
        }
        button {
          color: #ffffff;
          width: 208px;
          height: 64px;
          border: 1px solid #d6d6d6;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          background: transparent;
        }
        button:hover {
          background: #ca1425;
          border: 1px solid #ca1425;
          color: #ffffff;
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .developer {
    width: 100%;
    min-height: 100vh;
    .c_banner_1_box {
      width: 100%;
      min-height: 700px;

      .c_b_1_con {
        width: 1000px;

        .info {
          width: 44%;
          text-align: right;
          p.title {
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 45px;
            padding-bottom: 55px;
          }
          p {
            font-size: 18px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 100;
            color: #ffffff;
            line-height: 34px;
            padding-bottom: 55px;
          }
          button {
            color: #ffffff;
            width: 208px;
            height: 64px;
            border: 1px solid #ca1425;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            background: transparent;
          }
          button:hover {
            background: #ca1425;
            border: 1px solid #ca1425;
            color: #ffffff;
          }
        }
        img {
          width: 518px;
          display: inline;
          flex: 0.5;
        }
      }
    }
    .banner_list_box {
      width: 100%;

      background: #ffffff;
      display: flex;
      justify-content: space-around;
      .cont_l_box {
        width: 1000px;
        padding: 122px 0;
        display: flex;
        justify-content: space-between;
        // flex-wrap: wrap;
        align-items: center;
        text-align: left;
        .cont_l_info {
          width: 40%;
          p.title {
            font-size: 36px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 50px;
            padding: 0;
          }
          p {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 38px;
            padding: 60px 0;
          }
          button {
            color: #333333;
            width: 208px;
            height: 64px;
            border: 1px solid #d6d6d6;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            background: transparent;
          }
          button:hover {
            background: #ca1425;
            border: 1px solid #ca1425;
            color: #ffffff;
          }
        }
        img {
          width: 40%;
        }
      }
    }
    .block_bg {
      background: #000000;
      .cont_l_box {
        .cont_l_info {
          text-align: right;
          p.title {
            color: #ffffff;
          }
          p {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #dfdfdf;
            line-height: 38px;
            padding: 60px 0;
          }
          button {
            color: #ffffff;
            width: 208px;
            height: 64px;
            border: 1px solid #d6d6d6;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            background: transparent;
          }
          button:hover {
            background: #ca1425;
            border: 1px solid #ca1425;
            color: #ffffff;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .developer {
    width: 100%;
    min-height: 100vh;
    .c_banner_1_box {
      width: 100%;
      min-height: 700px;
      margin-top: 60px;
      background: #000000;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-content: flex-end;
      .c_b_1_con {
        width: 88%;
        display: flex;
        justify-content: space-between;
        align-content: flex-end;
        align-items: center;
        text-align: left;
        flex-wrap: wrap;

        .info {
          width: 100%;
          text-align: center;
          padding-bottom: 25px;
          p.title {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 32px;
            padding-bottom: 25px;
          }
          p {
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 100;
            color: #ffffff;
            line-height: 28px;
            padding-bottom: 25px;
          }
          button {
            color: #ffffff;
            width: 120px;
            height: 40px;
            border: 1px solid #ca1425;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            background: transparent;
          }
          button:hover {
            background: #ca1425;
            border: 1px solid #ca1425;
            color: #ffffff;
          }
        }
        img {
          width: 100%;
          display: inline;
          flex: 1;
        }
        img.pc {
          display: none;
        }
        img.m {
          display: block;
        }
      }
    }
    .banner_list_box {
      width: 100%;

      background: #ffffff;
      display: flex;
      justify-content: space-around;
      .cont_l_box {
        width: 88%;
        padding: 71px 0;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        text-align: left;
        .cont_l_info {
          width: 100%;
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          p.title {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 36px;
            padding: 0;
          }
          p {
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 28px;
            padding: 30px 0;
          }
          button {
            color: #333333;
            width: 120px;
            height: 40px;
            border: 1px solid #d6d6d6;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            background: transparent;
          }
          button:hover {
            background: #ca1425;
            border: 1px solid #ca1425;
            color: #ffffff;
          }
        }
        img.pc {
          display: none;
        }
        img {
          width: 60%;
          margin-bottom: 30px;
        }
        img.m {
          display: block;
        }
      }
    }
    .block_bg {
      background: #000000;
      .cont_l_box {
        .cont_l_info {
          text-align: right;
          p.title {
            color: #ffffff;
          }
          p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #dfdfdf;
            line-height: 28px;
            padding: 30px 0;
          }
          button {
            color: #ffffff;
            width: 120px;
            height: 40px;
            border: 1px solid #d6d6d6;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            background: transparent;
          }
          button:hover {
            background: #ca1425;
            border: 1px solid #ca1425;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>