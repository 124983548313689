<template>
  <div class='develop'>
    <div class="c_banner_1_box">
      <div class="c_b_1_con">
        <div class="info" v-class="'info  b'">
          <p class="title">{{$t("Roadmap.banner_top.title")}}</p>
          <p>{{$t("Roadmap.banner_top.info")}}</p>
        </div>

        <img v-class="' b'" src="../assets/img/develop/banner_1_icon.png" alt="">
      </div>

    </div>
    <div class="c_banner_2_box">
      <div class="c_b_2_cont">
        <p class="line"></p>
        <div class="c_b_2_c_list left" v-class="'c_b_2_c_list left b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_1.time')}}
            </p>
            <p> {{$t('Roadmap.list_1.info')}}</p>
          </div>
        </div>
        <div class="c_b_2_c_list right" v-class="'c_b_2_c_list right b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_2.time')}}
            </p>
            <p> {{$t('Roadmap.list_2.info')}}</p>
          </div>
        </div>
        <div class="c_b_2_c_list left" v-class="'c_b_2_c_list left b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_3.time')}}
            </p>
            <p> {{$t('Roadmap.list_3.info')}}</p>
          </div>
        </div>
        <div v-class="'c_b_2_c_list right b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_4.time')}}
            </p>
            <p> {{$t('Roadmap.list_4.info')}}</p>
          </div>
        </div>
        <div v-class="'c_b_2_c_list left b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_5.time')}}
            </p>
            <p> {{$t('Roadmap.list_5.info')}}</p>
          </div>
        </div>
        <div v-class="'c_b_2_c_list right b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_6.time')}}
            </p>
            <p> {{$t('Roadmap.list_6.info')}}</p>
          </div>
        </div>
        <div v-class="'c_b_2_c_list left b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_7.time')}}
            </p>
            <p> {{$t('Roadmap.list_7.info')}}</p>
          </div>
        </div>
        <div v-class="'c_b_2_c_list right b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_8.time')}}
            </p>
            <p> {{$t('Roadmap.list_8.info')}}</p>
          </div>
        </div>
        <div v-class="'c_b_2_c_list left b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_9.time')}}
            </p>
            <p> {{$t('Roadmap.list_9.info')}}</p>
          </div>
        </div>
        <div v-class="'c_b_2_c_list right b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_10.time')}}
            </p>
            <p> {{$t('Roadmap.list_10.info')}}</p>
          </div>
        </div>
        <div v-class="'c_b_2_c_list left b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_11.time')}}
            </p>
            <p> {{$t('Roadmap.list_11.info')}}</p>
          </div>
        </div>
        <div v-class="'c_b_2_c_list right b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_12.time')}}
            </p>
            <p> {{$t('Roadmap.list_12.info')}}</p>
          </div>
        </div>
        <div v-class="'c_b_2_c_list left b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_13.time')}}
            </p>
            <p> {{$t('Roadmap.list_13.info')}}</p>
          </div>
        </div>
        <div v-class="'c_b_2_c_list right b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_14.time')}}
            </p>
            <p> {{$t('Roadmap.list_14.info')}}</p>
          </div>
        </div>
        <div v-class="'c_b_2_c_list left b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_15.time')}}
            </p>
            <p> {{$t('Roadmap.list_15.info')}}</p>
          </div>
        </div>
        <div v-class="'c_b_2_c_list right b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_16.time')}}
            </p>
            <p> {{$t('Roadmap.list_16.info')}}</p>
          </div>
        </div>
        <div v-class="'c_b_2_c_list left b'">
          <i></i>
          <div class="info_box">
            <p class="title">
              {{$t('Roadmap.list_17.time')}}
            </p>
            <p> {{$t('Roadmap.list_17.info')}}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  name: '',
  data () {
    return {

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.b {
  animation: slideInUp 1s 0s;
}
.develop {
  width: 100%;
  min-height: 100vh;
  .c_banner_1_box {
    width: 100%;
    min-height: 36vw;
    background: #000000;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: flex-end;
    .c_b_1_con {
      width: 1100px;
      display: flex;
      justify-content: space-between;
      align-content: flex-end;
      align-items: center;
      text-align: left;
      .info {
        width: 50%;
        p.title {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 45px;
          padding-bottom: 55px;
        }
        p {
          font-size: 18px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 100;
          color: #ffffff;
          line-height: 34px;
        }
      }
      img {
        width: 447px;
        display: inline;
        flex: 0.5;
      }
    }
  }
  .c_banner_2_box {
    width: 100%;
    min-height: 36vw;
    background: #ffffff;
    display: flex;
    justify-content: space-around;
    padding: 130px 0;
    .c_b_2_cont {
      position: relative;
      min-width: 800px;
      p.line {
        position: absolute;
        width: 1px;
        background: #cccccc;
        height: 100%;
        left: 50%;
      }
      .c_b_2_c_list {
        width: 30%;
        padding-top: 100px;
        position: relative;
        i {
          display: inline-block;
          width: 17px;
          height: 17px;
          background: #ca1425;
          border-radius: 50%;
          top: 126px;
          position: absolute;
        }
        .info_box {
          p.title {
            font-size: 38px;
            font-family: Oswald-Medium, Oswald;
            font-weight: 500;
            color: #333333;
            line-height: 51px;
            padding: 10px 0;
          }
          p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 28px;
          }
        }
      }
      .c_b_2_c_list:last-child {
        padding-bottom: 120px;
      }
      .left {
        margin-left: calc(20% - 66px);
        text-align: right;
        i {
          right: -75px;
        }
      }
      .right {
        margin-left: calc(50% + 66px);
        text-align: left;
        i {
          left: -75px;
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .develop {
    width: 100%;
    min-height: 100vh;
    .c_banner_1_box {
      width: 100%;
      min-height: 38vw;
      background: #000000;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-content: flex-end;
      .c_b_1_con {
        width: 1000px;
        display: flex;
        justify-content: space-between;
        align-content: flex-end;
        align-items: center;
        text-align: left;
        .info {
          width: 50%;
          p.title {
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 45px;
            padding-bottom: 55px;
          }
          p {
            font-size: 18px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 100;
            color: #ffffff;
            line-height: 34px;
          }
        }
        img {
          width: 307px;
          display: inline-block;
          flex: none;
        }
      }
    }
    .c_banner_2_box {
      width: 100%;
      min-height: 36vw;
      background: #ffffff;
      display: flex;
      justify-content: space-around;
      padding: 130px 0;
      .c_b_2_cont {
        position: relative;
        p.line {
          position: absolute;
          width: 1px;
          background: #cccccc;
          height: 100%;
          left: 50%;
        }
        .c_b_2_c_list {
          width: 30%;
          padding-top: 100px;
          position: relative;
          i {
            display: inline-block;
            width: 17px;
            height: 17px;
            background: #ca1425;
            border-radius: 50%;
            top: 126px;
            position: absolute;
          }
          .info_box {
            p.title {
              font-size: 38px;
              font-family: Oswald-Medium, Oswald;
              font-weight: 500;
              color: #333333;
              line-height: 51px;
              padding: 10px 0;
            }
            p {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 28px;
            }
          }
        }
        .c_b_2_c_list:last-child {
          padding-bottom: 120px;
        }
        .left {
          margin-left: calc(20% - 66px);
          text-align: right;
          i {
            right: -75px;
          }
        }
        .right {
          margin-left: calc(50% + 66px);
          text-align: left;
          i {
            left: -75px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .develop {
    width: 100%;
    min-height: 100vh;
    .c_banner_1_box {
      width: 100%;
      min-height: 100vw;
      background: #000000;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-content: flex-end;
      margin-top: 60px;
      .c_b_1_con {
        width: 88%;
        display: flex;
        justify-content: space-between;
        align-content: flex-end;
        align-items: center;
        text-align: center;
        flex-wrap: wrap;
        .info {
          width: 100%;
          padding: 100px 0 30px 0;
          p.title {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 36px;
            padding-bottom: 25px;
          }
          p {
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 100;
            color: #ffffff;
            line-height: 28px;
          }
        }
        img {
          width: 100%;
          display: inline;
          flex: 1;
        }
      }
    }
    .c_banner_2_box {
      width: 88%;
      margin-left: 6%;
      min-height: 36vw;
      background: #ffffff;
      display: flex;
      justify-content: space-around;
      padding: 65px 0;
      .c_b_2_cont {
        position: relative;
        min-width: 100%;
        p.line {
          position: absolute;
          width: 1px;
          background: #cccccc;
          height: 100%;
          left: 0;
        }
        .c_b_2_c_list {
          width: 100%;
          padding-top: 50px;
          position: relative;
          i {
            display: inline-block;
            width: 12px;
            height: 12px;
            background: #ca1425;
            border-radius: 50%;
            top: 72px;
            left: -6px;
            position: absolute;
          }
          .info_box {
            padding-left: 30px;
            p.title {
              font-size: 18px;
              font-family: Oswald-Medium, Oswald;
              font-weight: 600;
              color: #333333;
              line-height: 36px;
              padding: 10px 0;
            }
            p {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 28px;
            }
          }
        }
        .c_b_2_c_list:last-child {
          padding-bottom: 120px;
        }
        .left {
          margin-left: calc(20% - 66px);
          text-align: left;
          i {
            right: 75px;
          }
        }
        .right {
          margin-left: calc(0%);
          text-align: left;
          i {
            left: -6px;
          }
        }
      }
    }
  }
}
</style>