import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Community from '../views/Community.vue'
import Develop from '../views/Develop.vue'
import Developer from '../views/Developer.vue'
import What from '../views/What.vue'
import Destruction from '../views/Destruction.vue'
import Wallet from '../views/Wallet.vue'
import Project from '../views/Project.vue'
import Details from '../views/Details.vue'
import Detailscom from '../views/Detailscom.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/community',
    name: 'Community',
    component: Community
  },
  {
    path: '/develop',
    name: 'Develop',
    component: Develop
  },
  {
    path: '/developer',
    name: 'Developer',
    component: Developer
  },
  {
    path: '/what',
    name: 'What',
    component: What
  },
  {
    path: '/destruction',
    name: 'Destruction',
    component: Destruction
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet
  },
  {
    path: '/project',
    name: 'Project',
    component: Project
  },
  {
    path: '/details',
    name: 'Details',
    component: Details
  },
  {
    path: '/detailscom',
    name: 'Detailscom',
    component: Detailscom
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next()
})
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  document.documentElement.scrollTop = 0
  // next()
})

export default router

