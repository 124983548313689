<template>
  <div class='footer'>
    <div class="footer_c_box">
      <div class="footer_c_l_box">
        <div>
          <p class="title">{{$t("Footer.left_box.list1.title")}}</p>
          <p class="list">{{$t("Footer.left_box.list1.info1")}}</p>
          <p class="list">{{$t("Footer.left_box.list1.info2")}}</p>
        </div>
        <div class="bottom">
          <p class="title">{{$t("Footer.left_box.list2.title")}}</p>
          <p class="list"><a href="https://www.bitcherry.io/static/BitCherry-White-Paper-CN-V2.0.1.pdf">{{$t("Footer.left_box.list2.info1")}}</a></p>
          <p class="list"><a href="https://www.bitcherry.io/static/BitCherry-White-Paper-EN-V2.0.1.pdf"> {{$t("Footer.left_box.list2.info2")}}</a></p>
          <p class="list"><a href="https://www.bitcherry.io/static/BitCherry-White-Paper-JP-V2.0.1.pdf">{{$t("Footer.left_box.list2.info3")}}</a></p>

        </div>
      </div>
      <div class="footer_c_r_box">
        <p class="title">{{$t("Footer.right_box.title")}}</p>
        <div>
          <div class="icon_list_box">
            <a href="https://twitter.com/BitCherryGlobal" @mouseover="showActive(1)" @mouseleave="showActive(0)">
              <img class="footer_iocn" src="../assets/img/footer/link_1.png" alt="">
              <img v-show="active==1" class="hide_tab" src="../assets/img/footer/link_1_light.png" alt="">
            </a>
            <a href="https://www.facebook.com/BCHCGlobal/?modal=admin_todo_tour" @mouseover="showActive(2)" @mouseleave="showActive(0)">
              <img class="footer_iocn" src="../assets/img/footer/link_2.png" alt="">
              <img v-show="active==2" class="hide_tab" src="../assets/img/footer/link_2_light.png" alt="">
            </a>
            <a href="https://t.me/BitCherryGlobal" @mouseover="showActive(3)" @mouseleave="showActive(0)">
              <img class="footer_iocn" src="../assets/img/footer/link_3.png" alt="">
              <img v-show="active==3" class="hide_tab" src="../assets/img/footer/link_3_light.png" alt="">
            </a>
            <a href="https://medium.com/@bitcherryofficial" @mouseover="showActive(4)" @mouseleave="showActive(0)">
              <img class="footer_iocn" src="../assets/img/footer/link_4.png" alt="">
              <img v-show="active==4" class="hide_tab" src="../assets/img/footer/link_4_light.png" alt="">
            </a>
            <a href="https://www.linkedin.com/company/bitcherry/" @mouseover="showActive(5)" @mouseleave="showActive(0)">
              <img class="footer_iocn" src="../assets/img/footer/link_5.png" alt="">
              <img v-show="active==5" class="hide_tab" src="../assets/img/footer/link_5_light.png" alt="">
            </a>

          </div>
          <div class="icon_list_box">
            <a href="https://www.instagram.com/bitcherryofficial/" @mouseover="showActive(6)" @mouseleave="showActive(0)">
              <img class="footer_iocn" src="../assets/img/footer/link_6.png" alt="">
              <img v-show="active==6" class="hide_tab" src="../assets/img/footer/link_6_light.png" alt="">
            </a>
            <a href="https://vk.com/bitcherry_russian" @mouseover="showActive(7)" @mouseleave="showActive(0)">
              <img class="footer_iocn" src="../assets/img/footer/link_7.png" alt="">
              <img v-show="active==7" class="hide_tab" src="../assets/img/footer/link_7_light.png" alt="">
            </a>
            <a href="https://www.reddit.com/user/BitCherry_Official/" @mouseover="showActive(8)" @mouseleave="showActive(0)">
              <img class="footer_iocn" src="../assets/img/footer/link_8.png" alt="">
              <img v-show="active==8" class="hide_tab" src="../assets/img/footer/link_8_light.png" alt="">
            </a>
            <a href="https://www.youtube.com/channel/UCBQ20Vuk3aqTIDPhZmYsTkQ" @mouseover="showActive(9)" @mouseleave="showActive(0)">
              <img class="footer_iocn" src="../assets/img/footer/link_9.png" alt="">
              <img v-show="active==9" class="hide_tab" src="../assets/img/footer/link_9_light.png" alt="">
            </a>
            <a href="https://chat.whatsapp.com/BXPdEOMQYlQL5FQIsmn8F7" @mouseover="showActive(10)" @mouseleave="showActive(0)">
              <img class="footer_iocn" src="../assets/img/footer/link_10.png" alt="">
              <img v-show="active==10" class="hide_tab" src="../assets/img/footer/link_10_light.png" alt="">
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  name: '',
  data () {
    return {
      active: 0
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    showActive (index) {
      this.active = index;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' >
.footer {
  width: 100%;
  min-height: 520px;
  background: #000000;
  .footer_c_box {
    width: 1200px;
    margin-left: calc(50% - 600px);
    padding: 100px 0;
    display: flex;
    text-align: left;
    .footer_c_l_box {
      width: 40%;
      margin-left: 10%;
      border-right: 1px solid #575757;
      div {
        p {
          padding-bottom: 10px;
        }
        p.title {
          color: #ffffff;
          font-size: 24px;
          line-height: 33px;
        }
        p.list {
          color: #999999;
          font-size: 16px;
          line-height: 33px;
          a {
            text-decoration: none;
            color: #999999;
          }
          a:hover {
            color: #ffffff;
          }
        }
        p.list:hover {
          color: #ffffff;
        }
      }
      div.bottom {
        padding-top: 30px;
      }
    }
    .footer_c_r_box {
      width: 45%;
      margin-left: 5%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      align-content: space-between;
      p.title {
        color: #ffffff;
        font-size: 24px;
        line-height: 33px;
        width: 100%;
        flex-direction: row;
      }
      .icon_list_box {
        padding-top: 40px;
        a {
          position: relative;
          width: 66px;
          height: 66px;
          margin-right: 30px;
          display: inline-block;
          .hide_tab {
            position: absolute;
            bottom: 0;
            left: 0;
          }
           img {
            width: 66px;
          }
        }
      }
    }
  }
}
@media screen and(max-width: 750px) {
  .footer {
    width: 100%;
    min-height: 520px;
    background: #000000;
    .footer_c_box {
      width: 88%;
      margin-left: calc(6%);
      padding: 50px 0;
      display: flex;
      text-align: left;
      flex-wrap: wrap;
      .footer_c_l_box {
        width: 100%;
        margin-left: 0%;
        border-right: none;
        div {
          p {
            padding-bottom: 5px;
          }
          p.title {
            color: #ffffff;
            font-size: 16px;
            line-height: 32px;
          }
          p.list {
            color: #999999;
            font-size: 14px;
            line-height: 24px;
          }
          p.list:hover {
            color: #ffffff;
          }
        }
        div.bottom {
          padding-top: 20px;
        }
      }
      .footer_c_r_box {
        width: 100%;
        margin-left: 0%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-content: space-between;
        p.title {
          color: #ffffff;
          font-size: 16px;
          line-height: 33px;
          width: 100%;
          flex-direction: row;
          padding-top: 20px;
        }
        .icon_list_box {
          padding-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          a {
            position: relative;

            margin-right: 25px;

            width: 40px;
            height: 40px;

            // display: flex;
            // justify-content: space-around;
            // align-items: center;
            display: inline-block;
            .hide_tab {
              position: absolute;
              bottom: 0;
              left: 0;
            }
            .footer_iocn {
              display: inline-block;
              width: 40px;
              height: 40px;
            }
             img {
              width: 40px;
              height: 40px;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
</style>