import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from '@/lang'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import animated from 'animate.css'
//Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(animated)
Vue.directive('class', {
  inserted: function (el, binding) {
    // 聚焦元素
    binding.addClass = () => {
      const { top } = el.getBoundingClientRect()
      const h = document.documentElement.clientHeight || document.body.clientHeight


      if (top < h) {


        el.className = binding.value
        if (binding.addClass) {
          window.removeEventListener('scroll', binding.addClass)
        }
      }
    }

    window.addEventListener('scroll', binding.addClass)

    binding.addClass()
  },
  unbind: function (el, binding) {
    if (binding.addClass) {
      window.removeEventListener('scroll', binding.addClass)
      console.log('取消事件绑定')
    }
  }
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
