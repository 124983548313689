<template>
  <div class='community'>
    <div class="c_banner_1_box">
      <div class="c_b_1_con">
        <div class="info" v-class="'info b'">
          <p class="title">{{$t("Community.banner_top.title")}}</p>
          <p>{{$t("Community.banner_top.info")}}</p>
        </div>

        <img v-class="'b'" src="../assets/img/community/banner_1_icon.png" alt="">
      </div>

    </div>
    <div class="c_banner_2_box">
      <div class="c_b_2_cont">

        <div class="activities">
          <BigTile />
          <p class="a_title" v-class="'a_title b'">{{$t("Community.banner_2.title")}}</p>
          <p class="a_info" v-class="'a_info b'">{{$t("Community.banner_2.info")}}</p>
          <ul>
            <li v-for="(item,index) in $t('Activity.activityList') " v-bind:key=" index" class="v-hide" v-class="'delay-'+(index+1)" v-show="index<moreshowIndex">
              <a @click=" getDescribe (index+1)" @mouseenter="getDescribe (index+1)" @mouseleave="getDescribe (index+1)" :href="item.src"><img :src="item.img" alt=""></a>
              <p v-show="showIndex==index+1" @click=" goNewAdd (item.src)">
                <span class="title">{{item.title}}</span>
                <span class="time">{{item.time}}</span>
              </p>
            </li>

          </ul>

          <button @click="MoreIn()" v-class="'b'">{{$t("Community.banner_2.btn_info")}} <i class="el-icon-arrow-down"></i></button>
        </div>
        <div class="promotion">
          <BigTile />
          <p class="p_title" v-class="'p_title b'">{{$t("Community.banner_3.title")}}</p>
          <p class="p_info" v-class="'p_info b'">{{$t("Community.banner_3.info")}}</p>
          <ul>
            <li v-for="(item,index) in $t('Community.banner_3.pleLists')" v-bind:key="index" class="v-hide" v-class="'delay-'+(index+1)">
              <img :src="item.img" alt="">
              <p class="name">{{item.title}}</p>
              <p class="title">{{item.ad}}</p>
              <p class="info">{{item.info}}</p>
            </li>

          </ul>
        </div>
      </div>

    </div>
    <div class="c_banner_3_box">
      <div class="c_b_3_cont">
        <div class="c_b_3_cont_info">
          <BigTile />
          <p class="title">{{$t("Community.banner_4.title")}}</p>
          <p class="info">{{$t("Community.banner_4.info")}}</p>
          <button>{{$t("Community.banner_4.btn_info")}}</button>
        </div>
        <img src="../assets/img/community/banner_3_icon.png" alt="">
        p
      </div>
    </div>
  </div>
</template>

<script>
import BigTile from '../components/BigTitle'
export default {
  components: { BigTile },
  name: '',
  data () {
    return {
      showIndex: 0,
      moreshowIndex: 6,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getDescribe (id) {

      this.showIndex = id

    },
    goNewAdd (src) {
      window.location.href = src
    },
    MoreIn () {
      this.moreshowIndex += 6
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.b {
  animation: slideInUp 1s 0s;
  visibility: visible;
}
.v-hide {
  visibility: hidden;
}
.delay-1 {
  animation: slideInUp 1s 0.1s;
  opacity: 1;
  transition-delay: 0.1s;
  visibility: visible;
}
.delay-2 {
  animation: slideInUp 1s 0.2s;
  opacity: 1;
  transition-delay: 0.2s;
  visibility: visible;
}
.delay-3 {
  animation: slideInUp 1s 0.3s;
  opacity: 1;
  transition-delay: 0.3s;
  visibility: visible;
}
.delay-4 {
  animation: slideInUp 1s 0.4s;
  opacity: 1;
  transition-delay: 0.4s;
  visibility: visible;
}
.delay-5 {
  animation: slideInUp 1s 0.5s;
  opacity: 1;
  transition-delay: 0.5s;
  visibility: visible;
}
.delay-6 {
  animation: slideInUp 1s 0.6s;
  transition-delay: 0.6s;
  visibility: visible;
  opacity: 1;
}
.community {
  width: 100%;
  min-height: 100vh;
  .c_banner_1_box {
    width: 100%;
    min-height: 33vw;
    background: #000000;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: flex-end;
    .c_b_1_con {
      width: 1100px;
      display: flex;
      justify-content: space-between;
      align-content: flex-end;
      align-items: center;
      text-align: left;
      .info {
        width: 50%;
        p.title {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 45px;
          padding-bottom: 55px;
        }
        p {
          font-size: 18px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 100;
          color: #ffffff;
          line-height: 34px;
        }
      }
      img {
        width: 447px;
        display: inline;
        flex: 0.5;
      }
    }
  }
  .c_banner_2_box {
    width: 100%;
    min-height: 36vw;
    background: #ffffff;
    display: flex;
    justify-content: space-around;
    padding: 130px 0;
    .c_b_2_cont {
      width: 1200px;
      .activities {
        width: 100%;
        padding-bottom: 126px;
        p {
          text-align: left;
        }
        p.a_title {
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 50px;
          padding: 50px 0;
        }
        p.a_info {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 38px;
        }
        ul {
          padding: 100px 0;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          text-align: center;
          li {
            width: 380px;
            margin-top: 30px;
            position: relative;
            p {
              width: 100%;
              height: 100%;
              position: absolute;
              background: rgba(0, 0, 0, 0.74);
              left: 0;
              top: 0;
              display: flex;
              justify-content: flex-start;
              align-content: flex-end;
              flex-wrap: wrap;

              span {
                display: inline-block;
                width: 100%;
                color: #ffffff;
                font-size: 18px;
                padding: 10px;
              }
              span.time {
                color: #b1b1b1;
                font-size: 14px;
              }
            }
            a {
              display: inline-block;
              img {
                width: 100%;
              }
            }
          }
        }
        button {
          color: #333333;
          width: 208px;
          height: 64px;
          border: 1px solid #d6d6d6;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          background: transparent;
        }
        button:hover {
          background: #ca1425;
          border: 1px solid #ca1425;
          color: #ffffff;
        }
      }
      .promotion {
        width: 100%;
        p {
          text-align: left;
        }
        p.p_title {
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 50px;
          padding: 50px 0;
        }
        p.p_info {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 38px;
          width: 60%;
        }
        ul {
          padding: 100px 0;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            width: 350px;
            padding-top: 90px;
            p.name {
              color: #333333;
              font-size: 20px;
              line-height: 28px;
              text-align: center;
              font-weight: 500;
              padding-top: 30px;
            }
            p.title {
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #444444;
              line-height: 20px;
              padding: 23px 0;
            }
            p.info {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 26px;
              text-align: center;
            }
            img {
              width: 103px;
            }
          }
        }
      }
    }
  }
  .c_banner_3_box {
    width: 100%;
    min-height: 707px;
    background: #000000;
    display: flex;
    justify-content: space-around;

    .c_b_3_cont {
      width: 1200px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      .c_b_3_cont_info {
        padding: 130px 0;
        width: 50%;
        text-align: left;
        p.title {
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 50px;
          padding: 50px 0;
        }
        p.info {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 38px;
          padding-bottom: 86px;
        }
        button {
          width: 208px;
          height: 64px;

          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          background: #666666;
          color: #ffffff;
          border: 1px solid #454545;
        }
        button:hover {
          // background: #ca1425;
          // border: 1px solid #ca1425;
          color: #ffffff;
        }
      }
      img {
        position: absolute;
        right: 0;
        top: 0;
        width: 78%;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .community {
    width: 100%;
    min-height: 100vh;
    .c_banner_1_box {
      width: 100%;
      min-height: 35vw;
      background: #000000;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-content: flex-end;
      .c_b_1_con {
        width: 1000px;
        display: flex;
        justify-content: space-between;
        align-content: flex-end;
        align-items: center;
        text-align: left;
        .info {
          width: 50%;
          p.title {
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 45px;
            padding-bottom: 55px;
          }
          p {
            font-size: 18px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 100;
            color: #ffffff;
            line-height: 34px;
          }
        }
        img {
          width: 347px;
          display: inline;
          flex: 0.5;
        }
      }
    }
    .c_banner_2_box {
      width: 100%;
      min-height: 36vw;
      background: #ffffff;
      display: flex;
      justify-content: space-around;
      padding: 130px 0;
      .c_b_2_cont {
        width: 1000px;
        .activities {
          width: 100%;
          padding-bottom: 126px;
          p {
            text-align: left;
          }
          p.a_title {
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 50px;
            padding: 50px 0;
          }
          p.a_info {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 38px;
          }
          ul {
            padding: 100px 0;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            text-align: center;
            li {
              width: 310px;

              img {
                width: 100%;
              }
            }
          }
          button {
            color: #333333;
            width: 208px;
            height: 64px;
            border: 1px solid #d6d6d6;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            background: transparent;
          }
          button:hover {
            background: #ca1425;
            border: 1px solid #ca1425;
            color: #ffffff;
          }
        }
        .promotion {
          width: 100%;
          p {
            text-align: left;
          }
          p.p_title {
            font-size: 36px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 50px;
            padding: 50px 0;
          }
          p.p_info {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 38px;
            width: 60%;
          }
          ul {
            padding: 80px 0;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li {
              width: 310px;
              padding-top: 70px;
              p.name {
                color: #333333;
                font-size: 20px;
                line-height: 28px;
                text-align: center;
                font-weight: 500;
                padding-top: 30px;
              }
              p.title {
                text-align: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #444444;
                line-height: 20px;
                padding: 23px 0;
              }
              p.info {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 26px;
              }
              img {
                width: 103px;
              }
            }
          }
        }
      }
    }
    .c_banner_3_box {
      width: 100%;
      min-height: 588px;
      background: #000000;
      display: flex;
      justify-content: space-around;

      .c_b_3_cont {
        width: 1000px;
        position: relative;
        display: flex;
        justify-content: flex-start;
        .c_b_3_cont_info {
          padding: 80px 0;
          width: 50%;
          text-align: left;
          p.title {
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 50px;
            padding: 50px 0;
          }
          p.info {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 38px;
            padding-bottom: 86px;
          }
          button {
            color: #ffffff;
            width: 208px;
            height: 64px;
            border: 1px solid #ca1425;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            background: transparent;
          }
          button:hover {
            background: #ca1425;
            border: 1px solid #ca1425;
            color: #ffffff;
          }
        }
        img {
          position: absolute;
          right: 0;
          top: 0;
          width: 78%;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .community {
    width: 100%;
    min-height: 100vh;
    .c_banner_1_box {
      width: 100%;
      min-height: 33vw;
      background: #000000;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-content: flex-end;
      margin-top: 60px;
      .c_b_1_con {
        width: 88%;
        display: flex;
        justify-content: space-around;
        align-content: flex-end;
        align-items: center;
        text-align: center;
        flex-wrap: wrap;
        .info {
          width: 100%;
          p.title {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 36px;
            padding-bottom: 25px;
            padding-top: 60px;
          }
          p {
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 100;
            color: #ffffff;
            line-height: 28px;
          }
        }
        img {
          width: 100%;
          display: inline;
          flex: 1;
        }
      }
    }
    .c_banner_2_box {
      width: 100%;
      min-height: 36vw;
      background: #ffffff;
      display: flex;
      justify-content: space-around;
      padding: 65px 0;
      .c_b_2_cont {
        width: 88%;
        .activities {
          width: 100%;
          padding-bottom: 63px;
          p {
            text-align: left;
          }
          p.a_title {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 36px;
            padding: 25px 0;
          }
          p.a_info {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 28px;
          }
          ul {
            padding: 50px 0;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            text-align: center;
            li {
              width: 380px;
              margin-top: 30px;
              position: relative;
              p {
                width: 100%;
                height: 100%;
                position: absolute;
                background: rgba(0, 0, 0, 0.74);
                left: 0;
                top: 0;
                display: flex;
                justify-content: flex-start;
                align-content: flex-end;
                flex-wrap: wrap;

                span {
                  display: inline-block;
                  width: 100%;
                  color: #ffffff;
                  font-size: 14px;
                  padding: 10px;
                }
                span.time {
                  color: #b1b1b1;
                  font-size: 12px;
                }
              }
              a {
                display: inline-block;
                img {
                  width: 100%;
                }
              }
            }
          }
          button {
            color: #333333;
            width: 120px;
            height: 40px;
            border: 1px solid #d6d6d6;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            background: transparent;
          }
          button:hover {
            background: #ca1425;
            border: 1px solid #ca1425;
            color: #ffffff;
          }
        }
        .promotion {
          width: 100%;
          p {
            text-align: left;
          }
          p.p_title {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 36px;
            padding: 25px 0;
          }
          p.p_info {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 28px;
            width: 100%;
          }
          ul {
            padding: 50px 0;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li {
              width: 350px;
              padding-top: 90px;
              p.name {
                color: #333333;
                font-size: 20px;
                line-height: 28px;
                text-align: center;
                font-weight: 500;
                padding-top: 30px;
              }
              p.title {
                text-align: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #444444;
                line-height: 20px;
                padding: 23px 0;
              }
              p.info {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 26px;
              }
              img {
                width: 103px;
              }
            }
          }
        }
      }
    }
    .c_banner_3_box {
      width: 100%;
      min-height: 357px;
      background: #000000;
      display: flex;
      justify-content: space-around;

      .c_b_3_cont {
        width: 88%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        .c_b_3_cont_info {
          padding: 45px 0 150px 0;
          width: 100%;
          text-align: left;
          z-index: 99;
          p.title {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 36px;
            padding: 25px 0;
          }
          p.info {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 28px;
            padding-bottom: 43px;
          }
          button {
            color: #ffffff;
            width: 120px;
            height: 40px;
            border: 1px solid #ca1425;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            background: #666666;
            color: #ffffff;
            border: 1px solid #454545;
          }
          button:hover {
            // background: #ca1425;
            // border: 1px solid #ca1425;
            color: #ffffff;
          }
        }
        img {
          position: absolute;
          right: 0;
          top: 235px;
          width: 100%;
          z-index: 1;
        }
      }
    }
  }
}
</style>