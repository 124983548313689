export default {
  Nav: {
    home: 'Home',
    wallet: 'Wallet',
    developer: 'Developer',
    de_ceshi: 'Testnet',
    de_wendang: 'Developer documentation',
    de_shenji: 'Audit report',
    GitHub: 'GitHub',
    explorer: 'Explorer',
    roadmap: 'Roadmap',
    Community: 'Community',
    Whitepaper: 'Whitepaper',
    Destruction: 'Destruction',
    ChineseWhitepaper: 'ChineseWhitepaper',
    EnglishWhitepaper: 'EnglishWhitepaper',
    JapaneseWhitepaper: 'JapaneseWhitepaper',
    Language: 'Language',
    Chinese: 'Chinese',
    English: 'English',
    about: "About",
    xiangmudongtai: "Project News",
    token: "Token"
  },

  Home: {
    banner1: {
      info1: 'THE WORLD\'S FIRST COMMERCIAL SCALABLE BLOCKCHAIN INFRASTRUCTURE BASED ON ',
      info2: 'IPv8 ',
      info3: 'TECHNOLOGY',
      more: 'Leam More'
    },
    banner2: {
      list: [
        {
          title: 'Safe and Friendly',
          imgsrc: require("../assets/img/home_banner_1_list_1.png"),
          infos: [
            "Support Multiple Programming Languages",
            " Support External Smart Contract",
            " Complete Functions & Tools",
            "Support IPFS",
          ]
        },
        {
          title: 'Innovation',
          imgsrc: require("../assets/img/home_banner_1_list_2.png"),
          infos: [
            " Based On IPv8",
            " Peer to Peer",
            " Improve Data Accessibility",
            " Highly Secure",
          ]
        },
        {
          title: 'Excellent Performance',
          imgsrc: require("../assets/img/home_banner_1_list_3.png"),
          infos: [
            "aBFT Consensus Algorithm",
            " Horizontal Sharding",
            " Longitudinal Side Chain",
            "Theory TPS 100000+",
          ]
        },
      ]
    },
    banner3: {
      what_list_1: {
        title1: 'What is ',
        title2: 'BitCherry?',
        info: 'BitCherry is a universal block chain infrastructure that meets commercial applications, provides a high-performance, high-secure, and high-available underlying public chain support for distributed commerce on the chain.'
      },
      video_box: 'https://www.bitcherry.io/video/videoen.mp4',
      what_list_2: {
        bigtitle: 'BLOCKCHAIN PLUS',
        bigtitle1: '',
        title1: 'Distributed Commerce',
        info: 'Theory, the technical team of the BitCherry has years of experience in the internet industry. BlockChain Plus was proposed for the first time, aiming to improve the current public chain\'s poor scalability, insufficient performance, unreasonable governance structure, and low security through executable technical means.'
      },

      what_list_3: {

        title1: 'Data Security',
        info: 'BitCherry has built P2Plus protocol with thinking of innovating the physical layer. The protocol work is put in lower IP layer and the data link layer, and it will significantly improve data transmission speed and accessibility. The point-to-point private key encryption technology used by P2Plus guarantees node privacy and data security more effectively.'
      },
      what_list_4: {

        title1: 'High Performance Efficient',
        info: 'BitCherry\'s whole node operation is attached to the HashGraph, which ensures decentralization and saves heaty workload proof. Based on the aBFT algorithm and replying on the "side chain+sharding" expansion design, it also solves the block congestion problem, so that the resource occupation of a single application will not affect the other performance on chains.'
      },
      what_list_5: {

        title1: 'Consensus Governance',
        info: 'In terms of gonvernance structure, BitCherry\'s structure is more open to users. It first proposes PoUc for user value proof, refers to the user virtual voting weights in terms of consensus, selects the node to abtain accounting rewards, and enables pass permit holders to participate in the project development and chain governance.'
      },

    },
    home_banner_3: {
      bigtitle: "BCHC Market Performance",
      lists: [
        {
          title: 'Market Value',
          val: "$12.11B"
        },
        {
          title: '24 Hours Volume',
          val: "$100M"
        },
        {
          title: 'Holders',
          val: "50,0000"
        },
      ],
      more_btn: 'Learn More'
    },
    home_banner_4: {
      bigtitle: "BitCherry News",
      more_btn: 'Learn More'
    }
  },
  Footer: {
    left_box: {
      list1: {
        title: "Contact Us",
        info1: 'Business Contact:  business@BitCherry.io',
        info2: 'Customer Service:  contact@BitCherry.io',
      },
      list2: {
        title: "Download",
        info1: 'BitCherry Whitepaper（Chinese）',
        info2: 'BitCherry Whitepaper（English）',
        info3: 'BitCherry Whitepaper（Japanese）',
      },
    },
    right_box: {
      title: 'Official Social Media'
    }

  },
  Wallet: {
    banner_top: {
      title: "CherryWallet",
      info: "CherryWallet is a powerful ecological wallet of BitCherry, including CherryWallet Offline Wallet, CherryWallet APP and CherryWallet Public Chain Wallet."
    },
    b_list_box_1: {
      info: "The online and offline separation is more secure, with CherryWallet Offline Wallet broadcast trading and consulting balance, comprehensive support of high-quality assets, BTC, ETH, BCHC, USDT, EOS, etc. and to grasp the dynamic market, real-time update, the latest industry information tracking report      Excellent application, excellent selection, a necessary entrance to the blockchain world"
    },
    b_list_box_2: {
      imgsrc: require('../assets/img/wallet/7EN.jpg'),
      imgsrcm: require('../assets/img/wallet/s7EN.jpg'),
    },
    b_list_box_3: {
      title: 'Completely isolated network',
      infos: [
        'Disconnected to the Internet, completely eradicating hackers intrusion',
        'Cold end based trades and signatures',
        'Hot end broadcasting deals'
      ]
    },
    b_list_box_4: {
      title: 'Seed Password+Payment Password',
      infos: [
        'Dual password security management, eradicating asset theft and fraud',
        'Encrypted storage, never touching the network',
        'Security verification, deleted after use '
      ]
    },
    RecommendedWallet: 'Recommended Wallet'
  },
  Developer: {
    banner_top: {
      title: 'Developer',
      info: "BitCherry’s blockchain service layer design makes it easy for developers to quickly create modules according to market demand. It also providing smooth, low-cost, and time-saving solutions for developers who lack blockchain experience.",
      btn_info: "Enter Developer Site"
    },
    banner_2: {
      title: "Developer Community",
      info: "The BitCherry technology community is as important as BitCherry’s technology core team. Community members have a deep understanding of the BitCherry technology architecture and products. Community members enjoy the flexibility and creativity of autonomy and full community support. Let’s see if you can join our development team.",
      btn_info: 'Join the Community',
    },
    banner_3: {
      title: "Developer Incentives / Events",
      info: "Rewards are a way to motivate people to participate in fixing software defects or realizing small functions. Reward driven development is a feature of the business model based on open source software system. Gain revenue by discovering bugs, adding features, and building BitCherry subsidy resources",
      btn_info: 'Join the Community',
    },
    banner_4: {
      title: "BitCherry Testnet",
      info: "BitCherry testnet is built by the core team and supported by the technical community. The test network completely simulates the operation of the BitCherry mainnet. Users can experience the functions of various products of BitCherry. This makes it easy to test new features.",
      btn_info: 'Start Testing',
    },
  },
  //发展路线
  Roadmap: {
    banner_top: {
      title: 'Roadmap',
      info: ' As an emerging, self-updating open-source platform, BitCherry to promoting innovation and eliminating bottlenecks continues to shape the future of this industry.Since BitCherry\'s creation, we have developed a flexible and scalable way to create security, optimization, new industry standards for interoperability, and scalability.'
    },
    list_1: {
      time: "2020 Q4",
      info: "Testnet Online"
    },
    list_2: {
      time: "2020 Q4",
      info: "Developer Community V1 Online"
    },
    list_3: {
      time: "2020 Q4",
      info: "Blockchain Explorer Beta Online"
    },
    list_4: {
      time: "2021 Q1",
      info: "Main net V1 Online"
    },
    list_5: {
      time: "2021 Q1",
      info: "Blockchain Explorer Online"
    },
    list_6: {
      time: "2021 Q1",
      info: " Explorer Wallet Plug-in Online"
    },
    list_7: {
      time: "2021 Q2",
      info: "Mobile End Wallet online"
    },
    list_8: {
      time: "2021 Q2",
      info: "DApp Ecosystem Lauch"
    },
    list_9: {
      time: "2021 Q3",
      info: "Developer Community V2 Online"
    },
    list_10: {
      time: "2021 Q3",
      info: "DEX Protocol Online "
    },
    list_11: {
      time: "2021 Q4",
      info: "Main net V2 Online"
    },
    list_12: {
      time: "2021 Q4",
      info: "DeFi Lending/Financing Protocol Online"
    },
    list_13: {
      time: "2022 Q1",
      info: "DeFi Insurance Protocol Online"
    },
    list_14: {
      time: "2022 Q3",
      info: "Cross Chain Online"
    },
    list_15: {
      time: "2022 Q3",
      info: "Sharding Online"
    },
    list_16: {
      time: "2022 Q4",
      info: "DeFi Ecosystem Reach to 100+"
    },
    list_17: {
      time: "2023 Q2",
      info: "Distributed Business Ecosystem Online"
    },
  },
  Community: {
    banner_top: {
      title: 'BitCherry Community',
      info: ' BitCherry is an open, community-driven open source by the blockchain project. In the future will be based on security and stability to the center of blockchain technology, and BitCherry Foundation will ensure coordination with development partners and the ecosystem'
    },
    banner_2: {
      title: "Community Event",
      info: "BitCherry will organize community activities, including in the form of festivals, airdrop, and other activities to enhance community cohesion.",
      btn_info: 'See More',

    },
    banner_3: {
      title: "BitCherry Ambassador",
      info: "BitCherry's Country Ambassador will focus on raising awareness of the corresponding region to enhance the global ecosystem and network effects of BitCherry. In every quarter, Ambassador is elect by the BitCherry community and core team",
      pleLists: [
        {
          img: require('../assets/img/community/peple/p1.png'),
          title: 'George Deeb',
          ad: 'BitCherry Middle East Partner',
          info: 'Founder of satoshiat.com middle east marketing company',
        },
        {
          img: require('../assets/img/community/peple/p2.png'),
          title: 'Long Miguel',
          ad: 'BitCherry Southeast Asia-Vietnam Partner',
          info: 'Cryptocurrency Marketing Development  Market Research and Analysis',
        },
        {
          img: require('../assets/img/community/peple/p3.png'),
          title: 'Reno Rafael Siregar',
          ad: 'BitCherry Southeast Asia-Indonesia Partner',
          info: 'Indonesia Community Manage Expert',
        },
        {
          img: require('../assets/img/community/peple/p4.png'),
          title: 'Kripto Sozluk',
          ad: 'BitCherry Europe-Turkey Partner',
          info: 'Well-known YouTube channel Influencer',
        },
        {
          img: require('../assets/img/community/peple/p5.png'),
          title: 'Jean Stiven',
          ad: 'BitCherry France Partner',
          info: 'Blockchain Enthusiast; Marketing/Community Manager;',
        },
        {
          img: require('../assets/img/community/peple/p6.png'),
          title: 'Bodrul Alam',
          ad: 'BitCherry North America Partner',
          info: 'Star community operation manager；Industry Expert',
        },
      ]
    },
    banner_4: {
      title: "BitCherry Vote",
      info: "BitCherry's Country Ambassador will focus on raising awareness of the corresponding region to enhance the global ecosystem and network effects of BitCherry. In every quarter, Ambassador is elect by the BitCherry community and core team",
      btn_info: 'Stay tuned.'
    },

  },
  Destruction: {
    banner_top: {
      title: 'Destruction Announcement',
      info: 'BitCherry is committed to building a distributed network based on blockchain technology to improve the efficiency of the industry, expand the scale of market transactions, and effectively solve the industry\'s pain points. It will continue to take such measures as token destruction to make all supporters of BitCherry enjoy the value improvement brought by BCHC\'s total flow deflation.      We will continue to increase BCHC application scenarios and welfare feedback activities and thank you for your support and encouragement with actions.',
      btn_info: 'Latest Destruction Plan'
    },
    banner_2: {
      title: "Latest Destruction Announcement",
      btn_info: 'Destruction Address',
      list_1: [
        {
          title: 'Destruction Projects',
          info: "BTOK Mining Activities"
        },
        {
          title: 'Destruction Cycle',
          info: "2020/06/06至2020/06/06"
        },
        {
          title: 'Destruction Quantity',
          info: "1000万个"
        },
        {
          title: 'History Destruction Quantity',
          info: "10000万个"
        },
      ],
      title_2: "Circulation Information Disclosure",
      list_2: [
        {
          title: 'Total in stock',
          info: "BTOK Mining Activities"
        },
        {
          title: 'Current Circulation',
          info: "2020/06/06至2020/06/06"
        },
      ],
      title_3: "Destruction Data Details",
      list_title: [
        "Destruction Projects",
        "Destruction Cycle",
        "Destruction Quantity",
        "Destruction Address"
      ],
      list_3: [
        {
          name: "BTOK Mining Activities",
          time: "2020/01/01",
          num: '23231.232322',
          address: "rerqerqwerwerwe",
          btn: 'see'
        },
        {
          name: "BTOK Mining Activities",
          time: "2020/01/01",
          num: '23231.232322',
          address: "rerqerqwerwerwe",
          btn: 'see'
        },
        {
          name: "BTOK Mining Activities",
          time: "2020/01/01",
          num: '23231.232322',
          address: "rerqerqwerwerwe"
        },
        {
          name: "BTOK Mining Activities",
          time: "2020/01/01",
          num: '23231.232322',
          address: "rerqerqwerwerwe"
        },
        {
          name: "BTOK Mining Activities",
          time: "2020/01/01",
          num: '23231.232322',
          address: "rerqerqwerwerwe"
        },
        {
          name: "BTOK矿机活动",
          time: "2020/01/01",
          num: '23231.232322',
          address: "rerqerqwerwerwe"
        },
      ],
      see_more: "View more"

    }
  },
  What: {
    banner_top: {
      title: "What is BCHC",
      info: "Supporting BitCherry eco-system, BCHC has ensured the decentralized of BitCherry, making participation within this ecology freely and fairly. In addition, BCHC is used to pay for various products and services on our platform."
    },
    banner_2: {
      title: 'BCHC usage scenarios',
      info: 'BitCherry to prod traditional enterprise technology resources, human resources, business resources, and a variety of innovative resources to integrate, and include product traceability, supply chain finance, and commercial consumption, digital assets, finance, e-commerce, cloud computing, and other technology-related blockchain coding and underlying architecture.',
      list_info_1: 'Product traceability',
      list_info_2: 'Commercial consumption',
      list_info_3: 'E-commerce',
      list_info_4: 'Supply Chain Finance',
      list_info_5: 'Asset Digitization',
      list_info_6: 'Distributed Computing',
    },
    banner_3: {
      title: 'Exchange'
    },
    banner_4: {
      title: 'Asset Wallet'
    },
    banner_5: {
      title: 'Price Tracking Website'
    }
  },
  Media: {
    banner: {
      title: 'Media Reports',
      infos: [
      ]
    },
    mediaList: [
      {
        img: require('../assets/img/media1/0609002.png'),
        title: "The Outbreak of BitCherry Distributed Eco Booming, Leading the Global Public Blockchain Competition",
        time: '2021.04.07',
        links: 'http://www.chainspeaker.com/the-outbreak-of-bitcherry-distributed-eco-booming-leading-the-global-public-blockchain-competition/',
        infos: [
          {
            info: 'BitCherry, the world’s first scalable blockchain infrastructure based on IPv8 technology to serve distributed commerce. BitCherry, as a distributed commercial public blockchain project, is committed to building an IPv8 blockchain infrastructure that can serve all types of businesses.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0609002.png'),
        title: "BitCherry Outlook: Digital World “Allspark” to create diverse, sustainable global ecosystem",
        time: '2021.04.07',
        links: 'http://www.cryptodoughtoday.com/bitcherry-outlook-digital-world-allspark-to-create-diverse-sustainable-global-ecosystem/',
        infos: [
          {
            info: 'After the BitCherry test network formally launched version v1.0.1 and v2.0.1 BitCherry test network on-line version of the official update, BitCherry distributed ecological fund was established, and invested 10 million US dollars for the development of environmental chain applications, competition, incubation, and investment.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/201211001.png'),
        title: "PBWS | BitCherry founder Paul: innovative Blockchain finance industry will reform the global financial system",
        time: '2019.06.12',
        links: 'https://medium.com/bitcherryglobal/pbws-bitcherry-founder-paul-innovative-blockchain-finance-industry-will-reform-the-global-c6a54e5bcc58',
        infos: [
          {
            info: '10th December 2020, BitCherry was invited to attend PBWS 2020 as a sponsor. As the best European blockchain conference, Paris Blockchain Week Summit brought together 1,70+ entrepreneurs, investors, and media worldwide to talk about blockchain and cryptocurrency to make France the first G20 country to create a business-friendly environment for our rapidly growing ecosystem. ',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0609002.png'),
        title: "From Technological Innovation to Large-Scale Applications, BitCherry’s Development Has Entered a Mature Stage",
        time: '2019.06.12',
        links: 'https://www.newsbtc.com/press-releases/from-technological-innovation-to-large-scale-applications-bitcherrys-development-has-entered-a-mature-stage/',
        infos: [
          {
            info: 'In respect of market development, BitCherry global community has covered up to more than 1 million users in a year with BCHC turnover exceeded 10 billion, creating 300 times increase miracle. Also, BitCherry successfully held more than 100 community events that drew a total of nearly 200 community partners to join and it has participated in over 10 international conferences.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0519002.png'),
        title: "BitCherry Introducing Distributed Business Model on Consensus: Distributed",
        time: '2019.06.12',
        links: 'https://zycrypto.com/bitcherry-introducing-distributed-business-model-on-consensus-distributed/',
        infos: [
          {
            info: 'The Consensus: Distributed 2020 has come to a successful end. In this year’s thought feast of global crypto scholars, blockchain practitioners, and digital economists, blockchain, and cryptocurrency these two hotspots yield unusually brilliant results.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0505004.png'),
        title: "From E-Commerce to Distributed Business– Understanding on How to Create a New Eco-Business After the Upgrade of BitCherry Technology",
        time: '2019.06.12',
        links: 'https://nulltx.com/from-e-commerce-to-distributed-business-understanding-on-how-to-create-a-new-eco-business-after-the-upgrade-of-bitcherry-technology/',
        infos: [
          {
            info: 'As we all know, the public chain is a blockchain that provides services to the public. The commercial value of the public chain is to provide infrastructure and commercial application support for various commercial fields……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0505003.png'),
        title: "From E-Commerce to Distributed Business– Understanding on How to Create a New Eco-Business After the Upgrade of BitCherry Technology",
        time: '2019.06.12',
        links: 'https://www.newsbtc.com/press-releases/from-e-commerce-to-distributed-business-understanding-on-how-to-create-a-new-eco-business-after-the-upgrade-of-bitcherry-technology/',
        infos: [
          {
            info: 'On May 5th 2020, distributed business blockchain project BitCherry will have 1st anniversary celebration, and on the same day will release a new version of its White Paper named by “BitCherry-the world’s first commercial scalable blockchain infrastructure based on IPV8 technology White Paper v2.0.1”, it is another major technology update made by BitCherry in the field of electronic business distribution.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0304001.png'),
        title: "What is the value of BlockChain Plus first proposed by BitCherry?",
        time: '2019.06.12',
        links: 'https://www.siliconvalleynews.net/news/264121531/what-is-the-value-of-blockchain-plus-first-proposed-by-bitcherry',
        infos: [
          {
            info: 'BitCherry wants to provide a safe, efficient, independent design philosophy to seize the current business environment, layout precisely to become public focus of business field. ',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0226002.png'),
        title: "BitCherry sets off a new wave of enabling distributed businesses with the blockchain technology",
        time: '2019.06.12',
        links: 'https://apnews.com/50beeaf997af302a3022e5f41fbf412b',
        infos: [
          {
            info: 'P2P protocols conventionally are built over TCP transport layer on IPv4 /IPv6 WAN, and BitCherry’s ‘P2Plus’ Encryption Protocol based on IPv8 underlying  encryption protocols are built on more underlying IP layer and within data link layer……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0226001.png'),
        title: "BitCherry - Build a Trusted Distributed Business Ecosystem",
        time: '2019.06.12',
        links: 'https://finance.yahoo.com/news/bitcherry-sets-off-wave-enabling-184500554.html?guccounter=1',
        infos: [
          {
            info: 'As The world\'s first commercial scalable blockchain infrastructure based on IPv8 technology.BitCherry focuses on comprehensive and in-depth development in the industry. With strong ecological resources, BitCherry pays great attentions to business needs.……',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0220001.png'),
        title: "The good news of distributed commerce is here! BitCherry bring BCHC to create a trusted distributed business environment",
        time: '2019.06.12',
        links: 'https://3g.163.com/money/article/F56QQE5V04249CUG.html',
        infos: [
          {
            info: 'The second of the most comprehensive Blockchain and Cryptocurrency conferences in MENA and Eurasia will take place for two days in Istanbul on February 20-21, 2020 with the best international names in the sector.  The world’s first distributed e-commerce network BitCherry as the main sponsors of the conference, Mr. Paul, Founder & CEO of BitCherry as a special guest to attend the summit.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0212002.png'),
        title: "Stay be with BitCherry together, this is 2020’s most influential Blockchain summit",
        time: '2019.06.12',
        links: 'https://www.btchaber.com/blockchain-economy-2020-icin-sayili-gun-kaldi/',
        infos: [
          {
            info: 'The theme of this summit will include Blockchain technology, artificial intelligence, big data, decentralized technology, the Internet by itself and many other fields. It is the most influential Blockchain summit in the Middle East, North Africa and Eurasia',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0212001.png'),
        title: "BitCherry is going to be a sponsor! The most influential Blockchain summit in Eurasia is about to be held",
        time: '2019.06.12',
        links: 'https://www.marketwatch.com/press-release/blockchain-economy-conference-is-about-to-begin-2020-02-07',
        infos: [
          {
            info: 'BitCherry is the primary sponsor! The second Blockchain and Digital Currency Summit, Turkey Economy Summit 2020 will be held in Istanbul (Turkey), from February 20th to 21st, 2020.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0204001.png'),
        title: "Forthcoming conference. BitCherry will attend Blockchain Economy Istanbul Summit on February. ",
        time: '2019.06.12',
        links: 'https://uzmancoin.com/bitcherry-blockchain-economy/',
        infos: [
          {
            info: 'The second of the most comprehensive Blockchain and cryptocurrency conference in MENA and Eurasia will take place during two days in Istanbul on February 20-21, 2020 with the best international names in the sector.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0115002.png'),
        title: "Tokenization of BitCherry-BCHC’s status quo & future",
        time: '2019.06.12',
        links: 'http://3g.163.com/ntes/20/0106/11/F2733NBF04138JTG.html',
        infos: [
          {
            info: 'As a new concept, BCHC has been received unprecedented attention in 2019 last year. From the technical point of view, IT team of BitCherry dig in in application and dissemination, and its scale and influence have been expanding.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0115001.png'),
        title: "The Value Carrier of BitCherry BCHC-strategic layout in South Africa",
        time: '2019.06.12',
        links: 'https://www.sohu.com/a/364980597_675570',
        infos: [
          {
            info: '2019, BitCherry distributed public chain has officially start global marketing strategy, Singapore, Malta and Jakarta, etc. In October 2019 practical application of BitCherry block chain technology has been realized in The Democratic Republic of the Congo, South Africa.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0108001.jpg'),
        title: "Global Recruitment of BitCherry Community Ambassadors",
        time: '2019.06.12',
        links: 'https://nulltx.com/global-recruitment-of-bitcherry-community-ambassadors/',
        infos: [
          {
            info: 'BitCherry has started a strategic layout in the United States, Russia, South Korea, Vietnam, Singapore, the Philippines, Turkey and many other countries. In order to enable more community users with our consensus can participate in BitCherry’s path to constructing together and share the value of development, we are now recruiting community ambassadors in communities around the world.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1225002.png'),
        title: "\"Blockchain 4.0\" proposed by BitCherry BCHC",
        time: '2019.06.12',
        links: 'http://mini.eastday.com/a/191201172525347.html',
        infos: [
          {
            info: 'BitCherry is the first distributed e-commerce platform in the world. Based on the blockchain technology, it is committed to create a super ecosystem  which will be able to cover all aspects of people’s need like food, clothing, housing and transportation.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1225001.png'),
        title: "Foreign Minister Wang Yi: \"17 + 1 cooperation\" will promote better integration China's development strategies with CEEC",
        time: '2019.06.12',
        links: 'https://www.jianshu.com/p/f14d994b0e1d',
        infos: [
          {
            info: 'Paul, founder and CEO of BitCherry and other blockchain industry elites were invited to attend in the meeting. During the meeting, BitCherry,  China’s CEE blockchain Excellence Center and the distributed business public chain project, signed the memorandum of “Cooperating on China’s CEE blockchain Excellence Center”. Both sides discussed how to apply blockchain technology to energy, finance, aerospace, manufacturing, logistics, commerce, etc industries and how to  promote a better international cooperation between China and Eastern European countries.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1218002.png'),
        title: "BitCherry Founder: Empower Traditional Business with Blockchain Can Make Difference",
        time: '2019.06.12',
        links: 'https://www.coinspeaker.com/BitCherry-founder-business-blockchain',
        infos: [
          {
            info: 'Blockchain technology is now the first choice for many large enterprises around the world. Although the traditional centralized data set has been continuously innovated over the years to achieve data warehouse and load balancing, while blockchain is still in its infancy and is not yet ready for the high speed of data processing we have got used to, the future trend is still in favor of distributed data storage.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1211001.png'),
        title: "Do you know the core advantages of BCHC distributed database storage?",
        time: '2019.06.12',
        links: 'http://zhtieba.cn/forum.php?mod=viewthread&tid=259292&extra=',
        infos: [
          {
            info: 'Deployed international marketing as distributed storage services network BitCherry have build distributed service system relying on its own block chain technology, and creating a new era of business sector also a new perfect business model in distributed storage services area.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1204002.png'),
        title: "BCHC is suitable for a variety of business scenarios and starts a new business format if distributed business",
        time: '2019.06.12',
        links: 'http://www.beijingleather.com.cn/cj/cj110532215.html',
        infos: [
          {
            info: 'The development of blockchain can not be separated with scientific and technological innovation, BitCherry keeps consistently step to explore mysteries of blockchain industry, expand in the process of app technology fission.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1204001.png'),
        title: "How will e-commerce decide in the future from \"pick one of two\" -The rise of BCHC distributed e-commerce",
        time: '2019.06.12',
        links: 'http://www.pashu.org/hq/2019/1126/hq111876001.html',
        infos: [
          {
            info: 'BitCherry system will be  build by decentralized  distributed commercial organizations, with self-propagation community characteristics, to ensure to realize border-free e-commerce network ecology.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1127004.png'),
        title: "Value of BCHC empower distributed e-commerce new life ",
        time: '2019.06.12',
        links: 'http://www.3news.cn/pindao/2019/1115/1004376898.html',
        infos: [
          {
            info: 'In order to better understand the transition between the fourth retail e-commerce revolution and the fifth retail blockchain revolution, we need to clearly distinguish as distributed e-commerce value carrier whether BCHC can bring new vitality.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1127003.png'),
        title: "BCHC arouse discussion and leads great reform of data storage security",
        time: '2019.06.12',
        links: 'https://www.sohu.com/a/354719262_120161927',
        infos: [
          {
            info: 'Since the advent of blockchain applications, it has brought great changes to the new era of the digital economy, and economic development of distributed system has gradually formed a new trend that has attracted much attention from community. As BitCherry’s Distributed Systems E-commerce network value carrier BCHC will have public recognition in data innovation security and direction.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1127002.png'),
        title: "Technology Leads us to the Future, BCHC Gives New Value to Distributed Business",
        time: '2019.06.12',
        links: 'http://finance.jxyuging.com/finance/2019/1120/56690.html',
        infos: [
          {
            info: 'BCHC is a kind of token of BitCherry platform issued by blockchain underlying technology which provides asset liquidity for users of the platform and is widely used as an internal payment means of the platform ecology.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1127001.png'),
        title: "How BCHC's distributed philosophy leads global economic change",
        time: '2019.06.12',
        links: 'http://www.jpm.cn/article-88934-1.html',
        infos: [
          {
            info: 'Based on global requirements, BitCherry distributed business network will build distributed business infrastructure, empower from  business, product, technology three areas to improve the economic and ecological distributed system.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1120004.jpg'),
        title: "Decrypt the correlation between BCHC and Internet information security technology",
        time: '2019.06.12',
        links: 'http://www.3news.cn/pindao/2019/1115/1004376898.html',
        infos: [
          {
            info: 'The distributed system and consensus computing method establish a secure big data architecture for the BitCherry public blockchain. In this foundation, it is also necessary to ensure the security of various applications, and the blockchain smart contract module shoulders this mission.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1120003.jpg'),
        title: "BCHC value presentation held at the Singapore Fintech Festival",
        time: '2019.06.12',
        links: 'http://www.hidongbei.com/v-1-605754.aspx',
        infos: [
          {
            info: 'As the world\'s first distributed business project, BitCherry team was invited to participate in "Singapore fintech Festival"',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1120002.jpg'),
        title: "Decrypt the correlation between BCHC and Internet information security technology",
        time: '2019.06.12',
        links: 'http://use.17car.com.cn/use/2019/1115/30631.html',
        infos: [
          {
            info: 'The distributed system and consensus computing method establish a secure big data architecture for the BitCherry public blockchain. In this foundation, it is also necessary to ensure the security of various applications, and the blockchain smart contract module shoulders this mission.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1120001.jpg'),
        title: "BCHC value presentation held at the Singapore Fintech Festival",
        time: '2019.06.12',
        links: 'http://www.wanjiquan.com/zixun/201911/5452.html',
        infos: [
          {
            info: 'As the world\'s first distributed business project, BitCherry team was invited to participate in "Singapore fintech Festival".',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1113002.jpg'),
        title: "BitCherry landing in Malta, set off a paradigm revolution of distributed business",
        time: '2019.06.12',
        links: 'http://m.cnbzol.com/www_news/20191107/1002185789.html',
        infos: [
          {
            info: 'The "AIBC summit Malta" blockchain summit hosted by BitCherry, the world\'s first distributed business project, was launched in Malta. This is another heavyweight conference focusing on distributed business and blockchain Technology launched by BitCherry after the popular opening station "city empowerment - San Francisco station".',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1113001.jpg'),
        title: "BitCherry landing in Malta, set off a paradigm revolution of distributed business",
        time: '2019.06.12',
        links: 'http://wap.yesky.com/446/638006446.shtml',
        infos: [
          {
            info: 'The "AIBC summit Malta" blockchain summit hosted by BitCherry, the world\'s first distributed business project, was launched in Malta. This is another heavyweight conference focusing on distributed business and blockchain Technology launched by BitCherry after the popular opening station "city empowerment - San Francisco station".',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1030004.jpg'),
        title: "BitCherry – The Road of Building a Distributed Commerce City",
        time: '2019.06.12',
        links: 'https://www.coinspeaker.com/BitCherry-the-road-of-building-a-distributed-commerce-city/',
        infos: [
          {
            info: 'On 28th October 2019, the project of distributed e-commerce public chain will be unfolding through the global activity on “Urban Empowerment – Creating a City of Distributed Commerce”. This is one of the major activities that BitCherry has been a part of since the signing of strategic cooperation agreement with the National Youth of the Congo (DRC).',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1030003.jpg'),
        title: "Defi, technology, business, understand the global road of BitCherry from global activities",
        time: '2019.06.12',
        links: 'https://shandong.hexun.com/2019-10-22/198951718.html',
        infos: [
          {
            info: 'BitCherry, the world\'s first distributed network serving the business field, intends to bring cutting-edge distributed technology concepts and advanced distributed business models to many countries and cities through the global activity entitled "enabling cities to build a distributed business city".',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1030002.jpg'),
        title: "BitCherry – The Road of Building a Distributed Commerce City",
        time: '2019.06.12',
        links: 'https://coinvestasi.com/press-releases/BitCherry-membangun-kota-perdagangan-terdistribusi/',
        infos: [
          {
            info: 'On 28th October 2019, the project of distributed e-commerce public chain will be unfolding through the global activity on “Urban Empowerment – Creating a City of Distributed Commerce”. This is one of the major activities that BitCherry has been a part of since the signing of strategic cooperation agreement with the National Youth of the Congo (DRC).',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1030001.jpg'),
        title: "Defi, technology, business, understand the global road of BitCherry from global activities",
        time: '2019.06.12',
        links: 'http://www.southmoney.com/caijing/gongsixinwen/201910/4221579.html',
        infos: [
          {
            info: 'BitCherry, the world\'s first distributed network serving the business field, intends to bring cutting-edge distributed technology concepts and advanced distributed business models to many countries and cities through the global activity entitled "enabling cities to build a distributed business city".',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1021004.jpg'),
        title: "Bringing blockchain to the third world: the layout and ambitions of distributed ecommerce BitCherry",
        time: '2019.06.12',
        links: 'http://www.lsjinfo.com/index/news/info/id/1478.html',
        infos: [
          {
            info: 'On October 9, 2019, Paul, the founder and CEO of BitCherry, Kim, the chairman of WTIA and the consultant of BitCherry, and other people exchanged views with the director of the office of the president of the Democratic Republic of Congo, the Minister of national infrastructure, the Minister of Finance and the Minister of energy and mining on "bulk e-commerce, mineral traceability, cross-border payment, resource registration, business registration" and other blockchain application fields in the Democratic Republic of Congo.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1021003.jpg'),
        title: "BitCherry Signed a Strategic Cooperation Agreement with the Democratic Republic of Congo to promote the development of economic transformationof.",
        time: '2019.06.12',
        links: 'https://www.kuam.com/story/41180491/news',
        infos: [
          {
            info: 'From 10th to 12th October 2019, BitCherry signed the “Memorandum of Understanding on Strategic Cooperation for National Infrastructure Construction and Energy Economic Transformation in Congo (DRC). In the contract, BitCherry founder and CEO Paul, BitCherry consultant and WTIA chairman Kim exchange their views on several key areas such as cross-border payments, bulk e-commerce, new energy raw materials data, new energy assets, and new energy asset transactions with the Directors of the president’s office, Minister of State Infrastructure, Minister of Finance, and Minister of Mineral Resources and Energy.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1021002.jpg'),
        title: "Bringing blockchain to the third world: the layout and ambitions of distributed ecommerce BitCherry",
        time: '2019.06.12',
        links: 'http://www.cnhan.com/html/biz/20191016/1052018.htm',
        infos: [
          {
            info: 'On October 9, 2019, Paul, the founder and CEO of BitCherry, Kim, the chairman of WTIA and the consultant of BitCherry, and other people exchanged views with the director of the office of the president of the Democratic Republic of Congo, the Minister of national infrastructure, the Minister of Finance and the Minister of energy and mining on "bulk e-commerce, mineral traceability, cross-border payment, resource registration, business registration" and other blockchain application fields in the Democratic Republic of Congo.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1021001.jpg'),
        title: "BitCherry Signed a Strategic Cooperation Agreement with the Democratic Republic of Congo to promote the development of economic transformationof.",
        time: '2019.06.12',
        links: 'http://markets.financialcontent.com/stocks/news/read/38981541',
        infos: [
          {
            info: 'From 10th to 12th October 2019, BitCherry signed the “Memorandum of Understanding on Strategic Cooperation for National Infrastructure Construction and Energy Economic Transformation in Congo (DRC). In the contract, BitCherry founder and CEO Paul, BitCherry consultant and WTIA chairman Kim exchange their views on several key areas such as cross-border payments, bulk e-commerce, new energy raw materials data, new energy assets, and new energy asset transactions with the Directors of the president’s office, Minister of State Infrastructure, Minister of Finance, and Minister of Mineral Resources and Energy.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1016004.jpg'),
        title: "The Government of the Democratic Republic of the Congo has signed a strategic cooperation agreement with BitCherry, a distributed business project, to enter the fast track of energy transformation",
        time: '2019.06.12',
        links: 'http://www.zmke.com/post/201910/10257.html',
        infos: [
          {
            info: ' From October 10 to 12, 2019, BitCherry, the world\'s first distributed network system based on blockchain technology serving e-commerce, reached a deep consensus with the Minister of Youth Development of the Congo (DRC), and signed the Memorandum of Understanding on Cooperation in National Infrastructure Construction and Energy Economization Transition Strategy of the Democratic Republic of the Congo.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1016003.jpg'),
        title: "Cumlaude Crypto 2019 sparks blockchain enthusiasm among Indonesian students",
        time: '2019.06.12',
        links: 'https://www.beritasatu.com/digital/577590/kalangan-mahasiswa-sambut-antusias-cumlaude-crypto-2019https://jpnn.com/news/cumlaude-crypto-edukasi-teknologi-blockchain-dan-aset-kripto-untuk-mahasiswa',
        infos: [
          {
            info: 'The jakarta-cumlaude cryptocurrency roadshow is a series of events designed to introduce Indonesian students to blockchain technology and cryptographic assets.On top of the event, BitCherry introduced the theme of "the application of blockchain technology in the e-commerce industry".',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1016002.jpg'),
        title: "The Government of the Democratic Republic of the Congo has signed a strategic cooperation agreement with BitCherry, a distributed business project, to enter the fast track of energy transformation",
        time: '2019.06.12',
        links: 'http://www.chinaweekly.cn/24757.htm',
        infos: [
          {
            info: ' From October 10 to 12, 2019, BitCherry, the world\'s first distributed network system based on blockchain technology serving e-commerce, reached a deep consensus with the Minister of Youth Development of the Congo (DRC), and signed the Memorandum of Understanding on Cooperation in National Infrastructure Construction and Energy Economization Transition Strategy of the Democratic Republic of the Congo.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1016001.jpg'),
        title: "Cumlaude Crypto 2019 sparks blockchain enthusiasm among Indonesian students",
        time: '2019.06.12',
        links: 'https://www.tribunnews.com/techno/2019/10/01/cumlaude-crypto-2019-edukasi-teknologi-blockchain-dan-aset-kripto-untuk-mahasiswa',
        infos: [
          {
            info: 'The jakarta-cumlaude cryptocurrency roadshow is a series of events designed to introduce Indonesian students to blockchain technology and cryptographic assets.On top of the event, BitCherry introduced the theme of "the application of blockchain technology in the e-commerce industry".',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1008003.jpg'),
        title: "The university of Indonesia on the tuyere: BitCherry helps the blockchain education in Indonesia to realize the curve overtaking",
        time: '2019.06.12',
        links: 'https://www.yunhe24.com/article/24593',
        infos: [
          {
            info: "The roadshow of a trip to six cities in Indonesia was successfully concluded. BitCherry, as the sponsor of the distributed network of public blockchain, was invited to participate in the whole roadshow. In this roadshow, BitCherry and other projects in this show have received extensive attention from local blockchain enthusiasts in Indonesia.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1008002.jpg'),
        title: "Blockchain projects are gaining popularity in Indonesia,BCHC helped overtake the local economy curve",
        time: '2019.06.12',
        links: 'http://www.chinatradenews.com.cn/content/201909/23/c84806.html',
        infos: [
          {
            info: "The roadshow of a trip to six cities in Indonesia was successfully concluded. BitCherry, as the sponsor of the distributed network of public blockchain, was invited to participate in the whole roadshow. In this roadshow, BitCherry and other projects in this show have received extensive attention from local blockchain enthusiasts in Indonesia.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/1008001.jpg'),
        title: "The university of Indonesia on the tuyere: BitCherry helps the blockchain education in Indonesia to realize the curve overtaking",
        time: '2019.06.12',
        links: 'http://www.qingtingcj.com/tnews/75378.html',
        infos: [
          {
            info: "The roadshow of a trip to six cities in Indonesia was successfully concluded. BitCherry, as the sponsor of the distributed network of public blockchain, was invited to participate in the whole roadshow. In this roadshow, BitCherry and other projects in this show have received extensive attention from local blockchain enthusiasts in Indonesia.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0925004.jpg'),
        title: "BitCherry: the first e-commerce platform using blockchain technology",
        time: '2019.06.12',
        links: 'https://nhipcaudautu.vn/doanh-nghiep/BitCherry-san-thuong-mai-dien-tu-dau-tien-su-dung-cong-nghe-chuoi-khoi-3330336/',
        infos: [
          {
            info: "BitCherry's community representatives in Vietnam delivered a keynote speech titled \"the power of ABCD\" at the Innovation Summit 2019. Where A refers to artificial intelligence, B to blockchain, C to cloud computing and D to big data.These technologies will optimize operational processes and costs by creating new business models to suit future customers. The power of ABCD will determine the path of the digital economy.BitCherry CEO Paul also explained the new technology trend in more detail during the conference.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0925003.jpg'),
        title: "Using distributed business ideas and technology to lead the transformation of distributed economy",
        time: '2019.06.12',
        links: 'https://bitcoinnewsindo.com/BitCherry-memimpin-transformasi-ekonomi-terdistribusi-dengan-ide-dan-teknologi-bisnis-terdistribusi/',
        infos: [
          {
            info: "BitCherry chose to start with business needs. With its own technology concepts to constantly balance the “Impossible Triangle” relationship, BitCherry build a distributed e-commerce infrastructure, improve the distributed e-commerce ecosystem, and gradually construct a super eco-network under distributed economy.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0925002.jpg'),
        title: "BitCherry! USES distributed business philosophy and technology to lead the transformation of distributed economy",
        time: '2019.06.12',
        links: 'https://www.jinse.com/news/blockchain/468054.html',
        infos: [
          {
            info: "BitCherry chose to enter the e-commerce sector, which is in urgent need of a distributed revolution.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0925001.jpg'),
        title: "Using distributed business ideas and technology to lead the transformation of distributed economy",
        time: '2019.06.12',
        links: 'https://www.erienewsnow.com/story/41071368/BitCherry-leading-the-transformation-of-the-distributed-economy-with-distributed-business-ideas-and-technologies',
        infos: [
          {
            info: "BitCherry chose to start with business needs. With its own technology concepts to constantly balance the “Impossible Triangle” relationship, BitCherry build a distributed e-commerce infrastructure, improve the distributed e-commerce ecosystem, and gradually construct a super eco-network under distributed economy.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0918004.jpg'),
        title: "Towards the era of blockchain 4.0: BCHC gives power to the distributed e-commerce platform",
        time: '2019.06.12',
        links: 'http://www.zmke.com/post/201909/8833.html',
        infos: [
          {
            info: " The blockchain 4.0 era is approaching.In order to promote the implementation of blockchain in the actual business scene, BitCherry, the world's largest distributed e-commerce platform, plans to achieve the goal of \"blockchain 4.0\" in two stages.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0918003.jpg'),
        title: "The new power of digital finance: BCHC deeply shapes the economic ecology",
        time: '2019.06.12',
        links: 'http://www.chinatradenews.com.cn/content/201909/16/c84017.html',
        infos: [
          {
            info: " The industry holds a positive and optimistic attitude towards the arrival of the era of \"blockchain 4.0\" and breaks away from the shackled centralized e-commerce, which has become an irreversible trend. Therefore, the potential of distributed e-commerce network platform will be further highlighted, and the value of BCHC as a digital certificate will be more widely recognized.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0918002.jpg'),
        title: "Towards the era of blockchain 4.0: BCHC gives power to the distributed e-commerce platform",
        time: '2019.06.12',
        links: 'http://www.chinaweekly.cn/19708.html',
        infos: [
          {
            info: " The blockchain 4.0 era is approaching.In order to promote the implementation of blockchain in the actual business scene, BitCherry, the world's largest distributed e-commerce platform, plans to achieve the goal of \"blockchain 4.0\" in two stages.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0918001.jpg'),
        title: "The new power of digital finance: BCHC deeply shapes the economic ecology",
        time: '2019.06.12',
        links: 'http://www.ceoim.com/article-146341-1.html',
        infos: [
          {
            info: "The industry holds a positive and optimistic attitude towards the arrival of the era of \"blockchain 4.0\" and breaks away from the shackled centralized e-commerce, which has become an irreversible trend. Therefore, the potential of distributed e-commerce network platform will be further highlighted, and the value of BCHC as a digital certificate will be more widely recognized.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0910005.jpg'),
        title: "Distributed E-commerce Network Project BitCherry was invited to attend the Hanoi Innovation Summit in Vietnam to actively build the Community in Vietnam",
        time: '2019.06.12',
        links: 'https://nulltx.com/distributed-e-commerce-network-project-BitCherry-was-invited-to-attend-the-hanoi-innovation-summit-in-vietnam-to-actively-build-the-community-in-vietnam/',
        infos: [
          {
            info: "BitCherry, a distributed e-commerce network project and also the only Blockchain e-commerce project invited to Hanoi Innovation Summit 2019, had its representatives of the Vietnamese community and its opinion leaders attended the convention.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0910004.jpg'),
        title: "BCHC frequently invited by blockchain conference,Industry affirmed its value and growth",
        time: '2019.06.12',
        links: 'http://news.flyxg.com/2019/qiqu_0904/158912.html',
        infos: [
          {
            info: " Whether in Beijing, Vietnam or Indonesia,BCHC has become an emerging force in the field of distributed economy. The new ideas and methods it advocates provide a broad imagination space for local economic transformation, and its value and growth potential have been deeply recognized by the industry.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0910003.jpg'),
        title: "Distributed E-commerce Network Project BitCherry was invited to attend the Hanoi Innovation Summit in Vietnam to actively build the Community in Vietnam",
        time: '2019.06.12',
        links: 'https://vnbit.net/press-release-du-an-mang-thuong-mai-dien-tu-phan-tan-BitCherry-duoc-moi-tham-du-hoi-nghi-thuong-dinh-doi-moi-ha-noi-tai-viet-nam/',
        infos: [
          {
            info: "BitCherry, a distributed e-commerce network project and also the only Blockchain e-commerce project invited to Hanoi Innovation Summit 2019, had its representatives of the Vietnamese community and its opinion leaders attended the convention.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0910002.jpg'),
        title: "The distributed economy has exploded Vietnam,BitCherry enabling traditional retail services",
        time: '2019.06.12',
        links: 'https://coinvestasi.com/press-releases/BitCherry-memberdayakan-industri-layanan-ritel-tradisional-di-vietnam/',
        infos: [
          {
            info: "Organized by Business Review, one of Vietnam's most prominent and authoritative Business magazines, “Vietnam Tech Summit 2019” is officially underway in ho chi minh city.BitCherry was not only invited to the summit as one of the conference sponsors, but also founder and CEO Paul was invited as a guest speaker, laying a solid foundation for implementing a distributed economy in Vietnam.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0910001.jpg'),
        title: "Bob Qin, chief scientist at BitCherry: for 2019, forget about the public and private blockchains",
        time: '2019.06.12',
        links: 'https://news.huoxing24.com/20190903213359377881.html',
        infos: [
          {
            info: '"Decentralization, centralization, that\'s a political or commercial concept," said Bob Qin, chief scientist at BitCherry and chairman of the north American blockchain foundation. "from an engineering point of view, it\'s a two-way, incremental process."',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0903007.jpg'),
        title: "Distributed economy explodes in Vietnam: BCHC empowers traditional retail services",
        time: '2019.06.12',
        links: 'http://www.chinaweekly.cn/17859.html',
        infos: [
          {
            info: "With the theme of \"ABCD Power\", the \"Vietnam Tech Summit 2019\" was officially held, sponsored by Business Review, one of the most famous and authoritative Business magazines in Vietnam. BitCherry, whose token is BCHC, was one of the sponsors, and its founder and CEO gave a speech.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0903006.jpg'),
        title: "Distributed Economy Storms Vietnam with BitCherry Empowering Traditional Retail Service Industry",
        time: '2019.06.12',
        links: 'https://www.coinspeaker.com/distributed-economy-outbreaks-in-vietnam-BitCherry-is-empowering-traditional-retail-service-industry/',
        infos: [
          {
            info: "On August 29th, 2019, the Vietnam Tech Summit 2019, was officially held in Ho Chi Minh City, Vietnam. Not only was BitCherry invited to the summit as one of the sponsors to the conference, but also Paul, its founder and CEO was invited to give a speech as a special guest, laying a solid foundation for the distributed economy implementation in Vietnam.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0903005.jpg'),
        title: "Distributed economy explodes in Vietnam: BCHC empowers traditional retail services",
        time: '2019.06.12',
        links: 'https://tech.china.com/article/20190829/kejiyuan0129356269.html',
        infos: [
          {
            info: "  With the theme of \"ABCD Power\", the \"Vietnam Tech Summit 2019\" was officially held, sponsored by Business Review, one of the most famous and authoritative Business magazines in Vietnam. BitCherry, whose token is BCHC, was one of the sponsors, and its founder and CEO gave a speech.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0903004.jpg'),
        title: "Exploration of BitCherry towards distributed economic – Using technology to empower the industry, ecology to deliver the value",
        time: '2019.06.12',
        links: 'https://www.kitv.com/story/40957227/news',
        infos: [
          {
            info: "After two days of in-depth discussion from 20th to 21st August, the POW’ER 2019 Global Developers Conference had successfully ended in Beijing, China. In this conference, the topic of the future development trend of blockchain distributed economy has attracted an extensive attention from all of the participants.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0903003.jpg'),
        title: "Distributed Economy Storms Vietnam with BitCherry Empowering Traditional Retail Service Industry",
        time: '2019.06.12',
        links: 'https://vnbit.net/press-release-kinh-te-phan-tan-dang-bung-no-tai-viet-nam-BitCherry-dang-trao-quyen-cho-nganh-dich-vu-ban-le-truyen-thong/',
        infos: [
          {
            info: "On August 29th, 2019, the Vietnam Tech Summit 2019, was officially held in Ho Chi Minh City, Vietnam. Not only was BitCherry invited to the summit as one of the sponsors to the conference, but also Paul, its founder and CEO was invited to give a speech as a special guest, laying a solid foundation for the distributed economy implementation in Vietnam.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0903002.jpg'),
        title: "Exploration of BitCherry towards distributed economic – Using technology to empower the industry, ecology to deliver the value",
        time: '2019.06.12',
        links: 'http://www.kuam.com/story/40969825/news',
        infos: [
          {
            info: "After two days of in-depth discussion from 20th to 21st August, the POW’ER 2019 Global Developers Conference had successfully ended in Beijing, China. In this conference, the topic of the future development trend of blockchain distributed economy has attracted an extensive attention from all of the participants.",
            img: '',

          },


        ]
      },

      {
        img: require('../assets/img/media1/0903001.jpg'),
        title: "BCHC will present at the Hanoi Innovation Summit to further build Vietnamese communities",
        time: '2019.06.12',
        links: 'http://www.3news.cn/pindao/2019/0830/0613363179.html',
        infos: [
          {
            info: "On August 30, 2019, the two-day \"Hanoi Innovation Summit 2019\" was held in Hanoi national convention center.BitCherry, the distributed e-commerce network project, was invited to the summit as the only blockchain e-commerce project, and its Vietnamese community representatives and opinion leaders attended the meeting.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827009.jpg'),
        title: "BitCherry Chief Scientist Bob Qin says \"Smart contract is not restricted to a particular technology in which it should be built according to business scenarios instead\"",
        time: '2019.06.12',
        links: 'http://www.abc6.com/story/40957227/news',
        infos: [
          {
            info: "BitCherry Chief Scientist and Chairman of the North American Blockchain Foundation Bob Qin was invited as a special guest to present a key speech on “Decentralized distributed computing: Distributed e-commerce network public chain innovation”",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827008.jpg'),
        title: "BitCherry builds a new distributed e-commerce network public blockchain based on distributed computing",
        time: '2019.06.12',
        links: 'http://www.enet.com.cn/article/2019/0823/A20190823984241.html',
        infos: [
          {
            info: "As a leading practice of distributed commerce, BitCherry, a distributed e-commerce network platform, participated in the discussion of the distributed economy sub-conference.Bob Qin, chief scientist of BitCherry and chairman of the blockchain foundation of North America, delivered the keynote speech \"decentralized distributed computing, distributed e-commerce network BitCherry public blockchain innovation\", explaining the significance of BitCherry public blockchain for distributed e-commerce application from the perspective of distributed computing.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827007.jpg'),
        title: "BitCherry Chief Scientist Bob Qin says \"Smart contract is not restricted to a particular technology in which it should be built according to business scenarios instead\"",
        time: '2019.06.12',
        links: 'https://www.kitv.com/story/40957227/news',
        infos: [
          {
            info: "BitCherry Chief Scientist and Chairman of the North American Blockchain Foundation Bob Qin was invited as a special guest to present a key speech on “Decentralized distributed computing: Distributed e-commerce network public chain innovation”.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827006.jpg'),
        title: "POW’ER 2019 Global Developers Conference successfully completed BitCherry to establish a brand-new distributed e-commerce network public chain through distributed computing",
        time: '2019.06.12',
        links: 'http://www.digitaljournal.com/pr/4426158',
        infos: [
          {
            info: "BitCherry Chief Scientist and Chairman of North American Blockchain Foundation Bob Qin had present key speeches on “Decentralized distributed computing: BitCherry distributed e-commerce public chain innovation,” from the perspective of distributed computing to explain the importance of BitCherry public chain towards the application of distributed e-commerce.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827005.jpg'),
        title: "BitCherry builds a new distributed e-commerce network public blockchain based on distributed computing",
        time: '2019.06.12',
        links: 'http://www.ciotimes.com/IT/178948.html',
        infos: [
          {
            info: "As a leading practice of distributed commerce, BitCherry, a distributed e-commerce network platform, participated in the discussion of the distributed economy sub-conference.Bob Qin, chief scientist of BitCherry and chairman of the blockchain foundation of North America, delivered the keynote speech \"decentralized distributed computing, distributed e-commerce network BitCherry public blockchain innovation\", explaining the significance of BitCherry public blockchain for distributed e-commerce application from the perspective of distributed computing.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827004.jpg'),
        title: "POW’ER 2019 Global Developers Conference successfully completed BitCherry to establish a brand-new distributed e-commerce network public chain through distributed computing",
        time: '2019.06.12',
        links: 'https://www.kuam.com/story/40957160/news',
        infos: [
          {
            info: "BitCherry Chief Scientist and Chairman of North American Blockchain Foundation Bob Qin had present key speeches on “Decentralized distributed computing: BitCherry distributed e-commerce public chain innovation,” from the perspective of distributed computing to explain the importance of BitCherry public chain towards the application of distributed e-commerce.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827003.jpg'),
        title: "BitCherry Chief Scientist and Chairman of North American Blockchain Foundation Bob Qin talks about “Decentralized distributed computing: Distributed e-commerce network public chain innovation”",
        time: '2019.06.12',
        links: 'https://www.lubbockcw.com/story/40964859/BitCherry-chief-scientist-and-chairman-of-north-american-blockchain-foundation-bob-qin-talks-about-decentralized-distributed-computing-distributed-ecommerce-network-public-chain-innovation',
        infos: [
          {
            info: " On 21st August 2019, the POW’ER 2019 Global Developers Conference hosted by Mars Finance was held in Beijing, BitCherry Chief Scientist and Chairman of North American Blockchain Foundation Bob Qin had present a key speech on “Decentralized distributed computing: Distributed e-commerce network public chain innovation”.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827002.jpg'),
        title: "The distributed economy has sparked debate about BCHC's willingness to create more payment scenarios",
        time: '2019.06.12',
        links: 'http://www.chinablockchainnews.cn/blockbhainapplication/2019/08/26/12919.html',
        infos: [
          {
            info: "Distributed e-commerce represented by BitCherry is not only driven by user demand, but also driven by technological changes such as block chain. BCHC relies on such a platform to realize its own value.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0827001.jpg'),
        title: 'BitCherry Chief Scientist and Chairman of North American Blockchain Foundation Bob Qin talks about “Decentralized distributed computing: Distributed e-commerce network public chain innovation”',
        time: '2019.06.12',
        links: 'http://markets.financialcontent.com/stocks/news/read/38758934',
        infos: [
          {
            info: "On 21st August 2019, the POW’ER 2019 Global Developers Conference hosted by Mars Finance was held in Beijing, BitCherry Chief Scientist and Chairman of North American Blockchain Foundation Bob Qin had present a key speech on “Decentralized distributed computing: Distributed e-commerce network public chain innovation”.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0823004.jpg'),
        title: 'BCHC appears in the global blockchain conference to break the blockchain "impossible triangle',
        time: '2019.06.12',
        links: 'http://www.dzshbw.com/news/2019/shuma_0819/221364.html',
        infos: [
          {
            info: "The 2019 global blockchain developer conference was held in Beijing from August 20 to 22. As the first digital certificate of the world's first distributed e-commerce network, BCHC was presented at the conference.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0823003.jpg'),
        title: 'BitCherry chain network: break the "impossible triangle" limitation of blockchain by layering technology architecture',
        time: '2019.06.12',
        links: 'http://www.weilaicaijing.com/article/37017',
        infos: [
          {
            info: "Faced with these technological innovations, BitCherry chose to balance the impossible triangle from everything to business needs.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0823002.jpg'),
        title: "The ecological environment of e-commerce is becoming more and more mature,BCHC will provide landing support for more fields",
        time: '2019.06.12',
        links: 'http://www.afinance.cn/lc/lcjj/201908/2429125.html',
        infos: [
          {
            info: 'The distributed economy BBS of "POW `ER 2019 global blockchain developers conference" was officially held. Bob Qin, chief scientist of BCHC and chairman of the north American blockchain foundation, as the special guest, delivered a speech entitled "decentralized distributed computing: distributed e-commerce network public chain innovation".',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0823001.jpg'),
        title: 'BitCherry chain network: break the "impossible triangle" limitation of blockchain by layering technology architecture',
        time: '2019.06.12',
        links: 'https://www.csdn.net/article/a/2019-08-19/15979627',
        infos: [
          {
            info: "Faced with these technological innovations, BitCherry chose to balance the impossible triangle from everything to business needs.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0819001.jpg'),
        title: "POW 'er 2019 focuses on the distributed economy BitCherry public blockchain enables the development of distributed e-commerce",
        time: '2019.06.12',
        links: 'https://news.huoxing24.com/20190816173504754556.html',
        infos: [
          {
            info: 'From August 20 to 21, 2019, Mars finance will hold "POW `er 2019 global developer conference" in Beijing. Bob Qin, chairman of the north American blockchain foundation and chief scientist of BitCherry, will give a speech entitled "decentralized distributed computing: distributed e-commerce network public chain innovation".',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0819002.jpg'),
        title: 'Where does the vitality of distributed e-commerce come from?',
        time: '2019.06.12',
        links: 'http://m.zhoukan.cc/jujiao/2019/0816/jujiao08042723.html',
        infos: [
          {
            info: 'The vitality of distributed e-commerce comes from its internal mechanism. At the same time, under the promotion of this internal mechanism, distributed e-commerce finally sets foot on the road of prosperity through function expansion.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0819003.jpg'),
        title: 'The fifth revolutionary in Retail Industry, The Road to the Rise of Distributed E-Commerce',
        time: '2019.06.12',
        links: 'http://www.northcarolinaheadlines.com/news/story/145784/the-fifth-revolutionary-in-retail-industry-the-road-to-the-rise-of-distributed-ecommerce.html',
        infos: [
          {
            info: 'After all, it can be seen that the vitality of distributed e-commerce comes from its internal mechanism. At the same time, under the enhancement of this internal mechanism and through the expansion of functions, distributed e-commerce finally embarks on the road to prosperity.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0819004.jpg'),
        title: 'Where does the vitality of distributed e-commerce come from?',
        time: '2019.06.12',
        links: 'http://www.hscbw.com/a/zonghe/2019/0816/138187.html?1565953635',
        infos: [
          {
            info: 'The vitality of distributed e-commerce comes from its internal mechanism. At the same time, under the promotion of this internal mechanism, distributed e-commerce finally sets foot on the road of prosperity through function expansion.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0819005.jpg'),
        title: 'The fifth revolutionary in Retail Industry, The Road to the Rise of Distributed E-Commerce',
        time: '2019.06.12',
        links: 'http://news.salemnewsheadlines.com/story/407876/the-fifth-revolutionary-in-retail-industry-the-road-to-the-rise-of-distributed-ecommerce.html',
        infos: [
          {
            info: 'After all, it can be seen that the vitality of distributed e-commerce comes from its internal mechanism. At the same time, under the enhancement of this internal mechanism and through the expansion of functions, distributed e-commerce finally embarks on the road to prosperity.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0816002.jpg'),
        title: 'How much potential does BitCherry borderless ecology inspire in BCHC?',
        time: '2019.06.12',
        links: 'http://www.hzfc.cc/zx/shangye/19612.html',
        infos: [
          {
            info: "After the successful entry of BitCherry project into the Singapore market, it successfully expanded into the Indonesian market.This naturally leads BCHC's fans to wonder how much more potential BCHC has as proof of BitCherry.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0816001.jpg'),
        title: 'BCHC and SCC have reached strategic cooperation to build a smart city worth tens of billions of dollars',
        time: '2019.06.12',
        links: 'https://tech.china.com/article/20190809/kejiyuan0129342708.html',
        infos: [
          {
            info: 'BCHC announces the signing of strategic cooperation agreements with SCC and WTIA.The signing of the strategic cooperation marks the in-depth cooperation among the three parties in key areas such as project incubation, technology research and development, promotion activities, and promoting the development of smart cities and blockchain.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0809004.jpg'),
        title: 'BCHC enables distributed e-commerce platform to solve the platform trust crisis',
        time: '2019.06.12',
        links: 'http://www.3news.cn/pindao/2019/0807/0613358346.html',
        infos: [
          {
            info: "BitCherry is the world's first distributed e-commerce platform, which is based on blockchain technology and is committed to creating a super ecosystem that covers all aspects of food, clothing, housing and transportation.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0809003.jpg'),
        title: 'BitCherry announced a strategic partnership with SCC and WTIA to build a $42 billion smart city',
        time: '2019.06.12',
        links: 'https://coins.group/BitCherry-announce-their-strategic-collaboration-achievement-with-scc-and-wita-on-building-a-42-billion-smart-city/',
        infos: [
          {
            info: 'On July 30, 2019, BitCherry, a distributed e-commerce network, announced the signing of a strategic cooperation agreement with SCC and WTIA in Indonesia. The signing of the strategic cooperation marks the in-depth cooperation among the three parties in key areas such as project incubation, technology research and development, promotion activities, and promoting the development of smart cities and blockchain.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0809002.jpg'),
        title: 'BCHC enables distributed e-commerce platform to solve the platform trust crisis',
        time: '2019.06.12',
        links: 'http://news.flyxg.com/2019/qiqu_0807/156589.html',
        infos: [
          {
            info: "BitCherry is the world's first distributed e-commerce platform, which is based on blockchain technology and is committed to creating a super ecosystem that covers all aspects of food, clothing, housing and transportation.",
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0809001.jpg'),
        title: 'BitCherry announced a strategic partnership with SCC and WTIA to build a $42 billion smart city',
        time: '2019.06.12',
        links: 'https://vnbit.net/press-release-BitCherry-cong-bo-thanh-tuu-hop-tac-chien-luoc-cua-ho-voi-scc-va-wita-ve-viec-xay-dung-thanh-pho-thong-minh-tri-gia-42-ty-do-la/',
        infos: [
          {
            info: 'On July 30, 2019, BitCherry, a distributed e-commerce network, announced the signing of a strategic cooperation agreement with SCC and WTIA in Indonesia. The signing of the strategic cooperation marks the in-depth cooperation among the three parties in key areas such as project incubation, technology research and development, promotion activities, and promoting the development of smart cities and blockchain.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806003.jpg'),
        title: 'BitCherry announced a strategic partnership with SCC and WTIA to build a $42 billion smart city',
        time: '2019.06.12',
        links: 'https://www.liantuan.vip/investment/detail?home=%25E8%25B5%2584%25E8%25AE%25AF&id=30576',
        infos: [
          {
            info: 'On July 30, 2019, BitCherry, a distributed e-commerce network, announced the signing of a strategic cooperation agreement with SCC and WTIA in Indonesia. The signing of the strategic cooperation marks the in-depth cooperation among the three parties in key areas such as project incubation, technology research and development, promotion activities, and promoting the development of smart cities and blockchain.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806008.jpg'),
        title: 'BitCherry signed strategic cooperation agreements with the Indonesian government and the global blockchain investment alliance',
        time: '2019.06.12',
        links: 'https://bitcoinnewsindo.com/BitCherry-menandatangani-perjanjian-kerja-sama-strategis-dengan-pemerintah-indonesia/',
        infos: [
          {
            info: 'On July 29, 2019, Indonesian chamber of commerce and industry (KADIN), global block chain investment alliance GBIA and Hong Kong block chain association signed a strategic cooperation agreement with BitCherry.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806002.jpg'),
        title: 'BitCherry announced a strategic partnership with SCC and WTIA to build a $42 billion smart city',
        time: '2019.06.12',
        links: 'http://www.qingtingcj.com/tnews/70144.html',
        infos: [
          {
            info: 'On July 30, 2019, BitCherry, a distributed e-commerce network, announced the signing of a strategic cooperation agreement with SCC and WTIA in Indonesia. The signing of the strategic cooperation marks the in-depth cooperation among the three parties in key areas such as project incubation, technology research and development, promotion activities, and promoting the development of smart cities and blockchain.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806004.jpg'),
        title: 'CherryWallet has launched pre-orders for a worldwide commercial-grade secure wallet service',
        time: '2019.06.12',
        links: 'https://bihu.com/article/1942687320 ',
        infos: [
          {
            info: 'CherryWallet, a secure storage project for blockchain digital assets, officially held a global launch ceremony at the southeast Asia blockchain summit GBIS 2019 (Indonesia) on July 30.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806007.jpg'),
        title: 'BitCherry has teamed up with the Indonesian government and the global blockchain investment alliance to help transform the Indonesian economy',
        time: '2019.06.12',
        links: 'https://coinvestasi.com/press-releases/BitCherry-restrukturisasi-ekonomi-indonesia-bersama-pemerintah-indonesia-dan-global-blockchain-investment-alliance/',
        infos: [
          {
            info: 'On July 29, 2019, Indonesian chamber of commerce and industry (KADIN), global blockchain investment alliance GBIA and Hong Kong blockchain association signed a strategic cooperation agreement with BitCherry.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806005.jpg'),
        title: 'The Global Blockchain Investment Summit (GBIS) is about to happen in Indonesia. Explore the transformation of Indonesia’s digital economy.',
        time: '2019.06.12',
        links: 'https://nulltx.com/the-global-blockchain-investment-summit-gbis-is-about-to-happen-in-indonesia-explore-the-transformation-of-indonesias-digital-economy/',
        infos: [
          {
            info: 'On April 24th, the 2019 Global Blockchain Investment Summit (GBIS) was successfully held in Singapore. During this summit, regulators from different countries and technology experts gathered to discuss the current status, development, and future of blockchain technology and distributed commerce in Singapore.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806006.jpg'),
        title: 'The Global Blockchain Investment Summit (GBIS) is about to happen in Indonesia',
        time: '2019.06.12',
        links: 'https://digitalnotice.in/?p=1586',
        infos: [
          {
            info: 'On April 24th, the 2019 Global Blockchain Investment Summit (GBIS) was successfully held in Singapore. During this summit, regulators from different countries and technology experts gathered to discuss the current status, development, and future of blockchain technology and distributed commerce in Singapore.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0806001.jpg'),
        title: 'BitCherry announced a strategic partnership with SCC and WTIA to build a $42 billion smart city',
        time: '2019.06.12',
        links: 'http://uzb.com.cn/Blockchain/1304675.html',
        infos: [
          {
            info: 'On July 30, 2019, BitCherry, a distributed e-commerce network, announced the signing of a strategic cooperation agreement with SCC and WTIA in Indonesia. The signing of the strategic cooperation marks the in-depth cooperation among the three parties in key areas such as project incubation, technology research and development, promotion activities, and promoting the development of smart cities and blockchain.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/073105.jpg'),
        title: 'BitCherry signed strategic cooperation agreements with the Indonesian government and global blockchain investment company to jointly promote the adjustment of Indonesia`s economic structure',
        time: '2019.06.12',
        links: 'http://www.blockchainno1.io/news/17057',
        infos: [
          {
            info: 'In the future, BitCherry will continue to build a global platform for blockchain and distributed power suppliers that can be distributed to business applications, connect global business resources, develop digital economics, and build globally distributed business systems.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/073104.jpg'),
        title: 'The Global Blockchain Investment Summit (GBIS) is About to Happen in Indonesia. Explore the Transformation of Indonesia’s Digital Economy',
        time: '2019.06.12',
        links: 'https://www.coinspeaker.com/the-global-blockchain-investment-summit-gbis-is-about-to-happen-in-indonesia-explore-the-transformation-of-indonesias-digital-economy/',
        infos: [
          {
            info: 'During the two-day summit, dozens of in-depth keynote speeches and panel discussion will explore the development trend and application prospects of blockchain technology, providing enterprises, industries, and governments with cutting-edge thinking and innovative solutions for economic transformation.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/073103.jpg'),
        title: 'The global block chain investment summit (GBIS) will soon be held in Indon',
        time: '2019.06.12',
        links: 'https://vnbit.net/press-release-hoi-nghi-thuong-dinh-dau-tu-blockchain-toan-cau-gbis-sap-dien-ra-tai-indonesia/',
        infos: [
          {
            info: 'In recent years, blockchain technology and application in southeast Asia have developed rapidly in the past.Governments and associations around the world and blockchain alliances are actively exploring blockchain technology.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/073102.jpg'),
        title: 'Touch 100 billion e-commerce market BitCherry shines in Indonesia',
        time: '2019.06.12',
        links: 'http://www.dzshbw.com/news/2019/baoguang_0730/217811.html',
        infos: [
          {
            info: 'BitCherry will work with the Indonesian government on a regional basis to promote rapid economic transformation in Indonesia.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/073101.jpg'),
        title: 'BitCherry signed three strategic cooperation agreements with the Indonesian government to jointly promote the economic transformation of Indonesia',
        time: '2019.06.12',
        links: 'http://www.chinaweekly.cn/13971.html',
        infos: [
          {
            info: 'During the conference, BitCherry development team officially held the global launch ceremony of public chain project development, and signed strategic cooperation agreements with Indonesia chamber of commerce and industry (KADIN), global block chain investment alliance GBIA, and Hong Kong block chain association.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/073001.jpg'),
        title: 'BitCherry signed three strategic cooperation agreements with the Indonesian government to jointly promote the economic transformation of Indonesia',
        time: '2019.06.12',
        links: 'http://www.zmke.com/post/201907/6227.html',
        infos: [
          {
            info: 'BitCherry will hand in hand to the Indonesian government help Indonesia enterprise build distributed business ecosystem, both sides signed respectively the chain block with distributed business investment to promote the economy transformation strategic cooperation memorandum of understanding, the building block chain solution to reduce the logistics cost of Indonesia`s strategic cooperation memorandum of understanding, the block chain solutions to promote the financial efficiency of Indonesia memorandum of strategic cooperation.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/073000.jpg'),
        title: 'BitCherry (BCHC) exclusively sponsored and attended the global block chain investment summit (GBIS) dinner',
        time: '2019.06.12',
        links: 'http://www.newyorkcj.com/blockchain/2019/07/26/6720.html',
        infos: [
          {
            info: 'BitCherry, the world`s first distributed e-commerce platform, is the exclusive sponsor of the GBIS dinner.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/072902.jpg'),
        title: 'The global block chain investment summit is about to be held. BCHC will help the development of distributed e-commerce',
        time: '2019.06.12',
        links: 'http://www.zmke.com/post/201907/6027.html',
        infos: [
          {
            info: 'As the world`s first distributed e-commerce network, BitCherry behind BCHC is committed to empowering the traditional retail service industry through block chain technology and creating a new super ecology of Shared e-commerce.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/072901.jpg'),
        title: 'Touch 100 billion e-commerce market BitCherry shines in Indonesia',
        time: '2019.06.12',
        links: 'https://coinvestasi.com/press-releases/ecommerce-BitCherry-indonesia/',
        infos: [
          {
            info: 'How big is the potential for BitCherry, the world`s first distributed e-commerce network that will impact Indonesia`s $100 billion e-commerce market?',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/0729.jpg'),
        title: 'The Global Blockchain Investment Summit (GBIS) is About to Happen in Indonesia',
        time: '2019.06.12',
        links: 'https://www.cryptonewsz.com/the-global-blockchain-investment-summit-gbis-is-about-to-happen-in-indonesia-explore-the-transformation-of-indonesias-digital-economy/32586/',
        infos: [
          {
            info: 'The 2019 Global Blockchain Investment Summit will jointly discuss the development prospects of blockchain technology and application, explore how governments and traditional businesses of various countries can integrate into the tide of digital economy transformation, and grasp the lifeblood of technology and economic development.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/caijing.jpg'),
        title: 'Is there any alternative to the centralized trading platform?',
        time: '2019.06.12',
        links: 'https://www.jinse.com/blockchain/392601.html',
        infos: [
          {
            info: 'In the centralized trading platform manufacturers several alone today, the store is big, will have to cheat customers.',
            img: '',

          },


        ]
      },
      {
        img: require('../assets/img/media1/nulltx.jpg'),
        title: 'Distributed ECommerce: A New Commercial Civilization',
        time: '2019.05.29',
        links: 'https://nulltx.com/distributed-e-commerce-a-new-commercial-civilization/',
        infos: [
          {
            info: 'Different from the centralized trading platform, the distributed e-commerce network has a decentralized network structure, and each subject has an equal position to form a decentralized business ecology, which enables it to get out of the difficulties of the centralized trading platform.',
            img: ''
          },

        ]
      },
      {
        img: require('../assets/img/media1/zhonghua.jpg'),
        title: 'Why is the distributed e-commerce industry the hope of tomorrow?',
        time: '2019.05.23',
        links: 'https://tech.china.com/article/20190709/kejiyuan0129324421.html',
        infos: [
          {
            info: 'BitCherry distributed e-commerce is to realize value creation in the process of combining strong entities, expanding the ecology, growing together and contributing to each other.',
            img: ''
          },

        ]
      },
      {
        img: require('../assets/img/media1/dailyherald.jpg'),
        title: 'BitCherry will debut Global Blockchain Summit in Singapore',
        time: '2019.05.22',
        links: 'http://finance.dailyherald.com/dailyherald/news/read/38090497/',
        infos: [
          {
            info: 'On April 24, the global blockchain investment summit will held in Singapore. BitCherry will launch as a special project in the summit.',
            img: ''
          },

        ]
      },
      {
        img: require('../assets/img/media1/tianji.jpg'),
        title: 'By tearing down corporate walls, BitCherry benefits business participants promptly and fairly',
        time: '2019.05.21',
        links: 'http://news.yesky.com/hotnews/402/851294902.shtml',
        infos: [
          {
            info: 'By tearing down corporate walls, BitCherry benefits business participants promptly and fairly',
            img: ''
          },


        ]
      },
      {
        img: require('../assets/img/media1/souhu.jpg'),
        title: 'BitCherry: the incubator of amazon and alibaba in the blockchain field ',
        time: '2019.05.05',
        links: 'http://www.sohu.com/a/315971361_120022803',
        infos: [
          {
            info: 'If Block chain and other emerging technologies can take over the banner of the Internet, continue to write a new business legend?',
            img: ''
          },



        ]
      },
      {
        img: require('../assets/img/media1/pitt.jpg'),
        title: 'BitCherry distributed e-commerce platform was officially launched',
        time: '2019.04.24',
        links: 'http://markets.post-gazette.com/postgazette/news/read/38090497',
        infos: [
          {
            info: 'The global blockchain investment summit was held in Singapore, where BitCherry distributed e-commerce platform held a launch ceremony.',
            img: ''
          },

        ]
      },
      {
        img: require('../assets/img/media1/lanchao.jpg'),
        title: 'Galanz event fermentation, BitCherry solution outside the centralized trading platform distributed e-commerce',
        time: '2019.04.21',
        links: 'http://www.fjii.com/sy/qysy/2019/0620/218515.shtml',
        infos: [
          {
            info: 'Why not believe that BitCherry distributed e-commerce networks will not be successful?',
            img: ''
          },

        ]
      },

    ]
  },
  Notice: {
    banner: {
      title: 'Announcement',
      infos: [
        ''
      ]
    },
    noticeList: [
      {
        img: require('../assets/img/media/0531001en.png'),
        title: "BitCherry Weekly Report  ",
        time: '2021.05.31',
        infos: [

          {
            info: '',
            img: require('../assets/img/media/0531002en.png'),
            strong: '1'
          },



        ]
      },
      {
        img: require('../assets/img/media/0518001en.png'),
        title: "BitCherry Weekly Report （2021.05.10~05.16） ",
        time: '2021.05.10-05.16',
        infos: [

          {
            info: '',
            img: require('../assets/img/media/0518002en.png'),
            strong: '1'
          },



        ]
      },
      {
        img: require('../assets/img/media/0505001en.png'),
        title: "BitCherry 2nd Anniversary, a letter to the community from the founder & CEO Paul",
        time: '2021.05.05',
        infos: [
          {
            info: 'To BitCherry Community,',
            img: '',
            strong: '1'
          },
          {
            info: 'May 5, 2021, is the day of the anniversary of the establishment of BitCherry, two years. I am glad to share with you the development process, technological advances, ecological building, and subsequent project planning since BitCherry two years.',
            img: '',
            strong: '0'
          },
          {
            info: 'When we start building the BitCherry platform, we hope it will provide a simple, easy-to-use blockchain infrastructure for people and customized different blockchain applications to support the business. So in May 2020, with my team to modify the direction of development of BitCherry After careful consideration, the BitCherry redefined as distributed based IPv8 technical services blockchain infrastructure in business expansion. We hope to redefine BitCherry Commercial, public chain redefines DApp ecology, redefines consensus and community. We have worked hard to make this vision a reality. After two years of unremitting efforts, the combination of all the wisdom and actions of the entire team, BitCherry leading network (BitCherry Chain) is coming soon; the combination of "available blockchain infrastructure + distributed business ecology" will better show everyone a brand new distributed business economic system.',
            img: '',
            strong: '0'
          },
          {
            info: 'High performance, high safety, and availability are the valid values of the product.',
            img: '',
            strong: '1'
          },
          {
            info: 'We believe that establishing a distributed business ecosystem is to show an entire business world on the chain, and infrastructure will be the fulcrum of this business world. BitCherry network from the test version v1.0.1 to v2.0.1 version, BitCherry team to maintain the attitude of continuous innovation, and constantly update and optimize BitCherry network environment our network more secure, easy to use, fast, Decentralization and high expansion performance.',
            img: '',
            strong: '0'
          },
          {
            info: 'As a core technology companies, we will continue to follow the official route established technology development, continue to improve the significant technology node, complete all the critical steps in a controlled environment to ensure that the system is running the entire blockchain stable and safe, and after delivery of each technology node, synchronous testing in a real-time environment. With the continuous improvement of technology and adequate verification, we will pay more attention to the development of the developer community, allowing developers to build applications more efficiently, which will become the basis for us to build a strong developer community and a distributed business ecosystem.',
            img: '',
            strong: '0'
          },
          {
            info: 'Promoting the development of digital commerce is the core of ecological development',
            img: '',
            strong: '1'
          },
          {
            info: 'We always believe that global business will be digitized in the near future, and blockchain technology is the medium to carry the digital transformation of business. This is why we built BitCherry, a scalable blockchain infrastructure for distributed commerce. We also believe that future blockchain applications must solve practical problems according to actual application scenarios, and BitCherry Chain applications must also solve the shortcomings of real business and create new value. Therefore, we need like-minded people to work with us to achieve this goal and build a complete distributed business ecosystem.',
            img: '',
            strong: '0'
          },
          {
            info: 'In March 2021, we officially established BitCherry distributed ecological fund. In the first phase, we invested 10 million US dollars in the development, competition, incubation, and investment of ecological projects, and launched the "All Sparks Plan" worldwide to improve BitCherry’s distributed business ecology. BitCherry has also won the support of members of the global community, and its influence has spread to more than 10 countries around the world. In just two months, dozens of developer teams have signed up to contribute to BitCherry’s distributed business ecosystem in their respective fields, bringing various types of decentralized business applications in various areas. Next, we will carefully screen every project in BitCherry distributed ecosystem, aiming to cultivate more valuable and potential blockchain business applications and create a young, dynamic, and unlimited distributed business ecosystem on the BitCherry Chain.',
            img: '',
            strong: '0'
          },
          {
            info: 'In terms of global ecological compliance layout, we have carried out regional cooperation with governments and local enterprises in Dubai, Africa, Indonesia, Vietnam, and other countries to promote the rapid transformation of local economy jointly. In the next step, we will maintain our dominant position in these countries, establish more in-depth cooperation with our partners, continue to promote the progress of standardization in the United States, Singapore, Russia, France, Japan, and other countries, continue to enhance the global traction of BitCherry’s distributed business ecology, and truly enable the global real economy with blockchain technology.',
            img: '',
            strong: '0'
          },
          {
            info: '"Abide by The Original Intention, Forge Ahead" is the principle of our progress.',
            img: '',
            strong: '0'
          },
          {
            info: 'A project is most afraid to drift with the tide, and every decision it makes is just a stress reaction in the environment at that time. Blockchain technology has only developed for more than ten years. It has expanded its vision to the whole wave of digital economy. The mapping of the digital world to the real world is still in its infancy. However, even though the environment is changing all the time, BitCherry’s principles remain unchanged. In addition to pursuing the distributed business world, BitCherry also abides by the original intention, we want BitCherry to be the most solid foundation in the digital business world. We will continue to accumulate, forge ahead, balance every development decision, give the community the most stable and sustainable feedback, provide landing blockchain solutions for businesses in various fields, and let global organizations and individuals easily enjoy the good changes brought by blockchain technology to the world.',
            img: '',
            strong: '0'
          },
          {
            info: 'Finally, I would like to sincerely thank every community user who trusts us, every developer who contributes code and chain applications to BitCherry, and every partner of the BitCherry team. I hope you can accompany us in the future and witness the growth of BitCherry.',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry Founder & CEO Paul',
            img: '',
            strong: '0'
          },
          {
            info: 'May 5th 2021',
            img: '',
            strong: '0'
          },




        ]
      },
      {
        img: require('../assets/img/media/1224001en.png'),
        title: "BitCherry Official Launch the \"All Spark Plan\" to set up a Special Ecological Support Fund of US $10 million",
        time: '2021.03.29',
        infos: [
          {
            info: 'On March 29, BitCherry officially launched the "All Spark Plan" to cultivate more valuable and potential blockchain business applications and enrich BitCherry’s distributed business ecosystem. BitCherry Distributed Business Ecological Fund also announced that it will officially invest 10 million US dollars in the development, competition, incubation, and investment of ecological projects, and provide financial, technical, and operational promotion support for developers. ',
            img: '',
            strong: '0'
          },
          {
            info: '"Spark is the soul of Transformers, and AllSpark is the source and destination of Transformers." ',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry, as the world\'s first distributed business infrastructure based on IPv8, is determined to become the " AllSpark" in the blockchain world, and the " AllSpark" also represents the diversity and sustainability of BitCherry’s future ecology. BitCherry will enable the development of distributed business applications through cohesive technology, community, and ecological resources, activate " AllSpark" one after another, and create a young, dynamic, and infinite possible blockchain world. In this " AllSpark Plan", developers can deploy and run DAPP on BitCherry. We will evaluate top 10 according to the operation of the registered projects and give "Super Spark" incubation reward. ',
            img: '',
            strong: '0'
          },
          {
            info: ' ',
            img: require('../assets/img/media/0329001en.png'),
            strong: '0'
          },
          {
            info: 'Registration Time: 29th March 2021 (GMT+8) ',
            img: '',
            strong: '0'
          },
          {
            info: 'All projects rated as “Super Spark” will have the opportunity to obtain at least one of the following interests: ',
            img: '',
            strong: '0'
          },
          {
            info: 'Investment opportunities of BitCherry Distributed Business Ecological Fund Resource support of BitCherry distributed Eco-commerce ',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry global marketing matrix resource tilt ',
            img: '',
            strong: '0'
          },
          {
            info: 'Project strategic operation / consulting analysis ',
            img: '',
            strong: '0'
          },
          {
            info: 'In addition, BitCherry will provide financial support, technical support, dedicated strategy planning, resource support, team training and other comprehensive support for the selected Top 3 projects / teams. It includes: ',
            img: '',
            strong: '0'
          },
          {
            info: 'Financial Support: Provide special funds and follow-up financial assistant for the project. ',
            img: '',
            strong: '0'
          },
          {
            info: 'Technical Guidance: Help the project team to sort out the technical roadmap and all kinds of technical support. ',
            img: '',
            strong: '0'
          },
          {
            info: 'Resource Support: Provide resource support of giant projects in BitCherry business ecological alliance and accelerate the application and landing of incubation projects in an all-round way. ',
            img: '',
            strong: '0'
          },
          {
            info: 'Construct Content Matrix: Through the BitCherry global content matrix, we help the project carry out brand promotion, obtain users and establish a global community. ',
            img: '',
            strong: '0'
          },
          {
            info: 'Registration: ',
            img: '',
            strong: '0'
          },
          {
            info: 'Project developers are invited to visit BitCherry website（ www.bitcherry.io ）Register or submit your application through the registration link below. ',
            img: '',
            strong: '0'
          },
          {
            info: 'Registration Form: https://forms.gle/CEKC9PK2vsGf2JQ57  ',
            img: '',
            strong: '0'
          },
          {
            info: 'Scoring Rules: The "AllSpark Plan" will pay more attention to the construction of BitCherry ecological infrastructure, so the following track projects are more likely to get bonus points in the final scoring: DeFi applications, games, auction platform, distributed cloud computing, Oracle, derivatives trading, data services, etc. ',
            img: '',
            strong: '0'
          },
          {
            info: 'Risk Warning: ',
            img: '',
            strong: '0'
          },
          {
            info: '1.BitCherry will only announce authorization, promotion, and other collaborations through the official social media platform. Developers and users should check carefully to avoid losses caused by false information. ',
            img: '',
            strong: '0'
          },
          {
            info: '2.All users and developers can participate in the test environment and subsequent stages of BitCherry Chain for free, and there is no charging fee. ',
            img: '',
            strong: '0'
          },
          {
            info: '3.All users must distinguish the test environment from BitCherry Mainnet. The assets generated in the test environment do not have any value. Be aware of counterfeit currency fraud. ',
            img: '',
            strong: '0'
          },
          {
            info: '4.The official website is（ www.bitcherry.io ）. Please be cautious with private key phishing. ',
            img: '',
            strong: '0'
          },
          {
            info: '5. All rights of the event are reserved by BitCherry.  ',
            img: '',
            strong: '0'
          },
          {
            info: 'Official Website: http://bit.ly/BitCherryOfficialWebsite  ',
            img: '',
            strong: '0'
          },
          {
            info: 'Twitter: http://bit.ly/BitCherryTwitter  ',
            img: '',
            strong: '0'
          },
          {
            info: 'Telegram: http://bit.ly/BitCherryTelegram  ',
            img: '',
            strong: '0'
          },
          {
            info: 'Discord: http://bit.ly/BitCherryDiscord  ',
            img: '',
            strong: '0'
          },




        ]
      },
      {
        img: require('../assets/img/media/1224001en.png'),
        title: "BitCherry launched the closed beta test version of Network v1.0.1",
        time: '2020.12.24',
        infos: [
          // {
          //   info: ' ',
          //   img: require('../assets/img/media/0704001.png'),
          //   strong: '0'
          // },
          {
            info: 'Good Day! Members of BitCherry Community,',
            img: '',
            strong: '0'
          },
          {
            info: "2020 December xx, BitCherry formally launched a closed beta test version v1.0.1 network. BitCherry is the world's first commercial, scalable blockchain infrastructure based on IPv8 technology. It was committed to building commercial thoroughfare blockchain technology and social links' reality and helps traditional enterprises construct a trusted distributed business environment. BitCherry has been focused on defining and critical system components to provide the most basic, the prior art by improving the blockchain's pain points, can build services in all types of commercial, high-performance, high-security, high availability, and expandable IPv8 Infrastructure platform.",
            img: '',
            strong: '0'
          },
          {
            info: 'The testnet of BitCherry v1.0.1 version of the official closed beta, marking BitCherry network infrastructure has begun to take shape, will develop BitCherry entered a new stage, which is an important milestone in the transition to the main network, we will continuously improve and enrich the technical content, to create a blockchain infrastructure that can fully support all kinds of businesses.',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry v1.0.1 Testnet Function',
            img: '',
            strong: '5'
          },
          {
            info: 'Using a composite account model of main account + sub-account',
            img: '',
            strong: '5'
          },
          {
            info: 'Each sub-account can be independently configured to transfer funds, execute smart contracts, and other permissions to fully protect account security. The account address generation complies with the BIP44 standard, supports seamless conversion of Ethereum addresses into internal system addresses, and reduces developers\' barriers to use.',
            img: '',
            strong: '0'
          },


          {
            info: 'Execution of Transactions',
            img: '',
            strong: '5'
          },
          {
            info: 'You can create an account and use the test currency for basic transactions and token transfers. In addition to the leading currency, each account can also have built-in ERC20 standard assets (sub-currency), such as UDST/ETH/BTC/TRX/EOS, etc., without writing and deploying smart contracts and saving the loss of virtual machine calls. Higher performance while supporting ultra-low fee transfer.',
            img: '',
            strong: '0'
          },
          {
            info: 'Smart Contract',
            img: '',
            strong: '5'
          },
          {
            info: 'The EVM virtual machine provides a secure execution link for the smart contract bytecode and supports the Solidity language to develop smart contracts. Phase 2 of the virtual machine WASM, and supports other advanced programming languages, Go / Rust / C / C ++ / JS / TS, etc., will also support Ethernet Square interfaces. Developers can easily migrate contracts to BitCherry test online, achieving seamless migration.',
            img: '',
            strong: '0'
          },
          {
            info: 'Data Structure ',
            img: '',
            strong: '5'
          },
          {
            info: 'Based on the hash relationship graph HashRelationshipSpectrum as the data structure, the underlying consensus mechanism is asynchronous ABFT to achieve high-efficiency transactions.',
            img: '',
            strong: '0'
          },
          {
            info: 'Explorer ',
            img: '',
            strong: '5'
          },
          {
            info: 'Provide comparable Etherscan blocks browser BitCherryScan, intuitive visual way to show the blockchain\'s operation, and supports query of blocks, transactions, accounts, and smart contracts.',
            img: '',
            strong: '0'
          },
          {
            info: 'Wallet',
            img: '',
            strong: '5'
          },
          {
            info: 'The wallet provides browser plug BitCherryWalletConnect, supports wallet functionality to call directly by JS through web pages for transaction signature, transfers, and smart contract call instructions.',
            img: '',
            strong: '0'
          },
          {
            info: 'Mining based on PoUc consensus',
            img: '',
            strong: '5'
          },
          {
            info: 'Based on the PoUc incentive system, it also has the functions of proxy voting and mortgage assets to obtain corresponding mining rewards. The block reward mechanism is equipped with an automatic adjustment algorithm to encourage more users to participate in mining. (Mining, mortgage, and voting functions will be opened in the second phase of the testnet)',
            img: '',
            strong: '0'
          },
          {
            info: 'More reasonable architecture system',
            img: '',
            strong: '5'
          },
          {
            info: 'It adopts the loosely coupled architecture of the bottom-level pluggable consensus module + the upper-level application layer. It extends the general ABCI (Application BlockChain Interface) interface to achieve a more vital consensus function.',
            img: '',
            strong: '0'
          },
          {
            info: 'Complete developer tools',
            img: '',
            strong: '5'
          },
          {
            info: 'It provides developers with BitCherryTools, enabling developers to quickly write, deploy, run, test, debug smart contracts. ',
            img: '',
            strong: '0'
          },
          {
            info: 'We also provide developers with basic usage documents for the community to refer to. These include:',
            img: '',
            strong: '0'
          },
          {
            info: '1. How to create a BCWallet wallet address and send the corresponding transaction;',
            img: '',
            strong: '0'
          },
          {
            info: '2. How to use IDE to create contracts, publishing contracts, and execute contracts. ',
            img: '',
            strong: '0'
          },
          {
            info: 'Before BitCherry mainnet is formally launched, we will conduct a multi-stage iterative network test update delivery function by a fixed stage, implement a create / node election, the electoral consensus/verification, and cross-chain interaction and complete the network economy peripheral tools, perfect developer system, etc.',
            img: '',
            strong: '0'
          },
          {
            info: 'Through our subsequent developer tasks, developers can be personally involved in BitCherry network test and win up to 10 million BCHC. We will also encourage all blockchain enthusiasts and developers to participate in the test and bring us more valuable opinions. If you have any suggestions, can communicate via e-mail or GitHub issue with BitCherry technical team.',
            img: '',
            strong: '0'
          },
          {
            info: 'GitHub issue：https://github.com/BitcherryFoundation/blockchain/issues',
            img: '',
            strong: '0'
          },
          {
            info: 'Discord: https://discord.gg/nNY9A9tn2D',
            img: '',
            strong: '0'
          },
          {
            info: ' ',
            img: require('../assets/img/media/1224002en.jpg'),
            strong: '0'
          },




        ]
      },
      {
        img: require('../assets/img/media/0708001en.png'),
        title: "Reminder about the fraudulent activities of someone who misappropriated the name of BCHC and misrepresented IPFS",
        time: '2020.07.08',
        infos: [
          // {
          //   info: ' ',
          //   img: require('../assets/img/media/0704001.png'),
          //   strong: '0'
          // },
          {
            info: 'Recently BitCherry received a feedback from our community users, there were some frauds that pretended to be a BitCherry customer service who posted a scam a publish event information "on BCHC & IPFS mining assets package", allowing users to save BCHC and USDT.',
            img: '',
            strong: '0'
          },
          {
            info: 'For this issue, BitCherry official hereby certify that: BitCherry with IPFS do not plan to have any form of activity. For criminals who spoil the name of BCHC; BitChery strongly condemns these fraud activities and has the right to pursue its legal responsibilities.',
            img: '',
            strong: '0'
          },
          {
            info: 'Once again we want to remind the BitCherry community members, please set a good awareness of security involving unscrupulous personnel requirements for BCHC and USDT transfers, must be cautious, all activity announcements must prevail to official sources, we hope this announcement will help you to protect your privacy and assets.',
            img: '',
            strong: '0'
          },





        ]
      },
      {
        img: require('../assets/img/media/0704001en.png'),
        title: "BitCherry successfully landed on the world's largest market website CoinMarketCap (CMC)",
        time: '2020.07.04',
        infos: [
          // {
          //   info: ' ',
          //   img: require('../assets/img/media/0704001.png'),
          //   strong: '0'
          // },
          {
            info: 'On July 4rd, the world’s first commercial infrastructure based on IPv8 technical technology BitCherry was officially listed on CoinMarketCap. In its first year of establishment , BitCherry’s  global user community members has reached  1 million, through its digital line card BCHC turnover exceeded 10 billion, BitCherry business concept has also been distributed all over the world including the United States , Russia, China, Britain, Middle East, France and more than 10 countries. Meanwhile, BitCherry was mentioned in CoinGecko, Neironix, Coincodex, CryptoCompare, Coindar, WorldCoinIndex and other global digital asset transaction information search websites.',
            img: '',
            strong: '0'
          },
          {
            info: 'Founded in 2013, CoinMarketCap (CMC) has become one of the largest digital asset transaction information query websites. The platform does not only aggregate news alerts and report analysis, but only focuses on the data itself. CoinMarketCap is committed to provide users real information on each asset, making it easier to understand the digital assets efficiency and convenience. Currently, CoinMarketCap has collected more than 2,600 digital currencies with more than 260 exchange information it has 65 million unique monthly visits.',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry’s listing on CoinMarketCap, means that in technology, community, ecology and other aspects of the global market BitCherry has gained the most authoritative recognition. Facing the global diversification of business needs, BitCherry is continuing to upgrade its technology with new finance, new industries and innovative enterprises. BitCherry continues to use the diversified development strategy of "globalization + commercial + ecology" , using the "block chain + Innovative means of "distributed business"  for the world\'s most commercially valuable public chain platform, create a distributed business ecosystem for global business users, and truly achieve a "blockchain+" that can be implemented to serve the innovation of global business.',
            img: '',
            strong: '0'
          },

          {
            info: 'At the same time, BitCherry’s strong(soft) power and influence in the global market has long been underestimated.  BitCherry thinks the industry needs to be open enough for innovation, equality, freedom ecological, collaboration, and those aspects, BitCherry will continue to use technology and brand strength, high-performance, high-security chain on distributed business applications. BitCherry will provide high availability of the underlying technical support, and will provide customers around the world with safe, professional, efficient, convenient and reliable service chain, BitCherry block chain is becoming the most trusted business ecosystem infrastructure platform through their own efforts',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/0618001en.png'),
        title: "BCHC (BitCherry’s project token) officially listed on BTEX-The Leading Cryptocurrency Contract Exchange",
        time: '2020.06.18',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/0618001en.png'),
            strong: '0'
          },
          {
            info: 'On June 18th,  2020 BCHC (BitCherry’s project token) has officially listed on BTEX- The Leading Cryptocurrency Contract Exchange while allowing the users to deposit, and will open BCHC / USDT trading pair at 11:00 (UTC+8) on June 19th. As the world-class commercial public chain platform, BitCherry has built a ‘P2Plus’ Encryption Protocol with a new thinking of innovative physical layer, provides the operational mechanism such as smart contract & cross-chain consensus to achieve highly scalable data architecture by hash map which is improved by the relationship atlas. This blockchain infrastructure greatly reduces development costs.',
            img: '',
            strong: '0'
          },
          {
            info: 'BTEX Exchange is a well-known digital asset trading platform established in 2017. It has more than 1 million registered trading users from all over the world. It currently supports 9 languages. The company members are all in the industry\'s top exchanges and major financial institutions.  BTEX will use its rich industry resources, excellent technical researches to develop its team and to establish itself as a leader in the industry and will lead the new trend of global digital asset trading platforms.',
            img: '',
            strong: '0'
          },
          {
            info: 'The listing on BTEX will further accelerate the rhythm of BitCherry development and promote the global industry blockchain application landing. As the world\'s first commercial scalable blockchain infrastructure based on IPv8 technology, BitCherry has committed to creating a business network that links blockchain technology with society, helping traditional enterprises build a trusted distributed business ecosystem, and fields which achieves high-speed circulation of ecological value.',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabgen0613001.png'),
        title: "Announcement on BitCherry Foundation and BitTok Company’s important strategic cooperation",
        time: '2020.06.16',
        infos: [

          {
            info: 'According to the relevant provisions of Management Charter of BitCherry Foundation, the foundation discussions considered and decided to reach deep strategic cooperation with BitTok exchange.  Both parties will develop their cooperation in three dimensions: blockchain application, digital asset transaction, and public chain technology. All of these dimensions will establish a feasible solution for the future digitization of crypto assets.',
            img: '',
            strong: '0'
          },
          {
            info: ' BitCherry has passed the path from landing mode to practice paradigm. Since its establishment,  BitCherry always was trying to satisfy every commercial entity from the technical architecture of commercial. Combined with the independently developed IPv8 technology, sharing application, cross-chain protocol technology, and turing complete technology, BitCherry has successfully achieved breakthrough on high TPS, providing a feasible theoretical and practical basis for landing applications. In the future, BitCherry will continue to fulfill the "three major strategies", actively promote the use of scenarios and find more commercial uses, to create a complete ecosystem that contains technical, community, business and many elements. BitCherry’s overall goal is to achieve value of BlockChain Plus, will build blockchain based underlying infrastructure platform for the next generation, and will provide reliable and practical tool for the real economy as well as industrial applications. ',
            img: '',
            strong: '0'
          },
          {
            info: 'In this cooperation, both parties will use their respective resource advantages to create greater value for all BCHC holders worldwide. For detailed cooperation rules, please refer to the official announcement of BitTok.',
            img: '',
            strong: '0'
          },


          {
            info: 'BitCherry Operation Team',
            img: '',
            strong: '0'
          },


          {
            info: 'June 16th, 2020.',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/0616001en.png'),
        title: "BCHC List On Eastern Europe Largest Cryptocurrency Exchange - Coinsbit",
        time: '2020.06.16',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/0616001en.png'),
            strong: '0'
          },
          {
            info: 'According to the official news, on June 16th, at 20:00 (UTC+8), BCHC (BitCherry’s project token) officially listed on Coinsbit - The Eastern Europe Largest Cryptocurrency Exchange, while allowing the users to deposit, and will open BCHC / USDT trading pair on June 17th, at 20:00 (UTC+8).',
            img: '',
            strong: '0'
          },
          {
            info: 'As the world\'s first commercial scalable blockchain infrastructure based on IPv8 technology of high performance, high security and consensus governance for blockchain business applications, and committed to creating a business network that links blockchain technology with society, helping traditional enterprises build a trusted distributed business ecosystem, and fields which achieves high-speed circulation of ecological value. Since BitCherry’s Project Token- BCHC list on UPEX, Poloniex Exchanges, BitCherry has received numerous reports of international media. In this time, Listing to the largest trading platform in Eastern Europe - Coinsbit in a short time, its globalization landscape to a new level.',
            img: '',
            strong: '0'
          },
          {
            info: 'Coinsbit as the largest cryptocurrency exchange in Eastern Europe, with 2.2 million registered users from 200+ countries. Coinsbit has accepting Visa/MasterCard for deposit and withdraw funds and supports 14 languages. According to CoinMarketCap Coinsbit is Top 20 crypto exchange by adjusted volume with 24h trading volume of more than $800 million USD. ',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabgen0613001.png'),
        title: "Announcement on issues related to WBF launching BCHC",
        time: '2020.06.13',
        infos: [

          {
            info: 'Recently, BitCherry Foundation received from community feedback, WBF Exchange issued a notice on launching a BCHC, hereby to certify that, until now, we have not had any form of communication and business cooperation with all of WBF, the listing behavior is their spontaneous behavior.',
            img: '',
            strong: '0'
          },
          {
            info: ' Starting from this year, BitCherry has been conducted its market strategy in the Middle East, European and American markets. The main direction of the global community in these countries is to gradually build a consensus force and a steadily market. Recent BitCherry Foundation BCHC was listed on the Middle East well-known online trading platform UPEX and on the well-known US legendary trading platform Poloniex. For online exchange issues, BitCherry Foundation insists on excellence solutions, and keeping the principle of cautious, so we have conducted the relevant exchange detailed market research, that should be eligible with the principles of BitCherry market strategy.',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry Foundation cannot avoid spontaneous listing behavior on third parties, we would like to remind BitCherry community users about the problems BitCherry is facing, please refer to our official announcement mainly, but also be careful not to go through the exchange platforms that were not confirmed with the official announcement, to avoid unnecessary loss of assets.',
            img: '',
            strong: '0'
          },
          {
            info: 'In the end, we would like to thank you for your continued attention and support of BitCherry.',
            img: '',
            strong: '0'
          },

          {
            info: '13th June 2020',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabgen0611001.png'),
        title: "BCHC (BitCherry’s project token) officially listed on Poloniex- The Legendary Crypto Asset Exchange",
        time: '2020.06.11',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/mediabgen0611001.png'),
            strong: '0'
          },
          {
            info: 'on June 11th, BCHC (BitCherry’s project token) officially listed on Poloniex- The Legendary Crypto Asset Exchange, and will open BCHC / USDT trading pair at 10:00 (Singapore time). Poloniex was established in 2014, the leading global digital currency trading platform in the United States. The transaction volume once reached the top 3 in the world, Poloniex was acquired by Circle Company. Circle Company was investing by Goldman Sachs, Baidu, Bitmain, and get the investment from TRON Foundation. Its vision is to restructure the global trust system, providing opportunities for Polonauts across the globe.',
            img: '',
            strong: '0'
          },
          {
            info: 'As the world\'s first commercial scalable blockchain infrastructure based on IPv8 technology, BitCherry have put forward a set of practical solutions which delicate to solve problems what the current public chain facing: inefficient technical performance, serious congestion problems on the main chain, high development threshold, and single token usage scenario, etc. BitCherry proposed to use "Blockchain Plus" to construct a high trusted distributed business environment for enterprises to create more easy-to-use general block chain infrastructure, and to provide efficient Blockchain technology solutions.',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry has actively develop their global market, continued to deep promote in more than 20 countries worldwide. After BCHC’s listing on the Leading Cryptocurrency Exchange in the MENA-UPEX, BitCherry has not only deepen their marketing layout in Middle East, but also reported and gave highly praised by renowned block chain medias. Since BitCherry project token (BCHC) list on Poloniex, it will strengthen its strategic layout of the global market, build a business model and a highly trusted and trusted distributed business ecosystem, to achieves high-speed circulation of ecological value and expands the business closed loop.',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabgen0529001.png'),
        title: "100,000 BCHC Grand Prize Waiting For You! Airdrop and Net Buying Contest Is Start Now!",
        time: '2020.05.29',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/mediabgen0529001.png'),
            strong: '0'
          },
          {
            info: 'Celebrate the launch of BCHC UPEX, 100,000 BCHC gifts are waiting for you',
            img: '',
            strong: '0'
          },
          {
            info: 'Event 1. Event airdrop, 10,000 BCHC airdrop first come first serve!',
            img: '',
            strong: '1'
          },
          {
            info: 'Activity time: 2020/5/28 18:00–2020/6/4 18:00',
            img: '',
            strong: '0'
          },
          {
            info: 'Participation method: Register for UPEX and real-name authentication, and share the event poster to 2 Telegram communities to participate in 10,000 BCHC.',
            img: '',
            strong: '0'
          },
          {
            info: 'After sharing, send the screenshot to UPEX customer service telegram: @salahghrabei',
            img: '',
            strong: '0'
          },
          {
            info: 'Event 2. Net Buying Contest',
            img: '',
            strong: '1'
          },

          {
            info: 'Activity time: 2020/5/28 18:00–2020/6/4 18:00',
            img: '',
            strong: '0'
          },

          {
            info: 'Activities: Users who have a net purchase volume (net purchase volume = purchase volume-sales volume-withdrawal volume) greater than 5000 BCHC during the event can participate in the contest.',
            img: '',
            strong: '0'
          },

          {
            info: 'The top 30 users of net purchases can get the following rewards:',
            img: '',
            strong: '0'
          },

          {
            info: 'The first user with a net purchase volume, an additional reward of 20,000 BCHC',
            img: '',
            strong: '0'
          },

          {
            info: 'Users with 2 to 10 net purchases will receive an additional 4000 BCHC',
            img: '',
            strong: '0'
          },

          {
            info: 'Users with 11 to 20 net purchases will be rewarded with an additional 2,000 BCHC',
            img: '',
            strong: '0'
          },

          {
            info: 'Users with 21 to 30 net purchases will be rewarded with an additional 1,000 BCHC',
            img: '',
            strong: '0'
          },





        ]
      },
      {
        img: require('../assets/img/media/0527001en.png'),
        title: "BCHC (BitCherry project token) officially listed on the Middle East's largest digital asset trading platform UPEX",
        time: '2020.05.27',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/0527001en.png'),
            strong: '0'
          },
          {
            info: '2020, On May 27th, BCHC (BitCherry’s project token) officially listed on UPEX- the Leading Cryptocurrency Exchange in the MENA, and will open BCHC / USDT trading pair at 18:00 (Singapore time) on May 28th. As the Middle East’s largest digital currency trading platform, UPEX has obtained legal license issued by the Bahraini government. It supports the legal trading of US dollars under the supervision of the local central bank, and supports several fiat currencies from countries like: Saudi Arabia, Bahrain, and the UAE. And besides that its average daily transaction volume has exceeds three hundred million dollars. UPEX\'s uniquely and localized operations has satisfied the needs of users in the Middle East,  at the same time it provides Arabic language support to Middle East users and supports spot trading services for multinational fiat currencies and multiple digital currencies.',
            img: '',
            strong: '0'
          },
          {
            info: 'The Middle East is well-known to the world for its vast reserves of crude oil. In recent years, the governments of the Middle East have been looking for new technologies to optimize the oil economy. Government and companies in the Middle East have shown active support for the introduction of blockchain technology.  ',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry always uphold open attitude to top block chain project, creating new business structure. Now BCHC’s listing on UPEX will help the value of BCHC to be recognized in the Middle East market. With the support of BitCherry ecosystem and credit system, the rights and interests of investors in the Middle East will also be greatly protected.',
            img: '',
            strong: '0'
          },
          {
            info: 'In the near future, BitCherry will build deeper cooperation with companies to help them achieve blockchain transformation, and will actively expand the business to help companies build more influential distributed commercial architecture.  We believe that with the support of the blockchain business network, the economy of the Middle East will surely complete economical iteration and transformation.',
            img: '',
            strong: '0'
          },
          {
            info: 'As pioneer of strategic layout in Middle East marketing, BitCherry will keep focus on Middle East marketing, establish deep cooperation with more local enterprises and jointly promote block chain technology development, broaden business scenario, to create a new blockchain business ecology, I believe with the help of blockchain business network which BitCherry built, Middle East economy will have a better and complete strategic iteration, transformation and upgrading.',
            img: '',
            strong: '0'
          },
          {
            info: '',
            img: '',
            strong: '0'
          },





        ]
      },
      {
        img: require('../assets/img/media/mediabgen0505003.png'),
        title: "An Open Letter for BitCherry Community-Message from BitCherry CEO on One Year Anniversary",
        time: '2020.05.05',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/mediabgen0505003.png'),
            strong: '0'
          },
          {
            info: 'Time flies like arrow! After nearly a year of development and high efficient technical upgrading, from the past distributed e-commerce blockchain BitCherry has evolved into the world\'s first commercial scalable blockchain infrastructure based on IPv8 technology.',
            img: '',
            strong: '0'
          },
          {
            info: 'Look back at what we\'ve achieved, the milestones we reached over the past 12 months, BitCherry has grown into a mature team. As of today, we have successfully held more than 100 community events, attracted more than 200 community partners to join us, have signed strategic cooperation agreements with four countries, and participated in more than 10 international conferences. The concept of distributed business has spread to more than 10 countries including: the United States, Turkey, Dubai, Indonesia, and Vietnam… We never stop.',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry team has always been a constant to seek steady during progress, from the publish of first edition of project White Paper, to now technical upgrade are on schedule as planned. Thanks to this, BitCherry also gain amount of loyal fans, more than 1 million community users are now all witnessing our growth. Although 2019 the market fluctuate obviously, but under this environment, BitCherry do everything we want to do, in my opinion this is not enough, if we talk block chain as a chronicle, then 2019 is just the first chapter of BitCherry.',
            img: '',
            strong: '0'
          },
          {
            info: 'Where is the future of blockchain?',
            img: '',
            strong: '0'
          },
          {
            info: 'As mentioned above, in the past few years, the blockchain industry took the world by storm, and survived between the troughs, big waves will eventually seek sand, and a project that combines technology, business, and ecological improvement must survive. It is only a matter of time as ‘good money drives out bad’ as of the public\'s blockchain awareness increases.',
            img: '',
            strong: '0'
          },
          {
            info: 'As far as the concerned companies like FaceBook, JPMorgan, Microsoft, and IBM already being transformed by blockchain, all these proves the the feasibility and necessity of decentralized technology and distributed business model, it also means that this technological revolution is quietly changing our lives.',
            img: '',
            strong: '0'
          },
          {
            info: 'Returning to the theme, from the brutal development of blockchain projects a few years ago to current compliant deployment, "survival" has become history, and the mission of "steady development" has gradually become the mainstream. There are indeed some refreshing leaders industry.',
            img: '',
            strong: '0'
          },
          {
            info: 'Technical Direction of BitCherry',
            img: '',
            strong: '0'
          },
          {
            info: 'Yes, BitCherry is one such distributed project at industry forefront.As you can see, in the past 12 months, BitCherry made several technical adjustments and optimizations, now we are running on this public chain track with a whole new attitude. Perhaps in your eyes, stepping into this public chain river may not be the right choice, but in my opinion, the opportunities and potential of the public chain far beyond our imagination.',
            img: '',
            strong: '0'
          },
          {
            info: 'As we all know, the operation of network applications is inseparable from the basic network architecture, but in the current public chain field, a public chain that can truly support large-scale network applications has not been developed yet. Concept and implementation are always two completely different things, which explains why we have moved our perspective to the public chain when we have achieved little success in the field of distributed e-commerce.',
            img: '',
            strong: '0'
          },
          {
            info: 'Then now, I will make a statement about what are we and where we are.',
            img: '',
            strong: '0'
          },
          {
            info: 'In theory, our technical team accumulated many years of engineering experience and proposed Blockchain Plus for the first time. Which aims to change the current public chain\'s scalability, insufficient performance, unreasonable governance structure, and low security through executable technical means. First of all, on the network protocol, we put forward P2Plus. In short, it is to shift the network protocol to the lower layer IP network layer and data link layer to ensure the reachability and security of p2p data transmission. Secondly, in terms of data structure, we adopted HashRelationshipSpectrum technology, which made the consensus layer algorithm and the underlying P2P algorithm highly consistent, and brought high-speed network connected efficiently and ledger consensus high secure. In view of the governance structure, we designed the proof of user contribution, and conducted a virtual vote according user\'s behavior-PoUc. The selected nodes get bookkeeping rewards, also allowing token holders have the right to participate in project development and on-chain governance to improve node efficiency. On data expansion, BitCherry has integrated the concept of sharding and side chain to improve the performance of Dapp.',
            img: '',
            strong: '0'
          },
          {
            info: 'Some of our development plans after this technical upgrade are as following:',
            img: '',
            strong: '0'
          },
          {
            info: 'Technical implementation and testing: Besides expand our business ecosystem, we will perfect each and every major technology mission in accordance with our official technical roadmap and complete all key steps in a controlled environment to ensure the stability and security of the entire system. In addition, after each technical mission completed, we will test simultaneously real-time.',
            img: '',
            strong: '0'
          },
          {
            info: 'Technical Community Construction: The project code is synchronized on GitHub, will open source in real time, and technical team will also introduced to public at the same time. We’ll build developer community, recruit talented individual developers to work with us. We will set up a reasonable developer incentive plan to promote our Technical progress.',
            img: '',
            strong: '0'
          },
          {
            info: 'Governance Structure Planning: We cherish every user, whether node operator or an individual token holder. With the continuous improvement of technology and effective verification, we will gradually disclose our economic governance structure, and publicly vote on the basis of long-term network stability, security, and consistency of complete ecosystem.',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry Business Perspective after Update',
            img: '',
            strong: '0'
          },
          {
            info: 'Expanding distributed business landscape: For traditional businesses, we will actively develop application scenarios, create more convenient and easy-to-use universal blockchain infrastructure for enterprises, and provide them more efficient and convenient blockchain technical solutions. Next step, we will continue to enhance BitCherry’s positive influence in the global market, by looking for the head of businesses, government agencies endorsement. At present, we have partners in the United States, Dubai, Turkey, Indonesia, Vietnam and other places. In the future, we will continue to keep our right position and keep explore.',
            img: '',
            strong: '0'
          },
          {
            info: 'Deeply empower traditional enterprises: Since project launch, we have been looking for suitable opportunities to educate enterprises and explore cooperation which involved supply chain finance, e-commerce, and basic energy companies. In the next step, we will establish deeper cooperation with our partners to help them to finish blockchain transformation. In addition, we will actively carry out business development to help more influential companies to build distributed business architecture.',
            img: '',
            strong: '0'
          },
          {
            info: 'Improve Overall Community: besides business and technology, community is always a key point of a project and an important part of a project ecology. Next, we will pay more attention to the output content quality in the community. Now one of our plan is to build developer community to help fans know us through different dimensions. We will actively search cooperate with major educational institutions and organizations through seminars, offline meetup, etc.',
            img: '',
            strong: '0'
          },
          {
            info: 'What’s more?',
            img: '',
            strong: '0'
          },
          {
            info: 'Reverse has never been an easy task, and"next step" is always unknown, but trying and exploring is a good habit, opportunities and future are always hidden behind the unknown. Therefore, on the next journey, we are inviting you to stay with us to meet surprise in ‘unknown’, and we are inviting you witness with us, witness the future growth of BitCherry.',
            img: '',
            strong: '0'
          },
          {
            info: 'Kind thanks!    ',
            img: '',
            strong: '0'
          },




        ]
      },
      {
        img: require('../assets/img/media/mediabgen0505002.png'),
        title: "BitCherry X BitTok 1st Anniversary Event，Net Purchase BCHC Get Free USDT",
        time: '2020.05.05',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/mediabgen0505002.png'),
            strong: '0'
          },
          {
            info: '2020 May 5, BitCherry now release: BitCherry ONE YEAR anniversary campaign, and today BitCherry officially release new version of White Paper -‘BitCherry-- The world\'s first commercial scalable blockchain infrastructure based on IPv8 technology White Paper v2.0.1.’',
            img: '',
            strong: '0'
          },
          {
            info: 'Today, BitCherry launch its anniversary event with BitTok during 00: 00 May 5 - 24:00 (GMT + 8) May 11 2020.You will get your USDT rewards by trading and holding a certain amount of BCHC.',
            img: '',
            strong: '0'
          },
          {
            info: 'Application Time:',
            img: '',
            strong: '1'
          },
          {
            info: '0: 00 May 5 - 24:00 (GMT + 8) May 11 2020',
            img: '',
            strong: '0'
          },
          {
            info: 'Event Duration:',
            img: '',
            strong: '1'
          },
          {
            info: '0: 00 May 5 - 24:00 (GMT + 8) May 17 2020',
            img: '',
            strong: '0'
          },
          {
            info: 'Rules:',
            img: '',
            strong: '1'
          },
          {
            info: 'Within the "Application time”, a net purchase of BCHC-greater than or equal to 10,000 and holding for more than 7 consecutive days users will receive 50 USDT rewards. The rewards will be distributed within 3 working days after the expiration of holding in your wallet.',
            img: '',
            strong: '0'
          },
          {
            info: 'Tips:',
            img: '',
            strong: '1'
          },
          {
            info: '1. Net Purchase= buying-selling;',
            img: '',
            strong: '0'
          },
          {
            info: '2. BitTok will take a random snapshot of the user\'s account between 00:00 and 03:00 every day, to calculate if the net purchase amount is greater than or equal to 10,000 BCHC, to determine whether holding meets the standard or not;',
            img: '',
            strong: '0'
          },
          {
            info: '3. If 1 day in 7 does not meet the standard during holding period, the accumulated days of holding will be reset to zero. Complete to 10,000, holding days calculate restart;',
            img: '',
            strong: '0'
          },
          {
            info: '4. The number of BCHC held in users’ account before 24:00 (GMT + 8) May 4, 2020 will not take into account in the net purchase of this event;',
            img: '',
            strong: '0'
          },
          {
            info: '5. Users can choose to participate on any day during the event, but only if finish application procedure and holding for 7 consecutive days can receive the reward.',
            img: '',
            strong: '0'
          },




        ]
      },
      {
        img: require('../assets/img/media/mediabgen0505.png'),
        title: "《BitCherry- the world's first commercial scalable blockchain infrastructure based on IPV8 technology White Paper v2.0.1》official release",
        time: '2020.05.05',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/mediabgen0505.png'),
            strong: '0'
          },

          {
            info: '2020,May 5th, BitCherry has officially released ‘BitCherry-the world\'s first commercial scalable blockchain infrastructure based on IPV8 technology White Paper v2.0.1’ it is another major technology update after BitCherry set foot in E-commerce.   ',
            img: '',
            strong: '0'
          },
          {
            info: 'The development of Internet has went through decades of EVT(engineering validation test), from this perspective, blockchain technology today is only the beginning of commercialization progress. We are expecting a blockchain infrastructure that can achieve real commercial landing.  ',
            img: '',
            strong: '0'
          },

          {
            info: 'In this white paper, we will introduce you a valued public chain that is beneficial for future distributed business. BitCherry’s updated technology ecology including: P2Plus encryption protocol (Internet Protocol), the hash map-HashRelationshipSpectrum (data structure), aBFT + PoUc (consensus mechanism), sharding technology, side chains and cross-chain protocols. We believe through this revolutionary upgrade BitCherry will greatly promote the commercial value of future society, and also promote further influence by blockchain technology in commercial society. ',
            img: '',
            strong: '0'
          },
          {
            info: 'In the future, with the continuous improvement of technology, as the world\'s first commercial scalable blockchain infrastructure based on IPV8 technology, BitCherry will provide  provides a high-performance, high-secure, and high-available underlying public chain support for distributed commerce on the chain. The service will also involve supply chain finance, asset digitization, commercial consumption, e-commerce, distributed cloud computing and more business areas.',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabgen0504.png'),
        title: "ONE YEAR | Remain True to Our Original Aspiration-Introduction of Distributed Commercial BlockChain",
        time: '2020.05.04',
        infos: [
          {
            info: ' ',
            img: require('../assets/img/media/mediabgen0504.png'),
            strong: '0'
          },

          {
            info: 'Good News, 5th May 2020 is the first anniversary of BitCherry, :hash:BitCherry will Launch live broadcast event on BitCherry English Community, BitCherry CEO Paul as the live guests. . In the last 10 minutes of event, you can ask questions about BitCherry to Paul，Paul will arbitrarily selected five to answer, selected participats each will receive 50BCHC reward. :+1:                    ',
            img: '',
            strong: '0'
          },
          {
            info: ' How to Enter: Join community t.me/BitCherryEnglish',
            img: '',
            strong: '0'
          },

          {
            info: ' Live theme: ‘ONE YEAR live broadcast, Remain True to Our Original Aspiration-Introduction of Distributed Commercial Blockchain’ ',
            img: '',
            strong: '0'
          },
          {
            info: ' Time: Thursday, May 5, 2020 at 8:00 pm(UTC+8)',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabgen38.png'),
        title: "BitCherry April Fool‘s Day Giveaway Event，5000BCHC are waiting for you！",
        time: '2020.04.01',
        infos: [
          {
            info: ' Great News, BitCherry April Fool‘s Day Giveaway Event is being conducted from April 1 - 7！5000BCHC are waiting for you！',
            img: '',
            strong: '1'
          },
          {
            info: 'https://twitter.com/bitcherryglobal/status/1245022465404751874?s=21',
            img: '',
            strong: '0'
          },
          {
            info: 'Each will get 5BCHC. There will be 10 lucky winners when participants quantity reaches 500.',
            img: '',
            strong: '1'
          },
          {
            info: 'Top1: 500个BCHC',
            img: '',
            strong: '0'
          },
          {
            info: 'Top2: 300个BCHC',
            img: '',
            strong: '0'
          },
          {
            info: 'Top3: 200个BCHC',
            img: '',
            strong: '0'
          },
          {
            info: 'Top4~10: 100个BCHC',
            img: '',
            strong: '0'
          },
          {
            info: 'How to Enter：',
            img: '',
            strong: '1'
          },
          {
            info: ' 1，Follow BitCherry Twitter @BitCherryGlobal + Retweet',
            img: '',
            strong: '0'
          },
          {
            info: '2，Join Telegram：https://t.me/BitCherryGlobal',
            img: '',
            strong: '0'
          },
          {
            info: ' 3，Fill in the form: http://GiveawayEvent.mikecrm.com/LEx5hQH',
            img: '',
            strong: '0'
          },
          {
            info: 'As for Lucky Draw，you will have more chance to be selected as one of the 10 lucky winners if you finish the following optional steps.',
            img: '',
            strong: '1'
          },
          {
            info: '1，Comment and share your interesting story about Aptil Fool‘s Day.',
            img: '',
            strong: '1'
          },
          {
            info: '2，Tag your friends to get likes，the more likes you get，the more chance to be one of the lucky winners.',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabg35.png'),
        title: "BCHC officially launches TrustWallet, a well-known wallet in the Middle East",
        time: '2020.03.10',
        infos: [
          {
            info: ' BitCherry token BCHC has already passed the audit procedure of Trust Wallet, and officially online now. With its listing, BitCherry will accelerate strategic layout in Turkey and Middle East marketing, and will promote commercial process in Turkey and Middle East with blockchain technology.',
            img: '',
            strong: '0'
          },
          {
            info: 'Trust Wallet (trustwallet.com ) is a secure, open source, decentralized and anonymous Ethereum wallet application that supports Ethereum and over 20,000 different Ethereum based tokens (ERC20, ERC223 and ERC721), seeing significant user adoption since its launch in November 2017. The company has built a reputation for security and has held itself to the guiding principles that it will never access user wallets, hold private keys, and ask for personal information.',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabg34en.png'),
        title: "BitCherry's new upgrade to the world's first commercial scalable blockchain infrastructure based on IPv8 technology",
        time: '2020.02.20',
        infos: [
          {
            info: 'Dear BitCherry community followers: ',
            img: '',
            strong: '1'
          },
          {
            info: ' Trust is the foundation of the business community, in order to improve the level of trust in the community, weak liquidity and financial resource monopolies and other issues. Starting from February 20th 2020, based on business scenarios, BitCherry global consensus will fully optimized its technology. While upgrading the technology, it will build a blockchain infrastructure that can truly meet the needs of future business applications. The latest version of the technical white paper will be released in the near future.',
            img: '',
            strong: '0'
          },
          {
            info: 'After the upgrade, BitCherry will become the world\'s first commercial scalable blockchain infrastructure based on IPv8 technology. world\'s first commercial scalable blockchain infrastructure based on IPv8 technology. It will have performance efficiency, data security, and a more reasonable and comprehensive consensus governance mechanism. It will cover many business fields including supply chain finance, asset digitization, commercial consumption, e-commerce, and distributed cloud computing, providing a high-performance, high-secure, and high-available underlying public chain support for distributed commerce on the chain. ',
            img: '',
            strong: '0'
          },
          {
            info: 'With the overall technology upgrade, we have also conducted a comprehensive revision on BitCherry’s official website, new sectors will be added: new technical features and commercial application scenarios sectors.',
            img: '',
            strong: '0'
          },
          {
            info: 'Website: www.bitcherry.io ',
            img: '',
            strong: '0'
          },
          {
            info: ' Now, the revision version website has been on trial, and the website information is being improved. We are apologizing for the temporary inconvenient to browse. Hope to have your understanding.',
            img: '',
            strong: '0'
          },
          {
            info: 'In the end we want to thank all of you for consistently support to us. ',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry',
            img: '',
            strong: '1'
          },
          {
            info: '20th February 2020',
            img: '',
            strong: '1'
          },





        ]
      },
      {
        img: require('../assets/img/media/mediabg33.png'),
        title: "Blockchain Economy 2020 Tickets Giveaway Event",
        time: '2020.02.04',
        infos: [
          {
            info: ' In order to celebrate Valentine\'s Day, BitCherry decided to conduct free tickets giveaway event, participate in the event on Twitter/Facebook/Instagram/LinkedIn/Telegram and you will have opportunity to win the tickets of Blockchain Economy 2020 held in Istanbul! For event details, please follow us on Twitter/Facebook/Instagram/LinkedIn or join Telegram community!',
            img: '',
            strong: '0'
          },




        ]
      },
      {
        img: require('../assets/img/media/mediabg32.png'),
        title: "Global Recruitment of BitCherry Community Ambassadors",
        time: '2019.12.31',
        infos: [
          {
            info: ' Dear Members of BitCherry Communities:',
            img: '',
            strong: '0'
          },
          {
            info: 'The BitCherry project could not has been developed so far without the strong support and encouragement of community members. We believe that an outstanding community is one of the most important assisting factors in project development. BitCherry has started a strategic layout in the United States, Russia, South Korea, Vietnam, Singapore, the Philippines, Turkey and many other countries. In order to enable more community users with our consensus can participate in BitCherry\'s path to constructing together and share the value of development, we are now recruiting community ambassadors in communities around the world.',
            img: '',
            strong: '0'
          },
          {
            info: 'The BitCherry project, known as BITCHERRY FOUNDATION LTD., was founded in Singapore. It is a high-performance underlying network architecture based on distributed ledger technology, fitting the future commercial society. BitCherry is committed to creating a commercial link between BlockChain technology and the real world, creating a decentralized network that meets business needs by registering assets in chains and making them efficiently circulated. And integrating deeply with the thousand-billion-scale consumption scenarios, the network’s token-economic advantages can be used to link the global e-commerce operators and consumers, and then together create a free, safe, mutually-administrated, mutually-trusted, fair, and borderless e-commerce ecology, in which all values are freely circulated and messages are highly coupled. To expand the global reach of the BitCherry community, BitCherry has now officially launched the Recruitment Plan of Global Community Ambassadors!',
            img: '',
            strong: '0'
          },
          {
            info: 'In the BitCherry global community, we will pick you, the most energetic one from thousands candidates, to take charge of the Central ‘C’ position of the community\'s future development, to make continuous and positive contributions to the project, to help the growth of the technology community, and to build the best community more efficiently with BitCherry!',
            img: '',
            strong: '0'
          },
          {
            info: 'Requirement:',
            img: '',
            strong: '0'
          },
          {
            info: '	•	Identify with BitCherry\'s vision and values and understand BitCherry\'s core technologies and project progress;',
            img: '',
            strong: '0'
          },
          {
            info: '•	Be prepared, capable and motivated to deepen your understanding of BitCherry and expand its influence in the global BlockChain industry;',
            img: '',
            strong: '0'
          },

          {
            info: '•	Have a number of active community or self-media followers;',
            img: '',
            strong: '0'
          },
          {
            info: '•	Good at running Telegram, Facebook, WhatsApp and other communities;',
            img: '',
            strong: '0'
          },
          {
            info: '•	Have a certain understanding and thinking about the BlockChain industry;',
            img: '',
            strong: '0'
          },
          {
            info: '•	Make sure you are available and keeping active for at least one quarter before you apply (exit, missing, or failure to complete your job will not be unrewarded);',
            img: '',
            strong: '0'
          },
          {
            info: '•	Good English and experience in operating BlockChain or communities.',
            img: '',
            strong: '0'
          },
          {
            info: 'Responsibilities:',
            img: '',
            strong: '0'
          },
          {
            info: '•	Assist in the management of BitCherry\'s official telegram group (answering user questions, keeping an active atmosphere within the group, maintaining group chat contents, etc.);',
            img: '',
            strong: '0'
          },
          {
            info: '•	Create topics and participate in discussions within BitCherry\'s official telegram group;',
            img: '',
            strong: '0'
          },
          {
            info: '•	Construct flow ecology with BitCherry;',
            img: '',
            strong: '0'
          },
          {
            info: '•	Follow, forward, like and comment on BitCherry\'s social media materials and content;',
            img: '',
            strong: '0'
          },
          {
            info: '•	Share the progress, planning, news and other content of the BitCherry project to social media platforms and communities in time;',
            img: '',
            strong: '0'
          },
          {
            info: '•	Submit a monthly administrator work summary to the community and share your feelings and experiences.',
            img: '',
            strong: '0'
          },
          {
            info: 'Bonus advantages:',
            img: '',
            strong: '0'
          },
          {
            info: '•	Translate official BitCherry content into different languages and share them to the community;',
            img: '',
            strong: '0'
          },
          {
            info: '•	Produce original contents about BitCherry (articles, videos, stickers, etc.);',
            img: '',
            strong: '0'
          },
          {
            info: '•	Establish and manage multilingual online communities of 200-300 people (depending on the needs of specific community development);',
            img: '',
            strong: '0'
          },
          {
            info: '•	Provide valuable ideas and advice for BitCherry\'s global community development;',
            img: '',
            strong: '0'
          },
          {
            info: '•	Have access to abundant market resources, including local mainstream exchanges, well-known media platforms, and offline retail consumption scenario applying resources.',
            img: '',
            strong: '0'
          },
          {
            info: 'Rewards and benefits:',
            img: '',
            strong: '0'
          },

          {
            info: '•	Plenty of tokens and other rewards;',
            img: '',
            strong: '0'
          },
          {
            info: '•	BitCherry\'s exclusive gifts;',
            img: '',
            strong: '0'
          },
          {
            info: '•	Being the first to know the latest project information and technological progress;',
            img: '',
            strong: '0'
          },
          {
            info: '•	The opportunity to communicate online with BitCherry core technical members about technology;',
            img: '',
            strong: '0'
          },

          {
            info: '•	Opportunities to participate in BitCherry\'s global offline meetings with priority;',
            img: '',
            strong: '0'
          },

          {
            info: '•	More mystery benefits.',
            img: '',
            strong: '0'
          },
          {
            info: 'Application：http://fw3nuu919ozea7cr.mikecrm.com/GyEIAFz',
            img: '',
            strong: '0'
          },
          {
            info: 'Due to legal constraints, users in the following regions will not be able to participate in BitCherry Community Ambassador Plan this time:',
            img: '',
            strong: '0'
          },
          {
            info: 'China, The Balkans, Myanmar, C?te d\'Ivoire, Cuba, Iran, Iraq, Liberia, North Korea, Sudan, Syria, Zimbabwe, Central African Republic, Crimea, Lebanon, Libya, Somalia, South Sudan, Venezuela, Yemen, Albania, Bosnia and Herzegovina, Kosovo, Macedonia, Moldova, Serbia, Burundi, Ethiopia, Guinea-Bissau, Afghanistan, Kyrgyzstan, Laos, Malawi, Mali, Mozambique, Sri Lanka, Tanzania, Timor-Leste, Trinidad and Tobago, Turkmenistan, Uganda and Uzbekistan.',
            img: '',
            strong: '0'
          },
          {
            info: 'Disclaimer:',
            img: '',
            strong: '0'
          },
          {
            info: '1. BitCherry reserves the right to make unilateral adjustments to the rules of the event, including award settings, selection criteria, awards, etc.;',
            img: '',
            strong: '0'
          },
          {
            info: '2. Any violation of laws and regulations, rules of activity, infringement of the interests of others, etc. will lead to being disqualified from participating;',
            img: '',
            strong: '0'
          },
          {
            info: '3. Please pay close attention to the relevant announcements issued by BitCherry, pay attention to fake news, phishing websites, phishing emails, clone accounts, etc. BitCherry is exempt from all losses incurred by the user as a result of personal credulity of false news;',
            img: '',
            strong: '0'
          },
          {
            info: '4. BitCherry reserves the final right to interpret this activity;',
            img: '',
            strong: '0'
          },
          {
            info: '5. Please contact business@bitcherry.io if you have any question about this event.',
            img: '',
            strong: '0'
          },

          {
            info: '6. This recruitment will commence on 1 January 2020 and will be effective for a long period of time;',
            img: '',
            strong: '0'
          },
          {
            info: '* The BitCherry team has the right to revoke its authority and rewards if the candidate has a negative impact on the BitCherry brand during his or her tenure (posting spam, spreading false statements, and even other illegal statements.)',
            img: '',
            strong: '0'
          },






        ]
      },
      {
        img: require('../assets/img/media/mediabgen31.png'),
        title: "Wanna be Christmas Star and get 888BCHC? ",
        time: '2019.12.20',
        infos: [
          {
            info: ' Christmas is coming！In order to reward our users, we are about to launch Christmas Star Event from December 20 to December 31! The event rules are as following:',
            img: '',
            strong: '0'
          },
          {
            info: '1, Record a video with saying ‘Merry Christma, BitCherry!’',
            img: '',
            strong: '0'
          },
          {
            info: '2, Share the video to your Facebook (or Instagram) and Tag @BCHCGlobal (@BitCherryofficial) and invite your friend to get likes!',
            img: '',
            strong: '0'
          },
          {
            info: '3, Join BitCherry Global telegram community :( https://t.me/BitCherryGlobal ) and fill in the form in the pinned announcement!',
            img: '',
            strong: '0'
          },
          {
            info: 'The 1st prize: 888BCHC(1 winner) ',
            img: '',
            strong: '0'
          },
          {
            info: 'The 2nd prize: 88BCHC(3winners) ',
            img: '',
            strong: '0'
          },
          {
            info: 'The 3rd prize: 66BCHC(6 winners)',
            img: '',
            strong: '0'
          },

          {
            info: 'If the number of likes reaches 10, you will get 5BCHC as rewards(only for top 100 users). If the number of likes reaches 30, you will get 30BCHC as rewards(only for top 50 users). We will also rank according to the number of likes, you can check the prize details on above content!',
            img: '',
            strong: '0'
          },
          {
            info: 'Note: Every winner can only get one prize. The final interpretation right of the event belongs to BitCherry. ',
            img: '',
            strong: '0'
          },
          {
            info: 'Facebook：@BCHCGlobal',
            img: '',
            strong: '0'
          },
          {
            info: 'Instagram：BitCherryofficial',
            img: '',
            strong: '0'
          },
          {
            info: 'Telegram:BitCherryGlobal BitCherryIndonesia BitCherryVietnam',
            img: '',
            strong: '0'
          },
          {
            info: 'BitTok：www.BitCherry.io',
            img: '',
            strong: '0'
          },



        ]
      },
      {
        img: require('../assets/img/media/mediabg26.jpg'),
        title: "BitCherry signed a strategic cooperation agreement with China-CEEC Blockchain Centre of Excellence ",
        time: '2019.12.04',
        infos: [
          {
            info: 'On December 13, 2019, the "1st China-CEEC Blockchain Summit" hosted by China-CEEC Blockchain Centre of Excellence and the Slovak government was successfully held in Bratislava, Slovakia.',
            img: '',
            strong: '0'
          },
          {
            info: 'On summit seminar, BitCherry founder & CEO Paul delivered a speech of " future of distributed business". BitCherry signed " China-CEEC Blockchain Centre of Excellence memorandum of understanding " during conference, the two sides have exchanged ideas on how block chain technology would be used in energy , finance, aerospace, manufacturing, logistics, commerce and other fields.',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry will continue to lay out global distributed ecology, create commercial applications to help developers and enterprise to deploy block chain commercial applications more secure, fully compliant. Future, BitCherry will continue and actively explore the possibility of more traditional economic transformation to digital business with strong technical strength, create more forward-looking distributed business applications, and promote the process of global digital economy.',
            img: '',
            strong: '0'
          },

        ]
      },
      {
        img: require('../assets/img/media/mediabg30.png'),
        title: "BitCherry Thanksgiving airdrop community event has been concluded successfully.",
        time: '2019.12.04',
        infos: [
          {
            info: ' About this event: November 28 - December 3 users has joined BitCherry Telegram community, and followed Facebook, Instagram, Twitter, LinkedIn, etc. any of our two official accounts, and uploaded screenshots. 10 lucky users will be selected, each one will get 20 BCHC.',
            img: '',
            strong: '0'
          },
          {
            info: 'Telegram：https://t.me/BitCherryGlobal ',
            img: '',
            strong: '0'
          },
          {
            info: 'facebook:https://www.facebook.com/BCHCGlobal/ ',
            img: '',
            strong: '0'
          },
          {
            info: 'Twitter：https://twitter.com/BitCherryGlobal ',
            img: '',
            strong: '0'
          },
          {
            info: 'Instagram：https://www.instagram.com/BitCherryofficial/ ',
            img: '',
            strong: '0'
          },
          {
            info: 'Linkedin:https://www.linkedin.com/company/BitCherry/ ',
            img: '',
            strong: '0'
          },
          {
            info: 'Winners will be publicized this week in all BitCherry communities, please stay tuned! ',
            img: '',
            strong: '0'
          },

        ]
      },
      {
        img: require('../assets/img/media/mediabg25.jpg'),
        title: "BitCherry project team was invited to participate in\"Singapore fintech Festival\"",
        time: '2019.11.20',
        infos: [
          {
            info: 'On November 11, the Singapore station of BitCherry\'s global tour officially opened, and the BitCherry project team was invited to participate in the "Singapore fintech Festival". Singapore Financial Technology Festival has been held for the fourth time. It is jointly sponsored by the monetary authority of Singapore (MAS) and the Singapore Banking Association. It is known as the largest financial technology event in the world. More than 40000 guests were invited to this financial science and technology festival, including elite representatives and opinion leaders from global financial institutions, technology enterprises, investment companies, central banks, regulators and academia.',
            img: '',
            strong: '0'
          },
          {
            info: 'BitCherry project team held an exchange and discussion with tens of thousands of financial technology elites from around the world on the theme of "sustainable development", "finance and technology", "the future of finance" and "opportunities and index technologies for investment in global markets" at this financial technology festival. Together, the elites discuss how to help countries around the world make full use of the potential of blockchain technology with the goal of reforming financial models, exchange information with regulators and global policy makers, and discuss potential legal issues related to digital currency.',
            img: '',
            strong: '0'
          },
          {
            info: 'In the future, BitCherry will continue to explore the construction of blockchain infrastructure, maintain network security and cross-border transaction security based on the concept of blockchain technology empowering traditional economy, promote the digital and standardized development of commercial economy in multiple dimensions, and make continuous contributions to promoting industrial upgrading and ecological intelligent development.',
            img: '',
            strong: '0'
          },

        ]
      },
      {
        img: require('../assets/img/media/mediabg23.jpg'),
        title: "BitCherry ended SF BLOCKCHAIN WEEK 2019 San Francisco station successfully",
        time: '2019.11.06',
        infos: [
          {
            info: 'On October 28, 2019, SF blockchain week 2019 officially opened in San Francisco, USA. BitCherry was invited to attend this year\'s block chain week in San Francisco as the us leg of the global tour of "enabling cities -- building distributed commercial cities\".On October 31, Paul, founder and CEO of BitCherry, was invited to attend a roundtable discussion titled \"the future of applicable digital currency", and Shared with the guests on the topic of "current regulatory status and future development of digital currency" and "how to empower global business through distributed technology".',
            img: '',
            strong: '0'
          },
          {
            info: 'Sponsored by Libra, Facebook and Huobi, the conference brings together top blockchain companies, scholars, developers and investors from all over the world, inviting more than 5,000 people, 350 blockchain enterprises and more than 240 speakers. At this conference, the participants discussed the new model of global economy and sorted out the latest trends in the industry. The value of distributed economy began to highlight and gradually entered the global vision. BitCherry\'s in-depth communication with many industry elites further expanded the global market, while strengthening the participants\' awareness of the distributed economy. In the future, BitCherry will continue to deeply cultivate technology research and development, actively participate in ecological sharing, and continue to deepen the status of the industry, providing efficient and safe technical fertile soil for the landing and development of distributed economy.',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabg22.jpg'),
        title: "BitCherry CEO Paul was interviewed by state television in the democratic republic of Congo",
        time: '2019.10.16',
        infos: [
          {
            info: 'On October 11, 2019, the democratic republic of Congo (DRC) state television reported on the "BitCherry delegation to Africa for cooperation talks with the Congolese government on the distributed e-commerce public blockchain project". In the report, the Congolese government said that the cooperation will integrate the advantages of resources of the Democratic Republic of the Congo and promote the development of Congo\'s blockchain economy. Subsequently, BitCherry\'s founder and CEO Paul, BitCherry\'s consultant and WTIA Chairman Kim, and others also received an interview with Congolese National Television. In the interview, BitCherry founder and CEO Paul said: "This strategic cooperation with the Congolese government will start with the ecology of"blockchain + new energy area", accelerate the development of blockchain industry, realize tokenization of assets, and fundamentally solve the problems of Congo\'s backward economy. Next, we will gradually enter the global market, allowing the global new energy industry to participate in BitCherry\'s ecological construction." ',
            img: '',
            strong: '0'
          },


        ]
      },
      {
        img: require('../assets/img/media/mediabg21.jpg'),
        title: "BitCherry signed a strategic cooperation agreement with the government of the democratic republic of Congo",
        time: '2019.10.16',
        infos: [
          {
            info: 'From October 10 to 12, 2019, BitCherry signed a Memorandum of Understanding on Strategic Cooperation in National Infrastructure Construction and Energy Economization Transition in the Democratic Republic of the Congo with the Congolese Ministry of Youth Development. In the collaboration, BitCherry founder and CEO Paul, BitCherry consultant WTIA Chairman Kim and President of the Congo Presidential Office, Minister of National Infrastructure, Minister of Finance, Minister of Youth Development, Minister of Energy and Mining have cooperated on cross-border payments, bulk e-commerce, data uploading of new energy raw materials, new energy assets uploading, new energy assets trading and other key areas.',
            img: '',
            strong: '0'
          },
          {
            info: 'Located in the central and western part of Africa, Congo covers an area of 342,000 square kilometers. According to the U.S. Geological Survey, there are about 7.1 million tons of proven terrestrial cobalt resources in the world, of which Congo is the world\'s largest cobalt reserves, accounting for 47.9% of the total global cobalt reserves. This cooperation will integrate new energy resources and build a global "blockchain + new energy field" ecological economy from three commercial landing directions.',
            img: '',
            strong: '0'
          },
          {
            info: 'Because Congo has a large number of local child labor involved in illegal cobalt mining, many sources of materials are not legitimate. If the data of new energy raw materials are linked up, including the source of raw materials, material storage, resource management, resource supply and other data, it will ensure that the source channel of raw materials is complete and legitimate.',
            img: '',
            strong: '0'
          },
          {
            info: 'By utilizing the bottom structure technology of blockchain, the new energy assets can be linked up, and the energy assets can be tokenized, so that the circulation and transaction of energy assets can be realized. Energy will circulate in the capital market as a financial asset, which will realize the all-round transformation of Congo\'s economy and strengthen the development of Congo\'s overall economy.',
            img: '',
            strong: '0'
          },
          {
            info: 'This cooperation will integrate the superior resources of both sides, in order to realize the democratic republic of Congo\'s "overtaking corner" in the field of blockchain.This cooperation will continue to provide more efficient solutions of blockchain assets to Congo and the whole Africa, to ease the flow of global assets, tries to build a global scale and economic benefit ecological of new blockchain energy economy.',
            img: '',
            strong: '0'
          },



          {
            img: require('../assets/img/media/mediabg20.jpg'),
            title: "BitCherry was invited to participate in the road show of six cities in Indonesia",
            time: '2019.09.18',
            infos: [
              {
                info: 'From September 5 to September 20, 2019, Indonesia’s “Six Cities Tour” roadshow officially kicked off. BitCherry, together with other excellent blockchain projects in the world, went to Brawijaya Malang, Teknologi Surabaya, Gadjah Mada Jogja, Telkom Bandung, Teknologi Bandung, Atma Jaya Jakarta and other six universities with their own characteristic technologies and applications. The roadshow of "six cities tour" in Indonesia will popularize the basic knowledge of blockchain and spread the idea and method of applying blockchain technology to traditional business through exhibitions, lectures, seminars and other forms.',
                img: '',
                strong: '0'
              },
              {
                info: 'So far, BitCherry\'s "six cities tour" has been well received in Indonesia. "The roadshow has popularized the knowledge of blockchain technology and application scenarios, which is very helpful to tap and cultivate talents in blockchain project in Indonesia, which will indirectly or directly help the better development and transformation of the Indonesian economy," said the school leaders.',
                img: '',
                strong: '0'
              },



            ]
          },
          {
            img: require('../assets/img/media/mediabg19.jpg'),
            title: "BitCherry was invited to the Hanoi innovation summit in Vietnam",
            time: '2019.08.30',
            infos: [
              {
                info: 'On August 30, 2019, the two-day "Hanoi Innovation Summit 2019" Innovation Summit was held in Hanoi national convention center. BitCherry was invited to the summit as the only blockchain e-commerce project, and representatives of the Vietnamese community and KOLs of BitCherry attended the meeting. As a dedicated BitCherry fan, BitCherry Vietnam community representatives Shared BitCherry\'s distributed technology features and community ecology concepts on the spot, focusing on "how BitCherry can reduce marginal costs and create new business models in an e-commerce market dominated by giants in an economic downturn". During the meeting, many participants agreed with BitCherry\'s distributed technology concept. The summit in emerging economies, launched by Vietnam plans to investment, SCHOOLAB, STARTUP, cooperated with the original around "consumer technology, environmental conservation, Bioscience, Smart City, and job opportunities, and logistics" .Nearly 3000 entrepreneurs, investors, industry and scholars from all over the world share the related areas such as professional insight.',
                img: '',
                strong: '0'
              },
              {
                info: 'During the summit, BitCherry communicated with many companies to jointly capture new business trends in the current economic situation and find new economic breakthroughs for the Vietnamese market.',
                img: '',
                strong: '0'
              },



            ]
          },
          {
            img: require('../assets/img/media/mediabg18.jpg'),
            title: "BitCherry was invited to the VTS 2019 technology summit in Vietnam",
            time: '2019.08.29',
            infos: [
              {
                info: 'On August 29, 2019, the "Vietnam Tech Summit 2019" technology Summit, sponsored by Business Review, one of Vietnam\'s most famous and authoritative Business magazines, was officially held in ho chi minh city, Vietnam. BitCherry was invited to the summit as one of the conference\'s sponsors. As one of the most important annual investment meeting of Business Review, the meeting invitation for Vietnam top 50 companies and enterprises to participate in. Guests included more than 300 industry elites including technology leaders, economists, ceos of well-known enterprises and entrepreneurs in various fields. Under the theme of "ABCD Power", the conference will discuss how to use AI, Blockchain, Cloud computing , Big data and other technologies to change the traditional business model and realize land-based business applications. Paul, the founder and CEO of BitCherry, was the guest speaker at the conference and had in-depth discussions and exchanges with guests on topics such as "how the distributed economy will subvert traditional business models" and "how BitCherry can implement the distributed economy in an ecological way".',
                img: '',
                strong: '0'
              },
              {
                info: 'During the meeting, Vietnam\'s well-known financial and business media FBNC conducted a personal interview with Paul."BitCherry will build the foundation for the core ecosystem by building a transparent, trusted, and distributed network platform that enables fair peer-to-peer sharing and trading," Paul said."And based on the distributed ecology of credit consensus to complete the value creation, upgrade to ecology namely finance.In the future, it will be based on technology and centered on ecology, with cross-field disruption including banking, accounting (auditing), insurance, healthcare, government affairs, transportation, taxation, food, agriculture, automobile and other fields."',
                img: '',
                strong: '0'
              },


            ]
          },
          {
            img: require('../assets/img/media/mediabg17.jpg'),
            title: "BitCherry was invited to POW 'ER 2019 global blockchain developer conference",
            time: '2019.08.23',
            infos: [
              {
                info: 'On August 21-22, 2019, "POW `ER 2019 global blockchain developers conference" was held in Beijing. BitCherry was invited to the conference as the first distributed e-commerce project in the world. The conference invited nearly 1500 technology leaders, representatives of investment institutions, research institutes and elite scholars from all over the world to conduct in-depth discussions on the development of new technologies such as digital economy, distributed industry, artificial intelligence and Internet of things, and share their judgment and outlook on the trend of new technologies. At the conference, BitCherry discussed with many industry elites how to develop a distributed economy and build a distributed sharing ecosystem, contributing to the value interconnection of global business integration.',
                img: '',
                strong: '0'
              },
              {
                info: 'As a special guest, Bob, chief scientist of BitCherry and chairman of the north American blockchain foundation, attended BBS, the theme of "distributed economy", and had in-depth discussions with the guests on the topic of "how to innovate the distributed e-commerce network public chain". "Distributed computing has evolved to the point where the entire system can be turned into a supercomputer using distributed computing technology," he said. "BitCherry`s vision is to become a supercomputer for e-commerce."',
                img: '',
                strong: '0'
              },


            ]
          },
          {
            img: require('../assets/img/media/mediabg15.jpg'),
            title: 'BitCherry distributed network project development has been officially launched',
            time: '2019.08.06',
            infos: [
              {
                info: " On July 29, 2019, at the global blockchain summit GBIS 2019(Indonesia), Paul, the founder and CEO of BitCherry, the world's first distributed e-commerce network, announced that BitCherry distributed e-commerce public blockchain project development was officially launched. BitCherry public blockchain will combine blockchain and DAG technology to provide a chain network system, support the public blockchain environment of e-commerce ecology, and create a new generation of Shared e-commerce global ecology.",
                img: '',
                strong: '0'
              },
              {
                info: 'At the global launch ceremony of BitCherry public blockchain, Bob, chief scientist of BitCherry, who has many years of industry project development experience, also delivered a speech titled "the iteration and future trend of public chain", highlighting the technical characteristics of BitCherry public blockchain, the design of consensus mechanism and the design of chain network system.',
                img: '',
                strong: '0'
              },


            ]
          },
          {
            img: require('../assets/img/media/mediabg16.jpg'),
            title: 'BitCherry announced a strategic partnership with SCC and WTIA to build a $42 billion smart city',
            time: '2019.08.06',
            infos: [
              {
                info: 'On July 30, 2019, BitCherry, a distributed e-commerce network, announced the signing of a strategic cooperation agreement with SCC and WTIA in Indonesia. The signing of the strategic cooperation marks the in-depth cooperation among the three parties in key areas such as project incubation, technology research and development, promotion activities, and promoting the development of smart cities and blockchain.  ',
                img: '',
                strong: '0'
              },
              {
                info: 'BitCherry, together with SCC and WTIA, will build a $42 billion integrated smart city project, "Tunis economic city"."Tunis economic city" is a large new economic city located on the east coast of Tunisia. As the first project of cooperation between BitCherry, SCC and WTIA, the development and design of "Tunis economic city" is progressing smoothly as planned. In the smart urban complex, BitCherry distributed network will cover all urban life scenes, and its ecological pass certificate BCHC will also be used in all payment scenes in the urban ecology in the future to meet people`s payment needs in the complex. ',
                img: '',
                strong: '0'
              },


            ]
          },
          {
            img: require('../assets/img/media/mediabg14.jpg'),
            title: 'BitCherry air drops have begun',
            time: '2019.08.01',
            infos: [
              {
                info: 'During August 1~ August 7, join the BitCherry telegram community or invite your friends to the BitCherry telegram community，we will give away BCHC to our users，the rules are as following：',
                img: '',
                strong: '0'
              },
              {
                info: '300 members reached：select 10 lucky users，split 50BCHC',
                img: '',
                strong: '0'
              },
              {
                info: '500 members reached：select 20 lucky users，split 150BCHC',
                img: '',
                strong: '0'
              },
              {
                info: '1000members reached：select 30 lucky users，split 300BCHC',
                img: '',
                strong: '0'
              },
              {
                info: '2000members reached：select 40 lucky users，split 500BCHC',
                img: '',
                strong: '0'
              },
              {
                info: 'We also launched BCHC rewards activity on our Facebook and Instagram！',
                img: '',
                strong: '0'
              },
              {
                info: 'Instragram: https://www.instagram.com/BitCherryofficial/ ',
                img: '',
                strong: '0'
              },
              {
                info: 'Facebook: https://www.facebook.com/BCHCGlobal/?modal=admin_todo_tour',
                img: '',
                strong: '0'
              },


            ]
          },
          {
            img: require('../assets/img/media/mediabg13.jpg'),
            title: 'CherryWallet Cold wallet App was officially launched and pre-sold',
            time: '2019.07.30',
            infos: [
              {
                info: 'CherryWallet, a blockchain digital asset secure storage project, officially launched globally on July 30. At the launch ceremony, Aaron, chief commercial officer of CherryWallet, announced that the CherryWallet App was launched on the same day, and its product CherryWallet Pro cold wallet was also launched for pre-order.',
                img: '',
                strong: '0'
              },
              {
                info: 'As digital assets security block chain storage solution, CherryWallet qr code encrypted with a pioneering + near field communication (NFC) the way of interaction, hot and cold end original semi - password technology, synthetic private key by seed password + payment password, using qr code and NFC communication net, let the private key is never fundamentally eliminate the risk of private key hacked.',
                img: '',
                strong: '0'
              },
              {
                info: 'CherryWallet also pioneered situation-generation technology, which generates random passwords instantly to improve password security, depending on the user`s application situation.CherryWallet Pro cold wallet currently supports BTC, ETH, BCHC, USDT, EOS and other mainstream digital assets. With the continuous iteration and upgrading of wallet function, CherryWallet will provide more public chain digital assets support for exchanges, blockchain enterprises and trading users in the future.',
                img: '',
                strong: '0'
              }


            ]
          },
          {
            img: require('../assets/img/media/mediabg12.jpg'),
            title: 'BitCherry signed three strategic cooperation agreements with the Indonesian government',
            time: '2019.07.29',
            infos: [
              {
                info: 'On July 29, 2019, Indonesian chamber of commerce and industry (KADIN), global block chain investment alliance GBIA and Hong Kong block chain association signed a strategic cooperation agreement with BitCherry.',
                img: '',
                strong: '0'
              },
              {
                info: 'BitCherry will work with the Indonesian government to help Indonesian enterprises and projects build a distributed business ecosystem. The two sides will exchange views on "blockchain business investment, solutions, distributed e-commerce application" and other aspects in Indonesia.The two sides signed the memorandum of understanding on strategic cooperation on blockchain and distributed business investment to promote economic transformation in Indonesia, the memorandum of understanding on strategic cooperation on establishing blockchain solutions to reduce logistics costs in Indonesia, and the memorandum of understanding on strategic cooperation on providing blockchain solutions to promote economic and financial efficiency in Indonesia.At the same time, BitCherry will work with the Indonesian government on a regional basis to promote rapid economic transformation in Indonesia.',
                img: '',
                strong: '0'
              },

              {
                info: 'The signing of the strategic cooperation agreement with the Indonesian government also marks another milestone of BitCherry`s distribution in southeast Asian markets. This cooperation will further accelerate the pace of BitCherry`s adoption of technology to promote the implementation of blockchain in the global distributed e- commerce.In the future, BitCherry will continue to build a global distributed e- commerce blockchain platform, and build a global distributed business ecosystem with the mission of implementing distributed commercial applications, linking global commercial resources and enabling the development of digital economy.',
                img: '',
                strong: '0'
              },

            ]
          },
          {
            img: require('../assets/img/media/mediabg7en.jpg'),
            title: 'BitCherry Brand LOGO has been Upgraded Officially',
            time: '2019.05.23',
            infos: [
              {
                info: 'Recently, BitCherry brand LOGO has been upgraded officially. Compared with the original LOGO, the overall tone of the new LOGO evolved into pure "cherry red".',
                img: '',
                strong: '0'
              },
              {
                info: 'Overall, the idea of BitCherry brand LOGO comes from symbolization cherry, which is the abstract expression of cherry',
                img: '',
                strong: '0'
              },

              {
                info: 'In the traditional context, cherries are a symbol of beauty and youth. The pronunciation of cherry is very similar to cherish. The decentralized super e-commerce ecosystem built by BitCherry will also flourish like the cherry in its prime. BitCherry will also particularly cherish the rights and interests of each ecological participant, so that each user can benefit fairly from decentralized transactions.',
                img: '',
                strong: '0'
              },
              {
                info: 'From the details, the main part of BitCherry LOGO consists of BitCherry two initials "B" and "C", "B" stands for digital assets, "C" stands for physical business. "B" is linked to "C", which reflects the advantages of  BitCherry, the world`s first distributed e-commerce network:',
                img: '',
                strong: '0'
              },
              {
                info: 'By connecting the value connection between digital assets and physical businesses, the company perfectly combines powerful offline experiential services with efficient blockchain technology and distributed business forms, so as to provide a more efficient and valuable ecosystem for consumers, merchants and ecological community participants.',
                img: '',
                strong: '0'
              },
              {
                info: '   ',
                img: require('../assets/img/media/mediabg7enInfo1.png'),
                strong: '0'
              },
              {
                info: '   ',
                img: require('../assets/img/media/mediabg7enInfo2.png'),
                strong: '0'
              },
              {
                info: '   ',
                img: require('../assets/img/media/mediabg7enInfo3.png'),
                strong: '0'
              },
              {
                info: 'In addition, the upgraded LOGO boldly adopted the bright red, instead of the original dark red. In the sensory vision, the bright red is more conspicuous and prominent, and it also fits the true color of the cherry, which has a visual impact. At the same time, it is easy to carry out color matching, with excellent ductility and inclusiveness.',
                img: '',
                strong: '0'
              },
              {
                info: 'At present, people`s lives are increasingly connected with e-commerce. In the game rules established by traditional centralized e-commerce, no matter the merchants or consumers, their own rights and interests are not guaranteed, and the value of the platform`s ecological growth also cannot be shared.In BitCherry, the new added value of BitCherry is essentially derived from the joint contribution of all ecological participants, and the value will be equally allocated to all ecological participants.',
                img: '',
                strong: '0'
              },
              {
                info: 'After the upgrading of brand LOGO, BitCherry will continue to keep the original intention and build the first global distributed e-commerce network with sustainable upgrade and sound development. Eventually, carrying millions of merchants, BitCherry will expand e-commerce business to all over the world, and become the global distributed e-commerce business leader, ultimately achieve the goal of globally integrated business value interconnection.',
                img: '',
                strong: '0'
              },
            ]
          },
          {
            img: require('../assets/img/media/mediabg2en.png'),
            title: 'BitCherry will debut Global Blockchain Summit in Singapore',
            time: '2019.04.21',
            infos: [
              {
                info: 'BitCherry is the first Distribute E-Commerce Network, dedicated to creating a super-ecological network that encompasses all aspects of food, clothing, housing and transportation. On April 24, the global blockchain investment summit will held in Singapore. BitCherry will launch as a special project in the summit.',
                img: '',
                strong: '0'
              },
              {
                info: 'In the traditional e-commerce system, the platform itself has a complex set of points. For example, on the world-famous e-commerce platforms Amazon, eBay, Taobao, Jingdong, their respective points system and reward methods are different. The reward system has many restrictions and the platforms are isolated from each other. The ecology of social e-commerce is also fragmented, affecting the enthusiasm of users to actively spread and share. The BitCherry, as a new, open, self-distributed based social e-commerce platform, just born to address this pain point. Enhance the cohesiveness and synergy between merchants and consumers.',
                img: '',
                strong: '0'
              },
              {
                info: 'The current social e-commerce platforms on the market are central in nature, and these platforms store a large amount of user information and data. However, all kinds of incidents of illegally stealing user data have been emerging one after another. Various behaviors have caused users` privacy to leak and the platform cannot protect consumer rights. For example, in March 2019, Gearbest, a well-known e-commerce site, was leaking millions of user profiles and shopping orders. BitCherry is based on blockchain technology to build a decentralized social e-commerce ecosystem. The solution to the centralized platform through product on chain and service on chain can provide an efficient, secure and transparent trading environment for consumers, merchants, and participants in the ecological community.',
                img: '',
                strong: '0'
              },
              {
                info: 'BitCherry guarantee product reviews on the platform can be trusted to address consumer doubts about the credibility of the merchant. BitCherry enables the transaction through the means of smart contract, ensure that all transactions are automated, improve transaction efficiency and increase the credibility of the transaction. Meanwhile, BitCherry has built a complete e-commerce credit system on the blockchain. The basic information, product information, transaction records, and consumer reviews provided by the merchant are recorded on the blockchain. The business information will be transparent and open, which will improve the authenticity of the information and help to ensure the integrity of the platform and the quality of the merchants on the platform.',
                img: '',
                strong: '0'
              },
              {
                info: 'In the future, BitCherry aims to recruit millions of merchants. Ultimately, the business landscape of BitCherry will be extended to the whole world.',
                img: '',
                strong: '0'
              },

            ]
          },
          {
            img: require('../assets/img/media/mediabg3.jpg'),
            title: 'BitCherry Release Ceremony Held in Global Blockchain Investment Summit',
            time: '2019.04.24',
            infos: [
              {
                info: 'Nowadays, the e-commerce industry is still a trillion-dollar blue ocean market. In recent years, the e-commerce market has maintained a rapid growth momentum. In 2017, the global transaction volume reached 2.3 trillion US dollars, and China ranked first in the world with 29.16 trillion Yuan. But at the same time, the e-commerce market has faced several pain points for a long time: the problem of dishonesty is serious, the quality of goods is uneven, and personal information consumers is leaked. The market is basically stable, and the emerging platforms are difficult to survive. Is it feasible to solve the issues above through the trending blockchain technology?',
                img: '',
                strong: '0'
              },
              {
                info: 'The Answer is Yes! The advent of BitCherry will change the layout of e-commerce business. BitCherry positions itself as the world`s first distributed e-commerce network, and is committed to empowering retail service industry via blockchain technology as well as creating the new generation of sharing e-commerce ecosystem. On April 24, 2019 Global Blockchain Investment Summit was held in Singapore. As a special project, the global release ceremony of BitCherry was held here. Once unveiled, it has become the focus of the guests and industry peers',
                img: '',
                strong: '0'
              },
              {
                info: 'Traditional e-commerce industry, nominally referred to as shared communities, are basically independent. Although users have their own accounts, they can`t directly manage the data in the account and the operation is passive. Now, BitCherry effectively solved the problem with the distributed nature of blockchain.',
                img: '',
                strong: '0'
              },
              {
                info: 'In BitCherry ecosystem, super nodes, operation nodes, merchants, consumers and the platform will be engaged in the entire lifecycle of the commodity value circulation. The platform applies the blockchain ledger as the core technical framework, enabling each participant to securely trade and share benefit through token incentives.',
                img: '',
                strong: '0'
              },
              {
                info: 'Recently, blockchain has brought new technology-driven points for the development of e-commerce. How to combine the traditional distributed thinking with the existing business and reconstruct a new shared e-commerce model is the direction that many companies are exploring. As the pioneer of blockchain + e-commerce business model, BitCherry has laid a solid industrial foundation for popularization of the blockchain in the future. It is believed that every participant, including nodes, merchants, consumers and platforms, will get a value bonus in the wave of change. Therefore, we have enough reason to expect the day BitCherry will ultimately expand the distributed e-commerce into every corner of the globe.',
                img: '',
                strong: '0'
              },

            ]
          },

          {
            img: require('../assets/img/media/mediabg10.jpg'),
            title: 'BitCherry facilitates the GBIS 2019 global blockchain investment summit',
            time: '2019.04.24',
            infos: [
              {
                info: ' On July 29, 2019, the Global Blockchain Investment Summit 2019, the premier Blockchain event in southeast Asia, will be held in taikoo square, Jakarta, Indonesia. The summit is jointly hosted by GBIA, the global blockchain investment alliance, HKBA, the Indonesian chamber of commerce and industry, and BCEE. The world`s first distributed e-commerce network BitCherry as one of the co-organizers and sponsors of the summit, BitCherry founder &CEO PAUL and BitCherry co-founder &COO Kilian Hussmann will attend the roundtable BBS as special guests, and they will conduct in-depth discussions with on-site guests on topics such as "global blockchain investment industry chain ecology, how to explore the value of blockchain investment".',
                img: '',
                strong: '0'
              },
              {
                info: ' BitCherry founder &CEO PAUL will hold the BitCherry public blockchain launch ceremony at the summit. In the future, the dividends and future value of the whole ecological development strategy of distributed e-commerce blockchain will be imported into Indonesia and radiated to the whole southeast Asia, so as to reach a win-win situation in more scenarios.',
                img: '',
                strong: '0'
              },

            ]
          },

          {
            img: require('../assets/img/media/mediabg11.jpg'),
            title: 'BitCherry launches 130,000,000 IDR airdrop in Indonesia market',
            time: '2019.04.24',
            infos: [
              {
                info: 'On July 29, 2019, the Global Blockchain Investment Summit 2019, the premier Blockchain event in southeast Asia, will be held in taikoo square, Jakarta, Indonesia.The summit will invite those guests, including Indonesian leaders, the UN blockchain commission, prince of the United Arab Emirates, the Indonesian chamber of commerce and industry, the Indonesian central bank, the Indonesian ministry of investment, the united bank of Philippines and hundreds of industry elites. The summit aims to promote in-depth exchanges among national leaders, departments and practitioners of blockchain in various countries, share resources and reach consensus on strategic cooperation in many fields.',
                img: '',
                strong: '0'
              },
              {
                info: 'As the sponsor of the conference, BitCherry will continue to export its brand influence and further expand the Indonesian market. Therefore, BCHC worth 130,000,000 IDR will be given away during the summit. This community airdrop will be available for all participants in this summit and the whole Indonesian market.',
                img: '',
                strong: '0'
              },

            ]
          },

          {
            img: require('../assets/img/media/mediabg9Info.jpg'),
            title: 'The Golden Age of E-commerce Business and Wechat Business is Over, Distributed Business is Coming',
            time: '2019.06.12',
            infos: [
              {
                info: 'BitCherry creates the infrastructure for the distributed business. Relying on the blockchain technology, smart contracts can be distributed instantly, the intermediate retention and transfer can also be omitted, so as to achieve the direct distribution of value.',
                img: '',
                strong: '0'

              },
              {
                info: 'Speaking of e-commerce, people will naturally think of Ma Yun`s taobao; when it comes to wechat business, you must have read the inspirational words of selling masks in “Moments” of wechat. But for most people, distributed business is a new concept',
                img: '',
                strong: '0'

              },
              {
                info: 'Now the competition of e-commerce and wechat business is fierce, but the distributed business has just emerged. So what are the differences between e-commerce, wechat business and distributed business?',
                img: '',
                strong: '0'

              },
              {
                info: '“It is not difficult to start a business”, wechat business makes a lot of people’s dream come true, which is to start their own business. And wechat business makes  the logistics industry more prosperous than ever. Wechat business relies on social software, which makes buying and selling more accessible. People just need a mobile phone to sell things at home. Distributed e-commerce networks, represented by BitCherry, will also be incubators of distributed business opportunities.',
                img: '',
                strong: '0'

              },
              {
                info: 'Neither e-commerce nor wechat business can be separated from the centralized organization mode. In mid and late development, under the glamorous surface, the interests of merchants and consumers are always in conflict with those of platforms. This is also the current development dilemma of e-commerce giants and wechat business platforms, which causes counterfeit goods, consumers’ privacy leakage, unreasonable traffic allocation and other issues.',
                img: '',
                strong: '0'

              },
              {
                info: 'For example, wechat business platforms monopolize most of the profits of the ecosystem and distribute the platform flow at will, value and income does not really match, the operational costs of merchants become more and more high, consumers lose bargaining power, etc. However, wechat business generally transfers risks to merchants and extracts huge profits from them through pledge, which causes a lot of wechat business platforms cannot develop sustainablely.To put it simply, the value flow of e-commerce and wechat business cannot be separated from the centralized platform, and cannot achieve the circulation of value barrier-free.',
                img: '',
                strong: '0'

              },
              {
                info: 'BitCherry creates the infrastructure for the distributed business. Relying on the blockchain technology, smart contracts can be distributed instantly, the intermediate retention and transfer can also be omitted, so as to achieve the direct distribution of value.',
                img: '',
                strong: '0'

              },
              {
                info: 'BitCherry truly achieve the goal of  "no middlemen to earn price difference, sellers to earn more and buyers to spend less." BitCherry does not charge intermediate fees to consumers. Meanwhile, through consumption mining, consumers can gain "JD stock (BitCherry tokens)" while consuming on JD, so as to share the ecological premium of the platform.',
                img: '',
                strong: '0'

              },
              {
                info: 'Nowadays, there are only two kinds of people in the world: those who surf the Internet and those who do not..',
                img: '',
                strong: '0'

              },
              {
                info: 'In the future, there would only be two kinds of people in the world: those who are on the chain and those who are not. In addition, more and more people will be connected to the chain, and the generated information will also generate a brand new business model. BitCherry serves the people who are on the chain, and will bring more value to the distributed business.',
                img: '',
                strong: '0'

              },
              {
                info: 'Missed the e-commerce and wechat business, do you even wanna miss BitCherry?',
                img: '',
                strong: '0'

              },


            ]
          },
          {
            img: require('../assets/img/media/mediabg8en.jpg'),
            title: 'Break down the walls of enterprises , so that business participants benefit in a timely and fair manner',
            time: '2019.05.29',
            infos: [
              {
                info: 'The new business model is the model of blockchain community + products. Blockchain breaks down the walls of enterprises with a set of general economic model. The entire ecosystem of product supply chain, stakeholders, and user promotion has been opened up, and each participant in the chain has been motivated and rewarded in a timely manner.',
                img: '',
                strong: '0'
              },
              {
                info: 'One day, when you walk into a restaurant at random, after full of food and drink, you can take out your mobile phone to light up the screen, scan the code and easily use digital assets to complete the payment, thus receiving a token incentive.',
                img: '',
                strong: '0'
              },
              {
                info: 'This is not far away from us. Definitely, digital assets are now increasingly accepted by the mainstream society. Moon, a startup, recently announced that all lightning-network-based wallets will be available for purchase on Amazon, as long as they are powered by its Google plug-in. According to BLOCKDATA TECH data shows, among the online retail service providers with annual revenues exceeding US$100 million, dozens of companies such as Amazon, Microsoft, Newegg, FLIGHT, and Overstock are supporting digital asset payments.',
                img: '',
                strong: '0'
              },
              {
                info: ' These are just the first steps to connect digital assets with traditional e-commerce.To truly make "value flow" and enable people to make payment with digital assets as convenient as WeChat and alipay, it is inseparable from the support of infrastructure providers like BitCherry, a distributed e-commerce infrastructure provider, and the establishment of chain business on it. It is only a matter of time before BitCherry, the first distributed e-commerce network in the world, is perfect in technology.  ',
                img: '',
                strong: '0'
              },
              {
                info: ' Compared with the traditional online business, wechat business, the emerging chain business has unique advantages. In the era of traditional e-commerce, e-commerce giants, while enjoying most of the benefits brought by user growth, wield the monopoly stick to make unfair rules of the game and grab the rights and interests of merchants and users. Privacy leakage and bidding flow ranking emerge in an endless stream, and the users in the weak position  deeply hate this, but they have no choice. The distributed business model represented by chain business will effectively eliminate the stubborn problems of traditional e-commerce and achieve the fair distribution of interests within the ecology  ',
                img: '',
                strong: '0'
              },
              {
                info: '“The new business model is the model of blockchain community + products. Blockchain breaks down the walls of enterprises with a set of general economic model. The entire ecosystem of product supply chain, stakeholders, and user promotion has been opened up, and each participant in the chain has been motivated and rewarded in a timely manner.”',
                img: '',
                strong: '0'
              },
              {
                info: 'BitCherry created a super ecological environment covering all aspects of clothing, food, housing, transportation, and consumption in restaurants, which is just a fragment in the massive application scenes. Based on the BitCherry distributed e-commerce network, users, merchants and nodes can efficiently carry out value transfer and decentralized collaboration, and fairly and timely share the added value of the whole ecosystem. In the meantime, issues such as data validation, information chaining, consumer data protection, counterfeit and shoddy products will also be well resolved. It can be determined that the era of  chain business has come',
                img: '',
                strong: '0'
              },


            ]
          },

          {
            img: require('../assets/img/media/mediabg6en.png'),
            title: 'BitCherry：Cradle of Blockchain Amazon and Alibaba',
            time: '2019.05.22',
            infos: [
              {
                info: 'For blockchain industry, two core application scenarios for digital assets are detected: trading and payment. The former has incubated top exchanges such as Binance, Huobi, OKEx, and etc.; and great potential remains to be tapped for the latter with much imaginery space.',
                img: '',
                strong: '0'
              },
              {
                info: 'In the post-Internet era, once unparalleled e-commerce magnates are meeting with ceilings and have uncovered multiple issues. The question emerges as whether the realization of cutting-edge technologies such as Blockchain could take over the mission of the Internet and open a new chapter of history.',
                img: '',
                strong: '0'
              },
              {
                info: 'The answer is absolutely positive. As the traditional e-commerce surging in the past decade, dazzling data has been concealing a series of problems: CAC (cost of acquiring customers) is rapidly rising with profits getting thinner and thinner; small and medium-sized e-commerce merchants have been increasingly difficult to survive; the centralized platform "hegemonism", the bidding model of advertisement mount to high operating costs under the “hegemonism” of centralized platform; consumers have no privacy at all in the era of big data; traditional e-commerce is having hard time providing tailored service with increasing needs of consumption upgrades.',
                img: '',
                strong: '0'
              },
              {
                info: '',
                img: require('../assets/img/media/mediabg6enInfo.png'),
                strong: '0'
              },
              {
                info: 'The emergence of distributed ledger technology provides a feasible solution. Tycoons such as Amazon, Alibaba and JD.com are also promoting the application of blockchain technology in the e-commerce industry. JD.com even launched a JD Chain for enterprises, and released a white paper with open source code.',
                img: '',
                strong: '0'
              },
              {
                info: 'Subject to its huge body, the turn of a giant is always slow. However, a new type of decentralized e-commerce network was further proposed and put into practice. BitCherry is a typical representative.',
                img: '',
                strong: '0'
              },
              {
                info: 'With underlying technology of blockchain, BitCherry constructs a decentralized e-commerce application platform. Supplemented with intelligent hardware facilities, BitCherry achieves a payment network applied both online to offline and onchain to offchain, so as to realize a seamless value connection between digital assets and physical business. BitCherry decentralized e-commerce network can achieve:',
                img: '',
                strong: '0'
              },
              {
                info: 'Data rights assertions. Personal data ownership is protected by cryptography and all data is used with the user`s permission so as to protect personal privacy.',
                img: '',
                strong: '0'
              },
              {
                info: 'Consumption is mining. BitCherry ecological incentive model attracts a large number of users to join the network under "consumption is mining". The platform investors, referees and operators all share certain incentive in every segment of sales cycle. A more efficient incentive model will encourage higher extent of user participation within the ecosystem.',
                img: '',
                strong: '0'
              },
              {
                info: 'Ecological value fidelity. Recording products and services online will promote the whole ecological value fidelity and fundamentally solve the problem of counterfeiting. Business information and transaction records will also be on chain, providing information authenticity and platform integrity.',
                img: '',
                strong: '0'
              },
              {
                info: 'New value transfer collaboration. Compared with the traditional centralized e-commerce platform, BitCherry enjoys a new value transfer collaboration model in its organization. Through the restructure of traditional e-commerce, BitCherry ultimately allows each participant to benefit fairly from decentralized transactions.',
                img: '',
                strong: '0'
              },

              {
                info: 'Distributed e-commerce has become an inevitable development trend in the coming future. Since the beginning of this year, well-known individuals and public media have been rushing to e-commerce industry with data traffic beyond measure. Meanwhile Amazon and Alibaba have become "traditional" accidentally. "Sports car" is difficult to run on the cement floor. And the traditional e-commerce infrastructure cannot meet the needs of distributed commerce. Traditional e-commerce has encountered no fewer problems than decentralized e-commerce, confining the development of distributed e-commerce.',
                img: '',
                strong: '0'
              },
              {
                info: 'Thanks to the maturity of online payments, the Internet has reshaped traditional business models, since when the “WalMarts” have fallen from the altar. And as BitCherry perfects the distributed e-commerce infrastructure, a new generation of e-commerce giant will emerge eventually.',
                img: '',
                strong: '0'
              },
              {
                info: 'For blockchain industry, two core application scenarios for digital assets are detected: trading and payment. The former has incubated top exchanges such as Binance, Huobi, OKEx, and etc.; and great potential remains to be tapped for the latter with much imaginary space.',
                img: '',
                strong: '0'
              },
              {
                info: 'From this perspective, BitCherry will be the cradle of Blockchain Amazon and Alibaba.',
                img: '',
                strong: '0'
              },

            ]
          },
          {
            img: require('../assets/img/media/mediabg5en.jpg'),
            title: 'BitCherry Makes Fair Benefit for Merchants and Consumers',
            time: '2019.05.21',
            infos: [
              {
                info: 'Abstract：',
                img: '',
                strong: '0'
              },
              {
                info: 'In 2018, blockchain roared with fortunes, attracting a large number of people to step in this chaotic river. Just like the law of universal gravitation, the dust will be removed, and new concepts will eventually realize with true value unveiled. In 2019, after the bulls and bears when enthusiasm faded, people began to think about the direction of the entire industry and explore the true application value of blockchain.',
                img: '',
                strong: '0'
              },
              {
                info: 'On April 24, BitCherry made its debut on the global blockchain Investment Summit in Singapore. Born as the worldwide first distributed e-commerce network, BitCherry is committed to building the underlying infrastructure for e-commerce industry based on blockchain technology.',
                img: '',
                strong: '0'
              },
              {
                info: 'At present, trading and payment platforms are the most developed applications in blockchain industry. Top firms such as Binance and Bitfinex, etc lead the trend of trading platforms, whereas e-commerce of payment platform is still highly undervalued. There are adequate quality assets in the industry. However, efficient asset management and utilization is scarce resource. The core behind the problem lies in the lack of the underlying infrastructure, and this is precisely where BitCherry comes from.',
                img: '',
                strong: '0'
              },
              {
                info: 'BitCherry built an underlying infrastructure that integrates commercial payment hardware with software applications such as e-commerce platform and digital token wallet etc. The design realizes value circulation from online to offline and constitutes a full set of e-commerce solution on top of blockchain, exploring feasibilities of distributed e-commerce business model.',
                img: '',
                strong: '0'
              },
              {
                info: 'Compared with traditional centralized business model, reduction in transaction friction, lower transaction costs and higher transaction efficiency are the most significant advantages of distributed business. In addition, every participant in the ecosystem can enjoy profit sharing and value appreciation sharing. Five roles are engaged in BitCherry decentralized ecosystem: super node, merchants, consumers, operational nodes and Foundation. Each role plays its own functions, undertakes different tasks, and obtains positive returns based on its positioning. Hence the system realizes economic sharing with everyone`s participation.',
                img: '',
                strong: '0'
              },
              {
                info: 'Super nodes are licensors of the platform software. They achieve token rewards through licensing process and share the platform profits meanwhile. Merchants register on the platform via smart contracts and engage in the ecosystem based on intelligent hardware and software. Consumers obtain token rewards when purchasing goods at merchants. They can purchase online or offline through smart contract to achieve  “consumption mining”. The operational nodes will deploy merchant and consumer networks, increase the number of users on the ecosystem and promote the business cooperation opportunities. The Foundation is the core propellant for entire decentralized e-commerce ecosystem.',
                img: '',
                strong: '0'
              },
              {
                info: 'In the visible future, users will consume on e-commerce platform online, purchase a basket of goods with group price discount, and thus obtain token incentives. On the other hand, in offline-registered stores, users complete payment through digital platform wallet, with the whole transaction behavior and results immediately uploaded on chain, and both the consumer and the merchant will attain token incentives.',
                img: '',
                strong: '0'
              },
              {
                info: 'As described above, BitCherry portrays the principle use scenarios in the distributed e-commerce network. On this decentralized collaborative business platform, each participating role will gain sustainable and equitable benefits that match their efforts, achieving the unlimited potential of distributed commerce.',
                img: '',
                strong: '0'
              },
              {
                info: '',
                img: '',
                strong: '0'
              },

            ]
          },
          {
            img: require('../assets/img/media/mediabg4en.jpg'),
            title: 'BitTok—Launching the World Premier Project "BCHC" ',
            time: '2019.05.05',
            infos: [
              {
                info: 'BitCherry (BCHC) will have it`s initial global launch at BitTok.io at 12:00 pm Singapore time on May 5th, 2019. Transaction between BCHC and USDT will be opened for access simultaneously.',
                img: '',
                strong: '0'
              },
              {
                info: 'BitCherry is world`s first distributed e-commerce network, aiming at building a new generation of shared e-commerce ecology. Trading address: www.BitTok.io',
                img: '',
                strong: '0'
              },


            ]
          },


        ]
      },
    ]
  },
  Project: {
    title: 'Project News',
    btn_info: "See More"
  },
  Activity: {
    banner: {
      title: 'Event',
      infos: [
        ' '
      ]
    },
    activityList: [
      {
        img: require('../assets/img/activity/0505001en.png'),
        title: "BitCherry's 2nd Anniversary Airdrop",
        time: '2020-05-05',
        src: ''
      },
      {
        img: require('../assets/img/activity/0505002en.png'),
        title: "BitCherry 2nd Anniversary Giveaway, BCHC Net-buy Contest",
        time: '2020-05-05',
        src: ''
      },
      {
        img: require('../assets/img/activity/1.jpg'),
        title: "Guess BitCherry TPS Twitter Contest",
        time: '',
        src: 'https://twitter.com/BitCherryGlobal/status/1323926113094021120?s=20'
      },
      {
        img: require('../assets/img/activity/2.jpg'),
        title: "Tickets Giveaway--Paris Blockchain week summit 2020",
        time: '12.09-12.10',
        src: 'https://twitter.com/BitCherryGlobal/status/1331540855782473728?s=20'
      },
      {
        img: require('../assets/img/activity/3.png'),
        title: "BitCherry Technical Article Contest",
        time: '10.23-12.22',
        src: 'https://medium.com/bitcherryglobal/bitcherry-technical-articles-contest-c19843fc895d'
      },
      {
        img: require('../assets/img/activity/4.jpg'),
        title: "BitCherry Interview Event",
        time: '12.24',
        src: 'https://twitter.com/BitCherryGlobal/status/1331145692677476353?s=20'
      },
      {
        img: require('../assets/img/activity/5.jpeg'),
        title: "BitCherry Turkey Community Event",
        time: '12.24',
        src: 'https://twitter.com/BitCherryT/status/1338417805956407299'
      },
      {
        img: require('../assets/img/activity/6.jpeg'),
        title: "BitCherry Arabic Community Event",
        time: '11.28',
        src: 'https://twitter.com/BitcherryArabic/status/1332677836914749440/'
      },

    ]
  },
}