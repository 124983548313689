<template>
  <div id="app">

    <Nav msg="Welcome to Your Vue.js App" />
    <router-view></router-view>
    <Footer msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
import Nav from './components/Nav.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'app',
  components: {
    Nav,
    Footer
  },
  created () {
   
  }
}
</script>

<style>
#app {
  font-family: "Helvetica Neue", Helvetica, "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif, "PingFang SC";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
</style>
