<template>
  <div class='wallet'>
    <div class="c_banner_1_box">
      <div class="c_b_1_con">

        <div class="info" v-class="'info b'">
          <p class="title">{{$t('Wallet.banner_top.title')}}</p>
          <p>{{$t('Wallet.banner_top.info')}}</p>

        </div>

      </div>
    </div>

    <div class="b_list_box">
      <div class="b_list_cont">
        <img class="v-hide" v-class="'b'" src="../assets/img/wallet/image_3.png" alt="">
        <div class="b_list_cont_info v-hide" v-class="'b_list_cont_info b'">
          {{$t('Wallet.b_list_box_1.info')}}
        </div>
      </div>
    </div>

    <div class="b_list_img_box">
      <img class="banner_img" :src="$t('Wallet.b_list_box_2.imgsrc')" alt="">
      <img class="banner_img_m" :src="$t('Wallet.b_list_box_2.imgsrcm')" alt="">
    </div>
    <div class="b_list_box">
      <div class="b_list_cont">
        <img class="v-hide" v-class="'b'" src="../assets/img/wallet/image_1.png" alt="">

        <div class="b_list_cont_info" v-class="'b_list_cont_info b'">
          {{$t('Wallet.b_list_box_3.title')}}
          <p v-for="(item,index) in $t('Wallet.b_list_box_3.infos')" v-bind:key="index">
            {{item}}
          </p>
        </div>

      </div>
    </div>
    <div class="b_list_box bgG">
      <div class="b_list_cont">
        <img class="v-hide" v-class="'m b'" src="../assets/img/wallet/image_2.png" alt="">
        <div class="b_list_cont_info v-hide" v-class="'b_list_cont_info b'">
          {{$t('Wallet.b_list_box_4.title')}}
          <p v-for="(item,index) in $t('Wallet.b_list_box_4.infos')" v-bind:key="index">
            {{item}}
          </p>
        </div>
        <img class="v-hide" v-class="'pc b'" src="../assets/img/wallet/image_2.png" alt="">
      </div>
    </div>
    <div class="b_list_w_box">
      <div class="b_list_cont">
        <BigTile />
        <p class="title v-hide" v-class="'title b'"> {{$t('Wallet.RecommendedWallet')}}</p>
        <ul>
          <li v-class="'b'"><a href="http://www.cherrywallet.io/"><img src="../assets/img/wallet/w_1.png" alt=""></a></li>
          <li v-class="'b'"><a href="https://bitpay.com/"><img src="../assets/img/wallet/w_2.png" alt=""></a></li>
          <li v-class="'b'"><a href="https://token.im/"><img src="../assets/img/wallet/w_3.png" alt=""></a></li>
          <li v-class="'b'"><a href="https://trustwallet.com/"><img src="../assets/img/wallet/w_4.png" alt=""></a></li>
          <li v-class="'b'"><a href="https://www.tokenpocket.pro/en/"><img src="../assets/img/wallet/w_5.png" alt=""></a></li>

        </ul>

      </div>
    </div>
  </div>
</template>

<script>

import BigTile from '../components/BigTitle'
export default {
  components: { BigTile },
  name: '',
  data () {
    return {

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    window.scrollTo(0, 0);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.b {
  animation: slideInUp 1s 0s;
  opacity: 1;
  transition-delay: 0s;
  visibility: visible;
}
.v-hide {
  visibility: hidden;
}

.wallet {
  width: 100%;
  min-height: 100vh;
  .c_banner_1_box {
    width: 100%;
    min-height: 35vw;
    background: url("../assets/img/wallet/banner_bg.png") no-repeat 100% 100%;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: center;
    .c_b_1_con {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      text-align: left;
      .info {
        width: 40%;
        text-align: left;
        p.title {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 45px;
          padding-bottom: 55px;
        }
        p {
          font-size: 18px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 100;
          color: #ffffff;
          line-height: 34px;
          padding-bottom: 55px;
        }
      }
    }
  }
  .b_list_box {
    background: #ffffff;
    display: flex;
    justify-content: space-around;
    padding: 100px 0;
    width: 100%;
    .b_list_cont {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div.b_list_cont_info {
        width: 50%;
        text-align: left;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 43px;
      }
      img.m {
        display: none;
      }
    }
  }
  .b_list_w_box {
    background: #ffffff;
    display: flex;
    justify-content: space-around;
    padding: 130px 0;
    width: 100%;
    .b_list_cont {
      width: 1200px;
      p.title {
        text-align: left;
        padding: 50px 0;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 50px;
      }
      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 24%;
          padding-bottom: 50px;
          img {
            width: 100%;
          }
          img:hover {
            box-shadow: #c1c1c1 0px 0px 6px 2px;
          }
        }
      }
    }
  }
  .b_list_img_box {
    background: #ffffff;
    display: flex;
    justify-content: space-around;

    width: 100%;
    .banner_img {
      width: 100%;
    }
    .banner_img_m {
      width: 100%;
      display: none;
    }
  }
  .bgG {
    background: #eeeeee;
  }
}
@media screen and (max-width: 1400px) {
  .wallet {
    width: 100%;
    min-height: 100vh;
    .c_banner_1_box {
      .c_b_1_con {
        width: 1000px;
      }
    }
    .b_list_box {
      .b_list_cont {
        width: 1000px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div.b_list_cont_info {
          width: 50%;
          text-align: left;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 43px;
        }
        img {
          width: 40%;
        }
      }
    }
    .b_list_w_box {
      background: #ffffff;
      display: flex;
      justify-content: space-around;
      padding: 130px 0;
      width: 100%;
      .b_list_cont {
        width: 1000px;
      }
    }
    .b_list_img_box {
      background: #ffffff;
      display: flex;
      justify-content: space-around;

      width: 100%;
      .banner_img {
        width: 100%;
      }
    }
    .bgG {
      background: #eeeeee;
    }
  }
}
@media screen and (max-width: 750px) {
  .wallet {
    width: 100%;
    min-height: 100vh;
    .c_banner_1_box {
      width: 100%;
      min-height: calc(607px);
      margin-top: 60px;

      background: url("../assets/img/wallet/banner_bg_m.png") no-repeat 100%
        100%;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-content: flex-start;
      position: relative;
      .c_b_1_con {
        width: 88%;
        // position: absolute;
        // top: 60px;
        // left: 6%;
        display: flex;
        margin-top: 50px;
        justify-content: space-between;
        align-content: center;
        align-items: flex-start;
        text-align: left;
        height: 100%;
        align-content: flex-start;
        .info {
          width: 100%;
          text-align: center;
          p.title {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 36px;
            padding-bottom: 25px;
            text-align: center;
          }
          p {
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 100;
            color: #ffffff;
            line-height: 28px;
            padding-bottom: 55px;
          }
        }
      }
    }
    .b_list_box {
      background: #ffffff;
      display: flex;
      justify-content: space-around;
      padding: 50px 0;
      width: 100%;
      .b_list_cont {
        width: 88%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;
        div.b_list_cont_info {
          width: 100%;
          text-align: left;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 28px;
        }
        img {
          width: 60%;
          margin: 20px 0;
        }
        img.pc {
          display: none;
        }
        img.m {
          display: block;
        }
      }
    }
    .b_list_w_box {
      background: #ffffff;
      display: flex;
      justify-content: space-around;
      padding: 60px 0;
      width: 100%;
      .b_list_cont {
        width: 88%;
        p.title {
          text-align: left;
          padding: 20px 0;
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 36px;
        }
        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            width: 24%;
            padding-bottom: 20px;
            img {
              width: 100%;
            }
            img:hover {
              box-shadow: #c1c1c1 0px 0px 6px 2px;
            }
          }
        }
      }
    }
    .b_list_img_box {
      background: #ffffff;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      .banner_img {
        width: 100%;
        display: none;
      }
      .banner_img_m {
        width: 100%;
        display: block;
      }
    }
    .bgG {
      background: #eeeeee;
    }
  }
}
</style>