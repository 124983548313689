import Vue from 'vue'
import VueIl8n from 'vue-i18n'
import zh from './zh'
import en from './en'
Vue.use(VueIl8n)

// let getCookie = (name) => {
//   let arrd = null
//   let reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
//   if (document.cookie.match(reg)) {
//     arrd = document.cookie.match(reg)
//     return unescape(arrd[2])
//   } else {
//     return null
//   }
// }

let lang = localStorage.getItem('lan')//''// 'en_US'
if (lang == "zh_CN") {
  console.log('dfd')
  lang = "zh_CN"
} else {
  lang = "en_US"
}
export const i18n = new VueIl8n({
  locale: lang,
  messages: {
    zh_CN: {
      ...zh
    },
    en_US: {
      ...en
    }
  }
})
changeLanguage(lang)
export function changeLanguage (lang) {
  lang = lang
  i18n.locale = lang
  localStorage.setItem('lan', lang)
}